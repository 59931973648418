import axios from "axios";
import useAxios from "../hooks/useAxios";

const baseURL = process.env.REACT_APP_BASE_URL;

let productUrl = "/product";

const useProductAPI = () => {
  const { axiosInstance } = useAxios();

  const getProductList = async (data: any) => {
    let response = await axiosInstance.post(
      baseURL + `${productUrl}/product-list`,
      data
    );
    return response;
  };

  const getProductsListCount = async (data: any) => {
    try {
      const response = await axiosInstance.post(
        `${baseURL}${productUrl}/product-count`,
        data
      );
      return response;
    } catch (error) {
      return error;
    }
  };

  const getProductReview = async (data: any) => {
    try {
      const response = await axiosInstance.post(
        `${baseURL}/review/review-list`,
        data
      );
      return response;
    } catch (error) {
      return error;
    }
  };
  const saveProductReviewAPi = async (data: any) => {
    try {
      const response = await axiosInstance.post(
        `${baseURL}/review/review-save`,
        data
      );
      return response;
    } catch (error) {
      return error;
    }
  };

  const getProductBySlug = async (slug?: any, data?: any) => {
    try {
      const response = await axiosInstance.get(`${baseURL}${productUrl}/slug/${slug}`,data);
      return response;
    } catch (error) {
      return error;
    }
  }

  return {
    getProductList,
    getProductsListCount,
    getProductReview,
    getProductBySlug,
    saveProductReviewAPi
  };
};

export default useProductAPI;
