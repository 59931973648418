import React from "react";
import { Container } from "react-bootstrap";

const RefundPolicy = () => {
    return (
        <div className="privacy-policy-wrapper">
          <Container>
            <h1 style={{ color: "#6C17A4", marginTop: "20px" }}>Return & Refund Policy
            </h1>
            <div>
              <p
                style={{
                  marginTop: '"0in"',
                  marginRight: '"0in"',
                  marginBottom: '"8.0pt"',
                  marginLeft: '"0in"',
                  fontSize: '"F1.0pt"',
                  fontFamily: '"Calibri","sans-serif"',
                  lineHeight: '"normal"',
                  background: '"white"',
                }}
              >
                <span
                  style={{
                    fontSize: "15px",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}
                >


Junction of Tyres Pvt. Ltd. (‘MechMiles’) is committed to providing high-quality tyres and batteries and ensuring a satisfying shopping experience. If you are not completely satisfied with your purchase, please review our return and refund policy outlined below.

                </span>
              </p>
             
              
                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>1. Eligibility for Returns</p>
                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>1.1 Products Eligible for Return:</p>
                  <ul>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Tyres and batteries purchased through our website.
                    </li>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Items must be returned within 30 days from the date of delivery.

                    </li>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Products must be unused, undamaged, and in their original packaging.

                    </li>
                  </ul>
                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>1.2 Non-Returnable Items:</p>
                    <ul>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Items that have been installed or used.

                    </li>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Products that are damaged due to misuse or accidents.


                    </li>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Special order items or customized products.


                    </li>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Any products purchased through promotional discounts or final sale.



                    </li>
                  </ul>
                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>2. Return Process
</p>
                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>2.1 Initiate a Return:</p>

<ul>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Contact our Customer Service team at <a href="mailto:info@mechmiles.com" style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }} title="info@mechmiles.com">info@mechmiles.com</a> or call us at <a href="918104575898" style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }} title="918104575898">[+918104575898].</a>


                    </li>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Provide your order number and details of the product you wish to return.



                    </li>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Our team will guide you through the return process and provide a Return Merchandise Authorization (RMA) number if applicable.
                    </li>

                  </ul>

                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>2.2 Prepare Your Return:</p>

                    <ul>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Repack the product in its original packaging, including all accessories, manuals, and any free gifts received.



                    </li>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Clearly write the RMA number on the outside of the package.




                    </li>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Ensure the package is securely sealed to prevent damage during transit.

                    </li>

                  </ul>
                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>2.3 Return Shipping:</p>

<ul>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Return shipping costs are the responsibility of the customer unless the return is due to an error on our part or a defective product.




                    </li>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>We recommend using a trackable shipping service or purchasing shipping insurance for high-value items.
                    </li>

                  </ul>

                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>3.Refunds
</p>
                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>3.1 Processing Time:</p>


<ul>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Once we receive and inspect the returned item, we will process the refund within 7-10 business days.




                    </li>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Refunds will be issued to the original payment method used during the purchase.

                    </li>
                  

                  </ul>
                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>3.2 Refund Amount:</p>

<ul>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>The refund amount will include the cost of the product and any applicable taxes.





                    </li>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Shipping charges are non-refundable unless the return is due to a mistake on our part.


                    </li>
                  

                  </ul>

                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>4.Exchanges
</p>
                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>4.1 Exchanges:</p>
                  <ul>
   <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>We currently do not offer direct exchanges. If you wish to exchange a product, please return the original item and place a new order for the desired item.



                    </li>
                  

                  </ul>

                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>5.Defective or Incorrect Items

</p>
                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>5.1 Defective Products:</p>


<ul>
   <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>If you receive a defective or damaged product, please contact our Customer Service team within 7 days of delivery.




                    </li>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>We will provide instructions for returning the defective item and will cover return shipping costs



                    </li>

                  </ul>
                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>5.2 Incorrect Items:</p>

                  
<ul>
   <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>If you receive an incorrect item, please contact us within 7 days of delivery.





                    </li>
                    <li style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>We will arrange for the return of the incorrect item and provide the correct item or a refund, as preferred.




                    </li>

                  </ul>

                  
                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>6.Contact Information


</p>
<p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>For any questions or concerns regarding returns and refunds, please contact:



</p>
          
                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}><b>Email:</b><a href="mailto:info@mechmiles.com" title="info@mechmiles.com" style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}> info@mechmiles.com</a></p>
                   <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}><b>Phone:</b><a  href="918104575898" title="918104575898" style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>[+918104575898]</a></p>
<p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}><b>Address:</b>Junction of Tyres Private Limited<br />
                    Adj Dhanajwadi Shop No. 410 B Wingsra Rehab Building Rani Sati Malad(E)-400097, Mumbai, Maharashtra
                    </p>


                    <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>7.Policy Changes</p>
                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Junction of Tyres Pvt. Ltd. (Mechmiles) reserves the right to amend or update this Return & Refund Policy at any time. Any changes will be posted on our website and will apply to all new and existing orders.
                  </p>
                  <p style={{
                    fontSize: "F1.0pt",
                    fontFamily: "Poppins",
                    color: "#6C17A4",
                  }}>Junction of Tyres Pvt. Ltd. (Mechmiles) reserves the right to amend or update this Return & Refund Policy at any time. Any changes will be posted on our website and will apply to all new and existing orders.
                  </p>

            </div>
          </Container>
        </div>
      );
}
export default RefundPolicy;
