import { Image } from "primereact/image";
import Container from "react-bootstrap/Container";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import useHeroAPI from "../../api/useHeroAPI";
import bannerImage from "../../assets/images/banner-image.png";
import carSuvImg from "../../assets/images/car_suv.png";
import bikeImg from "../../assets/images/bike_scooter.png";
import threeWheelerImg from "../../assets/images/three_wheeler.png";
import commercialImg from "../../assets/images/commercial.png";
import image1 from "../../assets/images/TyreBrandLogos/image_1.png";
import image2 from "../../assets/images/TyreBrandLogos/image_2.png";
import image3 from "../../assets/images/TyreBrandLogos/image_3.png";
import image4 from "../../assets/images/TyreBrandLogos/image_4.png";
import image5 from "../../assets/images/TyreBrandLogos/image_5.png";
import image6 from "../../assets/images/TyreBrandLogos/image_6.png";
import image7 from "../../assets/images/TyreBrandLogos/image_7.png";
import image8 from "../../assets/images/TyreBrandLogos/image_8.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { setTabIndex } from "../../redux/tabIndexSlice";
import "./ServicePackages.css";
import { setLoading } from "../../redux/loadingSlice";
import carStand from "../../assets/images/car-stand.png";
import checkIcon from "../../assets/images/check.png";
import useVehicleAPI from "../../api/useVehicleAPI";
import vehicleText from "../../assets/images/vehicleText.png";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { TabView, TabPanel as PrimeTabPanel } from "primereact/tabview";
import carStandMini from "../../assets/images/car-stand-mini.png";
import servicePackageImg from "../../assets/images/service-package-image.png";
import servicePackageCheckImg from "../../assets/images/service-package-check.png";
import { FaRegClock } from "react-icons/fa";
import useAxios from "../../hooks/useAxios";
import usePackageAPI from "../../api/usePackageAPI";
import { addToCart } from "../../features/cart/cartSlice";
import { addToProduct, refreshProductList } from "../../redux/productSlice";
import useCartAPI from "../../api/useCartAPI";
import { isLoggedInState } from "../../redux/userState";
import { setShowLoginDialog } from "../../redux/dialogSlice";
import { MdDoNotDisturb, MdGpsFixed, MdLocationOn, MdOutlineCalendarMonth, MdSearch } from "react-icons/md";
import { CiClock2, CiLocationArrow1, CiLocationOn } from "react-icons/ci";
import { Calendar } from "primereact/calendar";
import HorizontalDatePicker from "../HorizontalDatePicker/HorizontalDatePicker";
import { format, isWithinInterval } from "date-fns";
import { AutoComplete } from "primereact/autocomplete";
import locationPin from "../../assets/images/location_pin.png";
import usePreDefinedListAPI from "../../api/usePreDefinedListAPI";
import useOrderAPI from "../../api/useOrderAPI";
import walletIcon from "../../assets/images/wallet-icon.png";
import dicountIcon from "../../assets/images/discount-icon.png";
import { Checkbox } from "primereact/checkbox";
import useWalletAPI from "../../api/useWalletAPI";

interface Option {
  name: string;
  code: string;
}

interface DropdownOptionsProps {
  brand: Option | null;
  setBrand: React.Dispatch<React.SetStateAction<Option | null>>;
  model: Option | null;
  setModel: React.Dispatch<React.SetStateAction<Option | null>>;
  variant: Option | null;
  setVariant: React.Dispatch<React.SetStateAction<Option | null>>;
  searchData: () => void;
  brands: Option[];
  models: Option[];
  variants: Option[];
}

const DropdownOptions: React.FC<DropdownOptionsProps> = ({
  brand,
  setBrand,
  model,
  setModel,
  variant,
  setVariant,
  searchData,
  brands,
  models,
  variants,
}) => (
  <div className="pb-4 pl-4 pr-4 dropdown-options">
    <Dropdown
      filter
      value={brand}
      onChange={(e: { value: Option }) => setBrand(e.value)}
      options={brands}
      optionLabel="name"
      placeholder="Select a Make"
      className="w-3 banner-dropdown"
      virtualScrollerOptions={{ itemSize: 38 }}
      showClear
    />
    <Dropdown
      filter
      value={model}
      onChange={(e: { value: Option }) => setModel(e.value)}
      options={models}
      optionLabel="name"
      placeholder="Select a Model"
      className="w-3 banner-dropdown"
      virtualScrollerOptions={{ itemSize: 38 }}
      showClear
    />
    <Dropdown
      filter
      value={variant}
      onChange={(e: { value: Option }) => setVariant(e.value)}
      options={variants}
      optionLabel="name"
      placeholder="Select a Variant"
      className="w-3 banner-dropdown"
      virtualScrollerOptions={{ itemSize: 38 }}
      showClear
    />
    <Button
      icon="pi pi-search"
      label="Search"
      onClick={searchData}
      className="w-2 search-button"
    ></Button>
  </div>
);

const ServicePackages = () => {
  const basePath = process.env.REACT_APP_BASE_PATH;

  const { cartSave, moveToWishlist, getPaymentLink, getPaymentStatus } = useCartAPI();
  const { getServicePointList } = usePreDefinedListAPI();
  const { saveCustomOrderAPI } = useOrderAPI()
  const { getWalletBalance } = useWalletAPI();
  const { predefinedFunc } = useHeroAPI();
  const { getPackageFunc } = usePackageAPI();
  const { getVehicleList, saveVehicle } = useVehicleAPI();


  const loading = useSelector((state: any) => state.loading);

  const tabIndex = useSelector((state: RootState) => state.tabIndex);
  const isUserLoggedin = useSelector(isLoggedInState);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const location = useLocation();

  const [selectedService, setSelectedService] = useState(null) as any;
  const [selectedServiceForCheckout, setSelectedServiceForCheckout] = useState(null) as any;
  const [userId, setUserId] = useState(null) as any;
  const [tyreType, setTyreType] = useState<Option | null | string>(null) as any;
  const [productByState, setProductByState] = useState<Option | null | string>(null);
  const [typesPreDefinedState, setTypesPreDefinedState] = useState([]);
  const [selectedTyreType, setSelectedTyreType] = useState("");
  const [tyreBrandImgs, setTyreBrandImgs] = useState([]) as any;
  const [brands, setBrands] = useState<Array<{ name: string; code: string }>>([]);
  const [brand, setBrand] = useState<Option | null>(null);
  const [models, setModels] = useState<Array<{ name: string; code: string }>>([]);
  const [model, setModel] = useState<Option | null>(null);
  const [variant, setVariant] = useState<Option | null>(null);
  const [variants, setVariants] = useState<Array<{ name: string; code: string }>>([]);
  const [cars, setCars] = useState([]) as any;
  const [selectedCarId, setSelectedCarId] = useState(null) as any;
  const [visible, setVisible] = useState(false);
  const [vehicleNumber, setVehicleNumber] = useState(null) as any;
  const [category, setCategory] = useState(null) as any;
  const [categories, setCategories] = useState(null) as any;
  const [make, setMake] = useState(null) as any;
  const [makesTwo, setMakesTwo] = useState(null) as any;
  const [makeTwo, setMakeTwo] = useState(null) as any;
  const [modelsTwo, setModelsTwo] = useState<Array<{ name: string; code: string }>>([]);
  const [modelTwo, setModelTwo] = useState<Option | null>(null);
  const [variantsTwo, setVariantsTwo] = useState<Array<{ name: string; code: string }>>([]);
  const [variantTwo, setVariantTwo] = useState<Option | null>(null);
  const [submitted, setSubmitted] = useState(false) as any;
  const [serviceAndRepairsData, setServiceAndRepairsData] = useState([]) as any;
  const [servicePackages, setServicePackages] = useState([]) as any;
  const [isProceeding, setIsProceeding] = useState(false);
  const [isSchedulingAppointment, setIsSchedulingAppointment] = useState(false);
  const [isLocationSelected, setIsLocationSelected] = useState(false);
  const [selectedServicePoint, setSelectedServicePoint] = useState(null) as any;
  const [servicePoints, setServicePoints] = useState([]) as any;
  const [selectedServicePointData, setSelectedServicePointData] = useState([]) as any;
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [pickedDateState, setPickedDateState] = useState(null) as any;
  const [pickedTime, setPickedTime] = useState(null) as any;
  const [pickedTimeOptions, setPickedTimeOptions] = useState(null) as any;
  const [filteredServicePoint, setFilteredServicePoint] = useState(null) as any;
  const [city, setCity] = useState([]) as any;
  const [hideWalletAndCoupon, setHideWalletAndCoupon] = useState(false) as any;
  const [walletBalance, setWalletBalance] = useState([]) as any;
  const [useWalletBalance, setUseWalletBalance] = useState(false) as any;
  const [currentCouponCode, setCurrentCouponCode] = useState<string>("");

  const fetchVehicleList = async () => {
    try {
      const payload = {
        user_id: userId,
      };

      const response: any = await getVehicleList(payload);

      const vehicleData = response?.data?.map((vehicle: any) => ({
        id: vehicle.id,
        color: vehicle.vehicle_color || "N/A",
        name:
          `${vehicle?.make?.name} ${vehicle?.model?.name} ${vehicle?.variant?.name}` ||
          "Name Not Available",
        licensePlate: vehicle.vehicle_number,
        imageUrl: vehicleText,
        make: vehicle?.make,
        model: vehicle?.model,
        variant: vehicle?.variant,
      }));
      setCars(vehicleData || null);
      setSelectedCarId(vehicleData?.find((car: any) => car.id === location?.state?.selectedCar?.id));
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  };

  const fetchAddress = async () => {
    const authTokens: any = JSON.parse(localStorage.getItem("authTokens") || "{}");
    if (authTokens?.user?.id) {
    }

    const walletBalanceResponse: any = await getWalletBalance(
      parseInt(authTokens.user?.company_id),
      parseInt(authTokens.user?.id)
    );

    if (
      walletBalanceResponse.status === 200 ||
      walletBalanceResponse.status === 201
    ) {
      if (walletBalanceResponse?.data?.balance >= 0) {
        setWalletBalance(walletBalanceResponse?.data?.balance);
      } else {
        setWalletBalance(0);
      }
    }else{
      dispatch(setLoading(false));
    }

  };

  const fetchChildPredefined = async () => {
    const foundTyreType: any = typesPreDefinedState.find(
      (type: any) => type?.name === tyreType?.name
    );

    setSelectedTyreType(foundTyreType?.predefined_id);

    if (
      foundTyreType &&
      foundTyreType?.name !== undefined &&
      foundTyreType?.name !== ""
    ) {
      // let parent_id = null;
      // if (location.pathname === "/") {
      //   parent_id = 8151;
      // } else if (location.pathname === "/batteries") {
      //   parent_id = 8152;
      // }

      // const brandPredefinedPayload = {
      //   entity_type: "BRAND",
      // };
      const makePredefinedPayload = {
        entity_type: "MAKE",
        parent_id: foundTyreType?.predefined_id ?? null,
      };
      const modelPredefinedPayload = {
        entity_type: "MODEL",
        parent_id: brand?.code ?? "",
      };
      const variantPredefinedPayload = {
        entity_type: "VARIANT",
        parent_id: model?.code ?? "",
      };

      // const brandPredefinedResponse = await predefinedFunc(
      //   brandPredefinedPayload
      // );
      const makePredefinedResponse = await predefinedFunc(
        makePredefinedPayload
      );
      const modelPredefinedResponse = await predefinedFunc(
        modelPredefinedPayload
      );
      const variantPredefinedResponse = await predefinedFunc(
        variantPredefinedPayload
      );

      const makeData = makePredefinedResponse?.data?.data.map((child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      });
      const modelData = modelPredefinedResponse?.data?.data.map((child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      });
      const variantData = variantPredefinedResponse?.data?.data.map((child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      });

      // if (brandPredefinedResponse.status === 200) {
      //   dispatch(setLoading(false));
      // }

      if (makeData) {
        setBrands(makeData);
        dispatch(setLoading(false));
      }
      // if (brand !== null) {
      setModels(modelData);
      // }

      // if (model !== null) {
      setVariants(variantData);
      // }
      // setTyreBrandImgs(brandPredefinedResponse?.data?.data);
    }
  };

  const fetchDependentData = async () => {
    dispatch(setLoading(true));
    const modelPredefinedPayload = {
      entity_type: "MODEL",
      parent_id: brand?.code ?? null,
    };
    const variantPredefinedPayload = {
      entity_type: "VARIANT",
      parent_id: model?.code ?? null,
    };
    const modelPredefinedResponse = await predefinedFunc(
      modelPredefinedPayload
    );
    const variantPredefinedResponse = await predefinedFunc(
      variantPredefinedPayload
    );

    if (modelPredefinedResponse.status === 200 &&variantPredefinedResponse.status === 200) {
      dispatch(setLoading(false));
    }else{
      dispatch(setLoading(false));
    }

    if (brand !== null) {
      const modelData = modelPredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      setModels(modelData);
    }

    if (model !== null) {
      const variantData = variantPredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      setVariants(variantData);
    }
  };

  const fetchDependentDataForAdd = async () => {
    if (category !== null) {
      const makePredefinedPayload = {
        entity_type: "MAKE",
        parent_id: category ?? null,
      };
      const makePredefinedResponse = await predefinedFunc(
        makePredefinedPayload
      );
      const makeData = makePredefinedResponse?.data?.data?.map((child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      });
      setMakesTwo(makeData);
    }

    if (makeTwo !== null) {
      const modelPredefinedPayload = {
        entity_type: "MODEL",
        parent_id: makeTwo ?? null,
      };
      const modelPredefinedResponse = await predefinedFunc(
        modelPredefinedPayload
      );
      const modelData = modelPredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      setModelsTwo(modelData);
    }
    if (modelTwo !== null) {
      const variantPredefinedPayload = {
        entity_type: "VARIANT",
        parent_id: modelTwo ?? null,
      };
      const variantPredefinedResponse = await predefinedFunc(
        variantPredefinedPayload
      );
      const variantData = variantPredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      setVariantsTwo(variantData);
    }
  };

  useEffect(() => {
    if (category !== null || makeTwo !== null || modelTwo !== null) {
      fetchDependentDataForAdd();
    }
  }, [category, makeTwo, modelTwo]);

  useEffect(() => {
    if (brand !== null || model !== null) {
      fetchDependentData();
    }
  }, [brand, model]);

  const fetchPredefined = async () => {
    dispatch(setLoading(true));
    const predefinedParentPayload = {
      entity_type: "CATEGORY",
    };
    const predefinedResponse = await predefinedFunc(predefinedParentPayload);
    if (predefinedResponse.status === 200) {
      setTypesPreDefinedState(predefinedResponse.data.data);
      setTyreType(predefinedResponse.data.data[0]);
      setCategories(predefinedResponse.data.data);
      dispatch(setLoading(false));
    }else{
      dispatch(setLoading(false));
    }
    fetchChildPredefined();

    const predefinedServices = {
      entity_type: "SERVICE-TYPE",
    };
    const predefinedServiceResponse = await predefinedFunc(predefinedServices);
    if (predefinedServiceResponse.status === 200) {
      if (location?.state?.variant?.code) {
        setSelectedService(predefinedServiceResponse?.data?.data[0]);
      } else if (location?.state?.service?.predefined_id) {
        const locationService = predefinedServiceResponse?.data?.data?.find((service: any) => service?.predefined_id === location?.state?.service?.predefined_id)
        setSelectedService(locationService);
      }
      setServiceAndRepairsData(predefinedServiceResponse?.data?.data);
    }else{
      dispatch(setLoading(false));
    }

    const cityPayload = {
      entity_type: "CITY",
    };
    const predefinedTimePayload = {
      entity_type: "TIME",
    };
    const predefinedCityResponse = await predefinedFunc(
      cityPayload
    );
    const predefinedTimeResponse = await predefinedFunc(predefinedTimePayload);

    const predefineTIme = predefinedTimeResponse?.data?.data.map(
      (child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      }
    );

    if (predefinedCityResponse.status === 200) {
      setCity(predefinedCityResponse?.data?.data);
    }else{
      dispatch(setLoading(false));
    }
    if (predefinedTimeResponse.status === 200) {
      setPickedTimeOptions(predefineTIme);
    }else{
      dispatch(setLoading(false));
    }
  };

  const fetchPackages = async (vehicle?: any) => {

    let variant_id: any

    if (vehicle?.code) {
      variant_id = vehicle?.code
    } else {
      variant_id = vehicle?.variant?.predefined_id
    }
    const predefinedParentPayload = {
      // sservice_type_id: 8160,
      service_type_id: selectedService?.predefined_id,
      variant_id: variant_id
    };

    const predefinedResponse = await getPackageFunc(predefinedParentPayload);
    if (predefinedResponse.status === 200 || predefinedResponse.status === 201) {
      setServicePackages(predefinedResponse?.data);
    }else{
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    fetchPredefined();

    if (location?.state) {
      setSelectedService(location?.state?.service)
    }

    fetchAddress();
  }, []);

  useEffect(() => {
    if (location.state) {
      const { brand, model, variant } = location.state as { brand: Option, model: Option, variant: Option };
      if (brand) setBrand(brand);
      if (model) setModel(model);
      if (variant) setVariant(variant);
    }
  }, [location]);

  useEffect(() => {
    if (selectedService) {
      fetchPackages(selectedCarId);
    }
  }, [selectedService]);

  useEffect(() => {
    if (tyreType !== null && tyreType?.name !== "") {
      fetchChildPredefined();
    }
  }, [tyreType]);

  useEffect(() => {
    const authTokens = JSON.parse(localStorage.getItem("authTokens") ?? "{}");
    if (authTokens?.user?.id) {
      setUserId(authTokens?.user?.id);
    }

    if (userId) {
      fetchVehicleList();
    }
  }, [userId, isUserLoggedin]);

  const handleTabSelect = (index: number, lastIndex: number, event: Event) => {
    event.preventDefault();
    // event.stopPropagation();
    const newVehicleType: any = typesPreDefinedState[index];
    // setTyreType((event.target as Element).textContent);
    setTyreType(newVehicleType);
    dispatch(setTabIndex(index));
    // setBrands(null);
    // setModels(null);
    // setVariants(null);
    setBrand(null);
    setModel(null);
    setVariant(null);
    dispatch(setLoading(true));
    fetchChildPredefined();
  };

  const handleByTabSelect = (index: any, lastIndex: number, event: Event) => {
    setProductByState((event.target as Element).textContent);
  };

  const searchData = () => {
    // navigate(`${basePath}products`, {
    //   state: { brand, model, variant, tyreType, productByState },
    // });

    fetchPackages(variant)
  };

  const navigateToProductList = (predefined_id: number) => {
    navigate(`${basePath}products`, {
      state: { predefined_id },
    });
  };

  const predefinedImgArr = [carSuvImg, bikeImg, threeWheelerImg, commercialImg];

  const handleSelectCar = (carId: any) => {
    setSelectedCarId(carId);
    fetchPackages(carId);
  };

  const handleCancel = () => {
    setVisible(false);
    setCategory(null);
    setCategories(null);
    setMakeTwo(null);
    setModelTwo(null);
    setVariantTwo(null);
  };
  const handleAddCar = async () => {
    if (!category || !modelTwo || !makeTwo || !variantTwo) {
      setSubmitted(true);
      return;
    }

    const payload = {
      // vehicle_name: vehicleName,
      vehicle_number: vehicleNumber,
      category_id: category,
      model_id: modelTwo,
      make_id: makeTwo,
      variant_id: variantTwo,
    };

    const formData = new FormData();

    formData.append("data", JSON.stringify(payload))

    const response: any = await saveVehicle(formData);

    if (response.status === 200 || response.status === 201) {
      fetchVehicleList();
      setCategory(null);
      setMakeTwo(null);
      setModelTwo(null);
      setVariantTwo(null);
      setVehicleNumber(null)
      setVisible(false);
      return toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `Vehicle Added Successfully`,
      });
    }else{
      dispatch(setLoading(false));
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={handleCancel}
        className="p-button-text"
      />
      <Button
        label="Submit"
        icon="pi pi-check"
        onClick={handleAddCar}
        autoFocus
      />
    </div>
  );

  const paymentStatus = async (paymentId: any) => {
    // console.log("paymentStatus", paymentId);

    const pickedTimeVar: any = pickedTimeOptions.find(
      (time: any) => time.code === pickedTime
    );


    const response: any = await getPaymentStatus(paymentId);
    if (response?.status === 200 || response?.status === 201) {
      if (response?.data?.data?.order_status === "FAILED") {
        return toast?.current?.show({
          severity: "error",
          summary: "Failed",
          detail: `Payment Failed`,
        });
      }
      if (response?.data?.data?.order_status === "SUCCESS") {
        toast?.current?.show({
          severity: "success",
          summary: "Successful",
          detail: `Payment Successful`,
        });

        const payload = {
          user_id: userId,
          service_point_id: selectedServicePointData[0]?.service_point_id,
          service_start_date: format(new Date(pickedDateState), 'yyyy-MM-dd'),
          service_start_time_id: pickedTimeVar?.code,
          vehicle_id: selectedCarId?.id,
          is_use_wallet: useWalletBalance,
          order_items: [
            {
              service_id: selectedServiceForCheckout?.service_id,
              item_quantity: 1,
              item_price: selectedServiceForCheckout?.offer_price,
            }
          ]
        };

        const orderSaveResponse: any = await saveCustomOrderAPI(payload);

        if (
          orderSaveResponse?.status === 200 ||
          orderSaveResponse?.status === 201
        ) {
          setTimeout(() => {
            navigate(`${basePath}profile`);
          }, 500);

          return toast?.current?.show({
            severity: "success",
            summary: "Successful",
            detail: `Payment Successful`,
          });
        }else{
          dispatch(setLoading(false));
        }
      }
    }
  };

  const serviceCheckout = async () => {
    setIsProceeding(false);

    const pickedTimeVar: any = pickedTimeOptions.find(
      (time: any) => time.code === pickedTime
    );

    const payload = {
      user_id: userId,
      service_point_id: selectedServicePointData[0]?.service_point_id,
      service_start_date: format(new Date(pickedDateState), 'yyyy-MM-dd'),
      service_start_time_id: pickedTimeVar?.code,
      vehicle_id: selectedCarId?.id,
      is_use_wallet: useWalletBalance,
      order_items: [
        {
          service_id: selectedServiceForCheckout?.service_id,
          item_quantity: 1,
          item_price: selectedServiceForCheckout?.offer_price,
        }
      ]
    }

    let response: any;

    if (selectedService?.offer_price === 0) {
      response = await saveCustomOrderAPI(payload);
    } else {
      const payload = {
        user_id: userId,
        amount: selectedServiceForCheckout?.offer_price,
        is_wallet: useWalletBalance,
      };

      const responsePayment: any = await getPaymentLink(payload);
      const paymentWindow: any = window?.open(
        responsePayment?.data?.short_url,
        "",
        "toolbar=0,status=0"
      );
      const timer = setInterval(() => {
        if (paymentWindow?.closed) {
          clearInterval(timer);
          paymentStatus(responsePayment?.data?.payment_id);
        }
      }, 1000);
    }
    if (response?.status === 200 || response?.status === 201) {
      setTimeout(() => {
        navigate(`${basePath}profile`);
      }, 500);

      return toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `${response?.data?.status}`,
      });
    }else{
      dispatch(setLoading(false));
    }

  };

  const footerContentTwo = (
    <div className="select-location-footer">
      <Button
        label="Cancel"
        onClick={() => setIsProceeding(false)}
        className="p-button-text select-location-footer-cancel"
      />
      <Button
        label="Confirm Booking"
        onClick={() => serviceCheckout()}
        autoFocus
        disabled={!pickedTime || !pickedDateState}
        className="select-location-footer-confirm"
      />
    </div>
  );



  const handleRemoveLocation = () => {
    setSelectedServicePoint(null);
    setIsLocationSelected(false);
    setIsSchedulingAppointment(false);
    setPickedDateState(null);
  };

  const handleLocationChange = () => {
    setIsSchedulingAppointment(false);
    setPickedDateState(null);
  };

  const handleScheduleAppointment = (e: any, servicePoint: any) => {
    e.preventDefault();
    setSelectedServicePointData([servicePoint]);
    setIsSchedulingAppointment(true);
  };

  const getDirectionFunc = (lat: any, lng: any) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.rel = "noopener noreferrer";

    // Simulate a click on the link
    document.body.appendChild(link);
    link.click();

    // Clean up the DOM
    document.body.removeChild(link);
  };

  const handleDateSelect = (date: Date) => {
    const startOfSelectedDate = new Date(date.setHours(0, 0, 0, 0));
    const startOfToday = new Date(new Date().setHours(0, 0, 0, 0));
    const endOfRange = new Date(
      startOfToday.getTime() + 10 * 24 * 60 * 60 * 1000
    );

    const isDateInRange = isWithinInterval(startOfSelectedDate, {
      start: startOfToday,
      end: endOfRange,
    });

    if (isDateInRange) {
      setPickedDateState(startOfSelectedDate);
    } else {
      setPickedDateState(null);
    }
  };

  const handleTimeChange = (e: any) => {
    setPickedTime(e.value);
  };

  const setSelectedServiceFunc = (option: any) => {
    setSelectedService(option)
  }

  const search = (event: any) => {
    setTimeout(() => {
      let _filteredServicePoint: any;

      if (!event.query.trim().length) {
        _filteredServicePoint = [...city];
      } else {
        _filteredServicePoint = city.filter((country: any) => {
          return country.name
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      setFilteredServicePoint(_filteredServicePoint);
    }, 250);
  };

  const handleLocationSelect = (e: any) => {
    setSelectedServicePoint(e.value);
    if (typeof e.value === "object") {
      setIsLocationSelected(true);
    }
  };

  const fetchServicePoints = async (predefined_id: any) => {
    const payload = {
      city_id: predefined_id,
    };
    const response: any = await getServicePointList(payload, "", "");
    if (response.status === 200) {
      setServicePoints(response.data);
    }else{
      dispatch(setLoading(false));
    }
  };

  const handleLocationSelection = async (e: any) => {
    fetchServicePoints(e.value.predefined_id);
  };

  const itemTemplate = (item: any) => {
    return (
      <div className="auto-complete-text-wrapper">
        <div className="auto-complete-container">
          <Image
            alt={item.name}
            src={locationPin}
            className={`auto-complete-pin-img`}
          // style={{ width: "26px" }}
          />
          <div>{item.name}</div>
        </div>
        <hr />
      </div>
    );
  };

  const useWalletBalanceFunc = async (e: any) => {
    // const isUsingWallet = e.checked === true ? true : null;
    // const response: any = await cartSummery(currentCouponCode ?? '', isUsingWallet);
    // if (response.status === 200) {
    //   setBillingDetails(response?.data);
    // setShowCouponDialog(false);
    setUseWalletBalance(e.checked)
    // }
  }

  const tab1HeaderTemplate = (options: any, item: any) => {
    return (
      <div
        className="tab-header-wrapper"
        style={{ cursor: "pointer" }}
        onClick={() => { options.onClick(); setSelectedServiceFunc(item) }}
      >
        <Image src={item?.url || carStandMini} className="service-packages-tab-image" />
        <div className="service-packages-tab-title">{item?.name}</div>
      </div>
    );
  };

  const handleAddToCart = async (product: any) => {
    if (!userId) {
      setTimeout(() => {
        dispatch(setShowLoginDialog(true));
      }, 1000);

      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Please Login`,
      });
    }

    if (!selectedCarId) {

      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Please Select a car`,
      });
    }

    // navigate("/mechmiles-customer/cart", { state: { product } });

    const payload = {
      service_id: product.service_id,
      item_quantity: 1,
      is_wishList: false,
      vehicle_id: selectedCarId?.id,
    };

    const response: any = await cartSave(payload);

    if (response.status === 201) {
      dispatch(addToCart(product));
      dispatch(addToProduct(product));
      dispatch(refreshProductList());
      fetchPackages(selectedCarId)
      return toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `${product?.service_name} Added to cart`,
      });
    }
  };

  const handleBookNow = async (product: any) => {
    if (!userId) {
      setTimeout(() => {
        dispatch(setShowLoginDialog(true));
      }, 1000);

      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Please Login`,
      });
    }

    if (!selectedCarId) {

      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Please Select a car`,
      });
    }

    // setSelectedService(product);
    setSelectedServiceForCheckout(product);
    setHideWalletAndCoupon(product?.offer_price !== 0)
    setIsProceeding(true);
  };

  return (
    <div className="servicerepair-wrapper">
      <Toast ref={toast} />
      {loading && <div className="loading-overlay">Loading...</div>}
      <div className="servicerepair-image-wrapper">
        <Image src={bannerImage} />
      </div>
      <Container className="home-tab">
        {!userId && (
          <Tabs
            forceRenderTabPanel
            defaultIndex={0}
            onSelect={(index: any, lastIndex: number, event: Event) =>
              handleByTabSelect(index, lastIndex, event)
            }
          >
            <TabList>
              <Tab>By Vehicle</Tab>
            </TabList>
            <TabPanel>
              <Tabs
                forceRenderTabPanel
                onSelect={(index: any, lastIndex: number, event: Event) =>
                  handleTabSelect(index, lastIndex, event)
                }
                selectedIndex={tabIndex}
              >
                <TabList>
                  <div className="internal-home-tab flex w-full justify-content-between">
                    <div>
                      <div className="tab-internal-title">
                        Experience the best vehicle service
                      </div>
                      <div className="tab-internal-sub-title">
                        Get instant quotes for your service
                      </div>
                    </div>
                    <div className="tab-internal-options">
                      {typesPreDefinedState?.map(
                        (types: any, index: number) => {
                          return (
                            <Tab key={index}>
                              <Image
                                src={
                                  tabIndex === index
                                    ? types?.active_thumbnail
                                    : types?.thumbnail
                                }
                                className="tyre-type-images"
                              />
                              {types.name}
                            </Tab>
                          );
                        }
                      )}
                    </div>
                  </div>
                </TabList>
                <TabPanel>
                  <DropdownOptions
                    brand={brand}
                    setBrand={setBrand}
                    model={model}
                    setModel={setModel}
                    variant={variant}
                    setVariant={setVariant}
                    searchData={searchData}
                    brands={brands}
                    models={models}
                    variants={variants}
                  />
                </TabPanel>
                <TabPanel>
                  <DropdownOptions
                    brand={brand}
                    setBrand={setBrand}
                    model={model}
                    setModel={setModel}
                    variant={variant}
                    setVariant={setVariant}
                    searchData={searchData}
                    brands={brands}
                    models={models}
                    variants={variants}
                  />
                </TabPanel>
                <TabPanel>
                  <DropdownOptions
                    brand={brand}
                    setBrand={setBrand}
                    model={model}
                    setModel={setModel}
                    variant={variant}
                    setVariant={setVariant}
                    searchData={searchData}
                    brands={brands}
                    models={models}
                    variants={variants}
                  />
                </TabPanel>
                <TabPanel>
                  <DropdownOptions
                    brand={brand}
                    setBrand={setBrand}
                    model={model}
                    setModel={setModel}
                    variant={variant}
                    setVariant={setVariant}
                    searchData={searchData}
                    brands={brands}
                    models={models}
                    variants={variants}
                  />
                </TabPanel>
              </Tabs>
            </TabPanel>
          </Tabs>
        )}
        {userId && (
          <div className="services-vehicle-list-wrapper">
            <div className="services-vehicle-list-title-wrapper">
              <div className="services-vehicle-list-title">
                Experience the best car service
              </div>
              <Button
                className="services-vehicle-list-add-new"
                onClick={() => setVisible(true)}
              >
                New Vehicle ?
              </Button>
            </div>
            <div className="select-your-car-wrapper">
              <div className="select-your-car-title">Select your car</div>
              <div className="select-your-car-container">
                <div className="select-your-car-wrapper">
                  <div className="select-your-car-header">
                    <h2>
                      Select Vehicle <span className="error">*</span>
                    </h2>
                    <p>Please select a Vehicle</p>
                  </div>
                  <div className="car-list">
                    {cars?.length === 0 && (
                      <div className="no-car-found-wrapper">
                        <div className="no-car-found-title">
                          No Car Found
                        </div>
                        <div className="no-car-found-sub-title">
                          Please add a new vehicle
                        </div>
                      </div>
                    )}
                    {cars?.map((car: any) => (
                      <div
                        key={car.id}
                        className={`car-item-wrapper ${selectedCarId?.id === car.id ? "selected-car" : ""
                          }`}
                        onClick={() => handleSelectCar(car)}
                      >
                        <div className="car-item">
                          <Image
                            src={car?.imageUrl}
                            alt={`${car?.name}`}
                            className="car-image"
                            height="108px"
                            width="103px"
                          />
                          <div className="car-data">
                            <div className="vehicle-make">{car?.make?.name}</div>
                            <div className="vehicle-model">{car?.model?.name}</div>
                            <div className="vehicle-variant">
                              {car?.variant?.name}
                            </div>
                            <p>{car.licensePlate}</p>
                          </div>
                        </div>
                        {selectedCarId?.id === car?.id && (
                          <div className="check-icon-wrapper">
                            <Image
                              src={checkIcon}
                              alt="Select Icon"
                              height="26px"
                              width="26px"
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="servicerepair-section-two">
          <div className="service-packages-wrapper">
            <div className="service-packages-wrapper-title">
              Service Packages
            </div>
            <div className="service-packages-container">
              <TabView className="service-packages-tab-view" >
                {serviceAndRepairsData.length > 0 && serviceAndRepairsData.map((item: any) => (
                  <PrimeTabPanel key={item?.predefined_id} headerTemplate={(options) => tab1HeaderTemplate(options, item)} >
                    <div className="service-packages-tab-content grid gap-2">
                      <div className="service-packages-tab-content-title col-12">
                        {item?.name}
                      </div>
                      {servicePackages?.length > 0 && servicePackages.map((servicePackage: any) => (
                        <div key={servicePackage?.service_id} className="single-service-package-wrapper col-12">
                          {servicePackage?.tag === "Recommended" ? <span className="package-tag">{servicePackage?.tag}</span> : null}
                          <Image
                            src={servicePackage?.service_type?.url ? servicePackage.service_type.url : servicePackageImg}
                            className="single-service-package-img"
                          />
                          <div className="single-service-package-container">
                            <div className="single-service-package-approx-time">
                              <FaRegClock /> Approx time:
                              {servicePackage?.approx_time}
                            </div>
                            <div className="single-service-package-title">
                              {servicePackage?.service_name}
                            </div>
                            <div className="single-service-package-sub-title">
                              <ul>
                                {servicePackage?.service_feature?.filter((feature: any) => feature.entity_type === "PRIMARY").map((feature: any) => (
                                  <li key={feature.service_feature_id}>{feature.feature_name}</li>
                                ))}
                              </ul>
                            </div>
                            <hr />
                            <div className="single-service-package-services-wrapper">

                              {servicePackage?.service_feature?.filter((feature: any) => feature.entity_type === "SECONDARY").map((feature: any) => (
                                <div
                                  key={feature.service_feature_id}
                                  className="single-service-package-services-container"
                                >
                                  <Image
                                    src={servicePackageCheckImg}
                                    className="single-service-package-check-image"
                                  />
                                  <div className="single-service-package-services-title">
                                    {feature.feature_name}
                                  </div>
                                </div>
                              ))}
                            </div>
                            <hr />
                            <div className="single-service-package-price-wrapper">
                              <div>
                                {(variant || selectedCarId) && <div className="single-service-package-price-container">
                                  <div className="single-service-package-price">
                                    &#8377; {servicePackage.offer_price}
                                  </div>
                                  <div className="single-service-package-cancelled-price">
                                    <s>&#8377; {servicePackage.price}</s>
                                  </div>
                                </div>}
                              </div>
                              <div className="single-service-package-add-to-cart">
                                {/* {(!variant || !location?.state?.selectedCar?.id) && <Button label="Select Vehicle" />} */}
                                {servicePackage?.service_entity_type === 'CUSTOM' && <Button label={(variant || selectedCarId) ? "Book Now" : "Select Vehicle"} onClick={() => handleBookNow(servicePackage)} />}
                                {servicePackage?.service_entity_type === 'FIXED' && <Button label={(variant || selectedCarId) ? "Add to Cart" : "Select Vehicle"} onClick={() => handleAddToCart(servicePackage)} />}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </PrimeTabPanel>
                ))}
              </TabView>
            </div>
          </div>
        </div>
      </Container>
      <Dialog
        header="Add a New Car"
        footer={footerContent}
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "90%" }}
      >
        <div className="add-new-car-wrapper grid">
          <div className="field col-6 vehicle-number">
            <label htmlFor="vehicle-number">Vehicle Number</label>
            <InputText
              className="w-full"
              value={vehicleNumber}
              onChange={(e) => setVehicleNumber(e.target.value)}
              placeholder="Vehicle Number"
              id="vehicle-number"
            />
          </div>
          <div className="field col-6 vehicle-category">
            <label>
              Category <span className="error">*</span>
            </label>
            <Dropdown
              className="w-full"
              value={category}
              options={categories}
              onChange={(e) => setCategory(e.value)}
              placeholder="Select a Category"
              optionValue="predefined_id"
              optionLabel="name"
              showClear
            />
            {submitted && !category && (
              <small className="error">Category is Required</small>
            )}
          </div>
          <div className="field col-6 vehicle-make">
            <label>
              Make <span className="error">*</span>
            </label>
            <Dropdown
              className="w-full"
              value={makeTwo}
              options={makesTwo}
              onChange={(e) => setMakeTwo(e.value)}
              placeholder="Select a Make"
              optionValue="code"
              optionLabel="name"
              filter
              showClear
            />
            {submitted && !makeTwo && (
              <small className="error">Make is Required</small>
            )}
          </div>
          <div className="field col-6 vehicle-model">
            <label>
              Model <span className="error">*</span>
            </label>
            <Dropdown
              className="w-full"
              filter
              value={modelTwo}
              options={modelsTwo}
              onChange={(e) => setModelTwo(e.value)}
              placeholder="Select a Model"
              optionValue="code"
              optionLabel="name"
              showClear
            />
            {submitted && !modelTwo && (
              <small className="error">Model is Required</small>
            )}
          </div>
          <div className="field col-6 vehicle-variant">
            <label>
              Variant <span className="error">*</span>
            </label>
            <Dropdown
              className="w-full"
              value={variantTwo}
              options={variantsTwo}
              onChange={(e) => setVariantTwo(e.value)}
              placeholder="Select a Variant"
              optionValue="code"
              optionLabel="name"
              showClear
              filter
            />
            {submitted && !variantTwo && (
              <small className="error">Variant is Required</small>
            )}
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Select a service point"
        visible={isProceeding}
        style={{ width: "70%" }}
        onHide={() => setIsProceeding(false)}
        footer={isSchedulingAppointment ? footerContentTwo : ""}
        className="is-proceeding-header"
      >
        {isLocationSelected && !isSchedulingAppointment && (
          <div className="selected-location-container">
            <div className="selected-location-wrapper">
              <div className="selected-location">
                <MdLocationOn className="selected-location-icon" />
                <span className="selected-location-text">
                  {selectedServicePoint?.name}
                </span>
              </div>
              <Button
                className="change-location-button"
                onClick={handleRemoveLocation}
              >
                Change Location
              </Button>
            </div>
            <div className="store-near-location-wrapper">
              <div className="store-near-location-title">
                Stores Near Your Location
              </div>
              <div className="store-near-location-list">
                {servicePoints.map((servicePoint: any, index: number) => (
                  <div className="service-point-card" key={index}>
                    <div className="service-point-name-wrapper">
                      <div className="service-point-name">
                        {servicePoint.service_point_name}
                      </div>
                      <div className="service-point-distance">
                        {/* {servicePoint.distance || "Distance"} */}
                      </div>
                    </div>
                    <div className="service-point-meta-wrapper">
                      <div className="service-point-opens">
                        <CiClock2 />
                        {`Opens At ${servicePoint.service_point_open_time || "Time"
                          }`}
                      </div>
                      <div className="service-point-address">
                        <CiLocationOn />
                        {servicePoint.service_point_address || "Address"}
                      </div>
                    </div>
                    {/* <div className="service-point-rating">
                      <StarPicker
                        value={parseInt(servicePoint.rating) || "2.5"}
                        halfStars
                        disabled={true}
                        size={15}
                      />
                      {servicePoint.rating || "0"}
                    </div> */}
                    <div className="service-point-actions">
                      <div className="service-point-actions-btns">
                        <Button
                          className="get-direction-button"
                          icon={
                            <CiLocationArrow1 style={{ fontSize: "16px" }} />
                          }
                          label="Get Direction"
                          onClick={() =>
                            getDirectionFunc(servicePoint.lat, servicePoint.lng)
                          }
                        />
                        <Button
                          className="schedule-appointment-button"
                          icon={
                            <MdOutlineCalendarMonth
                              style={{ fontSize: "16px" }}
                            />
                          }
                          label="Schedule Appointment"
                          onClick={(e) =>
                            handleScheduleAppointment(e, servicePoint)
                          } // Pass the servicePoint here
                        />
                      </div>
                      <div className="service-point-actions-img-container">
                        {servicePoint?.service_group?.image_url && (
                          <Image
                            src={servicePoint?.service_group?.image_url}
                            className="service-point-actions-img"
                          />
                        )}
                        {!servicePoint?.service_group?.image_url && (
                          <MdDoNotDisturb
                            style={{
                              height: "25px",
                              width: "25",
                              marginTop: "5px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {isLocationSelected && isSchedulingAppointment && (
          <div className="selected-location-container">
            <div className="selected-location-wrapper">
              <div className="selected-location">
                <MdLocationOn className="selected-location-icon" />
                <span className="selected-location-text">
                  {selectedServicePoint?.name}
                </span>
              </div>
              <Button
                className="change-location-button"
                onClick={handleRemoveLocation}
              >
                Change Location
              </Button>
            </div>
            <div className="store-near-location-wrapper">
              <div className="store-near-location-wrapper">
                <div className="store-near-location-title">
                  Stores Near Your Location
                </div>
                <div className="store-near-location-list">
                  {selectedServicePointData.map(
                    (servicePoint: any, index: number) => (
                      <div className="selected-store-point-wrapper">
                        <div className="store-point-info">
                          {/* <img
                            src={servicePoint.image || servicePointImage}
                            alt={servicePoint.service_point_name}
                            className="store-station-image"
                          /> */}
                          <div className="store-point-details">
                            <div className="store-point-name">
                              {servicePoint.service_point_name}
                            </div>
                            <div className="store-point-address">
                              {servicePoint.service_point_address ||
                                "Address Not Available"}
                            </div>
                          </div>
                        </div>
                        <div className="change-store-button-wrapper">
                          <Button
                            className="change-store-button"
                            onClick={handleLocationChange}
                          >
                            Change Service Point
                          </Button>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div className="schedule-your-appointment-wrapper">
                  <div className="store-near-location-title">
                    Schedule your appointment
                  </div>
                  <div className="schedule-your-appointment-container">
                    <div className="pick-a-date-wrapper width-50">
                      <div className="pick-a-date-title">
                        Pick A Date
                        <Calendar
                          id="pick-a-date-calendar"
                          value={pickedDateState}
                          onChange={(e: any) => setPickedDateState(e.value)}
                          showIcon
                          className="pick-a-date-calendar"
                          placeholder="Select Manually"
                          minDate={new Date()}
                        />
                      </div>
                      <div className="horizontal-pick-a-date">
                        <HorizontalDatePicker
                          startDate={
                            new Date(
                              new Date().setDate(new Date().getDate() + 4)
                            )
                          }
                          endDate={
                            new Date(
                              new Date().getTime() + 10 * 24 * 60 * 60 * 1000
                            )
                          }
                          onDateSelect={handleDateSelect}
                          externalSelectedDate={pickedDateState}
                        />
                      </div>
                    </div>
                    <div className="pick-a-time-wrapper width-50">
                      <div className="pick-a-time-title-wrapper">
                        <div className="pick-a-time-title">Pick A Time</div>
                        <div className="pick-a-time-calender-wrapper">
                          <Dropdown
                            value={pickedTime}
                            onChange={(e) => handleTimeChange(e)}
                            options={pickedTimeOptions}
                            virtualScrollerOptions={{ itemSize: 38 }}
                            optionLabel="name"
                            optionValue="code"
                            placeholder="Select Item"
                            className="pick-a-time-dropdown w-full"
                          />
                        </div>
                      </div>
                    </div>
                    {hideWalletAndCoupon && (
                      <div className="wallet-discount-wrapper col-6">
                        <div className="wallet-container  wallet-container-2">
                          <div className="wallet-title-wrapper">
                            <Image
                              src={walletIcon}
                              alt="walletIcon"
                              className="wallet-icon"
                            />
                            <div className="wallet-text-wrapper">
                              <div className="wallet-title">Wallet</div>
                              <div className="wallet-amount">
                                Wallet Balance: &#8377;{walletBalance ?? 0}
                              </div>
                            </div>
                          </div>
                          <div className="wallet-check-box">
                            <label htmlFor="wallet-balance">
                              Use wallet balance
                            </label>
                            <Checkbox
                              onChange={useWalletBalanceFunc}
                              checked={useWalletBalance}
                              inputId="wallet-balance"
                            ></Checkbox>
                          </div>
                        </div>
                        {/* <div className="discount-container">
                          <div className="wallet-title-wrapper">
                            <Image
                              src={dicountIcon}
                              alt="walletIcon"
                              className="wallet-icon"
                            />
                            <div className="wallet-text-wrapper">
                              <div className="wallet-title">Discount promocode</div>
                              <div
                                className="wallet-amount coupon-view-all"
                                onClick={fetchAllCouponFunc}
                              >
                                View All
                              </div>
                            </div>
                          </div>
                          <div className="wallet-check-box">
                            <span className="p-input-icon-right discount-input-wrapper">
                              <InputText
                                placeholder="Enter Promocode"
                                value={currentCouponCode}
                                onChange={(e: any) => setCurrentCouponCode(e.target.value)}
                              />
                              <Button
                                label="Apply"
                                className="discount-apply-btn"
                                onClick={fetchCartSummary}
                              />
                            </span>
                          </div>
                        </div> */}
                      </div>
                    )}
                    <div className="schedule-appointment-wrapper">
                      <div className="schedule-appointment-title">
                        Special Instruction
                      </div>
                      <InputText
                        value={specialInstructions}
                        onChange={(e: any) =>
                          setSpecialInstructions(e.target.value)
                        }
                        className="schedule-appointment-input"
                        placeholder="Enter Special Instruction"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isLocationSelected && (
          <span className="w-full">
            <AutoComplete
              field="name"
              value={selectedServicePoint}
              suggestions={filteredServicePoint}
              completeMethod={search}
              onChange={(e) => handleLocationSelect(e)}
              itemTemplate={itemTemplate}
              // panelFooterTemplate={panelFooterTemplate}
              className="service-point-auto-complete"
              placeholder="Enter a location"
              onSelect={(e) => handleLocationSelection(e)}
            />
            <div className="auto-complete-icons-wrapper p-input-icon-right">
              <MdSearch className="auto-complete-search-location" />
              <MdGpsFixed className="auto-complete-choose-location" />
            </div>
          </span>
        )}
      </Dialog>
    </div>
  );
};

export default ServicePackages;
