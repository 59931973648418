import React, { ComponentType } from 'react';
import { Navigate } from 'react-router-dom';

interface AuthGuardProps {
  isAuthenticated: boolean;
  component: ComponentType<any>;
}

const AuthGuard: React.FC<AuthGuardProps> = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => {
  let token = localStorage.getItem('authTokens');
  return token ? <Component {...rest} /> : <Navigate to="/" />;
};

export default AuthGuard;
