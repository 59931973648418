import { Image } from "primereact/image";
import { Container } from "react-bootstrap";

import downloadAppImage from "../../assets/images/download-app-img-2.png";
import playStoreBtn from "../../assets/images/playstore-btn.png";
import appStoreBtn from "../../assets/images/appstore-btn.png";
import customerQR from "../../assets/images/customer_qr.svg";
import customerPlayStore from "../../assets/images/playstore.png";

import posQR from "../../assets/images/pos_qr.svg";

import "./DownloadApp.css";

const DownloadApp = () => {
  return (
    <div>
      <Container>
        <div className="download-app-wrapper">
          <div className="download-app-text-container">
            <div className="download-app-text">Download our App</div>
            <div className="download-app-desc">
              Experience MechMiles on the go with our mobile application!
              Explore the realms of MechMiles right at your fingertips. Simply
              scan the QR code to download the app and embark on your journey
              with us.
            </div>
            <div className="store-btns">
              <div className="download-app-img">
                <Image src={playStoreBtn} alt="franchise Image" />
              </div>
              <div className="download-app-img">
                <Image src={appStoreBtn} alt="franchise Image" />
              </div>
            </div>
            <hr />
            <div className="qr-wrapper">
              <div className="download-app-img">
                <div className="qr-wrap">
                  <Image src={customerPlayStore} alt="franchise Image" />
                  <span className="qr-title">Android</span>
                </div>
                <div className="qr-wrap">
                  <Image src={customerQR} alt="Scan QR for" />
                  <span className="qr-title">iOS</span>
                </div>
              </div>
              <div className="download-app-desc">
                Scan the QR to download the application
              </div>
            </div>
          </div>
          <div className="download-app-img-banner">
            <Image src={downloadAppImage} alt="franchise Image" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DownloadApp;
