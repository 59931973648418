import React, { useEffect, useRef, useState } from "react";
import "./MyAddress.css";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import locationPinImage from "../../../assets/images/address-pin.png";
import { BsThreeDotsVertical } from "react-icons/bs";
import useUserAPI from "../../../api/useUserAPI";
import useHeroAPI from "../../../api/useHeroAPI";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import usePreDefinedListAPI from "../../../api/usePreDefinedListAPI";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/loadingSlice";
import { Paginator } from "primereact/paginator";

interface Address {
  address_id?: number;
  detail_address: string;
  street_address: string;
  landmark: string;
  pincode: string;
}

const MyAddress = () => {
  const {
    getUserAddress,
    saveUserAddress,
    markAsDefaultUserAddress,
    updateUserAddress,
    deleteUserAddress,
    getUserAddressCount,
  } = useUserAPI();
  const { predefinedFunc } = useHeroAPI();

  const toast = useRef<Toast>(null);
  const loading = useSelector((state: any) => state.loading);
  const dispatch = useDispatch();

  const addressTypeOptions = [
    { key: "BILLING", label: "Billing" },
    { key: "SHIPPING", label: "Shipping" },
  ];

  const defaultAddressOptions = [
    { name: "Yes", key: "true" },
    { name: "No", key: "false" },
  ];

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false);
  const [isEditingNewAddress, setIsEditingNewAddress] = useState(false);
  const [addressList, setAddressList] = useState([]) as any;
  const [userId, setUserId] = useState(null) as any;
  const [selectedMenuIndex, setSelectedMenuIndex] = useState<number | null>(
    null
  );
  const [submitted, setSubmitted] = useState(false) as any;
  const [selectedCity, setSelectedCity] = useState(null) as any;
  const [selectedState, setSelectedState] = useState(null) as any;
  const [selectedCountry, setSelectedCountry] = useState(null) as any;
  const [cityOptions, setCityOptions] = useState(null) as any;
  const [stateOptions, setStateOptions] = useState(null) as any;
  const [countryOptions, setCountryOptions] = useState(null) as any;
  const [deleteAddressDialogState, setDeleteAddressDialogState] = useState(
    null
  ) as any;
  const [address, setAddress] = useState<Address>({
    detail_address: "",
    street_address: "",
    landmark: "",
    pincode: "",
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPageOption, setRowsPerPageOption] = useState(10);
  const [records, setRecords] = useState(20);
  const [userRole, setUserRole] = useState("") as any;

  const showConfirmDeleteDialog = (addressToDelete: any) => {
    setDeleteAddressDialogState(addressToDelete);
  };

  // const deleteAddressConfirmed = async () => {
  //   if (deleteAddressDialogState) {
  //     await deleteVehicleFunc(deleteAddressDialogState);
  //     confirmDeleteDialog.current?.hide();
  //   }
  // };

  const [isDefaultAddress, setIsDefaultAddress] = useState<
    (typeof defaultAddressOptions)[number] | null
  >(null);

  const [addressType, setAddressType] = useState("BILLING");

  const toggleMenu = (index: number) => {
    if (selectedMenuIndex === index) {
      // If the same menu is clicked, close it
      setSelectedMenuIndex(null);
    } else {
      // Open the clicked menu
      setSelectedMenuIndex(index);
    }
  };

  const markAsDefaultAddressFunc = async (address: any, type: any) => {
    const payload = {
      entity_type: type,
      user_id: userId,
      address_id: address.address_id,
    };
    const response = await markAsDefaultUserAddress(payload);

    if (response.status === 201) {
      fetchUsersAddresses();
      setSelectedMenuIndex(null);
      return toast?.current?.show({
        severity: "success",
        summary: "Successful",
        detail: `${response?.data?.message}`,
      });
    }
  };

  const editAddressFunc = (address: any) => {
    setSelectedMenuIndex(null);
    setIsEditingNewAddress(true);
    setAddress(address);
    setSelectedCity(address.city.predefined_id);
    setSelectedState(address.state.predefined_id);
    setSelectedCountry(address.country.predefined_id);
    const defaultOption =
      defaultAddressOptions.find(
        (option) => option.key === String(address.is_default)
      ) ?? null;
    setIsDefaultAddress(defaultOption);
    setIsAddingNewAddress(true);
  };

  const deleteAddressConfirmed = async () => {
    const payload = {};

    const response = await deleteUserAddress(
      deleteAddressDialogState.address_id,
      payload
    );
    if (response.status === 201) {
      fetchUsersAddresses();
      setDeleteAddressDialogState(null);
      return toast?.current?.show({
        severity: "success",
        summary: "Deleted Successful",
        detail: `${response?.data?.message}`,
      });
    }
  };

  const renderAddressMenu = (address: any, index: number) => {
    console.log(userRole.code, "address", address.entity_type);
    return (
      <div
        className={`address-menu ${
          selectedMenuIndex === index ? "visible" : ""
        }`}
      >
        <ul>
          {!address.is_default && (
            <>
              {userRole.code !== "ROLE_CUSTOMER" &&
                address.entity_type === "SHIPPING" && (
                  <li
                    onClick={() => {
                      markAsDefaultAddressFunc(address.entity_type, "SHIPPING");
                    }}
                  >
                    Make default shipping address
                  </li>
                )}
              <li
                onClick={() => {
                  markAsDefaultAddressFunc(address.entity_type, "BILLING");
                }}
              >
                Make default billing address
              </li>
            </>
          )}
          <li
            onClick={() => {
              editAddressFunc(address);
            }}
          >
            Edit
          </li>
          <li onClick={() => showConfirmDeleteDialog(address)}>Delete</li>
        </ul>
      </div>
    );
  };

  const fetchUsersAddresses = async () => {
    const payload = {
      user_id: userId,
      page_number: pageNumber,
      page_size: rowsPerPageOption,
    };
    // const payload = {};

    const response = await getUserAddress(payload);
    const countResponse = await getUserAddressCount(payload);
    if (response?.status === 200) {
      dispatch(setLoading(false));
      setAddressList(response?.data);
      setRecords(countResponse?.data?.total_count);
    }
  };

  const fetchPredefinedList = async () => {
    const cityPredefinedPayload = {
      entity_type: "CITY",
    };
    const cityPredefinedResponse = await predefinedFunc(cityPredefinedPayload);
    const citiesData = cityPredefinedResponse?.data?.data.map((child: any) => {
      return {
        name: child.name,
        code: child.predefined_id,
      };
    });
    setCityOptions(citiesData);

    const statePredefinedPayload = {
      entity_type: "STATE",
    };
    const statePredefinedResponse = await predefinedFunc(
      statePredefinedPayload
    );
    const statesData = statePredefinedResponse?.data?.data.map((child: any) => {
      return {
        name: child.name,
        code: child.predefined_id,
      };
    });
    setStateOptions(statesData);

    const countryPredefinedPayload = {
      entity_type: "COUNTRY",
    };
    const countryPredefinedResponse = await predefinedFunc(
      countryPredefinedPayload
    );
    const countriesData = countryPredefinedResponse?.data?.data.map(
      (child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      }
    );
    setCountryOptions(countriesData);
  };

  useEffect(() => {
    dispatch(setLoading(true));
    fetchPredefinedList();
    const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    if (authTokens?.user) {
      setUserId(authTokens?.user?.id);

      const userRole = authTokens?.user?.roles[0];
      if (userRole) setUserRole(userRole);
      // setUserId(1);
    }
    if (userId) {
      fetchUsersAddresses();
    }
  }, [userId, pageNumber, rowsPerPageOption]);

  const hideMenu = () => {
    setIsMenuVisible(false);
  };

  const handleCancel = () => {
    setAddress({
      detail_address: "",
      street_address: "",
      landmark: "",
      pincode: "",
    });
    setSelectedCity(null);
    setSelectedState(null);
    setSelectedCountry(null);
    setIsDefaultAddress(null);
    setIsAddingNewAddress(false);
    setIsEditingNewAddress(false);
  };

  const handleNewAddressChange = async () => {
    if (
      address.detail_address === "" ||
      address.street_address === "" ||
      address.landmark === "" ||
      address.pincode === "" ||
      !selectedCity ||
      !selectedState ||
      !selectedCountry
    ) {
      setSubmitted(true);
      return;
    }

    if (address.address_id) {
      const payload = {
        detail_address: address.detail_address,
        street_address: address.street_address,
        landmark: address.landmark,
        pincode: address.pincode,
        entity_type: addressType,
        is_default: isDefaultAddress?.key === "true" ? true : false,
        city_id: selectedCity,
        state_id: selectedState,
        country_id: selectedCountry,
      };

      try {
        const response = await updateUserAddress(address.address_id, payload);
        if (response.status === 201) {
          fetchUsersAddresses();
          setIsAddingNewAddress(false);
          handleCancel();
          toast?.current?.show({
            severity: "success",
            summary: "Update Successful",
            detail: "Address updated successfully",
          });
        }
      } catch (error) {
        toast?.current?.show({
          severity: "error",
          summary: "Update Failed",
          detail: "Failed to update address",
        });
      }
    } else {
      const payload = {
        detail_address: address?.detail_address,
        street_address: address?.street_address,
        landmark: address?.landmark,
        pincode: address?.pincode,
        entity_type: addressType,
        is_default: isDefaultAddress?.key === "true" ? true : false,
        city_id: selectedCity,
        state_id: selectedState,
        country_id: selectedCountry,
      };
      const response = await saveUserAddress(payload);
      if (response.status === 200 || response.status === 201) {
        fetchUsersAddresses();
        setIsAddingNewAddress(false);
        setSubmitted(false);
        handleCancel();
        return toast?.current?.show({
          severity: "success",
          summary: "Successful",
          detail: `${response.data.message}`,
        });
      }
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        // icon="pi pi-times"
        onClick={handleCancel}
        className="p-button-text"
      />
      <Button
        label="Submit"
        // icon="pi pi-check"
        onClick={handleNewAddressChange}
        autoFocus
      />
    </div>
  );

  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const { value } = e.target;
    if (name === "pincode" && isNaN(Number(value))) {
      return;
    }
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const addingNewAddressFunc = () => {
    setIsEditingNewAddress(false);
    setIsAddingNewAddress(true);
  };

  const onPageChange = (e: any) => {
    const newPageNumber = e.first / e.rows + 1;
    setPageNumber(newPageNumber);
    // setCurrentPage(newPageNumber);
    setRowsPerPageOption(e.rows);
  };

  const paginatorTemplate = {
    layout:
      "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",

    CurrentPageReport: (options: any) => {
      return (
        <span
          style={{
            color: "#6c757d",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
            display: "block",
          }}
        >
          {options.first} to {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <>
      {loading && <div className="loading-overlay">Loading...</div>}
      <Toast ref={toast} />
      <div className="my-address-wrapper">
        <div className="my-address-title-wrapper">
          <div className="my-address-title-name">Address</div>
          <div className="my-address-title-add-new-btn">
            <Button
              label={"Add Address"}
              className="add-new-address-btn"
              onClick={addingNewAddressFunc}
            />
          </div>
        </div>
        <div className="my-address-list-wrapper">
          {addressList.length === 0 && <div>No address Available</div>}
          <div className="my-address-list-wrapper">
            {addressList?.map((address: any, index: number) => (
              <div key={address.id} className="my-address-single-address">
                <Image
                  src={locationPinImage}
                  className="my-address-pin-image"
                />
                <div className="my-address-title-container">
                  <div className="my-address-title">Address</div>
                  <div className="my-address-data">
                    {`${address.detail_address ?? ""}, ${
                      address.street_address ?? ""
                    }, ${address.landmark ?? ""},`}
                    <br />
                    {`${address.city.name ?? ""} - ${address.pincode ?? ""}`}
                  </div>
                </div>
                {address.is_default && (
                  <div
                    className={`my-address-default-tag ${
                      address.entity_type === "BILLING"
                        ? "default-billing-address"
                        : "default-shipping-address"
                    }`}
                  >
                    {address.entity_type === "BILLING"
                      ? "Billing Address"
                      : "Shipping Address"}
                  </div>
                )}
                <div
                  className="my-address-edit-btn"
                  onClick={() => toggleMenu(index)}
                >
                  <BsThreeDotsVertical />
                </div>
                {selectedMenuIndex === index &&
                  renderAddressMenu(address, index)}
              </div>
            ))}
            <Paginator
              first={(pageNumber - 1) * rowsPerPageOption}
              rows={rowsPerPageOption}
              totalRecords={records}
              rowsPerPageOptions={[5, 10, 25, records]}
              onPageChange={onPageChange}
              template={paginatorTemplate}
            />
          </div>
        </div>
      </div>
      <Dialog
        header={isEditingNewAddress ? "Edit Address" : "Add New Address"}
        footer={footerContent}
        visible={isAddingNewAddress}
        onHide={handleCancel}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "90%" }}
      >
        <div className="address-dialog-wrapper">
          <div className="grid">
            {!isEditingNewAddress && (
              <div className="field col-6">
                <label htmlFor="detail_address">
                  Address Type<span className="error">*</span>
                </label>
                {/* <div className="field-address-group">
                  {addressTypeOptions.map((option) => (
                    <div key={option.key} className="field-radiobutton">
                      <RadioButton
                        inputId={option.key}
                        name="addressType"
                        value={option.key}
                        onChange={(e) => setAddressType(e.value)}
                        checked={addressType === option.key}
                      />
                      <label htmlFor={option.key}>{option.label}</label>
                    </div>
                  ))}
                </div> */}

                <div className="field-address-group">
                  {addressTypeOptions.map(
                    (option) =>
                      // Only render the Shipping radio button if the user role is not ROLE_CUSTOMER
                      !(
                        userRole.code === "ROLE_CUSTOMER" &&
                        option.key === "SHIPPING"
                      ) && (
                        <div key={option.key} className="field-radiobutton">
                          <RadioButton
                            inputId={option.key}
                            name="addressType"
                            value={option.key}
                            onChange={(e) => setAddressType(e.value)}
                            checked={addressType === option.key}
                          />
                          <label htmlFor={option.key}>{option.label}</label>
                        </div>
                      )
                  )}
                </div>
              </div>
            )}
            <div className="field col-6">
              <label htmlFor="detail_address">
                Address 1 <span className="error">*</span>
              </label>
              <InputText
                className="w-full"
                id="detail_address"
                type="text"
                value={address?.detail_address}
                onChange={(e) => onInputChange(e, "detail_address")}
                placeholder="Detail Address"
              />
              {submitted && address?.detail_address === "" && (
                <small className="error">Address 1 is Required</small>
              )}
            </div>
            <div className="field col-6">
              <label htmlFor="street_address">
                Address 2 <span className="error">*</span>
              </label>
              <InputText
                className="w-full"
                id="street_address"
                type="text"
                value={address?.street_address}
                onChange={(e) => onInputChange(e, "street_address")}
                placeholder="Street Address"
              />
              {submitted && address?.street_address === "" && (
                <small className="error">Address 2 is Required</small>
              )}
            </div>
            <div className="field col-6">
              <label htmlFor="landmark">
                Landmark <span className="error">*</span>
              </label>
              <InputText
                className="w-full"
                id="landmark"
                type="text"
                value={address?.landmark}
                onChange={(e) => onInputChange(e, "landmark")}
                placeholder="Landmark"
              />
              {submitted && address?.landmark === "" && (
                <small className="error">Landmark is Required</small>
              )}
            </div>
            <div className="field col-6">
              <label htmlFor="pincode">
                Pincode <span className="error">*</span>
              </label>
              <InputText
                className="w-full"
                id="pincode"
                type="text"
                value={address?.pincode}
                onChange={(e) => onInputChange(e, "pincode")}
                placeholder="Pincode"
                maxLength={6}
              />
              {submitted && address?.pincode === "" && (
                <small className="error">Pincode is Required</small>
              )}
            </div>
            <div className="field col-6">
              <label htmlFor="city">
                City <span className="error">*</span>
              </label>
              <Dropdown
                className="w-full"
                value={selectedCity}
                options={cityOptions}
                onChange={(e) => setSelectedCity(e.value)}
                placeholder="Select a City"
                optionValue="code"
                optionLabel="name"
                showClear
                filter
              />
              {submitted && !selectedCity && (
                <small className="error">City is Required</small>
              )}
            </div>
            <div className="field col-6">
              <label htmlFor="city">
                State <span className="error">*</span>
              </label>
              <Dropdown
                className="w-full"
                value={selectedState}
                options={stateOptions}
                onChange={(e) => setSelectedState(e.value)}
                placeholder="Select a State"
                optionValue="code"
                optionLabel="name"
                showClear
                filter
              />
              {submitted && !selectedState && (
                <small className="error">State is Required</small>
              )}
            </div>
            <div className="field col-6">
              <label htmlFor="city">
                Country <span className="error">*</span>
              </label>
              <Dropdown
                className="w-full"
                value={selectedCountry}
                options={countryOptions}
                onChange={(e) => setSelectedCountry(e.value)}
                placeholder="Select a Country"
                optionValue="code"
                optionLabel="name"
                showClear
                filter
              />
              {submitted && !selectedCountry && (
                <small className="error">Country is Required</small>
              )}
            </div>
            <div className="field col-6">
              <label htmlFor="is_default">Set as Default Address</label>
              <div className="is_default-wrapper">
                {defaultAddressOptions.map((category) => {
                  return (
                    <div key={category.key} className="flex align-items-center">
                      <RadioButton
                        inputId={category.key}
                        name="category"
                        value={category}
                        onChange={(e) => setIsDefaultAddress(e.value)}
                        checked={
                          isDefaultAddress?.key === category.key || false
                        }
                      />
                      <label htmlFor={category.key} className="ml-2">
                        {category.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Confirm Delete"
        visible={deleteAddressDialogState !== null}
        style={{ width: "450px" }}
        modal
        onHide={() => setDeleteAddressDialogState(null)}
        footer={() => (
          <div>
            <Button
              label="No"
              icon="pi pi-times"
              onClick={() => setDeleteAddressDialogState(null)}
              className="p-button-text"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              onClick={deleteAddressConfirmed}
              autoFocus
            />
          </div>
        )}
      ></Dialog>
    </>
  );
};

export default MyAddress;
