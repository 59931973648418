import React from "react";
import "./QuickSteps.css";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import stepOne from "../../assets/images/quick_step_icon_1.png";
import stepTwo from "../../assets/images/quick_step_icon_2.png";
import stepThree from "../../assets/images/quick_step_icon_3.png";

const QuickSteps = () => {
  return (
    <>
      <div className="quick-step-text-wrapper">
        <div className="quick-steps-text">
          Quick 3-Steps To Schedule Your Appointment
        </div>
        <div className="quick-step-know-more-wrapper">
          <Button className="quick-step-know-mote-btn">Know More</Button>
        </div>
      </div>
      <div className="quick-steps-wrapper">
        <div className="quick-step-one quick-step-wrapper">
          <div className="quick-step-img">
            <Image src={stepOne} height="60px" width="60px" />
          </div>
          <div className="quick-step-text">Buy a Product or Service</div>
        </div>
        <div className="quick-step-two quick-step-wrapper">
          <div className="quick-step-img">
            <Image src={stepTwo} height="60px" width="60px" />
          </div>
          <div className="quick-step-text">Schedule your Date & Time</div>
        </div>
        <div className="quick-step-three quick-step-wrapper">
          <div className="quick-step-img">
            <Image src={stepThree} height="60px" width="60px" />
          </div>
          <div className="quick-step-img">
            <div className="quick-step-text">Pay Online</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickSteps;
