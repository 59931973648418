import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHeroAPI from "../../api/useHeroAPI";
import usePreDefinedListAPI from "../../api/usePreDefinedListAPI";
import { setLoading } from "../../redux/loadingSlice";
import "./storeLocator.css";
// import debounce from '../../utils/debounce';

const StoreLocator = () => {
  const defaultCity = "Mumbai";

  const loading = useSelector((state: any) => state.loading);
  const dispatch = useDispatch();

  const debounce = (func: any, wait: any) => {
    let timeout: any;
    return function executedFunction(...args: any) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const containerStyle = {
    width: "100%",
    height: "94vmin",
  };

  const center = {
    lat: 19.1860219,
    lng: 72.8563181,
  };
  const { getSPList } = usePreDefinedListAPI();
  const { predefinedFunc } = useHeroAPI();

  const [selected, setSelected] = useState(null) as any;
  const [searchInput, setSearchInput] = useState("") as any;
  const [servicePointsData, setServicePointsData] = useState([]) as any;
  const [selectedCity, setSelectedCity] = useState(null) as any;
  const [cityOptions, setCityOptions] = useState([]) as any;

  const fetchPredefinedOptions = async () => {
    const cityPredefinedPayload = {
      entity_type: "CITY",
    };
    const cityPredefinedResponse: any = await predefinedFunc(
      cityPredefinedPayload
    );
    // const citiesData = cityPredefinedResponse?.data?.data.map((child: any) => {
    //     return {
    //         name: child.name,
    //         code: child.predefined_id,
    //     };
    // });
    setCityOptions(cityPredefinedResponse?.data?.data);
    const foundedCity = cityPredefinedResponse?.data?.data?.find(
      (city: any) => city?.name === "Mumbai"
    );
    setSelectedCity(foundedCity);
  };

  const customMarkerIcon = {
    url: "https://mechmiles.com/location-pin.png",
    scaledSize: { width: 50, height: 50 } as google.maps.Size,
  };

  const fetchServicePoints = async (searchValue?: any) => {
    let city_id;
    if (searchValue && !selectedCity) {
      city_id = null;
    } else if (selectedCity) {
      city_id = selectedCity?.predefined_id;
    } else {
      const foundedCity = cityOptions?.find(
        (city: any) => city?.name === "Mumbai"
      );
      city_id = foundedCity?.predefined_id;
    }

    if (searchInput === "" || searchValue === "") {
      if (!selectedCity) {
        const foundedCity = cityOptions?.find(
          (city: any) => city?.name === "Mumbai"
        );
        setSelectedCity(foundedCity);
      }
    }

    const payload = {
      // page_number:1,
      // page_size:10
      city_id: city_id,
      // city_id: selectedCity,
      search_by: searchValue ?? searchInput,
    };

    const response: any = await getSPList(payload);
    if (response.status === 200) {
      dispatch(setLoading(false));
      const mappedServicePointList = response?.data?.map(
        (servicePoint: any) => {
          return {
            id: servicePoint?.service_point_id,
            name: servicePoint?.service_point_name,
            location: {
              lat: parseFloat(servicePoint?.lat ?? 19.183065286832203),
              lng: parseFloat(servicePoint?.lng ?? 72.85825967788698),
            },
            subTitle: servicePoint?.description,
            address: servicePoint?.service_point_address,
            phone: servicePoint?.service_point_contact,
          };
        }
      );
      setServicePointsData(mappedServicePointList);
    }
  };

  const fetchServicePointsDebounced = useCallback(
    debounce((searchValue: any) => {
      fetchServicePoints(searchValue);
    }, 500),
    [selectedCity]
  );

  useEffect(() => {
    if (searchInput !== null) {
      fetchServicePointsDebounced(searchInput);
    }
  }, [searchInput, fetchServicePointsDebounced]);

  useEffect(() => {
    dispatch(setLoading(true));
    fetchServicePoints();
  }, [selectedCity]);

  useEffect(() => {
    fetchPredefinedOptions();
  }, []);

  const handleSearchInput = (e: any) => {
    setSelectedCity(null);
    setServicePointsData([]);
    setSearchInput(e.target.value);
  };

  const handleServicePointSelection = (servicePoint: any) => {
    setSelected(servicePoint);
  };

  const getDirectionFunc = (lat: any, lng: any) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.rel = "noopener noreferrer";

    // Simulate a click on the link
    document.body.appendChild(link);
    link.click();

    // Clean up the DOM
    document.body.removeChild(link);
  };

  return (
    <div className="map-wrapper">
      {loading && <div className="loading-overlay">Loading...</div>}
      <LoadScript
        // Change the API key to your own Google Maps API key
        // googleMapsApiKey="AIzaSyDj9D7Sm1MLG1y4CimROoQu1SSUl4OnHyI"
        googleMapsApiKey=""
      >
        <div className="container-fluid p-0">
          <div className="location-search-panel">
            <div className="location-select-city">
              <div className="field col-12 ">
                <label htmlFor="city">Enter Your City Name</label>
                <Dropdown
                  className="w-full"
                  value={selectedCity}
                  options={cityOptions}
                  onChange={(e) => setSelectedCity(e.value)}
                  placeholder="Select a City"
                  // optionValue="predefined_id"
                  optionLabel="name"
                  showClear
                  filter
                  virtualScrollerOptions={{ itemSize: 38 }}
                />
              </div>
              <div className="field col-12">
                <label htmlFor="city">Search Service Points</label>
                <InputText
                  placeholder="Search Service Points"
                  className="col-12"
                  value={searchInput}
                  onChange={handleSearchInput}
                />
              </div>
            </div>
            {!servicePointsData?.length && (
              <div className="empty-service-point-list">
                No Service Points Found
              </div>
            )}
            {servicePointsData?.map((point: any) => (
              <div key={point.id} className="location-sp-option-wrapper">
                <div className="location-sp-name-wrapper">
                  <div className="location-sp-name">{point.name}</div>
                  <div className="location-sp-address">{point.address}</div>
                </div>
                <div className="location-sp-locate-btn">
                  <Button onClick={() => handleServicePointSelection(point)}>
                    Select
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <div className="map-container">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={5}
            >
              {/* <MarkerClusterer>
                                {(clusterer) => */}
              {servicePointsData?.map((point: any) => (
                <Marker
                  key={point.id}
                  position={point.location}
                  onClick={() => setSelected(point)}
                  icon={customMarkerIcon}
                  // clusterer={clusterer}
                />
              ))}
              {/* }
                            </MarkerClusterer> */}
              {selected && (
                <div className="map-pin-wrapper">
                  <InfoWindow
                    position={selected.location}
                    onCloseClick={() => setSelected(null)}
                  >
                    <div className="map-location-tooltip">
                      <div className="selected-service-point-name">
                        {selected?.name}
                      </div>
                      <div className="selected-service-point-address">
                        {selected?.address ?? "Address Not Available"}
                      </div>
                      {/* <div className='selected-service-point-phone'><FaPhone /> {selected?.phone ?? 'Phone Not Available'}</div> */}
                      <div className="selected-service-point-goto-location-wrapper">
                        <div
                          className="selected-service-point-goto-location"
                          onClick={() =>
                            getDirectionFunc(
                              selected?.location.lat,
                              selected?.location.lng
                            )
                          }
                        >
                          Go To Location
                        </div>
                      </div>
                    </div>
                  </InfoWindow>
                </div>
              )}
            </GoogleMap>
          </div>
        </div>
      </LoadScript>
    </div>
  );
};

export default StoreLocator;
