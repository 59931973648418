import React, { useEffect, useRef, useState } from "react";
import "./Summary.css";
import { Container } from "react-bootstrap";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import yokohamaMini from "../../assets/images/TyreBrandLogos/yokohama_mini_2.png";
import miniDot from "../../assets/images/mini_dot.png";
import { Image } from "primereact/image";
import servicePointImg from "../../assets/images/selected-service-point-img-mini.png";
import walletIcon from "../../assets/images/wallet-icon.png";
import dicountIcon from "../../assets/images/discount-icon.png";
import { FaPlus, FaRegEdit } from "react-icons/fa";
import { Checkbox } from "primereact/checkbox";
import useCartAPI from "../../api/useCartAPI";
import { useLocation, useNavigate } from "react-router-dom";
import { IoCalendarOutline } from "react-icons/io5";
import useUserAPI from "../../api/useUserAPI";
import dummyCarImg from "../../assets/images/dummy-car-img.png";
import checkIcon from "../../assets/images/check.png";
import useVehicleAPI from "../../api/useVehicleAPI";
import useHeroAPI from "../../api/useHeroAPI";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import useOrderAPI from "../../api/useOrderAPI";
import { RadioButton } from "primereact/radiobutton";
import dummyCarImg2 from "../../assets/images/dummyCarImage.png";
import { updateCartQuantity } from "../../features/cart/cartSlice";
import { useDispatch } from "react-redux";
import vehicleText from "../../assets/images/vehicleText.png";
import { format } from "date-fns";
import useCouponAPI from "../../api/useCouponAPI";
import useWalletAPI from "../../api/useWalletAPI";

interface ProductDetails {
  product_id: number;
  product_name: string;
  product_price: number;
  product_offer_price: number;
  category: Category;
  make?: Entity;
  model?: Entity;
  variant?: Entity;
}

interface Category {
  predefined_id: number;
  name: string;
  code: string;
  entity_type: string;
  field1: string;
  field2?: string;
  field3?: string;
  is_delete: string;
  is_deactivate: string;
  url?: string;
  thumbnail?: string;
}

interface Entity {
  predefined_id?: number;
  name?: string;
  code?: string;
  entity_type?: string;
  field1?: string;
  field2?: string;
  field3?: string;
  is_delete: string;
  is_deactivate: string;
  url?: string;
  thumbnail?: string;
}

interface Item {
  cart_item_id: number;
  product_id: number;
  item_quantity: number;
  total_amount: number;
  discount?: number;
  is_wishList: boolean;
  created_by_id: number;
  created_date: string;
  product: ProductDetails;
}

interface Car {
  id: number;
  name: string;
  color: string;
  licensePlate: string;
  imageUrl: string;
  make: string;
  model: string;
  variant: string;
}

interface Address {
  detail_address: string;
  street_address: string;
  landmark: string;
  pincode: string;
}

const Summary = () => {
  const basePath = process.env.REACT_APP_BASE_PATH;
  const corporateAdminRole = "ROLE_CORPORATE_ADMIN";
  const corporateUserRole = "ROLE_CORPORATE_USER";
  const fleetUserRole = "ROLE_FLEET_USER";
  const fleetUserOwner = "ROLE_FLEET_OWNER";
  const customerRole = "ROLE_CUSTOMER";

  const {
    cartList,
    cartRemoveItem,
    cartSummery,
    cartSummeryUsingWallet,
    getPaymentLink,
    getPaymentStatus,
  } = useCartAPI();

  const { getWalletBalance } = useWalletAPI();
  const { getVehicleList, saveVehicle } = useVehicleAPI();
  const { getUserAddress, saveUserAddress } = useUserAPI();
  const { predefinedFunc } = useHeroAPI();
  const { scheduleAppointment, saveOrderAPI } = useOrderAPI();
  const { getCouponList } = useCouponAPI();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const toast = useRef<Toast>(null);

  const [items, setItems] = useState<Item[]>([]);
  const [selectedServicePoint, setSelectedServicePoint] = useState() as any;
  const [pickedDate, setPickedDate] = useState([]) as any;
  const [pickedTime, setPickedTime] = useState([]) as any;
  const [walletBalance, setWalletBalance] = useState([]) as any;
  const [selectedCarId, setSelectedCarId] = useState(null) as any;
  const [visible, setVisible] = useState(false);
  const [vehicleName, setVehicleName] = useState(null) as any;
  const [vehicleNumber, setVehicleNumber] = useState(null) as any;
  const [make, setMake] = useState(null) as any;
  const [models, setModels] = useState(null) as any;
  const [variant, setVariant] = useState(null) as any;
  const [category, setCategory] = useState(null) as any;
  const [typeId, setTypeId] = useState(null) as any;
  const [selectedCity, setSelectedCity] = useState(null) as any;
  const [selectedState, setSelectedState] = useState(null) as any;
  const [selectedCountry, setSelectedCountry] = useState(null) as any;
  const [makes, setMakes] = useState(null) as any;
  const [model, setModel] = useState(null) as any;
  const [variants, setVariants] = useState(null) as any;
  const [categories, setCategories] = useState(null) as any;
  const [typeIds, setTypeIds] = useState(null) as any;
  const [cityOptions, setCityOptions] = useState(null) as any;
  const [stateOptions, setStateOptions] = useState(null) as any;
  const [countryOptions, setCountryOptions] = useState(null) as any;
  const [submitted, setSubmitted] = useState(false) as any;
  const [cars, setCars] = useState<Car[]>([]);
  const [pincodeError, setPincodeError] = useState("");

  const [userIdState, setUserIdState] = useState<string>("");
  const [companyIdState, setCompanyIdState] = useState<string>("");
  const [servicePointId, setServicePointId] = useState<string>("");
  const [billingDetails, setBillingDetails] = useState([]) as any;
  const [useWalletBalance, setUseWalletBalance] = useState(false) as any;
  const [useDiscountCode, setUseDiscountCode] = useState(false) as any;
  const [showCouponDialog, setShowCouponDialog] = useState(false) as any;
  const [billingAddressesState, setBillingAddressesState] = useState([]) as any;
  const [shippingAddressesState, setShippingAddressesState] = useState(
    []
  ) as any;
  const [billingAddresses, setBillingAddresses] = useState([]) as any;
  const [shippingAddresses, setShippingAddresses] = useState([]) as any;
  const [newBillingAddress, setNewBillingAddress] = useState([]) as any;
  const [newShippingAddress, setNewShippingAddress] = useState([]) as any;
  const [isChangingAddress, setIsChangingAddress] = useState(false);
  const [isChangingBillingAddress, setIsChangingBillingAddress] =
    useState(false);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState("");
  const [selectedShippingAddress, setSelectedShippingAddress] = useState("");
  const [selectedPickedDate, setSelectedPickedDate] = useState("");
  const [address, setAddress] = useState<Address>({
    detail_address: "",
    street_address: "",
    landmark: "",
    pincode: "",
  });
  const [allCouponList, setAllCouponList] = useState([]) as any;
  const [currentCouponCode, setCurrentCouponCode] = useState<string>("");
  const [hideWalletAndCoupon, setHideWalletAndCoupon] = useState(false) as any;
  const [makePaymentObject, setMakePaymentObject] = useState(null);
  const [newWindow, setNewWindow] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [userRole, setUserRole] = useState("") as any;

  const formatDateToString = (date: Date | null): string => {
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
    return "";
  };

  const defaultAddressOptions = [
    { name: "Yes", key: "true" },
    { name: "No", key: "false" },
  ];

  const [isDefaultAddress, setIsDefaultAddress] = useState<
    (typeof defaultAddressOptions)[number] | null
  >(null);

  useEffect(() => {
    const servicePointData = location.state;
    setSelectedServicePoint(servicePointData?.selectedServicePointData[0]);
    setServicePointId(
      servicePointData?.selectedServicePointData[0].service_point_id
    );
    setSelectedPickedDate(servicePointData?.pickedDateState);
    const date = new Date(servicePointData?.pickedDateState);
    const formattedDate = format(date, "do MMMM yyyy");
    setPickedDate(formattedDate);
    setPickedTime(servicePointData?.pickedTimeVar);

    handleCancel();
  }, []);

  useEffect(() => {
    const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");

    if (authTokens?.user?.roles) {
      if (
        // authTokens?.user?.roles[0].code === corporateAdminRole ||
        // authTokens?.user?.roles[0].code === corporateUserRole ||
        authTokens?.user?.roles[0].code !== customerRole
      ) {
        setHideWalletAndCoupon(true);
      }
      const userRole = authTokens?.user?.roles[0];
      if (userRole) setUserRole(userRole);
      if (
        authTokens?.user?.roles[0].code === corporateAdminRole ||
        authTokens?.user?.roles[0].code === corporateUserRole ||
        authTokens?.user?.roles[0].code === fleetUserOwner ||
        authTokens?.user?.roles[0].code === fleetUserRole
      ) {
        setUseWalletBalance(true);
      }
    }
  }, []);

  const fetchCartProducts = async () => {
    const payload = {
      is_wishList: false,
    };

    const response: any = await cartList(payload);
    const mappedProducts = response?.data?.data.map((item: any) => ({
      id: String(item?.product_id ?? item?.service_id) ?? 0,
      name:
        item?.product?.product_name ??
        item?.service?.service_name ??
        "Not Available",
      // rim: "205/45R17 88/Y",
      prodPrice: String(item.total_amount),
      quantity: item.item_quantity,
      prodTotal: String(item.total_amount * item.item_quantity),
      cartItemId: item?.cart_item_id,
      brandImage: item?.service_id ? null : item?.product?.brand?.thumbnail,
    }));
    setItems(mappedProducts);
  };

  const fetchAddress = async () => {
    const authTokens: any = JSON.parse(
      localStorage.getItem("authTokens") || "{}"
    );
    if (authTokens?.user?.id) {
    }
    const userId = authTokens.user?.id;
    setUserIdState(authTokens?.user?.id);
    setCompanyIdState(authTokens.user?.company_id);
    const walletAmount = authTokens.user?.wallet_balance;

    const walletBalanceResponse: any = await getWalletBalance(
      parseInt(authTokens.user?.company_id),
      parseInt(authTokens.user?.id)
    );

    if (
      walletBalanceResponse.status === 200 ||
      walletBalanceResponse.status === 201
    ) {
      if (walletBalanceResponse?.data?.balance >= 0) {
        setWalletBalance(walletBalanceResponse?.data?.balance);
      } else {
        setWalletBalance(0);
      }
    }

    const billingPayload = {
      user_id: userId,
      entity_type: "BILLING",
    };
    const shippingPayload = {
      user_id: userId,
      entity_type: "SHIPPING",
    };

    const billingResponse = await getUserAddress(billingPayload);
    const shippingResponse = await getUserAddress(shippingPayload);

    if (
      billingResponse?.data.length > 0 &&
      !billingResponse?.data.some((address: any) => address?.is_default)
    ) {
      setSelectedBillingAddress(billingResponse?.data[0]?.address_id);
    }
    if (
      shippingResponse?.data.length > 0 &&
      !shippingResponse?.data.some((address: any) => address?.is_default)
    ) {
      setSelectedShippingAddress(shippingResponse?.data[0]?.address_id);
    }

    if (billingResponse?.data.length === 1) {
      setBillingAddresses(billingResponse?.data || []);
      setBillingAddressesState(billingResponse?.data[0] || {});
    } else {
      const defaultBillingAddress = billingResponse?.data?.find(
        (address: any) => address?.is_default
      );
      setBillingAddresses(billingResponse?.data || []);
      setBillingAddressesState(defaultBillingAddress);
    }

    if (shippingResponse?.data.length === 1) {
      setShippingAddresses(shippingResponse?.data || []);
      setShippingAddressesState(shippingResponse?.data[0] || {});
    } else {
      const defaultShippingAddress = shippingResponse?.data?.find(
        (address: any) => address?.is_default
      );
      setShippingAddresses(shippingResponse?.data || []);
      setShippingAddressesState(defaultShippingAddress);
    }
  };

  const fetchPredefinedList = async () => {
    const tyrePayload = {
      entity_type: "TYRE-TYPE",
    };
    const typeIdResponse = await predefinedFunc(tyrePayload);
    const typeIdData = typeIdResponse?.data?.data.map((child: any) => {
      return {
        name: child.name,
        code: child.predefined_id,
      };
    });
    setTypeIds(typeIdData);

    const categoryPayload = {
      entity_type: "CATEGORY",
    };
    const categoryPredefinedResponse = await predefinedFunc(categoryPayload);
    const categoryData = categoryPredefinedResponse?.data?.data.map(
      (child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      }
    );
    setCategories(categoryData);

    const statePredefinedPayload = {
      entity_type: "STATE",
    };
    const statePredefinedResponse = await predefinedFunc(
      statePredefinedPayload
    );
    const statesData = statePredefinedResponse?.data?.data.map((child: any) => {
      return {
        name: child.name,
        code: child.predefined_id,
      };
    });
    setStateOptions(statesData);

    const countryPredefinedPayload = {
      entity_type: "COUNTRY",
    };
    const countryPredefinedResponse = await predefinedFunc(
      countryPredefinedPayload
    );
    const countriesData = countryPredefinedResponse?.data?.data.map(
      (child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      }
    );

    setCountryOptions(countriesData);
  };

  const fetchDependentData = async () => {
    if (category !== null) {
      const makePredefinedPayload = {
        entity_type: "MAKE",
        parent_id: category ?? null,
      };
      const makePredefinedResponse = await predefinedFunc(
        makePredefinedPayload
      );
      const makeData = makePredefinedResponse?.data?.data.map((child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      });
      setMakes(makeData);
    }

    if (make !== null) {
      const modelPredefinedPayload = {
        entity_type: "MODEL",
        parent_id: make ?? null,
      };
      const modelPredefinedResponse = await predefinedFunc(
        modelPredefinedPayload
      );
      const modelData = modelPredefinedResponse?.data?.data.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      setModels(modelData);
    }
    if (model !== null) {
      const variantPredefinedPayload = {
        entity_type: "VARIANT",
        parent_id: model ?? null,
      };
      const variantPredefinedResponse = await predefinedFunc(
        variantPredefinedPayload
      );
      const variantData = variantPredefinedResponse?.data?.data.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      setVariants(variantData);
    }
  };

  const fetchCitiesData = async () => {
    const cityPredefinedPayload = {
      entity_type: "CITY",
      parent_id: selectedState,
    };
    const cityPredefinedResponse = await predefinedFunc(cityPredefinedPayload);

    if (selectedState) {
      const citiesData = cityPredefinedResponse?.data?.data.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );

      setCityOptions(citiesData);
    }
  };

  useEffect(() => {
    fetchCitiesData();
  }, [selectedState]);

  useEffect(() => {
    if (category !== null || make !== null || model !== null) {
      fetchDependentData();
    }
  }, [category, make, model]);

  const fetchCartSummary = async () => {
    const response: any = await cartSummery(
      currentCouponCode,
      useWalletBalance
    );

    if (response?.response?.status === 400) {
      setCurrentCouponCode("");
      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `${response?.response?.data?.error}`,
      });
    }

    if (response.status === 200) {
      setBillingDetails(response?.data);
      setShowCouponDialog(false);
      if (currentCouponCode !== "") {
        setUseDiscountCode(true);
        return toast?.current?.show({
          severity: "success",
          summary: "Success",
          detail: `Coupon Applied`,
        });
      } else {
        setUseDiscountCode(false);
        // return toast?.current?.show({
        //   severity: "error",
        //   summary: "Success",
        //   detail: `Coupon Code is Empty`,
        // });
      }
    }
  };

  const fetchVehicleList = async () => {
    const authTokens: any = JSON.parse(
      localStorage.getItem("authTokens") || "{}"
    );
    if (authTokens?.user?.id) {
    }
    const userId = authTokens.user?.id;

    try {
      const payload = {
        user_id: userId,
      };

      const response: any = await getVehicleList(payload);

      const vehicleData = response?.data?.map((vehicle: any) => ({
        id: vehicle.id,
        color: vehicle.vehicle_color || "N/A",
        name:
          `${vehicle?.make?.name} ${vehicle?.model?.name} ${vehicle?.variant?.name}` ||
          "Name Not Available",
        licensePlate: vehicle.vehicle_number,
        imageUrl: vehicleText,
        make: vehicle?.make?.name,
        model: vehicle?.model?.name,
        variant: vehicle?.variant?.name,
        variantId: vehicle?.variant?.predefined_id,
      }));
      setCars(vehicleData || null);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  };

  useEffect(() => {
    fetchCartProducts();
    fetchAddress();
    fetchCartSummary();
    fetchVehicleList();
    fetchPredefinedList();
  }, []);

  const handleAddMoreItems = (e: any) => {
    e.preventDefault();
    navigate(`${basePath}products`);
  };

  const handleRemoveItem = async (e: any, item: any) => {
    e.preventDefault();
    const response: any = await cartRemoveItem(item?.cartItemId);
    if (response.status === 200 || response.status === 201) {
      await fetchCartProducts();
    }

    console.log(item);
  };

  const handleSelectCar = (carId: any) => {
    setSelectedCarId(carId.id);
  };

  const handleAddCar = async () => {
    if (!category || !model || !make || !variant) {
      setSubmitted(true);
      return;
    }

    const payload = {
      vehicle_name: vehicleName,
      vehicle_number: vehicleNumber,
      category_id: category,
      model_id: model,
      make_id: make,
      variant_id: variant,
    };

    const formData = new FormData();

    formData.append("data", JSON.stringify(payload));

    const response = await saveVehicle(formData);

    if (response.status === 200 || response.status === 201) {
      fetchVehicleList();
      setVisible(false);
      return toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `Vehicle Added Successfully`,
      });
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setVehicleName(null);
    setVehicleNumber(null);
    setCategory(null);
    setModel(null);
    setMake(null);
    setVariant(null);
    setTypeId(null);
    setSubmitted(false);
    setIsChangingBillingAddress(false);
    setNewBillingAddress(null);
    setNewShippingAddress(null);
    setIsChangingAddress(false);
    setAddress({
      detail_address: "",
      street_address: "",
      landmark: "",
      pincode: "",
    });
    setSelectedCity(null);
    setSelectedState(null);
    // setSelectedCountry(null);
    setIsDefaultAddress(null);
  };

  const handleNewAddressChange = async () => {
    if (newShippingAddress || newBillingAddress) {
      if (newBillingAddress) {
        const newAddress = billingAddresses.find(
          (address: any) => address?.address_id === newBillingAddress
        );
        setBillingAddressesState(newAddress);
        handleCancel();
      } else {
        const newAddress = shippingAddresses.find(
          (address: any) => address?.address_id === newShippingAddress
        );
        setShippingAddressesState(newAddress);
        handleCancel();
      }
      return;
    }

    if (
      address.detail_address === "" ||
      address.street_address === "" ||
      address.landmark === "" ||
      address.pincode === "" ||
      !selectedCity ||
      !selectedState
    ) {
      setSubmitted(true);
      return;
    }

    const payload = {
      detail_address: address?.detail_address,
      street_address: address?.street_address,
      landmark: address?.landmark,
      pincode: address?.pincode,
      entity_type: isChangingBillingAddress ? "BILLING" : "SHIPPING",
      // is_default: isDefaultAddress?.key === 'true' ? true : false,
      is_default: false,
      city_id: selectedCity,
      state_id: selectedState,
      country_id: selectedCountry,
    };

    const response = await saveUserAddress(payload);
    if (response.status === 200 || response.status === 201) {
      fetchAddress();
      setIsChangingAddress(false);
      setSubmitted(false);
      handleCancel();
      return toast?.current?.show({
        severity: "success",
        summary: "Successful",
        detail: `${response.data.message}`,
      });
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        // icon="pi pi-times"
        onClick={handleCancel}
        className="p-button-text"
      />
      <Button
        label="Submit"
        // icon="pi pi-check"
        onClick={handleAddCar}
        autoFocus
      />
    </div>
  );

  const footerContent2 = (
    <div>
      <Button
        label="Cancel"
        // icon="pi pi-times"
        onClick={handleCancel}
        className="p-button-text"
      />
      <Button
        label="Submit"
        // icon="pi pi-check"
        onClick={handleNewAddressChange}
        autoFocus
      />
    </div>
  );

  const handleProceedApproval = async () => {
    if (!selectedCarId) {
      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Please Select A vehicle`,
      });
    }

    if (
      userRole?.code === customerRole ||
      userRole?.code === fleetUserOwner ||
      userRole?.code === fleetUserRole
    ) {
      if (!selectedBillingAddress || !selectedShippingAddress) {
        return toast?.current?.show({
          severity: "error",
          summary: "Failed",
          detail: `Please Select Address`,
        });
      }
    }

    // if (!selectedBillingAddress || !selectedShippingAddress) {
    //   return toast?.current?.show({
    //     severity: "error",
    //     summary: "Failed",
    //     detail: `Please Address`,
    //   });
    // }

    if (
      !userIdState ||
      !servicePointId ||
      !pickedDate ||
      !pickedTime ||
      !selectedCarId
    ) {
      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Failed to Schedule an Appointment`,
      });
    }

    const payload = {
      user_id: userIdState,
      service_point_id: servicePointId,
      service_start_date: formatDateToString(new Date(selectedPickedDate)),
      service_start_time_id: pickedTime?.code,
      shipping_address_id: selectedShippingAddress,
      billing_address_id: selectedBillingAddress,
      vehicle_id: selectedCarId,
      is_use_wallet: useWalletBalance,
      instructions: location?.state?.specialInstructions ?? null,
      code: currentCouponCode,
    };

    const response: any = await scheduleAppointment(payload);

    if (response?.status === 200 || response?.status === 201) {
      setTimeout(() => {
        // Add forward slash for for production
        navigate(`${basePath}`);
      }, 500);
      dispatch(updateCartQuantity(0));

      return toast?.current?.show({
        severity: "success",
        summary: "Successful",
        detail: `Appointment Scheduled Successfully`,
      });
    } else if (response?.response?.status === 400) {
      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `${response.response.data.message}`,
      });
    } else {
      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Failed to Schedule an Appointment`,
      });
    }
  };

  const paymentStatus = async (paymentId: any) => {
    // console.log("paymentStatus", paymentId);

    const response: any = await getPaymentStatus(paymentId);
    if (response?.status === 200 || response?.status === 201) {
      if (response?.data?.data?.order_status === "FAILED") {
        return toast?.current?.show({
          severity: "error",
          summary: "Failed",
          detail: `Payment Failed`,
        });
      }
      if (response?.data?.data?.order_status === "SUCCESS") {
        toast?.current?.show({
          severity: "success",
          summary: "Successful",
          detail: `Payment Successful`,
        });

        const payload = {
          user_id: userIdState,
          service_point_id: servicePointId,
          service_start_date: formatDateToString(new Date(selectedPickedDate)),
          service_start_time_id: pickedTime?.code,
          shipping_address_id: selectedShippingAddress,
          billing_address_id: selectedBillingAddress,
          vehicle_id: selectedCarId,
          is_use_wallet: useWalletBalance,
          instructions: location?.state?.specialInstructions ?? null,
          code: currentCouponCode,
        };

        const orderSaveResponse: any = await saveOrderAPI(payload);

        if (
          orderSaveResponse?.status === 200 ||
          orderSaveResponse?.status === 201
        ) {
          setTimeout(() => {
            navigate(`${basePath}`);
          }, 500);
          dispatch(updateCartQuantity(0));

          return toast?.current?.show({
            severity: "success",
            summary: "Successful",
            detail: `Payment Successful`,
          });
        }
      }
      // setTimeout(() => {
      //   navigate(`${basePath}`);
      // }, 500);
      // dispatch(updateCartQuantity(0));

      // return toast?.current?.show({
      //   severity: "success",
      //   summary: "Successful",
      //   detail: `Payment Successfull`,
      // });
    }
  };

  const handlePaymentFunc = async () => {
    try {
      // Assuming `subscriptionService` is an equivalent service in your React app
      // Replace with your actual method to call the API
      // const data = await subscriptionService.buySubscription(
      //   subscription.subscriptionId,
      //   customer.tradeUserId,
      //   subscription.promoCode
      // );

      if (!selectedCarId) {
        return toast?.current?.show({
          severity: "error",
          summary: "Failed",
          detail: `Please Select A vehicle`,
        });
      }

      if (
        userRole?.code === customerRole ||
        userRole?.code === fleetUserOwner ||
        userRole?.code === fleetUserRole
      ) {
        if (!selectedBillingAddress || !selectedShippingAddress) {
          return toast?.current?.show({
            severity: "error",
            summary: "Failed",
            detail: `Please Select Address`,
          });
        }
      }
      if (billingDetails?.total === 0) {
        const payload = {
          user_id: userIdState,
          service_point_id: servicePointId,
          service_start_date: formatDateToString(new Date(selectedPickedDate)),
          service_start_time_id: pickedTime?.code,
          shipping_address_id: selectedShippingAddress,
          billing_address_id: selectedBillingAddress,
          vehicle_id: selectedCarId,
          is_use_wallet: useWalletBalance,
          instructions: location?.state?.specialInstructions ?? null,
          code: currentCouponCode,
        };

        const orderSaveResponse: any = await saveOrderAPI(payload);

        if (
          orderSaveResponse?.status === 200 ||
          orderSaveResponse?.status === 201
        ) {
          setTimeout(() => {
            navigate(`${basePath}`);
          }, 500);
          dispatch(updateCartQuantity(0));

          return toast?.current?.show({
            severity: "success",
            summary: "Successful",
            detail: `Payment Successful`,
          });
        } else {
          return toast?.current?.show({
            severity: "error",
            summary: "Failed",
            detail: `Payment Failed`,
          });
        }
      }

      const payload = {
        user_id: userIdState,
        amount: billingDetails?.total,
        is_wallet: useWalletBalance,
      };
      const response: any = await getPaymentLink(payload);
      setMakePaymentObject(response?.data);
      const paymentWindow: any = window?.open(
        response?.data?.short_url,
        "",
        "toolbar=0,status=0"
      );
      setNewWindow(paymentWindow);
      setPaymentId(response?.data?.payment_id);
      const timer = setInterval(() => {
        if (paymentWindow?.closed) {
          clearInterval(timer);
          paymentStatus(response?.data?.payment_id);
        }
      }, 1000);
    } catch (err: any) {
      if (err.status === 200) {
        let jsonString = err.error.text;
        jsonString = jsonString.substr(0, jsonString.indexOf('{"timestamp"'));
        // Handle the error case
      }
    }
  };

  const handleBillingAddressChange = () => {
    setIsChangingAddress(true);
    setIsChangingBillingAddress(true);
  };
  const handleShippingAddressChange = () => {
    setIsChangingAddress(true);
    setIsChangingBillingAddress(false);
  };

  const onInputChange = (
    
    e: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    const { value } = e.target;
    if (name === "pincode" && isNaN(Number(value))) {
      return;
    }

    if (name === "pincode") {
      if (!/^\d{6}$/.test(value)) {
        setPincodeError("Pincode must be a 6-digit number");
      } else {
        setPincodeError("");
      }
    }
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };
  

  const handleNewAddress = (e: any) => {
    if (isChangingBillingAddress) {
      setNewBillingAddress(e.value);
    } else {
      setNewShippingAddress(e.value);
    }
  };

  const handleNewBillingAddress = () => {
    setIsChangingAddress(true);
    setIsChangingBillingAddress(true);
  };
  const handleNewShippingAddress = () => {
    setIsChangingAddress(true);
    setIsChangingBillingAddress(false);
  };

  const handleBillingAddressSelection = (addressId: any) => {
    setSelectedBillingAddress(addressId);
  };
  const handleShippingAddressSelection = (addressId: any) => {
    setSelectedShippingAddress(addressId);
  };

  const redirectToCart = () => {
    navigate("/cart");
  };

  const fetchAllCouponFunc = async () => {
    const payload = {};

    const response: any = await getCouponList(payload);

    if (response.status === 200) {
      setAllCouponList(response.data);
      setShowCouponDialog(true);
    }
  };

  const useWalletBalanceFunc = async (e: any) => {
    const isUsingWallet = e.checked === true ? true : null;
    const response: any = await cartSummery(
      currentCouponCode ?? "",
      isUsingWallet
    );

    if (response?.response?.status === 400) {
      setCurrentCouponCode("");
      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `${response?.response?.data?.error}`,
      });
    }

    if (response.status === 200) {
      setBillingDetails(response?.data);
      setShowCouponDialog(false);
      setUseWalletBalance(e.checked);
    }
  };

  return (
    <div className="summery-wrapper">
      <Toast ref={toast} />
      <div className="summery-header-wrapper">
        <Container>
          <BreadCrumbs />
          <div className="summery-title">Summary</div>
        </Container>
      </div>
      <Container>
        <div className="summary-container">
          <div className="item-listing-wrapper">
            <div className="items-container">
              <div className="item-header">
                <span>Items</span>
              </div>
              <ul className="item-list">
                {items?.length === 0 && (
                  <div className="no-items">No Item Available</div>
                )}
                {items?.map((item: any, index: any) => (
                  <li key={item.id} className="item">
                    <div className="item-title-wrapper">
                      <Image
                        src={item?.brandImage}
                        alt="Yokohama Brand Logo"
                        className="item-title-image"
                      />
                      <div className="item-title-container">
                        <div className="item-index">
                          <strong>{index + 1})</strong>
                        </div>
                        <div className="item-name">{item.name}</div>
                      </div>
                    </div>
                    <Image src={miniDot} alt="Mini Dot" className="item-dot" />
                    {!hideWalletAndCoupon && (
                      <>
                        <div className="item-details">
                          &#8377; {item.prodPrice}
                        </div>
                        <Image
                          src={miniDot}
                          alt="Mini Dot"
                          className="item-dot"
                        />
                      </>
                    )}
                    {/* <div className="item-price">Price: &#8377;{item.price}</div>
                    <Image src={miniDot} alt="Mini Dot" className="item-dot" /> */}
                    <div className="item-quantity">
                      Quantity: {item.quantity}
                    </div>
                    {/* <Image src={miniDot} alt="Mini Dot" className="item-dot" /> */}
                    {/* <div className="item-total">&#8377;{item.total}</div> */}
                    <Button
                      className="item-remove"
                      onClick={(e: any) => handleRemoveItem(e, item)}
                    >
                      x
                    </Button>
                  </li>
                ))}
              </ul>
              <Button className="add-item" onClick={handleAddMoreItems}>
                + Add more items
              </Button>
            </div>
            <div className="change-service-point-container">
              <div className="service-point-header">
                <span>Service Point</span>
              </div>
              <div className="service-point">
                <div className="service-point-details">
                  <div className="service-point-address-wrapper">
                    <div className="service-point-data-wrapper">
                      {/* <Image
                        src={servicePointImg}
                        alt="servicePointImg"
                        className="service-point-img"
                      /> */}
                      <div className="service-pont-name-container">
                        <div className="service-point-name">
                          <span>
                            {selectedServicePoint?.service_point_name}
                          </span>
                        </div>
                        <div className="summery-service-point-address">
                          <span>
                            {selectedServicePoint?.service_point_address}
                          </span>
                        </div>
                        <div className="service-point-time">
                          <span>
                            <IoCalendarOutline />
                            <div>
                              {`${pickedDate}`} ({pickedTime?.name})
                              {/* {`${format(pickedDate, 'do MMMM yyyy')}`} ({pickedTime?.name}) */}
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="change-service-point-button-container">
                      <Button
                        className="change-service-point-button"
                        onClick={redirectToCart}
                      >
                        <FaRegEdit /> Change
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="select-car-container">
          <div className="select-car-wrapper">
            <div className="select-car-header">
              <h2>
                Select Vehicle <span className="error">*</span>
              </h2>
              <p>Please select a Vehicle</p>
            </div>
            <div className="car-list">
              {cars.map((car) => (
                <div
                  key={car.id}
                  className={`car-item-wrapper ${
                    selectedCarId === car.id ? "selected-car" : ""
                  }`}
                  onClick={() => handleSelectCar(car)}
                >
                  <div className="car-item">
                    <Image
                      src={car?.imageUrl}
                      alt={`${car?.name}`}
                      className="car-image"
                      height="108px"
                      width="103px"
                    />
                    <div className="car-data">
                      {/* <h3> */}
                      {/* {car.name} */}
                      <div className="vehicle-make">{car?.make}</div>
                      <div className="vehicle-model">{car?.model}</div>
                      <div className="vehicle-variant">{car?.variant}</div>
                      {/* </h3> */}
                      <p>{car.licensePlate}</p>
                    </div>
                  </div>
                  {selectedCarId === car?.id && (
                    <div className="check-icon-wrapper">
                      <Image
                        src={checkIcon}
                        alt="Select Icon"
                        height="26px"
                        width="26px"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <Button className="add-item" onClick={() => setVisible(true)}>
              + Add New Vehicle
            </Button>
          </div>
          {/* <Button className="proceed-button">Proceed</Button> */}
        </div>

        {(userRole.code === fleetUserRole ||
          userRole.code === customerRole ||
          userRole.code === fleetUserOwner) && (
          <div className="address-container">
            <div className="address-wrapper">
              <div className="billing-address-wrapper">
                <div className="billing-address title">Billing Address</div>
                <div className="billing-addresses-container">
                  <div className="billing-addresses-list-wrapper">
                    {billingAddresses?.length > 0 ? (
                      billingAddresses?.map((address: any) => (
                        <div
                          key={address?.address_id}
                          className="billing-address-card"
                        >
                          <RadioButton
                            inputId={`${address?.entity_type}_address_${address?.address_id}`}
                            name="billingAddress"
                            value={address?.address_id}
                            onChange={() =>
                              handleBillingAddressSelection(address?.address_id)
                            }
                            checked={
                              selectedBillingAddress === address?.address_id
                            }
                          />
                          <label
                            htmlFor={`${address?.entity_type}_address_${address?.address_id}`}
                            className="p-radiobutton-label"
                          >
                            {`${address?.detail_address}, ${address?.street_address}, ${address?.landmark},`}
                            <br />
                            {`${address?.city?.name} - ${address?.pincode}`}
                          </label>
                        </div>
                      ))
                    ) : (
                      <div className="no-address-card">
                        No address available
                      </div>
                    )}
                  </div>
                  <Button
                    className="add-new-address-button"
                    onClick={handleNewBillingAddress}
                  >
                    <FaPlus /> Add New Address
                  </Button>
                </div>
              </div>
              {userRole.code !== customerRole && (
                <div className="shipping-address-wrapper">
                  <div className="shipping-address title">Shipping Address</div>
                  <div className="billing-addresses-container">
                    <div className="billing-addresses-list-wrapper">
                      {shippingAddresses?.length > 0 ? (
                        shippingAddresses?.map((address: any) => (
                          <div
                            key={address?.address_id}
                            className="billing-address-card"
                          >
                            <RadioButton
                              inputId={`${address?.entity_type}_address_${address?.address_id}`}
                              name="shippingAddress"
                              value={address?.address_id}
                              onChange={() =>
                                handleShippingAddressSelection(
                                  address?.address_id
                                )
                              }
                              checked={
                                selectedShippingAddress === address?.address_id
                              }
                            />
                            <label
                              htmlFor={`${address?.entity_type}_address_${address?.address_id}`}
                              className="p-radiobutton-label"
                            >
                              {`${address?.detail_address}, ${address?.street_address}, ${address?.landmark},`}
                              <br />
                              {`${address?.city?.name} - ${address?.pincode}`}
                            </label>
                          </div>
                        ))
                      ) : (
                        <div className="no-address-card">
                          No address available
                        </div>
                      )}
                    </div>
                    <Button
                      className="add-new-address-button"
                      onClick={handleNewShippingAddress}
                    >
                      <FaPlus /> Add New Address
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {!hideWalletAndCoupon && (
          <div className="wallet-discount-wrapper">
            <div className="wallet-container">
              <div className="wallet-title-wrapper">
                <Image
                  src={walletIcon}
                  alt="walletIcon"
                  className="wallet-icon"
                />
                <div className="wallet-text-wrapper">
                  <div className="wallet-title">Wallet</div>
                  <div className="wallet-amount">
                    Wallet Balance: &#8377;{walletBalance ?? 0}
                  </div>
                </div>
              </div>
              <div className="wallet-check-box">
                <label htmlFor="wallet-balance">Use wallet balance</label>
                <Checkbox
                  onChange={useWalletBalanceFunc}
                  checked={useWalletBalance}
                  inputId="wallet-balance"
                ></Checkbox>
              </div>
            </div>
            <div className="discount-container">
              <div className="wallet-title-wrapper">
                <Image
                  src={dicountIcon}
                  alt="walletIcon"
                  className="wallet-icon"
                />
                <div className="wallet-text-wrapper">
                  <div className="wallet-title">Discount promocode</div>
                  <div
                    className="wallet-amount coupon-view-all"
                    onClick={fetchAllCouponFunc}
                  >
                    View All
                  </div>
                </div>
              </div>
              <div className="wallet-check-box">
                <span className="p-input-icon-right discount-input-wrapper">
                  <InputText
                    placeholder="Enter Promocode"
                    value={currentCouponCode}
                    onChange={(e: any) => setCurrentCouponCode(e.target.value)}
                  />
                  <Button
                    label="Apply"
                    className="discount-apply-btn"
                    onClick={fetchCartSummary}
                  />
                </span>
              </div>
            </div>
          </div>
        )}

        {!hideWalletAndCoupon && (
          <div className="billing-details-wrapper">
            <div className="billing-details-title">Billing Details</div>
            <div className="billing-details-content">
              <div className="billing-detail">
                <span className="billing-detail-label">Subtotal</span>
                <span>
                  &#8377;{" "}
                  {new Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(billingDetails?.subtotal?.toFixed(2) ?? 0)}
                </span>
              </div>
              <div className="billing-detail">
                <span className="billing-detail-label">GST</span>
                <span>
                  &#8377;{" "}
                  {new Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(billingDetails?.gst?.toFixed(2) ?? 0)}
                </span>
              </div>
              {useWalletBalance && (
                <div className="billing-detail">
                  <span className="billing-detail-label">Wallet Discount</span>
                  <span
                    className={`billing-detail-amount ${
                      useWalletBalance ? "discount" : ""
                    }`}
                  >
                    &#8377;{" "}
                    {new Intl.NumberFormat("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(billingDetails?.wallet_discount?.toFixed(2) ?? 0)}
                  </span>
                </div>
              )}
              {useDiscountCode && (
                <div className="billing-detail">
                  <span className="billing-detail-label">Discount</span>
                  <span
                    className={`billing-detail-amount ${
                      useDiscountCode ? "discount" : ""
                    }`}
                  >
                    &#8377;{" "}
                    {new Intl.NumberFormat("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(billingDetails?.discount?.toFixed(2) ?? 0)}
                  </span>
                </div>
              )}
            </div>
            <div className="billing-detail">
              <strong>
                <span className="billing-detail-label">Total</span>
              </strong>
              <strong>
                <span>
                  &#8377;{" "}
                  {new Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(billingDetails?.total?.toFixed(2) ?? 0)}
                </span>
              </strong>
            </div>
          </div>
        )}

        <div className="total-amount-wrapper">
          <div className="total-amount-title-container">
            {/* Total: &#8377; {billingDetails?.total}
            <div className="including-gst-text">(incl. of GST)</div> */}
          </div>
          <div className="proceed-for-approval-wrapper">
            <Button
              className="proceed-for-approval-btn"
              onClick={
                hideWalletAndCoupon ? handleProceedApproval : handlePaymentFunc
              }
            >
              {hideWalletAndCoupon ? "Proceed for Approval" : "Proceed to Pay"}
            </Button>
          </div>
        </div>
      </Container>
      <Dialog
        header="Add a New Car"
        footer={footerContent}
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "90%" }}
      >
        <div className="add-new-car-wrapper grid">
          {/* <div className="field col-6 vehicle-name">
            <label htmlFor="vehicle-name">
              Vehicle Name <span className="error">*</span>
            </label>
            <InputText
              className="w-full"
              value={vehicleName}
              onChange={(e) => setVehicleName(e.target.value)}
              placeholder="Vehicle Name"
              id="vehicle-name"
            />
            {submitted && !vehicleName && (
              <small className="error">Vehicle Name is Required</small>
            )}
          </div> */}
          <div className="field col-6 vehicle-number">
            <label htmlFor="vehicle-number">Vehicle Number</label>
            <InputText
              className="w-full"
              value={vehicleNumber}
              onChange={(e) => setVehicleNumber(e.target.value)}
              placeholder="Vehicle Number"
              id="vehicle-number"
            />
            {/* {submitted && !vehicleNumber && (
              <small className="error">Vehicle Number is Required</small>
            )} */}
          </div>
          <div className="field col-6 vehicle-category">
            <label>
              Category <span className="error">*</span>
            </label>
            <Dropdown
              className="w-full"
              value={category}
              options={categories}
              onChange={(e) => setCategory(e.value)}
              placeholder="Select a Category"
              optionValue="code"
              optionLabel="name"
              showClear
            />
            {submitted && !category && (
              <small className="error">Category is Required</small>
            )}
          </div>
          <div className="field col-6 vehicle-make">
            <label>
              Make <span className="error">*</span>
            </label>
            <Dropdown
              className="w-full"
              value={make}
              options={makes}
              onChange={(e) => setMake(e.value)}
              placeholder="Select a Make"
              optionValue="code"
              optionLabel="name"
              filter
            />
            {submitted && !make && (
              <small className="error">Make is Required</small>
            )}
          </div>
          <div className="field col-6 vehicle-model">
            <label>
              Model <span className="error">*</span>
            </label>
            <Dropdown
              className="w-full"
              filter
              value={model}
              options={models}
              onChange={(e) => setModel(e.value)}
              placeholder="Select a Model"
              optionValue="code"
              optionLabel="name"
              showClear
            />
            {submitted && !model && (
              <small className="error">Model is Required</small>
            )}
          </div>
          <div className="field col-6 vehicle-variant">
            <label>
              Variant <span className="error">*</span>
            </label>
            <Dropdown
              className="w-full"
              value={variant}
              options={variants}
              onChange={(e) => setVariant(e.value)}
              placeholder="Select a Variant"
              optionValue="code"
              optionLabel="name"
            />
            {submitted && !variant && (
              <small className="error">Variant is Required</small>
            )}
          </div>
          {/* <div className="field col-6 vehicle-type">
            <label>
              Type <span className="error">*</span>
            </label>
            <Dropdown
              className="w-full"
              value={typeId}
              options={typeIds}
              onChange={(e) => setTypeId(e.value)}
              placeholder="Select a Type"
              optionValue="code"
              optionLabel="name"
            />
            {submitted && !typeId && (
              <small className="error">Vehicle Type is Required</small>
            )}
          </div> */}
        </div>
      </Dialog>
      <Dialog
        header={
          isChangingBillingAddress
            ? "Change Billing Address"
            : "Change Shipping Address"
        }
        footer={footerContent2}
        visible={isChangingAddress}
        onHide={handleCancel}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "90%" }}
      >
        <div className="address-dialog-wrapper">
          <div className="grid">
            <div className="add-new-address-title col-12 mb-0">Add New</div>
            <div className="field col-6">
              <label htmlFor="detail_address">
                Address 1 <span className="error">*</span>
              </label>
              <InputText
                className="w-full"
                id="detail_address"
                type="text"
                value={address?.detail_address}
                onChange={(e) => onInputChange(e, "detail_address")}
                placeholder="Detail Address"
                disabled={newBillingAddress || newShippingAddress}
              />
              {submitted && address?.detail_address === "" && (
                <small className="error">Address 1 is Required</small>
              )}
            </div>
            <div className="field col-6">
              <label htmlFor="street_address">
                Address 2 <span className="error">*</span>
              </label>
              <InputText
                className="w-full"
                id="street_address"
                type="text"
                value={address?.street_address}
                onChange={(e) => onInputChange(e, "street_address")}
                placeholder="Street Address"
                disabled={newBillingAddress || newShippingAddress}
              />
              {submitted && address?.street_address === "" && (
                <small className="error">Address 2 is Required</small>
              )}
            </div>
            <div className="field col-6">
              <label htmlFor="landmark">
                Landmark <span className="error">*</span>
              </label>
              <InputText
                className="w-full"
                id="landmark"
                type="text"
                value={address?.landmark}
                onChange={(e) => onInputChange(e, "landmark")}
                placeholder="Landmark"
                disabled={newBillingAddress || newShippingAddress}
              />
              {submitted && address?.landmark === "" && (
                <small className="error">Landmark is Required</small>
              )}
            </div>
            <div className="field col-6">
              <label htmlFor="pincode">
                Pincode <span className="error">*</span>
              </label>
              <InputText
                className="w-full"
                id="pincode"
                type="text"
                value={address?.pincode}
                onChange={(e) => onInputChange(e, "pincode")}
                placeholder="Pincode"
                maxLength={6}
                disabled={newBillingAddress || newShippingAddress}
              />
              {submitted && address?.pincode === "" && (
                <small className="error">Pincode is Required</small>
              )}
               {pincodeError && (
          <small className="error">{pincodeError}</small>
      )}
              
            </div>

            <div className="field col-6">
              <label htmlFor="city">
                State <span className="error">*</span>
              </label>
              <Dropdown
                className="w-full"
                value={selectedState}
                options={stateOptions}
                onChange={(e) => setSelectedState(e.value)}
                placeholder="Select a State"
                optionValue="code"
                optionLabel="name"
                showClear
                disabled={newBillingAddress || newShippingAddress}
                virtualScrollerOptions={{ itemSize: 38 }}
                filter
              />
              {submitted && !selectedState && (
                <small className="error">State is Required</small>
              )}
            </div>
            <div className="field col-6">
              <label htmlFor="city">
                City <span className="error">*</span>
              </label>
              <Dropdown
                className="w-full"
                value={selectedCity}
                options={cityOptions}
                onChange={(e) => setSelectedCity(e.value)}
                placeholder="Select a City"
                optionValue="code"
                optionLabel="name"
                showClear
                // disabled={newBillingAddress || newShippingAddress}
                disabled={!selectedState}
                virtualScrollerOptions={{ itemSize: 38 }}
                filter
              />
              {submitted && !selectedCity && (
                <small className="error">City is Required</small>
              )}
            </div>

            <div className="field col-6">
              <label htmlFor="city">
                Country <span className="error">*</span>
              </label>
              <Dropdown
                className="w-full"
                value={22}
                options={countryOptions}
                onChange={(e) => setSelectedCountry(e.value || 22)}
                placeholder="Select a Country"
                optionValue="code"
                optionLabel="name"
                showClear
                disabled
                virtualScrollerOptions={{ itemSize: 38 }}
                filter
              />
              {/* {submitted && !selectedCountry && (
                <small className="error">Country is Required</small>
              )} */}
            </div>
            {/* <div className="field col-6">
              <label htmlFor="is_default">Set as Default Address</label>
              <div className="is_default-wrapper">
                {defaultAddressOptions.map((category) => {
                  return (
                    <div key={category.key} className="flex align-items-center">
                      <RadioButton
                        disabled={newBillingAddress || newShippingAddress}
                        inputId={category.key}
                        name="category"
                        value={category}
                        onChange={(e) => setIsDefaultAddress(e.value)}
                        checked={isDefaultAddress?.key === category.key || false}
                        />
                      <label htmlFor={category.key} className="ml-2">
                        {category.name}
                      </label>
                    </div>
                  );
                })}
              </div>
                {submitted && !isDefaultAddress?.key && (
                  <small className="error">Is Default is Required</small>
                )}
            </div> */}
          </div>
          {/* <div className="divide text-center">OR</div>
          <div className="grid">
            <div className="add-new-address-title col-12 mb-0">
              Select From Existing
            </div>
            <div className="field col-12">
              <Dropdown
                disabled={
                  !!address?.detail_address ||
                  !!address?.landmark ||
                  !!address?.pincode ||
                  !!address?.street_address ||
                  !!selectedCity ||
                  !!selectedCountry ||
                  !!selectedState
                }
                className="w-full"
                value={
                  isChangingBillingAddress
                    ? newBillingAddress
                    : newShippingAddress
                }
                options={
                  isChangingBillingAddress
                    ? billingAddresses
                    : shippingAddresses
                }
                onChange={(e) => handleNewAddress(e)}
                placeholder="Select an Address"
                optionValue="address_id"
                optionLabel="street_address"
                showClear
              />
            </div>
          </div> */}
        </div>
      </Dialog>
      <Dialog
        header="Available Coupons"
        visible={showCouponDialog}
        style={{ width: "40vw" }}
        onHide={() => setShowCouponDialog(false)}
      >
        <div className="coupon-list-wrapper">
          <span className="p-input-icon-right discount-input-wrapper mt-2">
            <InputText
              placeholder="Enter Promocode"
              value={currentCouponCode}
              onChange={(e) => setCurrentCouponCode(e.target.value)}
            />
            <Button
              label="Apply"
              className="discount-apply-btn"
              onClick={fetchCartSummary}
            />
          </span>
          {allCouponList.length !== 0 &&
            allCouponList.map((coupon: any) => (
              <div className="single-coupon-wrapper">
                <div className="single-coupon-upper">
                  <div className="single-coupon-title-wrapper">
                    <div className="single-coupon-title">
                      {coupon?.name ?? "Name Not Available"}
                    </div>
                    <div
                      className="single-coupon-apply-btn cursor-pointer"
                      onClick={() => setCurrentCouponCode(coupon.code)}
                    >
                      Apply
                    </div>
                  </div>
                  <div className="single-coupon-description">
                    {coupon?.description ?? "Description Not Available"}
                  </div>
                </div>
                <div className="single-coupon-save-upto">
                  {coupon?.sub_title ?? "Sub Title Not Available"}
                </div>
              </div>
            ))}
        </div>
      </Dialog>
    </div>
  );
};

export default Summary;
