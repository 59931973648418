import React from "react";
import { Container } from "react-bootstrap";

const TermsAndConditions = () => {
  return (
    <Container>
      <h1 style={{ color: "#6C17A4", marginTop: "20px" }}>
        Terms And Conditions
      </h1>
      <div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            CONTRACTUAL RELATIONSHIP
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            Junction of Tyres Private Limited, incorporated under the Companies
            Act, 2013, having its registered office at D-251, T/F, Laxmi Nagar
            DELHI East Delhi DL 110092 IN, operating under the trademarked name
            “MechMiles”, provides holistic 2- Wheeler, 3- Wheeler, Passenger
            Car, Light Commercial Vehicle, Truck services including maintenance,
            repair, etc. The use and access of our website www.MechMiles.in
            (“Website”), and our mobile applications “MechMiles
            (“Applications”), or any products or services in connection with the
            Application or Website (“Services”) shall be governed by these terms
            and conditions (“Terms”). The Website and Applications are together
            called the “Platform”.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            These terms constitute a legally binding and enforceable agreement
            between MechMiles (“Company/we/us/our”) and its Users
            (“User/you/your”) and govern your use of the Platform to browse
            and/or avail the Services displayed by us on the Platform. By
            accessing and using our Services, you agree to be bound by these
            terms, establishing a contractual relationship between you and the
            Company. In case you do not agree to the terms, you may not use or
            access or stop your use or access of our Services. These Terms
            expressly supersede prior written agreements with you. The Company
            requests the User to carefully go through these terms &amp;
            conditions (“Terms and Conditions” or “Terms”) prior to accessing
            the Platform or availing Services (as defined below) using the
            Platform.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            The Company reserves the right, in its sole discretion, to change,
            modify or amend the Terms for complying with the legal or regulatory
            framework and for other legitimate business purposes, at any time,
            and the Company will post the amended Terms at the domain of
            www.MechMiles.in/terms. It is your responsibility to review the
            Terms for any changes and you are encouraged to check the Terms
            frequently. The Company shall not be under an obligation to notify
            Users of any changes to the Terms and Conditions. If you continue to
            use the Platform, Content (as defined below) and/or Services after
            the updated Terms and Conditions have been published, it shall be
            deemed that you have read and understood and accept the updated
            Terms and Conditions. Any reference to Terms and Conditions, made
            herein shall refer to the latest version of the Terms and
            Conditions.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            No information provided on the Platform shall be considered a
            substitute for your independent investigation. These Terms are
            collectively an electronic record in terms of the Indian Contract
            Act, 1872; the Information Technology Act, 2000, the rules made
            thereunder; and the amended provisions pertaining to the electronic
            records in various other statutes as amended by the Information
            Technology Act, 2000. These Terms are generated by a computer system
            and do not require any digital or electronic signature.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            DEFINITIONS
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            “Content” will include (but is not limited to) reviews, images,
            photos, audio, video, location data, nearby places, and all other
            forms of information or data. "Your Content" or "User Content" means
            Content that you upload, share or transmit, through or in connection
            with the Services, such as likes, ratings, reviews, images, photos,
            messages, profile information, and any other materials that you
            publicly display or displayed in your account profile. "MechMiles
            Content" means Content that the Company creates and makes available
            in connection with the Services including, but not limited to,
            visual interfaces, interactive features, graphics, design,
            compilation, computer code, products, software, aggregate ratings,
            reports and other usage-related data in connection with activities
            associated with your account and all other elements and components
            of the Services excluding Your Content.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            “Estimated Cost” will include the service provider’s approximation
            of what the Service is likely to cost. The purpose of cost
            estimation is to predict the quantity, cost, and price of the
            resources required to complete a job.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            “Membership Plan(s)” means subscription or other kinds of membership
            offers provided by the Company to Users from time to time through
            online or offline media. The terms, conditions and prices of such
            plans shall be communicated through the applicable media at the time
            of issuance of such offers.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            “Service(s)” means services provided through the Platform, as
            described in further detail under Clause 3 below. The Services
            provided through the Platform may change from time to time, at the
            sole discretion of the Company.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            “User” or “You” shall mean any individual/entity who owns a 2-
            Wheeler, 3- Wheeler, Passenger Car, Light Commercial Vehicle, Truck
            in urban centres especially metros, who is desirous of availing
            Services on the Platform as and who are registered on the Platform
            for this purpose.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            SERVICES
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            The Services constitute a technology platform that enables Users of
            the Company’s Platform, provided as part of the Services, to arrange
            and schedule repair or maintenance services for your 2- Wheeler, 3-
            Wheeler, Passenger Car, Light Commercial Vehicle, Truck with Company
            operated services centres &amp; independent service provider
            providers. Once you submit a request, we may provide you with a list
            of possible service providers along with their experience
            information.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            The Company also provides towing service to get your vehicle out of
            the situation and back on road on a chargeable basis.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You acknowledge that the Company does not provide any repair and
            maintenance services for its customers and that all such above
            stated services are also provided by other independent service
            provider contractors/ franchisee/licensee who are not employed by
            the Company.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You acknowledge that even after accepting and/ or confirming an
            appointment for service/repair of your 2- Wheeler, 3- Wheeler,
            Passenger Car, Light Commercial Vehicle, Truck, the Company cannot
            guarantee its availability. At the time of your appointment request
            to the Company, the Company will make reasonable efforts to connect
            you with the service providers so that you can avail automobile
            repair service at or near your location.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You acknowledge that the cost and time period for the
            services/repairs are estimated in accordance with your request and
            are proximate in nature.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            ELIGIBILITY
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You hereby represent and warrant that you are eighteen (18) years of
            age or above and are fully able and competent to understand and
            agree to the terms, conditions, obligations, affirmations,
            representations, and warranties set forth in these terms, and to
            abide by and comply with the Terms stated therein.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You are in compliance with all laws and regulations in the country
            in which you live when you access and use the Services. You agree to
            use the Services only in compliance with these Terms and applicable
            laws, and in a manner that does not violate our legal rights or
            those of any third-parties.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            If you will be using the Services on behalf of an organization, you
            agree to these Terms on behalf of that organization and you
            represent that you have the authority to act on behalf of your
            organization.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            USE OF SERVICES
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You must create an account in order to use some of the features
            offered by the Platform. Use of any personal information you provide
            to us during the account creation process is governed by our Privacy
            Policy available on our app/website. You must keep your password
            confidential and you are solely responsible for maintaining the
            confidentiality and security of your account details, all changes
            and updates submitted through your account, and all activities that
            occur in connection with your account.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            Following sign-up, we will make certain Services available to you
            free of charge. The Services will include the ability to search for
            service providers for automotive repair or servicing work, the
            ability to view detailed profiles of service providers and their
            service prices and rating information, and the ability to post
            feedback and ratings in relation to any service provider you have
            used as a result of your use of the Platform. We reserve the right
            to add, change or remove Services from our Platform at any time, and
            may make certain Services chargeable at our discretion.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You must only book a Service if you have a genuine requirement for
            the work detailed to be performed. You must only post feedback in
            relation to a service provider if you have availed Service from them
            as a result of your use of the Platform.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            SUPPORT
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            The Company offers an email as well as phone call-based support
            system along with an online knowledge pool of FAQs. In case you
            require any assistance or support, you may access support resources
            or contact our support by emailing at info@MechMiles.com or calling
            our helpline at +91 7094431033 (Monday to Sunday between 7 am and 9
            pm).
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            The Company shall revert to every complaint at the earliest upon
            receipt of the complaint during the business days. Further, the
            Company shall take the best possible efforts to redress the
            complaint within 7 days of receipt of the complaint. Any suggestions
            by the Company regarding the use of the Services shall not be
            construed as a warranty.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            The User agrees and acknowledges that the Company shall address and
            attempt to resolve the complaint received in accordance with the
            standard policies and procedures adopted by the Company, the User’s
            disapproval/discontent with the outcome/mode of redressal shall not
            be deemed to mean non-redressal of the complaint by the Company. Any
            suggestions by the Company regarding the use of the Service shall
            not be construed as a warranty.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            The Company shall take reasonable care and measures required for the
            safety of your 2- Wheeler, 3- Wheeler, Passenger Car, Light
            Commercial Vehicle, Truck in our possession. In the event, the
            User’s 2- Wheeler, 3- Wheeler, Passenger Car, Light Commercial
            Vehicle, Truck is stolen while in the possession of the Company, the
            Company will take all reasonable measures and extend necessary
            support to the User for recovering their 2- Wheeler, 3- Wheeler,
            Passenger Car, Light Commercial Vehicle, Truck, such as, promptly
            informing the User of the occurrence of the theft, immediately
            report the theft to the nearest police station, co-operate and aid
            the local authorities to recover the vehicle, etc.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            PROHIBITED USAGE OF THE PLATFORM
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            Users shall be prohibited from carrying out any illegal activity in
            the Platform including but not limited to acts mentioned are
            violating or attempting to violate the integrity or security of the
            Platform; transmitting any information on or through the Platform
            that is disruptive or competitive to the provision of our Services;
            intentionally submitting on the Platform any incomplete, false or
            inaccurate information; making any unsolicited communications to
            other Users; using any engine, software, tool, agent or other device
            or mechanism (such as spiders, robots, avatars or intelligent
            agents) to navigate or search the Platform; circumventing or
            disabling any digital rights management, usage rules, or other
            security features of the Platform. Any unlawful activities on the
            Platform are prohibited by the laws of India.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            The Company shall, upon obtaining knowledge by itself or being
            brought to actual knowledge by an affected person in writing or
            through email signed with electronic signature about any such
            information as mentioned above, be entitled to disable such
            information that is in contravention of this Clause 7. We shall also
            be entitled to preserve such information and associated records for
            at least 90 days for production to governmental authorities for
            investigation purposes. In case of non-compliance with any
            applicable laws, rules or regulations, or the Agreement (including
            the Privacy Policy) by a User, we shall have the right to
            immediately terminate your access or usage rights to the Platform
            and Services and to remove non-compliant information from the
            Platform.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            We may disclose or transfer User-generated information to our
            affiliates or governmental authorities in such manner as permitted
            or required by applicable law, and you hereby consent to such
            transfer by us. In accordance with the applicable laws, we shall
            transfer sensitive personal data or information including any
            information, to any other body corporate or a person in India, or
            located in any other country, that ensures the same level of data
            protection that is adhered to by us, only if such transfer is
            necessary for the performance of the lawful contract between the
            Company or any person on its behalf and the User or where the User
            has consented to data transfer.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            All comments, feedback, reviews, suggestions, ideas, and other
            submissions disclosed, submitted or offered to the Company on or by
            the Platform or otherwise disclosed, submitted or offered in
            connection with your use of the Platform (collectively, the
            "Comments") shall be and remain Company’s property. Such disclosure,
            submission or offer of any Comments shall constitute an assignment
            to the Company of all worldwide rights, titles and interests and
            shall not be limited in any way in its use, commercial or otherwise,
            of any Comments. Thus, the Company will own exclusively all such
            rights, titles and interests and shall not be limited in any way in
            its use, commercial or otherwise, of any Comments. The Company shall
            be under no obligation (1) to maintain any Comments in confidence;
            (2) to pay to User any compensation for any Comments; or (3) to
            respond to any User Comments.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You agree that no Comments submitted by you to the Platform will
            violate any right of any third party, including copyright,
            trademark, privacy or other personal or proprietary rights(s). You
            further agree that no Comments submitted by you to the Platform will
            be or contain libellous or otherwise unlawful, abusive or obscene
            material. You are and shall remain solely responsible for the
            content of any Comments you make.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            Additionally, you agree not to upload, post, e-mail or otherwise
            send or transmit any material designed to interrupt, destroy or
            limit the functionality of our service on the Platform. We may
            terminate or restrict your use of our Platform and Services if you
            violate these Terms or engage in any illegal or fraudulent use of
            our Platform or Services.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You agree that neither you nor anyone acting on your behalf, will
            directly or indirectly, publish, post, or otherwise issue any
            defamatory comments or remarks, in writing, orally or electronic
            format, against the Company, and its respective employees, clients,
            operations or services. Any violation of these Terms or intimidation
            for such will make you liable to compensate the Company for losses
            caused due to such acts of yours. In order to recover the damages
            and losses and prosecute you for illegal defamation, the Company
            shall be entitled to initiate civil and/or criminal proceedings
            against you as per the appropriate law, by the Company.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            USER DATA
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You represent and warrant that any information provided by you in
            connection with your use of the Platform is and shall remain true,
            accurate and complete and that you will maintain and update such
            information regularly. You agree that if any information you provide
            to us is false, inaccurate, obsolete or incomplete, we may terminate
            your use of and access to the Platform.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You agree that the Company, may, in accordance with its Privacy
            Policy, collect and use your information, technical data, and
            related information for the validation of the documents submitted.
            The Company may use information and data pertaining to your use of
            the Services for analytics, trends identification, and purposes of
            statistics to further enhance the effectiveness and efficiency of
            the Platform and transfer the same to its group companies and
            service providers in furtherance of your access to these Services.
            You provide your consent to such use and sharing of your
            information.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            Subject to applicable laws, the Company may be directed by law
            enforcement agencies or the government and related bodies to
            disclose data in relation to Users in connection with criminal
            proceedings. You understand and agree that in such instances, the
            Company shall have the right to share such data with relevant
            agencies or bodies.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            INTELLECTUAL PROPERTY RIGHTS
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            The Platform is designed, updated and maintained independently by
            the Company. When you access the Platform, you do so under a license
            granted from us. All rights, titled and interest in text, graphics,
            audio, design and other works on the Platform together with all the
            intellectual property related thereto including inter alia the
            domain name, copyrights, the look and feel of the Platform, the
            trade name and trademarks, patents and designs are the sole property
            of the Company or its licensors. All service provider trademarks and
            brand names that appear on the Platform are the property of their
            respective owners. Content on the Platform is for personal use only,
            unless permitted otherwise. Any alteration of the material or use of
            the material contained in the Platform for any commercial purpose is
            a violation of the intellectual property of the Company and/or its
            affiliates or associates or of its service provider information
            providers. We reserve the right to suspend or cancel the service at
            any time if a computer virus, bug, or other technical problem
            corrupts the security or proper administration of the service.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You acknowledge and agree that through your use of the Platform or
            by availing any Services through or at the Platform, no right
            (empress or implied) is granted to you in respect of such Content.
            The Company reserves the right to change or modify the Content from
            time to time at its sole discretion.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            LICENSE
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            Subject to your compliance with these Terms, the Company grants you
            a limited, non-exclusive, non-sublicensable, revocable,
            non-transferable licence to (i) access and use the Applications on
            your personal device solely in connection with your use of the
            Services; and (ii) access and use any content, information, and
            related materials that may be made available through the Services,
            in each case, solely in connection with your use of the Services.
            The Company and its licensors reserve all rights not expressly
            granted herein.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You will not use, copy, adapt, modify, prepare derivative works
            based upon, distribute, license, sell, transfer, publicly display,
            publicly perform, transmit, stream, broadcast or otherwise exploit
            the Platform and Services, except as expressly permitted in the
            Terms and Conditions. No licenses or rights are granted to you by
            implication or otherwise under any intellectual property rights
            owned or controlled by the Company or its licensors, except for the
            licenses and rights expressly granted in the Terms and Conditions.
            The Platform and the intellectual property rights vested therein are
            owned by the Company.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            LIMITATION OF LIABILITY
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            The Company does not warrant that the results that are obtained from
            the use of the Service will be accurate or reliable. You agree that
            except as expressly stated by the Company, the Services and all the
            products provided/delivered to you through the Service are provided
            'as is' and 'as available for your use, and are without any
            representation, warranties or conditions of any kind, either express
            or implied, including all implied warranties. While we endeavour to
            ensure your access and use of the Platform is safe, we cannot and do
            not represent and warrant that the Platform or its server will be
            error-free, uninterrupted, free from unauthorized access, and/or
            otherwise meet your requirements.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            In no case are our directors, employees, affiliates, agents,
            contractors, interns, suppliers, service providers or licensors,
            liable for indirect, incidental, special, exemplary, punitive or
            consequential damages, including lost profits, lost data, personal
            injury or property damage related to, in connection with, or
            otherwise resulting from any use of the Services, even if the
            Company has been advised of the possibility of such damages.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            The Company shall not be liable for any damages, liability or losses
            arising out of your use of or reliance on the Services or your
            inability to access or use the Services; or any transaction or
            relationship between you and service provider or any delay or
            failure in performance resulting from causes beyond Company’s
            reasonable control.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            EXEMPTIONS TO LIABILITY OF COMPANY
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            User further agrees and confirms that the Company shall not be
            responsible, in any manner whatsoever, for any delay/unavailability
            of Services or failure to meet its obligations under the Terms and
            Conditions, which may be caused, directly or indirectly, due to:
            your failure to cooperate; your unavailability and/or
            unresponsiveness; your failure to provide accurate and complete
            information; your failure to provide or facilitate the submission of
            User materials in a timely manner; or any event beyond the Company’s
            reasonable control.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            User further agrees that the Company shall be only liable to provide
            assistance and cooperation in the event of theft or accident of the
            vehicles while in the premises of the service partners. The Company
            shall provide all requisite assistance in a timely manner in respect
            of any insurance claims, reporting to police and/or any other formal
            steps as may be required but shall not be liable to compensate the
            User for its loss unless the same is caused due to an act solely
            attributable to the Company or due to gross negligence of Company
            (and not the service partner).
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            BILLING/CHARGES
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You acknowledge that by using the Services, you may be subject to
            charges from a service provider for the services you receive
            ("Charges"). The Company, as a limited payment collection agent for
            a service provider, will facilitate your payment of the applicable
            Charges after you have received services through your use of the
            Service. The Charges will be handled as if you had directly paid the
            service provider service provider. All Charges will include all
            applicable taxes unless otherwise specified by law. Unless otherwise
            stated by the Company, all Charges paid by you are final and
            non-refundable.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            The Charges may be updated or changed at any time as per the
            analysis of the services provider or your new requests. The updated
            Charges shall be communicated to you by the Company and only upon
            your approval of the Charges, the services will be provided by the
            service provider service provider.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You acknowledge that the Charges from a service provider service
            provider shall be duly paid and on account of default in payment,
            the same shall be considered as a breach of these terms. In the
            event you commit such a default/breach, you shall be liable to pay
            the liquidated damages of an amount equivalent to the value of the
            said services. You hereby authorize the Company to deduct or collect
            the amount payable as liquidated damages through such means as the
            Company may exercise.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            In case of any material changes have been made and there is a
            default in payment by the User, the Company has a right to keep the
            vehicle in its possession until and unless payment in full has been
            made by the User along with other costs if applicable. In the event
            you fail to make the payment of the Charges despite the passage of
            more than 90 days since the completion of the services, the Company
            shall be entitled to recover the same by selling off your vehicle.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You acknowledge that you will be liable for the payment of towing
            services provided by the Company as per the Company’s Charges.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            Membership Plans will be offered by the company from time to time
            through its website, app, social and print media. You can purchase
            the membership through the Platform by adding it into the cart,
            checking out and making the payment.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            The Company reserves the right to alter/modify/suspend/discontinue
            any terms and conditions associated with the Fee including but not
            limited to the period, rate, services included in the membership.
            Further, the Company shall give you notice of any change made to the
            features of the membership prior to the expiry of the same. We will
            provide you with advance notice of any change in Fees.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            Payment for the Services by the Users may be processed through the
            service provider internet payment service providers. By purchasing
            any Services through the Platform, the User hereby consents and
            agrees to abide by such service provider Internet payment service
            providers’ applicable terms and conditions and privacy policies. The
            User agrees and acknowledges that the Company has no responsibility
            or liability under the terms and conditions and policies of the
            service provider internet payment service provider. In the event
            such service provider internet payment service providers’ customer
            terms and conditions or privacy policies are not acceptable to you,
            please do not purchase such Services through the Platform.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            Additionally, we have no authority over, or liability for, the
            delivery, safety, legality or any other such aspect of transactions
            or services availed by the Users through the service provider
            internet payment service providers. The Company will not be
            responsible for ensuring that any service provider internet payment
            service provider the Users operate with, will carry out the complete
            transaction, or has the appropriate authority to do so. In the
            event, the Users experience any disruptions or problems while making
            the payment for the Services through the service provider internet
            payment service providers, or if the User has a dispute with such
            service provider internet payment service providers, the User should
            resolve the disruptions, problems or disputes directly with that
            service provider internet payment service provider.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            CANCELLATION AND REFUND
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You have no right to request a refund or cancellation for an
            accepted service from the Platform. Once an order is placed (i.e.,
            upon acceptance of the estimated cost by the User), you shall not be
            entitled to cancel such order or seek a refund for the Services
            provided.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            DISPUTE RESOLUTION
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            In case of any dispute or difference between the User and the
            Company, regarding the interpretation of these terms, any claims or
            any disputes arising out of or pursuant to these terms, the same
            shall be referred to a sole arbitrator who shall be appointed by
            mutual consent. The proceedings shall be conducted under the
            provisions of the Arbitration and Conciliation Act, 1996, and the
            courts of Delhi shall have sole jurisdiction over the matter and/or
            for the enforcement as the case may be.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            GOVERNING LAW
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            These Terms and any separate agreements whereby we provide you
            Services shall be governed by and construed in accordance with the
            laws of India and jurisdiction of Mumbai, India.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            GENERAL PROVISIONS
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            Severability: If any provision of these Terms is found to be illegal
            or unenforceable by a court or other competent authority, the
            remaining provisions of these Terms will remain in effect. If a part
            of an unlawful or unenforceable provision could be made lawful or
            enforceable if that part were removed, the rest of the provision
            will remain in effect (unless that would contradict the clear
            intention of the clause, in which case the entirety of the relevant
            provision will be deemed to be deleted).
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            Waiver: Any failure by the Company to enforce or exercise any
            provision of the Terms, or any related right, shall not constitute a
            waiver by the Company of that provision or right.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            The Company provides these Terms so that you are aware of the Terms
            that apply to your use of the Platform and Services. You acknowledge
            that the Company has given you a reasonable opportunity to review
            these Terms and you have agreed to them.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            The Terms and Conditions are personal to you and are not assignable
            or transferable by you except with Company’s prior written consent.
            The company may assign, transfer or delegate any of its rights and
            obligations hereunder without any consent.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            All notices under the Terms and Conditions will be in writing and
            will be deemed to have been duly given when received if personally
            delivered or sent by certified or registered mail, return receipt
            requested; when receipt is electronically confirmed, if transmitted
            by facsimile or e-mail; or 2 days after it is sent, if sent for next
            day delivery by recognized overnight delivery service.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            No agency, partnership, joint venture, or employment relationship is
            created as a result of the Terms and Conditions and neither party
            has any authority of any kind to bind the other in any respect.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            TERMINATION
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            The Company may terminate your usage of the Platform or any Service
            at any time for any reason, including breach of the Terms and
            Conditions. The Company has the right (but not the obligation) to
            refuse to grant access to the Platform. Except for the rights and
            licenses granted in these Terms, we reserve all other rights and
            grant no other rights or licenses, implied or otherwise.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            Once temporarily suspended, indefinitely suspended or terminated,
            you may not continue to use the Platform under the same account, a
            different account or re-register under a new account. On termination
            of an account due to the reasons mentioned herein, such User shall
            no longer have access to data, messages and other material kept on
            the Platform by such User.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You may terminate these terms at any time, for any reason. The
            obligations and liabilities incurrent by the parties prior to the
            termination date, for all intents and purposes, will survive the
            termination of these Terms. These Terms will remain in effect until
            either you or we terminate them. If you fail, or we suspect you have
            failed, to comply with any term or provision of these Terms, we may
            terminate this agreement without notice, and you will remain liable
            for all amounts due up to and including the date of termination.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            INDEMNIFICATION
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You shall defend, indemnify, and hold harmless the Company, its
            affiliates/subsidiaries/joint venture partners and each of its, and
            its affiliates’/subsidiaries/joint venture partners’ employees,
            contractors, directors, suppliers and representatives from all
            liabilities, losses, claims, and expenses, including reasonable
            attorneys’ fees, that arise from or relate to your use or misuse of,
            or access to, the Services and Platform; or your violation of the
            Terms and Conditions; or any applicable law, contract, policy,
            regulation or other obligation. We reserve the right to assume the
            exclusive defence and control of any matter otherwise subject to
            indemnification by you, in which event you will assist and cooperate
            with us in connection therewith.
          </p>
        </div>
        <div
          style={{
            textAlign: "left",
            color: "rgb(33, 37, 41)",
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: "16px",
          }}
        >
          <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
            CONTACTING YOU
          </h3>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You agree that we may contact you through telephone, email, SMS, or
            any other means of communication for the purpose of obtaining
            feedback in relation to Platform or our Services; obtaining feedback
            in relation to any other Users listed on the Platform; or resolving
            any complaints, information, or queries by other Users regarding
            your critical Content.
          </p>
          <p style={{ color: "rgb(108, 23, 164)", fontSize: "15px" }}>
            You agree to provide your fullest co-operation further to such
            communication by the Company. By submitting suggestions or other
            feedback regarding our Services/Platform, you agree that we can use
            and share such feedback for any purpose without any compensation to
            you and we are under no obligation to keep such feedback
            confidential.
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <h3 style={{ color: "rgb(108, 23, 164)", fontSize: "20px" }}>
              SHIPPING AND DELIVERY POLICY
            </h3>
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Thank you for choosing MechMiles for your purchases. We appreciate
              your support of our products/services. Please be aware that
              MechMiles does not offer shipping or delivery services.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
            }}
          ></p>
        </div>
      </div>
    </Container>
  );
};

export default TermsAndConditions;
