import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectItems } from "../../features/cart/cartSlice";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { Container } from "react-bootstrap";
import { RiGiftFill } from "react-icons/ri";
import yokohama from "../../assets/images/TyreBrandLogos/yokohama_mini.png";
import sampleTyreImage from "../../assets/images/sample_tyre.png";
import serviceSampleImage from "../../assets/images/serviceSampleImage.png";
import servicePointImage from "../../assets/images/selected-service-point-img.png";
import {
  CiClock2,
  CiLocationArrow1,
  CiLocationOn,
  CiSquarePlus,
} from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { Image } from "primereact/image";
import addMorePlus from "../../assets/images/add-more-plus.png";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { AutoComplete } from "primereact/autocomplete";
import locationPin from "../../assets/images/location_pin.png";
import { Calendar } from "primereact/calendar";
import "./Cart.css";
import { CountryService } from "../../service/CountryService";
import {
  MdDirections,
  MdDoNotDisturb,
  MdGpsFixed,
  MdLocationOn,
  MdOutlineCalendarMonth,
  MdOutlineKeyboardArrowDown,
  MdSchedule,
  MdSearch,
} from "react-icons/md";
import { StarPicker } from "react-star-picker";
import HorizontalDatePicker from "../../components/HorizontalDatePicker/HorizontalDatePicker";
import { InputText } from "primereact/inputtext";
import useCartAPI from "../../api/useCartAPI";
import useHeroAPI from "../../api/useHeroAPI";
import usePreDefinedListAPI from "../../api/usePreDefinedListAPI";
import servicePointImgMini from "../../assets/images/service_point_img_mini.png";
import { isWithinInterval } from "date-fns";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { updateCartQuantity } from "../../features/cart/cartSlice"; // Adjust the path as necessary
import { setLoading } from "../../redux/loadingSlice";
import noProductFoundImage from "../../assets/images/product-not-found.png";
import { refreshProductList } from "../../redux/productSlice";

interface Product {
  id?: string;
  prodImg?: string;
  prodName?: string;
  prodModel?: string;
  prodPrice?: string;
  quantity?: number;
  prodCompany?: string;
  prodStar?: string;
  prodReviewCount?: string;
  prodBestSeller?: boolean;
  prodBrand?: string;
  // Add more properties as needed
}
interface CartState {
  items: Product[];
}

interface RootState {
  cart: CartState;
}

interface CartSummary {
  subtotal: number;
  gst: number;
  total: number;
}

interface UserLocation {
  latitude: number;
  longitude: number;
}

const Cart = () => {
  const basePath = process.env.REACT_APP_BASE_PATH;

  const isShowPriceVar = "is_show_price";
  const roleCorporateAdmin = "ROLE_CORPORATE_ADMIN";
  const roleCorporateUser = "ROLE_CORPORATE_USER";

  const { predefinedFunc } = useHeroAPI();
  const { getServicePointList } = usePreDefinedListAPI();
  const {
    cartList,
    cartRemoveItem,
    cartSave,
    cartReduceItem,
    cartSummery,
    cartCount,
    moveToWishlist
  } = useCartAPI();

  const toast = useRef<Toast>(null) as any;

  const dispatch = useDispatch();
  const loading = useSelector((state: any) => state.loading);

  const [userId, setUserId] = useState(null) as any;
  const [userRole, setUserRole] = useState(null) as any;
  const [products, setProducts] = useState<Product[]>([]);
  const [product, setProduct] = useState([]) as any;
  const [servicePoints, setServicePoints] = useState([]) as any;
  const [selectedServicePointData, setSelectedServicePointData] = useState([]) as any;
  const [cartSummary, setCartSummary] = useState<CartSummary>({subtotal: 0,gst: 0,total: 0,});
  const [pickedDateState, setPickedDateState] = useState(null) as any;
  const [pickedTime, setPickedTime] = useState(null) as any;
  const [pickedTimeOptions, setPickedTimeOptions] = useState(null) as any;
  const [pickedTimeState, setPickedTimeState] = useState(null);
  const [isProceeding, setIsProceeding] = useState(false);
  const [city, setCity] = useState([]) as any;
  const [selectedServicePoint, setSelectedServicePoint] = useState(null) as any;
  const [filteredServicePoint, setFilteredServicePoint] = useState(null) as any;
  const [isLocationSelected, setIsLocationSelected] = useState(false);
  const [isSchedulingAppointment, setIsSchedulingAppointment] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [visible, setVisible] = useState(false);
  const [isShowPrice, setIsShowPrice] = useState(false) as any;
  const [userLocation, setUserLocation] = useState<UserLocation | null>(null);


  const fetchCartProducts = async () => {
    const payload = {
      user_id: userId,
      is_wishList: false,
    };

    const cartResponse: any = await cartList(payload);
    const countResponse: any = await cartCount(payload);

    if (cartResponse?.status === 200) {
      const mappedProducts = cartResponse?.data?.data?.map((item: any) => ({
        id: String(item?.product_id ?? item?.service_id) ?? 0,
        prodImg: item?.product?.document_as_product?.length > 0 ? item?.product?.document_as_product[0]?.document?.file_path : serviceSampleImage,
        prodName: (item?.product?.product_name ?? item?.service?.service_name) ?? "Not Available",
        prodPrice: String(item?.total_amount) ?? 0,
        quantity: item?.item_quantity ?? 0,
        prodBrand: item?.service_id ? null : item?.product?.brand?.thumbnail ?? yokohama,
        prodTotal: String(item?.total_amount * item?.item_quantity) ?? 0,
        cartItemId: item?.cart_item_id ?? 0,
        variantName: item?.vehicle?.variant?.name
      }));
      setProducts(mappedProducts);
      dispatch(setLoading(false));
    }
    else {
      dispatch(setLoading(false));
    }
    if (countResponse.status === 200) {
      dispatch(updateCartQuantity(countResponse?.data?.count));
    }
  };

  const fetchPredefined = async () => {
    const predefinedParentPayload = {
      entity_type: "CITY",
    };
    const predefinedTimePayload = {
      entity_type: "TIME",
    };
    const predefinedCityResponse = await predefinedFunc(
      predefinedParentPayload
    );
    const predefinedTimeResponse = await predefinedFunc(predefinedTimePayload);

    const predefineTIme = predefinedTimeResponse?.data?.data.map(
      (child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      }
    );

    if (predefinedCityResponse.status === 200) {
      setCity(predefinedCityResponse?.data?.data);
    }
    if (predefinedTimeResponse.status === 200) {
      setPickedTimeOptions(predefineTIme);
    }
  };
  const fetchServicePoints = async (predefined_id: any) => {
    const payload = {
      city_id: predefined_id,
      lat: userLocation?.latitude,
      lng: userLocation?.longitude,
    };
    const response: any = await getServicePointList(payload, "", "");
    if (response.status === 200) {
      setServicePoints(response.data);
    }
  };

  const fetchCartSummary = async () => {
    const response: any = await cartSummery(null, null);
    if (response.status === 200) {
      setCartSummary(response?.data);
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(setLoading(true));
    // Fetch data here...
    // After data is fetched, set loading to false
    // setTimeout(() => {
    //   dispatch(setLoading(false));
    // }, 300);
  }, [dispatch]);

  useEffect(() => {
    fetchCartSummary();
  }, [products]);

  useEffect(() => {
    const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");

    setUserId(authTokens?.user?.id);
    setUserRole(authTokens?.user?.roles[0]?.code);

    if (userId) {
      fetchCartProducts();
      fetchPredefined();
    }

    if (authTokens?.user?.is_show_price === true) {
      setIsShowPrice(true);
    }
  }, [userId]);

  const fetchUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
          // You can also fetch service points based on this location here
        },
        (error) => {
          console.error("Error Code = " + error.code + " - " + error.message);
          // Handle location error (User denied location access, etc.)
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
      // Handle case where the browser doesn't support Geolocation
    }
  };

  useEffect(() => {
    fetchUserLocation();
    // Other initialization code...
  }, []);

  const handleDateSelect = (date: Date) => {
    const startOfSelectedDate = new Date(date.setHours(0, 0, 0, 0));
    const startOfToday = new Date(new Date().setHours(0, 0, 0, 0));
    const endOfRange = new Date(
      startOfToday.getTime() + 10 * 24 * 60 * 60 * 1000
    );

    const isDateInRange = isWithinInterval(startOfSelectedDate, {
      start: startOfToday,
      end: endOfRange,
    });

    if (isDateInRange) {
      setPickedDateState(startOfSelectedDate);
    } else {
      setPickedDateState(null);
    }
  };

  const panelFooterTemplate = () => {
    const isServicePointSelected = (filteredServicePoint || []).some(
      (servicePoint: any) => servicePoint["name"] === selectedServicePoint
    );
    return (
      <div className="py-2 px-3">
        {isServicePointSelected ? (
          <span>
            <b>{selectedServicePoint}</b> selected.
          </span>
        ) : (
          "No Service Point is Selected."
        )}
      </div>
    );
  };

  const search = (event: any) => {
    setTimeout(() => {
      let _filteredServicePoint: any;

      if (!event.query.trim().length) {
        _filteredServicePoint = [...city];
      } else {
        _filteredServicePoint = city.filter((country: any) => {
          return country.name
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      setFilteredServicePoint(_filteredServicePoint);
    }, 250);
  };

  const itemTemplate = (item: any) => {
    return (
      <div className="auto-complete-text-wrapper">
        <div className="auto-complete-container">
          <Image
            alt={item.name}
            src={locationPin}
            className={`auto-complete-pin-img`}
          // style={{ width: "26px" }}
          />
          <div>{item.name}</div>
        </div>
        <hr />
      </div>
    );
  };

  const gotoSummary = () => {
    setIsProceeding(false);

    const pickedTimeVar: any = pickedTimeOptions.find(
      (time: any) => time.code === pickedTime
    );
    
    navigate(`${basePath}summary`, {
      state: {
        selectedServicePointData,
        pickedDateState,
        pickedTimeVar,
        specialInstructions,
      },
    });
  };

  const footerContent = (
    <div className="select-location-footer">
      <Button
        label="Cancel"
        onClick={() => setIsProceeding(false)}
        className="p-button-text select-location-footer-cancel"
      />
      <Button
        label="Confirm Booking"
        onClick={() => gotoSummary()}
        autoFocus
        disabled={!pickedTime || !pickedDateState}
        className="select-location-footer-confirm"
      />
    </div>
  );

  const cartItems = useSelector((state: RootState) => state.cart.items);

  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate(`${basePath}products`);
  };

  const increaseQuantity = async (id: string) => {
    const selectedProduct: any = products.find(
      (product: any) => product.id === id
    );

    if (selectedProduct && selectedProduct.quantity >= 2 && (userRole === roleCorporateAdmin || userRole === roleCorporateUser)) {
      toast?.current?.show({
        severity: "info",
        summary: "Quantity Limit",
        detail: "You cannot add more than 2 quantities of a single item.",
        life: 3000,
      });
      return;
    }
    const payload = {
      product_id: id,
      item_quantity: 1,
      is_wishList: false,
    };

    const response: any = await cartSave(payload);

    if (response.status === 201) {
      setProducts((prevProducts) =>
        prevProducts.map((product: any) =>
          product.id === id
            ? {
              ...product,
              quantity: (product.quantity ?? 1) + 1,
              prodTotal: (
                parseInt(product.prodTotal) + parseInt(product.prodPrice)
              ).toString(),
            }
            : product
        )
      );
    }
  };

  const decreaseQuantity = async (id: number, cartItemId: string) => {
    const selectedProduct: any = products.find(
      (product: any) => product.id === id
    );
    if (selectedProduct && selectedProduct.quantity <= 1) {
      return;
    }

    const payload = {
      cart_item_id: cartItemId,
    };

    const response: any = await cartReduceItem(payload);

    if (response.status === 200) {
      setProducts((prevProducts) =>
        prevProducts.map((product: any) =>
          product.id === id && (product.quantity ?? 1) > 1
            ? {
              ...product,
              quantity: (product.quantity ?? 1) - 1,
              prodTotal: (
                parseInt(product.prodTotal) - parseInt(product.prodPrice)
              ).toString(),
            }
            : product
        )
      );
    }
  };

  const proceedFunc = () => {
    setIsProceeding(true);
  };

  const handleLocationSelect = (e: any) => {
    setSelectedServicePoint(e.value);
    if (typeof e.value === "object") {
      setIsLocationSelected(true);
    }
  };

  const handleRemoveLocation = () => {
    setSelectedServicePoint(null);
    setIsLocationSelected(false);
    setIsSchedulingAppointment(false);
    setPickedDateState(null);
  };

  const handleScheduleAppointment = (e: any, servicePoint: any) => {
    e.preventDefault();
    setSelectedServicePointData([servicePoint]);
    setIsSchedulingAppointment(true);
  };

  const handleLocationChange = () => {
    setIsSchedulingAppointment(false);
    setPickedDateState(null);
  };

  const removeItemFunc = async () => {
    const response: any = await cartRemoveItem(product?.cartItemId);

    if (response.status === 200) {
      fetchCartProducts();
      const newCartQuantity = products.length;

      dispatch(updateCartQuantity(newCartQuantity));
      setVisible(false);
      return toast?.current?.show({
        severity: "success",
        summary: "Successful",
        detail: `${response?.data?.message}`,
      });
    } else {
      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Failed to remove item from cart`,
      });
    }
  };

  const handleRemoveItem = async (e: any, product: any) => {
    setProduct(product);
    setVisible(true);
  };

  const handleLocationSelection = async (e: any) => {
    fetchServicePoints(e.value.predefined_id);
  };

  const handleTimeChange = (e: any) => {
    setPickedTime(e.value);
  };

  const confirmDeleteFooter = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={removeItemFunc}
        autoFocus
      />
    </div>
  );

  const getDirectionFunc = (lat: any, lng: any) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.rel = "noopener noreferrer";

    // Simulate a click on the link
    document.body.appendChild(link);
    link.click();

    // Clean up the DOM
    document.body.removeChild(link);
  };

  const moveToWishListFunc = async (product: any) => {
    const payload = {
      cart_item_id: product.cartItemId,
    }

    const response: any = await moveToWishlist(payload)
    if (response.status === 200) {
      fetchCartProducts();
      const newCartQuantity = products.length;

      dispatch(updateCartQuantity(newCartQuantity));
      // dispatch(addToProduct(product));
      // dispatch(refreshProductList());
      return toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `Added to wishlist`,
      });
    }
  }

  return (
    <>
      {loading && <div className="loading-overlay">Loading...</div>}
      <Toast ref={toast} />
      <div className="cart-wrapper">
        <Container>
          <div className="cart-header-wrapper">
            <div className="breadcrumbs-wrapper">
              <BreadCrumbs />
            </div>
            <div className="cart-title">Items in your cart</div>
            {/* <div className="cart-offer-wrapper">
              <RiGiftFill className="cart-offer-gift-icon" />
              Diwali Delight: Shop for ₹3,424.00 more to receive a complementary
              gift item
            </div> */}
          </div>

          <div className="cart-products-price-wrapper">
            <div className="cart-products-wrapper">
              <div className="product-headings">
                <div className="product-heading"></div>
                {isShowPrice && (
                  <div className="product-heading price">Price</div>
                )}
                {!isShowPrice && <div className="product-heading price"></div>}
                {products?.length !== 0 && <div className="product-heading quantity">Quantity</div>}
                {isShowPrice && (
                  <div className="product-heading total">Total</div>
                )}
                {!isShowPrice && <div className="product-heading total"></div>}
              </div>
              {products.length === 0 && (
                <div className="no-products">
                  <Image
                    src={noProductFoundImage}
                    alt="No Product Found"
                    width={"200"}
                    height={"200"}
                    className="no-products-image"
                  />
                  <div className="no-products-text">No Products in Cart</div>
                </div>
              )}
              {products.map((product: any) => (
                <div className="cart-product-item" key={product.id}>
                  <Image
                    className="product-image"
                    src={product.prodImg}
                    alt={product.prodName}
                  />
                  <div className="product-details">
                    <Image
                      className="product-brand-img"
                      src={product.prodBrand}
                      alt={product.prodName}
                    />
                    <div className="product-name">{product?.prodName}</div>
                    <div className="product-vehicle">{product?.variantName}</div>
                    <div className="product-model">{product?.prodModel}</div>
                    <div className="product-actions">
                      <Button
                        className="remove-button"
                        onClick={(e: any) => handleRemoveItem(e, product)}
                      >
                        Remove
                      </Button>
                      <Button className="waitlist-button" onClick={() => moveToWishListFunc(product)}>
                        Move to Wishlist
                      </Button>
                    </div>
                  </div>
                  {isShowPrice ? (
                    <div className="product-price-wrapper">
                      <div className="product-price">
                        &#8377; {product.prodPrice}
                      </div>
                    </div>
                  ) : (
                    <div className="product-price-wrapper">
                      <div className="product-price"></div>
                    </div>
                  )}
                  <div className="product-quantity-wrapper">
                    <div className="product-quantity">
                      <button
                        className="quantity-decrease"
                        onClick={() =>
                          decreaseQuantity(product.id, product.cartItemId)
                        }
                      >
                        -
                      </button>
                      <span className="quantity-number">
                        {product.quantity}
                      </span>
                      <button
                        className="quantity-increase"
                        onClick={() => increaseQuantity(product.id)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  {isShowPrice && (
                    <div className="product-total-wrapper">
                      <div className="product-total">
                        &#8377; {product.prodTotal}
                      </div>
                    </div>
                  )}
                  {!isShowPrice && (
                    <div className="product-total-wrapper">
                      <div className="product-total"></div>
                    </div>
                  )}
                </div>
              ))}
              <Button className="add-more-button" onClick={redirectToHome}>
                <Image
                  src={addMorePlus}
                  alt="Add more plus Icon"
                  className="add-more-plus"
                />
                Add more items
              </Button>
            </div>
            <div className="cart-price-container">
              <div className="cart-price-wrapper">
                <div className="cart-summary-card">
                  {/* {isShowPrice ? */}
                  <div>
                    <div className="cart-summary-title">Cart Summary</div>
                    {[cartSummary].map((summary: any) => {
                      return (
                        <div className="cart-summary-details">
                          <div className="cart-summary-row">
                            <span className="cart-summary-label">Subtotal</span>
                            <span className="cart-summary-value">
                              &#8377; {isShowPrice ? summary?.subtotal : `***`}
                            </span>
                          </div>
                          <div className="cart-summary-row">
                            <span className="cart-summary-label">GST</span>
                            <span className="cart-summary-value">
                              &#8377; {isShowPrice ? summary?.gst : `***`}
                            </span>
                          </div>
                          <div className="cart-summary-row total">
                            <span className="cart-summary-label">Total</span>
                            <span className="cart-summary-value">
                              &#8377;{" "}
                              {isShowPrice
                                ? Number(summary?.total).toFixed(2)
                                : `***`}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/* : '' */}
                  {/* } */}
                </div>
              </div>
              <Button
                className="cart-summary-proceed-btn"
                onClick={proceedFunc}
              >
                Proceed
              </Button>
            </div>
          </div>
        </Container>
      </div>
      {/* {cartItems.map((item) => (
        <div key={item.id}>
          <img src={item.prodImg} alt={item.prodName} />
          <h2>{item.prodName}</h2>
          <p>{item.prodModel}</p>
          <p>{item.prodPrice}</p>
        </div>
      ))} */}
      <Dialog
        header="Select a service point"
        visible={isProceeding}
        style={{ width: "70%" }}
        onHide={() => setIsProceeding(false)}
        footer={isSchedulingAppointment ? footerContent : ""}
        className="is-proceeding-header"
      >
        {isLocationSelected && !isSchedulingAppointment && (
          <div className="selected-location-container">
            <div className="selected-location-wrapper">
              <div className="selected-location">
                <MdLocationOn className="selected-location-icon" />
                <span className="selected-location-text">
                  {selectedServicePoint?.name}
                </span>
              </div>
              <Button
                className="change-location-button"
                onClick={handleRemoveLocation}
              >
                Change Location
              </Button>
            </div>
            <div className="store-near-location-wrapper">
              <div className="store-near-location-title">
                Stores Near Your Location
              </div>
              <div className="store-near-location-list">
                {servicePoints.map((servicePoint: any, index: number) => (
                  <div className="service-point-card" key={index}>
                    <div className="service-point-name-wrapper">
                      <div className="service-point-name">
                        {servicePoint.service_point_name}
                      </div>
                      <div className="service-point-distance">
                        {/* {servicePoint.distance || "Distance"} */}
                      </div>
                    </div>
                    <div className="service-point-meta-wrapper">
                      <div className="service-point-opens">
                        <CiClock2 />
                        {`Opens At ${servicePoint.service_point_open_time || "Time"
                          }`}
                      </div>
                      <div className="service-point-address">
                        <CiLocationOn />
                        {servicePoint.service_point_address || "Address"}
                      </div>
                    </div>
                    {/* <div className="service-point-rating">
                      <StarPicker
                        value={parseInt(servicePoint.rating) || "2.5"}
                        halfStars
                        disabled={true}
                        size={15}
                      />
                      {servicePoint.rating || "0"}
                    </div> */}
                    <div className="service-point-actions">
                      <div className="service-point-actions-btns">
                        <Button
                          className="get-direction-button"
                          icon={
                            <CiLocationArrow1 style={{ fontSize: "16px" }} />
                          }
                          label="Get Direction"
                          onClick={() =>
                            getDirectionFunc(servicePoint.lat, servicePoint.lng)
                          }
                        />
                        <Button
                          className="schedule-appointment-button"
                          icon={
                            <MdOutlineCalendarMonth
                              style={{ fontSize: "16px" }}
                            />
                          }
                          label="Select Location"
                          onClick={(e) =>
                            handleScheduleAppointment(e, servicePoint)
                          } // Pass the servicePoint here
                        />
                      </div>
                      <div className="service-point-actions-img-container">
                        {servicePoint?.service_group?.image_url && (
                          <Image
                            src={servicePoint?.service_group?.image_url}
                            className="service-point-actions-img"
                          />
                        )}
                        {!servicePoint?.service_group?.image_url && (
                          <MdDoNotDisturb
                            style={{
                              height: "25px",
                              width: "25",
                              marginTop: "5px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {isLocationSelected && isSchedulingAppointment && (
          <div className="selected-location-container">
            <div className="selected-location-wrapper">
              <div className="selected-location">
                <MdLocationOn className="selected-location-icon" />
                <span className="selected-location-text">
                  {selectedServicePoint?.name}
                </span>
              </div>
              <Button
                className="change-location-button"
                onClick={handleRemoveLocation}
              >
                Change Location
              </Button>
            </div>
            <div className="store-near-location-wrapper">
              <div className="store-near-location-wrapper">
                <div className="store-near-location-title">
                  Stores Near Your Location
                </div>
                <div className="store-near-location-list">
                  {selectedServicePointData.map(
                    (servicePoint: any, index: number) => (
                      <div className="selected-store-point-wrapper">
                        <div className="store-point-info">
                          {/* <img
                            src={servicePoint.image || servicePointImage}
                            alt={servicePoint.service_point_name}
                            className="store-station-image"
                          /> */}
                          <div className="store-point-details">
                            <div className="store-point-name">
                              {servicePoint.service_point_name}
                            </div>
                            <div className="store-point-address">
                              {servicePoint.service_point_address ||
                                "Address Not Available"}
                            </div>
                          </div>
                        </div>
                        <div className="change-store-button-wrapper">
                          <Button
                            className="change-store-button"
                            onClick={handleLocationChange}
                          >
                            Change Service Point
                          </Button>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div className="schedule-your-appointment-wrapper">
                  <div className="store-near-location-title">
                    Select your prefered date & time
                  </div>
                    <p className="store-point-address mb-20">(The Mechmiles team will update you)</p>
                  <div className="schedule-your-appointment-container">
                    <div className="pick-a-date-wrapper width-50">
                      <div className="pick-a-date-title">
                        Pick A Date
                        <Calendar
                          id="pick-a-date-calendar"
                          value={pickedDateState}
                          onChange={(e: any) => setPickedDateState(e.value)}
                          showIcon
                          className="pick-a-date-calendar"
                          placeholder="Select Manually"
                          minDate={new Date(
                            new Date().setDate(new Date().getDate() + 4)
                          )}
                        />
                      </div>
                      <div className="horizontal-pick-a-date">
                        <HorizontalDatePicker
                          startDate={
                            new Date(
                              new Date().setDate(new Date().getDate() + 4)
                            )
                          }
                          endDate={
                            new Date(
                              new Date().getTime() + 10 * 24 * 60 * 60 * 1000
                            )
                          }
                          onDateSelect={handleDateSelect}
                          externalSelectedDate={pickedDateState}
                        />
                      </div>
                    </div>
                    <div className="pick-a-time-wrapper width-50">
                      <div className="pick-a-time-title-wrapper">
                        <div className="pick-a-time-title">Pick A Time</div>
                        <div className="pick-a-time-calender-wrapper">
                          <Dropdown
                            value={pickedTime}
                            onChange={(e) => handleTimeChange(e)}
                            options={pickedTimeOptions}
                            virtualScrollerOptions={{ itemSize: 38 }}
                            optionLabel="name"
                            optionValue="code"
                            placeholder="Select Item"
                            className="pick-a-time-dropdown w-full"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="schedule-appointment-wrapper">
                      <div className="schedule-appointment-title">
                        Special Instruction
                      </div>
                      <InputText
                        value={specialInstructions}
                        onChange={(e: any) =>
                          setSpecialInstructions(e.target.value)
                        }
                        className="schedule-appointment-input"
                        placeholder="Enter Special Instruction"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isLocationSelected && (
          <span className="w-full">
            <AutoComplete
              field="name"
              value={selectedServicePoint}
              suggestions={filteredServicePoint}
              completeMethod={search}
              onChange={(e) => handleLocationSelect(e)}
              itemTemplate={itemTemplate}
              // panelFooterTemplate={panelFooterTemplate}
              className="service-point-auto-complete"
              placeholder="Enter a location"
              onSelect={(e) => handleLocationSelection(e)}
            />
            <div className="auto-complete-icons-wrapper p-input-icon-right">
              <MdSearch className="auto-complete-search-location" />
              <MdGpsFixed className="auto-complete-choose-location" />
            </div>
          </span>
        )}
      </Dialog>
      <Dialog
        header="Confirm Remove"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
        footer={confirmDeleteFooter}
      >
        <p className="m-0">Are you sure you want to remove this cart item?</p>
      </Dialog>
    </>
  );
};

export default Cart;
