import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

import "./Counter.css";

const Counter = () => {
  const [counterReset, setCounterReset] = useState(false);
  return (
    <div className="counter-wrapper">
      <ScrollTrigger
        onEnter={() => setCounterReset(true)}
        onExit={() => setCounterReset(false)}
      >
        {counterReset && (
          <div class="counter-container container">
            <div class="counter-1 single-counter">
              <div class="counter-numbers">
              {/* <CountUp start={0} end={2500} duration={2} delay={0} /> */}
                2500+
              </div>
              <div className="counter-text">Service Point</div>
            </div>
            <hr />
            <div class="counter-2 single-counter">
              <div class="counter-numbers">
                {/* <CountUp start={0} end={20000} duration={2} delay={0} /> */}
                20000
              </div>
              <div className="counter-text">Customer Serviced</div>
            </div>
            <hr />
            <div class="counter-3 single-counter">
              <div class="counter-numbers">
                {/* <CountUp start={0} end={10} duration={2} delay={0} />+ */}
                +10
              </div>
              <div className="counter-text">Corporate/Fleet Owner</div>
            </div>
            <hr />
            <div class="counter-4 single-counter">
              <div class="counter-numbers">
                {/* <CountUp
                  start={0}
                  end={4.7}
                  duration={2}
                  delay={0}
                  decimals={1}
                  decimal="."
                /> */}
                4.7
              </div>
              <div className="counter-text">Customer Rating</div>
            </div>
          </div>
        )}
      </ScrollTrigger>
    </div>
  );
};

export default Counter;
