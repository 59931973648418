import React, { useEffect, useRef, useState } from "react";
import "./MyFavorite.css";
import useCartAPI from "../../../api/useCartAPI";
import sampleTyreImage from "../../../assets/images/sample_tyre.png";
import yokohama from "../../../assets/images/TyreBrandLogos/yokohama_mini.png";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import noProductFoundImage from "../../../assets/images/product-not-found.png";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/loadingSlice";
import { Paginator } from "primereact/paginator";

const MyFavorite = () => {
  const toast = useRef<Toast>(null) as any;
  const loading = useSelector((state: any) => state.loading);
  const dispatch = useDispatch();
  const { cartList, cartCount, cartRemoveItem } = useCartAPI();

  const [userId, setUserId] = useState(null) as any;
  const [products, setProducts] = useState([]) as any;
  const [isShowPrice, setIsShowPrice] = useState(false) as any;
  const [product, setProduct] = useState([]) as any;
  const [visible, setVisible] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPageOption, setRowsPerPageOption] = useState(10);
  const [records, setRecords] = useState(20);

  useEffect(() => {
    dispatch(setLoading(true));
    const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");

    setUserId(authTokens?.user?.id);

    if (userId) {
      fetchCartProducts();
    }
    if (authTokens?.user?.is_show_price === true) {
      setIsShowPrice(true);
    }
  }, [userId, pageNumber, rowsPerPageOption]);

  const fetchCartProducts = async () => {
    const payload = {
      user_id: userId,
      is_wishList: true,
      page_number: pageNumber,
      page_size: rowsPerPageOption,
    };

    const cartResponse: any = await cartList(payload);
    const countResponse: any = await cartCount(payload);

    if (cartResponse.status === 200) {
      dispatch(setLoading(false));
      const mappedProducts = cartResponse?.data?.data.map((item: any) => ({
        id: String(item.product_id) ?? 0,
        prodImg:
          item?.product?.document_as_product[0]?.document?.file_path ??
          sampleTyreImage,
        prodName: item.product.product_name ?? "Not Available",
        prodPrice: String(item.total_amount) ?? 0,
        quantity: item.item_quantity ?? 0,
        prodBrand: item?.product?.brand.thumbnail ?? yokohama,
        prodTotal: String(item.total_amount * item.item_quantity) ?? 0,
        cartItemId: item?.cart_item_id ?? 0,
      }));
      setProducts(mappedProducts);
      setRecords(countResponse?.data?.count);
    }else{
      dispatch(setLoading(false));
    }
  };

  const handleRemoveItem = async (e: any, product: any) => {
    setProduct(product);
    setVisible(true);
  };

  const removeItemFunc = async () => {
    // Add Wishlist remove API
    const response: any = await cartRemoveItem(product?.cartItemId);

    if (response.status === 200) {
      fetchCartProducts();

      setVisible(false);
      return toast?.current?.show({
        severity: "success",
        summary: "Successful",
        detail: `${response?.data?.message}`,
      });
    } else {
      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Failed to remove item from cart`,
      });
    }
  };

  const confirmDeleteFooter = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={removeItemFunc}
        autoFocus
      />
    </div>
  );

  const onPageChange = (e: any) => {
    const newPageNumber = e.first / e.rows + 1;
    setPageNumber(newPageNumber);
    // setCurrentPage(newPageNumber);
    setRowsPerPageOption(e.rows);
  };

  const paginatorTemplate = {
    layout:
      "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",

    CurrentPageReport: (options: any) => {
      return (
        <span
          style={{
            color: "#6c757d",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
            display: "block",
          }}
        >
          {options.first} to {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <div className="my-favorite">
      {loading && <div className="loading-overlay">Loading...</div>}
      <Toast ref={toast} />
      {products.length === 0 && (
        <div className="no-products">
          <Image
            src={noProductFoundImage}
            alt="No Product Found"
            width={"200"}
            height={"200"}
            className="no-products-image"
          />
          <div className="no-products-text">No Products Available</div>
        </div>
      )}
      {products.map((product: any) => (
        <div className="my-favorite-cart-product-item" key={product.id}>
          <Image
            className="my-favorite-product-image"
            src={product.prodImg}
            alt={product.prodName}
          />
          <div className="my-favorite-product-details">
            <Image
              className="my-favorite-product-brand-img"
              src={product.prodBrand}
              alt={product.prodName}
            />
            <div className="my-favorite-product-name">{product.prodName}</div>
            <div className="my-favorite-product-model">{product.prodModel}</div>
          </div>
            <div className="my-favorite-product-actions">
              <Button
                className="remove-button"
                onClick={(e: any) => handleRemoveItem(e, product)}
              >
                Remove
              </Button>
            </div>
        </div>
      ))}
      <Paginator
          first={(pageNumber - 1) * rowsPerPageOption}
          rows={rowsPerPageOption}
          totalRecords={records}
          rowsPerPageOptions={[5, 10, 25, records]}
          onPageChange={onPageChange}
          template={paginatorTemplate}
        />
      <Dialog
        header="Confirm Remove"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
        footer={confirmDeleteFooter}
      >
        <p className="m-0">Are you sure you want to remove this cart item?</p>
      </Dialog>
    </div>
  );
};

export default MyFavorite;
