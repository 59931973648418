import { Image } from "primereact/image";
import React, { useEffect, useState } from "react";
import "./RateCard.css";
import { Product } from "../Product/Product";
import { Paginator } from "primereact/paginator";
import noProductFoundImage from "../../assets/images/product-not-found.png";
import useProductListAPI from "../../api/useProductListAPI";
import useProductAPI from "../../api/useProductsAPI";

const RateCard = () => {
  const { getProductList, getProductsListCount } = useProductAPI();

  const [bikeTyres, setBikeTyres] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPageOption, setRowsPerPageOption] = useState(10);
  const [records, setRecords] = useState(0);
  const [userId, setUserId] = useState(null) as any;


  const fetchProductListFunc = async () => {
    const payload = {
      page_number: pageNumber,
      page_size: rowsPerPageOption,
      user_id: userId,
    };

    const response = await getProductList(payload);
    const countResponse: any = await getProductsListCount(payload);
    if (response.status === 200 || response.status === 201) {
      setBikeTyres(response?.data);
      setRecords(countResponse?.data?.count);
    }
  };

  useEffect(() => {
    const authTokens: any = JSON.parse(localStorage.getItem("authTokens") || "{}");
    if(authTokens?.user?.id){
      const userId = authTokens?.user?.id;
      setUserId(userId);
    }
    // if (selectedSortBy) {
      if(userId){
        fetchProductListFunc();
      }
    // }
  }, [userId, pageNumber]);

  const onPageChange = (e: any) => {
    const newPageNumber = e.first / e.rows + 1;
    setPageNumber(newPageNumber);
    // setCurrentPage(newPageNumber);
    setRowsPerPageOption(e.rows);
  };

  const paginatorTemplate = {
    layout:
      "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",

    CurrentPageReport: (options: any) => {
      return (
        <span
          style={{
            color: "#6c757d",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
            display: "block",
          }}
        >
          {options.first} to {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <div className="ratecard-wrapper">
      <div className="ratecard-title">Rate Card</div>
      <div className="ratecard-container">
        {bikeTyres.length === 0 && (
          <div className="no-rate-card-found">
            <Image
              src={noProductFoundImage}
              alt="No Product Found"
              width={"200"}
              height={"200"}
              className="no-product-found-image"
            />
            <div className="no-product-found-image-text">No Rate Card</div>
          </div>
        )}
        <div className="product-wrapper">
          {bikeTyres?.map((product: any, index: any) => (
            <Product key={index} product={product} showFeatures={false} isRateCard={true} />
          ))}
        </div>
        <Paginator
          first={(pageNumber - 1) * rowsPerPageOption}
          rows={rowsPerPageOption}
          totalRecords={records}
          rowsPerPageOptions={[10, 20, 30, records]}
          onPageChange={onPageChange}
          template={paginatorTemplate}
        />
      </div>
    </div>
  );
};

export default RateCard;
