import useAxios from '../hooks/useAxios';

const baseURL = process.env.REACT_APP_BASE_URL;
let url = '/order';
let countUrl = '/count';
let exportAllOrdersUrl = '/order-export';

const useOrderAPI = () => {
    const { axiosInstance } = useAxios();

    const scheduleAppointment = async (data: any) => {
        try {
            const response = await axiosInstance.post(`${baseURL}${url}/save`, data);
            return response;
        } catch (error) {
            return error;
        }
    };
    const allOrders = async (data: any, pageNumber?:string, pageSize?: string) => {
        try {
            const response = await axiosInstance.post(`${baseURL}${url}/`, data);
            return response;
        } catch (error) {
            return error;
        }
    };
    const allOrdersCount = async (data: any) => {
        try {
            const response = await axiosInstance.post(`${baseURL}${url}${countUrl}`, data);
            return response;
        } catch (error) {
            return error;
        }
    };
    const changeOrderStatus = async (data: any) => {
        try {
            const response = await axiosInstance.patch(`${baseURL}${url}/change-status`, data);
            return response;
        } catch (error) {
            return error;
        }
    };
    const getAllOrdersStats = async (data:any) => {
        try {
            const response = await axiosInstance.post(`${baseURL}${url}/stats`, data);
            return response;
        } catch (error) {
            return error;
        }
    };
    const exportAllOrders = async (data:any) => {
        try {
            const response = await axiosInstance.post(`${baseURL}${url}${exportAllOrdersUrl}`, data);
            return response;
        } catch (error) {
            return error;
        }
    };
    const saveOrderAPI = async (data:any) => {
        try {
            const response = await axiosInstance.post(`${baseURL}${url}/save`, data);
            return response;
        } catch (error) {
            return error;
        }
    };
    const saveCustomOrderAPI = async (data:any) => {
        try {
            const response = await axiosInstance.post(`${baseURL}${url}/custom-order/save`, data);
            return response;
        } catch (error) {
            return error;
        }
    };

    return { scheduleAppointment, allOrders, changeOrderStatus, getAllOrdersStats, exportAllOrders, allOrdersCount, saveOrderAPI, saveCustomOrderAPI };
};

export default useOrderAPI;
