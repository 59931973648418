import { useNavigate } from "react-router-dom";
import "./Services.css";

const Services = () => {
  const navigate = useNavigate();
  const redirectToServices = () => {
    navigate("/servicesandrepair");
  };
  return (
    <div className="services-wrapper">
      <div className="services-container">
        <div className="services-left-part"></div>
        {/* <div className="services-right-part">
          <h3 className="services-title">
            Get the best of tyre related services
          </h3>
          <div className="services-list-wrapper">
            <div className="wheel-alignment single-service">
              <div className="single-service-image">
                <Image src={wheelAlignment} width="50px" height="50px" />
              </div>
              <div className="single-service-name">
                Buy new tyres & accessories
              </div>
            </div>
            <div className="puncture single-service">
              <div className="single-service-image">
                <Image src={puncture} width="50px" height="50px" />
              </div>
              <div className="single-service-name">Tyre puncture</div>
            </div>
            <div className="steering single-service">
              <div className="single-service-image">
                <Image src={steering} width="50px" height="50px" />
              </div>
              <div className="single-service-name">Steering Calibration</div>
            </div>
            <div className="wheel-alignment single-service">
              <div className="single-service-image">
                <Image src={carIcon} width="50px" height="50px" />
              </div>
              <div className="single-service-name">Tyre fitting</div>
            </div>
            <div className="puncture single-service">
              <div className="single-service-image">
                <Image src={automotive} width="50px" height="50px" />
              </div>
              <div className="single-service-name">Batteries</div>
            </div>
          </div>
          <div className="view-all-wrapper">
            <span className="view-all-btn" onClick={redirectToServices}>
              View All
            </span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Services;
