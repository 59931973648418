import React, { useEffect, useRef, useState } from "react";
import "./MyVehicles.css";
import vehicleText from "../../../assets/images/vehicleText.png";
import useVehicleAPI from "../../../api/useVehicleAPI";
import { Image } from "primereact/image";
import trashIcon from "../../../assets/images/trash-icon.png";
import { Button } from "primereact/button";
import useHeroAPI from "../../../api/useHeroAPI";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/loadingSlice";

interface Car {
  id: number;
  name: string;
  color: string;
  licensePlate: string;
  imageUrl: string;
  make: string;
  model: string;
  variant: string;
}

const MyVehicles = () => {
  const toast = useRef<Toast>(null);
  const loading = useSelector((state: any) => state.loading);
  const dispatch = useDispatch();

  const modelsStaticOption = [{ name: "Honda", code: 5348 }];
  const { getVehicleList, saveVehicle, deleteVehicle } = useVehicleAPI();
  const { predefinedFunc } = useHeroAPI();
  const [submitted, setSubmitted] = useState(false) as any;
  const [isAddingNewVehicle, setIsAddingNewVehicle] = useState(false) as any;
  const [isEditingNewVehicle, setIsEditingNewVehicle] = useState(false) as any;
  const [cars, setCars] = useState<Car[]>([]);
  const [userId, setUserId] = useState(null) as any;
  const [makeOptions, setMakeOptions] = useState([]) as any;
  const [make, setMake] = useState([]) as any;
  const [modelOptions, setModelOptions] = useState(null) as any;
  const [model, setModel] = useState(null) as any;
  const [variantOptions, setVariantOptions] = useState(null) as any;
  const [variant, setVariant] = useState(null) as any;
  const [vehicle, setVehicle] = useState([]) as any;
  const [vehicleNumber, setVehicleNumber] = useState(null) as any;
  const [deleteVehicleDialog, setDeleteVehicleDialog] = useState(false) as any;

  const fetchPredefined = async () => {
    const makePredefinedPayload = {
      entity_type: "MAKE",
    };
    const makePredefinedResponse = await predefinedFunc(makePredefinedPayload);
    const makeData = makePredefinedResponse?.data?.data?.map((child: any) => {
      return {
        name: child.name,
        code: child.predefined_id,
      };
    });
    setMakeOptions(makeData);
  };

  const fetchDependentData = async () => {
    if (make?.length !== 0) {
      const modelPredefinedPayload = {
        entity_type: "MODEL",
        parent_id: make?.length === 0 ? null : make,
      };
      const modelPredefinedResponse = await predefinedFunc(
        modelPredefinedPayload
      );
      const modelData = modelPredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      setModelOptions(modelData);
    }
    if (model?.length !== 0) {
      const variantPredefinedPayload = {
        entity_type: "VARIANT",
        parent_id: model?.length === 0 ? null : model,
      };
      const variantPredefinedResponse = await predefinedFunc(
        variantPredefinedPayload
      );
      const variantData = variantPredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      setVariantOptions(variantData);
    }
  };

  useEffect(() => {
    dispatch(setLoading(true));
    fetchPredefined();
    const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    if (authTokens?.user) {
      setUserId(authTokens?.user?.id);
    }
    if (userId) {
      fetchVehicleList();
    }
  }, [userId]);

  useEffect(() => {
    if (make !== null || model !== null) {
      fetchDependentData();
    }
  }, [make, model]);

  const fetchVehicleList = async () => {
    try {
      const payload = {
        user_id: userId,
      };

      const response: any = await getVehicleList(payload);
      if (response?.status === 200) {
        dispatch(setLoading(false));
        
        const vehicleData = response?.data?.map((vehicle: any) => ({
          id: vehicle.id,
          color: vehicle.vehicle_color || "N/A",
          name:
            `${vehicle?.make?.name} ${vehicle?.model?.name} ${vehicle?.variant?.name}` ||
            "Name Not Available",
          licensePlate: vehicle.vehicle_number,
          imageUrl: vehicleText,
          make: vehicle?.make?.name,
          model: vehicle?.model?.name,
          variant: vehicle?.variant?.name,
        }));
        setCars(vehicleData || []);
      }else{
        dispatch(setLoading(false));
      }
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  };

  const handleCancel = () => {
    setIsAddingNewVehicle(false);
    setMake(null);
    setModel(null);
    setVariant(null);
    setVehicleNumber(null);
    setIsEditingNewVehicle(false);
  };

  const handleAddCar = async () => {
    if (!model || !make || !variant) {
      setSubmitted(true);
      return;
    }

    const payload = {
      vehicle_number: vehicleNumber,
      model_id: model,
      make_id: make,
      variant_id: variant,
    };

    const formData = new FormData();

    formData.append("data", JSON.stringify(payload))

    const response = await saveVehicle(formData);


    if (response.status === 200 || response.status === 201) {
      fetchVehicleList();
      handleCancel();
      setIsAddingNewVehicle(false);
      return toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `Vehicle Added Successfully`,
      });
    } else {
      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Failed To Save Vehicle`,
      });
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        // icon="pi pi-times"
        onClick={handleCancel}
        className="p-button-text"
      />
      <Button
        label="Submit"
        // icon="pi pi-check"
        onClick={handleAddCar}
        autoFocus
      />
    </div>
  );

  const addingNewVehicleFunc = () => {
    setIsAddingNewVehicle(true);
    setIsEditingNewVehicle(false);
  };

  const deleteTrashFunc = (car: any) => {
    setVehicle(car)
    setDeleteVehicleDialog(true);
  }
  const deleteVehicleFunc = async () => {
    const payload = {};

    const response: any = await deleteVehicle(vehicle.id, payload);

    if (response.status === 200 || response.status === 201) {
      fetchVehicleList();
      setDeleteVehicleDialog(false);
      return toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `Vehicle Deleted Successfully`,
      });
    } else {
      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Failed To Delete Vehicle`,
      });
    }
  }

  return (
    <>
      {loading && <div className="loading-overlay">Loading...</div>}
      <Toast ref={toast} />
      <div className="my-vehicle-wrapper">
        <div className="my-vehicle-title-wrapper">
          <div className="my-vehicle-title-name">Vehicle</div>
          <div className="my-vehicle-title-add-new-btn">
            <Button
              label={"Add vehicle"}
              className="add-new-vehicle-btn"
              onClick={addingNewVehicleFunc}
            />
          </div>
        </div>
        <div className="my-vehicle-list">
          {cars?.length === 0 && <div> No Vehicle Available</div>}
          {cars?.map((car) => (
            <div key={car.id} className={`car-item-wrapper `}>
              <div className="my-vehicle-item">
                <Image
                  src={car?.imageUrl}
                  alt={`${car?.name}`}
                  className="my-vehicle-image"
                  height="108px"
                  width="103px"
                />
                <div className="car-data">
                  {/* <h3> */}
                  {/* {car.name} */}
                  <div className="vehicle-make">{car?.make}</div>
                  <div className="vehicle-model">{car?.model}</div>
                  <div className="vehicle-variant">{car?.variant}</div>
                  {/* </h3> */}
                  <p>{car.licensePlate}</p>
                </div>
              </div>
              <div className="my-vehicle-trash-icon-wrapper" onClick={() => deleteTrashFunc(car)}>
                <Image
                  src={trashIcon}
                  alt="Select Icon"
                  height="26px"
                  width="26px"
                  className="vehicle-trash-icon"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Dialog
        header={isEditingNewVehicle ? "Edit Vehicle" : "Add New Vehicle"}
        footer={footerContent}
        visible={isAddingNewVehicle}
        onHide={handleCancel}
        style={{ width: "90vw" }}
        breakpoints={{ "960px": "75vw", "641px": "90%" }}
      >
        <div className="add-new-vehicle-wrapper grid">
          {/* <div className="field col-4 vehicle-name">
            <label htmlFor="vehicle-name">
              Vehicle Name <span className="error">*</span>
            </label>
            <InputText
              className="w-full"
              value={vehicleName}
              onChange={(e) => setVehicleName(e.target.value)}
              placeholder="Vehicle Name"
              id="vehicle-name"
            />
            {submitted && !vehicleName && (
              <small className="error">Vehicle Name is Required</small>
            )}
          </div> */}
          <div className="field col-4 vehicle-number">
            <InputText
              className="w-full"
              value={vehicleNumber}
              onChange={(e) => setVehicleNumber(e.target.value)}
              placeholder="Vehicle Number"
              id="vehicle-number"
            />
            {/* {submitted && !vehicleNumber && (
              <small className="error">Vehicle Number is Required</small>
            )} */}
          </div>
          <div className="field col-4 vehicle-make">
            <Dropdown
              className="w-full"
              value={make}
              options={makeOptions}
              onChange={(e) => setMake(e.value)}
              placeholder="Select a Make"
              optionValue="code"
              optionLabel="name"
              filter
              showClear
            />
            {submitted && !make && (
              <small className="error">Make is Required</small>
            )}
          </div>
          <div className="field col-4 vehicle-model">
            <Dropdown
              className="w-full"
              filter
              value={model}
              options={modelOptions}
              onChange={(e) => setModel(e.value)}
              placeholder="Select a Model"
              optionValue="code"
              optionLabel="name"
              showClear
            />
            {submitted && !model && (
              <small className="error">Model is Required</small>
            )}
          </div>
          <div className="field col-4 vehicle-variant">
            <Dropdown
              className="w-full"
              value={variant}
              options={variantOptions}
              onChange={(e) => setVariant(e.value)}
              placeholder="Select a Variant"
              optionValue="code"
              optionLabel="name"
            />
            {submitted && !variant && (
              <small className="error">Variant is Required</small>
            )}
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Confirm Delete"
        visible={deleteVehicleDialog}
        style={{ width: '450px' }}
        modal
        onHide={() => setDeleteVehicleDialog(false)}
        footer={() => (
          <div>
            <Button label="No" icon="pi pi-times" onClick={() => setDeleteVehicleDialog(false)} className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={deleteVehicleFunc} autoFocus />
          </div>
        )}
      ></Dialog>
    </>
  );
};

export default MyVehicles;
