import React from "react";
import "./ShippingAndDelivery.css";
import { Container } from "react-bootstrap";

const ShippingAndDelivery = () => {
  return (
    <div className="privacy-policy-wrapper">
      <Container>
        <h1 style={{ color: "#6C17A4", marginTop: "20px" }}></h1>
        <div>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Shipping And Delviery Policy
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Thank you for choosing MechMiles for your purchases. We appreciate
              your support of our products/services. Please be aware that
              MechMiles does not offer shipping or delivery services.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
            }}
          >
            &nbsp;
          </p>
        </div>
      </Container>
    </div>
  );
};

export default ShippingAndDelivery;
