import React from "react";
import Container from "react-bootstrap/Container";
import "./Footer.css";

import footerLogo from "../../assets/images/footer_logo.png";
import footerPhoneIcon from "../../assets/images/footer-phone-icon.png";
import { Image } from "primereact/image";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { IoMailOpenOutline } from "react-icons/io5";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-wrapper-1">
        <Container>
          <div className="footer-container">
            <div className="footer-left footer-inner-container">
              <div className="footer-img-wrapper">
                <Image src={footerLogo} />
              </div>
              <div className="footer-left-text">
                <strong>Corporate Address :</strong> <br />
                Junction of Tyres Private Limited <br />Adj Dhanajwadi Shop No. 410 B Wingsra Rehab Building Rani Sati Malad(E)-400097, Mumbai, Maharashtra

              </div>
              {/* <div className="footer-phone-icon-wrapper">
                <Image src={footerPhoneIcon} />
                <a href="tel:7094496333" className="footer-phone-text">
                  Call us at: +91 7094496333
                </a>
              </div> */}
              <div className="footer-email-icon-wrapper">
                {/* <Image src={footerPhoneIcon} /> */}
                <IoMailOpenOutline />
                <a href="mailto:Info@mechmiles.com" className="footer-phone-text">
                  Email us at: Info@mechmiles.com
                </a>
              </div>
              <div className="footer-social-links-wrapper">
                <div className="footer-facebook">
                  <a href="#">
                    <FaFacebookF />
                  </a>
                </div>
                <div className="instagram">
                  <a href="#">
                    <FaInstagram />
                  </a>
                </div>
                <div className="footer-linkedin">
                  <a href="#">
                    <FaLinkedinIn />
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-center footer-inner-container">
              <div className="footer-center-title">Links</div>
              <div className="footer-center-container">
                <a href="/">Tyres</a>
                {/* <a href="#">About</a> */}
                <a href="/batteries">Battery</a>
                <a href="/servicesandrepair">Services and Repair</a>
                <a href="/contact-us">Contact us</a>
              </div>
            </div>
            <div className="footer-right footer-inner-container">
              <div className="footer-right-title">Other Links</div>
              <div className="footer-right-container">
                <a href="/terms">Terms and conditions</a>
                {/* <a href="/refund">Refund and cancellation policy</a> */}
                <a href="/privacy">Privacy Policy </a>
                <a href="/refund">Return and Refund</a>
                <a href="/shipping-and-delivery-policy">
                  Shipping and Delivery Policy
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.mechmilespos.app">
                  Download POS App
                </a>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="footer-wrapper-2">
        <div className="footer-copyright-text">
          Copyright © 2023 mechmiles. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
