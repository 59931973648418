import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import './BreadCrumbs.css';

const capitalize = (s: any) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const BreadCrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  return (
    <div className='breadcrumbs-wrapper'>
      <Link to="/">Home</Link>
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;
        const displayName = capitalize(name);
        return isLast ? (
          <span key={name}> {` > ${displayName}`}</span>
        ) : (
          <React.Fragment key={name}>
            <Link to={routeTo}>{displayName}</Link>
            <span>{' > '}</span>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default BreadCrumbs;