import axios from "axios";
import useAxios from "../hooks/useAxios";

const baseURL = process.env.REACT_APP_BASE_URL;

const url = "/wallet-history";
const rechargeWalletUrl = "/company_master/recharge-wallet";
const rechargeCustomerWalletUrl = "/create";

const useWalletAPI = () => {
  const { axiosInstance } = useAxios();

  const getWalletHistory = async (data: any) => {
    let response = await axiosInstance.post(
      baseURL + `${url}/`,
      data
    );
    return response;
  };
  const getWalletBalance = async (companyId?: any, userId?: any) => {
    let response = await axiosInstance.get(
      baseURL + `${url}/balance?company_id=${companyId}&user_id=${userId}`
    );
    return response;
  };
  const rechargeWalletAPI = async (companyId?: any, data?: any) => {
    let response = await axiosInstance.patch(
      baseURL + `${rechargeWalletUrl}/${companyId}`, data
    );
    return response;
  };
  const rechargeCustomerWalletAPI = async (data?: any) => {
    let response = await axiosInstance.post(
      baseURL + `${url}${rechargeCustomerWalletUrl}`, data
    );
    return response;
  };

  return {
    getWalletHistory,
    getWalletBalance,
    rechargeWalletAPI,
    rechargeCustomerWalletAPI
  };
};

export default useWalletAPI;
