import { Image } from "primereact/image";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { FaHeart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useCartAPI from "../../api/useCartAPI";
import ceatMini from "../../assets/images/TyreBrandLogos/ceat_mini.png";
import sampleTyreImage from "../../assets/images/sample_tyre.png";
import { addToCart } from "../../features/cart/cartSlice";
import { setShowLoginDialog } from "../../redux/dialogSlice";
import { addToProduct, refreshProductList } from "../../redux/productSlice";
import { isLoggedInState, isLoggedOutState } from "../../redux/userState";
import Popup from "../ListingPopup/Popup";
import "./Product.css";

interface ProductProps {
  product: {
    product_id: number;
    product_name: string;
    warranty: string;
    rating: number | null;
    width: Dimension;
    aspect_ratio: Dimension;
    rim: Dimension;
    key_feature: string;
    fit_in_vehicle_name: string;
    product_price: number;
    ply_rating: number;
    product_offer_price: number;
    category_id: number;
    brand_id: number | null;
    make_id: number;
    model_id: number;
    tyer_type_id: number;
    tyer_ps_id: number;
    variant_id: number;
    product_img: string | null;
    description: string | null;
    discounted_price: number;
    is_goto: boolean;
    is_wishlist: boolean;
    model: Entity;
    make: Entity;
    variant: Entity;
    brand: Dimension;
    category: Entity;
    tyer_type: Entity;
    tyer_ps: Entity;
    product_feature: Feature[];
    document_as_product: any[];
  };
  showFeatures?: boolean;
  handleMouseEnter?: () => void;
  handleMouseLeave?: () => void;
}

interface Dimension {
  predefined_id: number;
  name: string;
  code: string;
  entity_type: string;
  is_delete: string;
  is_deactivate: string;
  url: string;
  thumbnail: string;
}

interface Entity extends Dimension {
  // Inherits all properties from Dimension
}

interface Feature {
  product_feature_id: number;
  feature_name: string;
  product_id: number;
  feature: Entity;
}

export const Product = ({
  product,
  showFeatures = true,
  handleMouseEnter,
  handleMouseLeave,
  isRateCard,
}: any) => {
  const basePath = process.env.REACT_APP_BASE_PATH;

  const toast = useRef<Toast>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { cartSave, moveToWishlist } = useCartAPI();

  const [userId, setUserId] = useState(null) as any;
  const [isShowPrice, setIsShowPrice] = useState(false) as any;
  const [isHidePrice, setIsHidePrice] = useState(false) as any;

  const isLoggedIn = useSelector(isLoggedInState);
  const isLoggedOut = useSelector(isLoggedOutState);

  useEffect(() => {
    const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    setUserId(authTokens?.user?.id);
    if (authTokens?.user?.is_show_price === true) {
      setIsShowPrice(true);
      setIsHidePrice(true);
    }
  }, [isLoggedIn, isLoggedOut, userId]);

  const handleAddToCart = async () => {
    if (!userId) {
      setTimeout(() => {
        dispatch(setShowLoginDialog(true));
      }, 1000);
      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Please Login`,
      });
    }

    // navigate("/mechmiles-customer/cart", { state: { product } });

    const payload = {
      product_id: product.product_id,
      item_quantity: 1,
      is_wishList: false,
    };

    const response: any = await cartSave(payload);

    if (response.status === 201) {
      dispatch(addToCart(product));
      dispatch(addToProduct(product));
      dispatch(refreshProductList());
      return toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `${product?.product_name} Added to cart`,
      });
    }
  };

  const handleGoToCart = () => {
    // Add forward slash for for production
    navigate(`${basePath}cart`);
  };

  const productFeatures = product?.product_feature
    ?.slice(0, 4)
    ?.map((features: any) => {
      return (
        <div
          style={{ display: `${features?.feature?.url ? `block` : `none`}` }}
          key={features?.feature_id}
        >
          <div className="prod-bottom-section-text">
            <Image src={features?.feature?.url} />
            <div className="features-text">
              {features?.feature?.name ?? "Not Available"}
            </div>
          </div>
        </div>
      );
    });

  const moveToWishListFunc = async (product: any) => {
    const payload = {
      product_id: product.product_id,
      item_quantity: 1,
      is_wishList: true,
    };

    const response: any = await cartSave(payload);
    if (response.status === 201) {
      // dispatch(addToProduct(product));
      dispatch(refreshProductList());
      return toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `${product?.product_name} Added to wishlist`,
      });
    }
  };

  const [hoveredProduct, setHoveredProduct] = useState(null) as any;

  return (
    <div
      onMouseEnter={() => setHoveredProduct(product)}
      onMouseLeave={() => setHoveredProduct(null)}
      className="product"
    >
      <Toast ref={toast} />
      <div className="product-top-section-wrapper product-images as">
        <div className="product-top-section-left">
          <div className="product-image-wrapper">
            <Image
              src={
                product?.document_as_product[0]?.document?.file_path ||
                sampleTyreImage
              }
              className="prod-image"
              height="110"
              width="150"
            />
          </div>
          {product.is_goto && showFeatures && (
            <div className="product-go-to-cart" onClick={handleGoToCart}>
              Go to cart
            </div>
          )}
          {!product.is_goto && showFeatures && (
            <div className="product-add-to-cart" onClick={handleAddToCart}>
              Add to cart
            </div>
          )}
        </div>
        <div className="product-top-section-right">
          <div className="product-manufacturer-logo">
            {/* {product.prodBestSeller && (
              <div className="product-bestseller"> Bestseller </div>
            )} */}
            <Image src={product?.brand?.thumbnail || ceatMini} />
            {isLoggedIn && !isRateCard && (
              <FaHeart
                className={`${
                  product.is_wishlist ? "wish-list-on" : "wish-list-off"
                }`}
                onClick={() => moveToWishListFunc(product)}
              />
            )}
          </div>
          <div className="prod-name" title={product.product_name}>
            {product.product_name}
          </div>
          {/* <div className="prod-model">{product.rim?.name}</div> */}
          {/* {showFeatures && <div className="prod-ratings">
            <StarPicker value={4.5} halfStars disabled={true} size={20} />
          </div>} */}
          {/* <div className="prod-review">{product.prodReviewCount} Reviews</div> */}
          {showFeatures && <div className="prod-review">10 Reviews</div>}
        </div>
      </div>
      <div className="prod-price-wrapper">
        <div className="prod-price-empty-wrapper"></div>
        <div className="prod-price-inner-wrapper">
          {/* {isShowPrice && */}
          {isRateCard && (
            <>
              <div className="prod-price">
                {isLoggedOut ? (
                  <>&#8377; {product.product_offer_price}</>
                ) : (
                  <>
                    <div className="is-showing-price">
                      &#8377; {product.product_offer_price}
                    </div>
                  </>
                )}
              </div>
              <div className="prod-inclusive-tax">
                Inclusive of all Taxes
                <hr className="prod-price-hr" />
              </div>
            </>
          )}
          {!isRateCard && (
            <>
              <div className="prod-price">
                {isLoggedOut ? (
                  <>&#8377; {product.product_offer_price}</>
                ) : (
                  <>
                    {!isShowPrice && (
                      <div className="is-not-showing-price">&#8377; ***</div>
                    )}
                    {isShowPrice && (
                      <div className="is-showing-price">
                      <div className="prod-price">
                      &#8377;{product?.product_offer_price}
                    </div>
                    <div className="prod-offer-price f-14">
                      &#8377;{product?.product_price}
                    </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="prod-inclusive-tax">
                Inclusive of all Taxes
                <hr className="prod-price-hr" />
              </div>
            </>
          )}
          {/* } */}
        </div>
        <div className="prod-bottom-section-container">
          {showFeatures && productFeatures}
        </div>
      </div>

      {hoveredProduct === product && !isRateCard && <Popup product={product} />}
      {/* {hoveredProduct === product  && <Popup product={product} />} */}
    </div>
  );
};
