import React, { useEffect, useRef, useState } from "react";
import "./DriverManagement.css";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { FaPlus } from "react-icons/fa";
import { Dialog } from "primereact/dialog";
import useLoginAPI from "../../api/useLogin";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import debounce from "../../utils/debounce";
import useDriverAPI from "../../api/useDriverAPI";
import { Toast } from "primereact/toast";
import useHeroAPI from "../../api/useHeroAPI";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/loadingSlice";

const DriverManagement = () => {
  const { getDriverList, getDriverCount, saveDriver, importDriverUpload, updateDriver } =useDriverAPI();
  const { predefinedFunc } = useHeroAPI();

  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const toast = useRef<Toast>(null);
  const loading = useSelector((state: any) => state.loading);
  const dispatch = useDispatch();


  // Updated renderColumnData function to handle 'STATUS' column
  const renderColumnData = (rowData: any, field: string) => {
    if (field === "status") {
      const status = rowData?.is_deactivate === "N" ? "Active" : "In-active";
      const color = rowData?.is_deactivate === "N" ? "green" : "red";
      return <span style={{ color }}>{status}</span>;
    }
    return rowData[field] ? rowData[field] : "Not Available";
  };

  // New function to handle driver assignment status
  const renderDriverStatus = (rowData: any) => {
    const status = rowData.driver_id ? "Assigned" : "Un-Assigned";
    const color = rowData.driver_id ? "blue" : "grey";
    return <span style={{ color: "#642088" }}>{status}</span>;
  };

  const [columns, setColumns] = useState([
    {
      field: "user.full_name",
      header: "DRIVER NAME",
      body: (rowData: any) => renderColumnData(rowData, "full_name"),
      style: { minWidth: "8rem" },
    },
    {
      field: "user.email",
      header: "EMAIL",
      body: (rowData: any) => renderColumnData(rowData, "email"),
      style: { minWidth: "8rem" },
    },
    {
      field: "user.phone",
      header: "MOBILE",
      body: (rowData: any) => renderColumnData(rowData, "phone"),
      style: { minWidth: "8rem" },
    },
    {
      field: "user.assigned_status.name",
      header: "STATUS",
      style: { minWidth: "8rem" },
    },
  ]);

  const [selectedDepartment, setSelectedDepartment] = useState(null) as any;
  const [selectedStatus, setSelectedStatus] = useState(null) as any;
  // const [statusOptions, setStatusOptions] = useState([]);
  const [visible, setVisible] = useState(false);
  const [staffListState, setStaffListState] = useState([]) as any;
  const [staff, setStaff] = useState([]) as any;
  const [userId, setUserId] = useState(null) as any;
  const [companyId, setCompanyId] = useState(null) as any;
  const [departmentId, setDepartmentId] = useState(null) as any;
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPageOption, setRowsPerPageOption] = useState(10);
  const [records, setRecords] = useState(20);
  const [formValues, setFormValues] = useState({
    fullName: "",
    vehicleNumber: "",
    email: "",
    phone: "",
    dl: ""
  });
  const [formErrors, setFormErrors] = useState({
    fullName: "",
    vehicleNumber: "",
    email: "",
    phone: "",
    dl: ""
  });
  const [isAddingNewStaff, setIsAddingNewStaff] = useState(false) as any;
  const [isViewMode, setIsViewMode] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]) as any;

  // const statusOptions = [
  //   { name: "Active", value: "N" },
  //   { name: "In-active", value: "Y" },
  // ];

  const fetchPredefined = async () => {
    const payload = {
      entity_type: "ASSIGNED-STATUS",
    };

    const response = await predefinedFunc(payload);

    if (response?.status === 200) {
        setStatusOptions(response?.data?.data);
    }
  
  };

  const fetchDriverList = async (searchValue?: string) => {
    const payload = {
      company_id: companyId ?? null,
      page_number: pageNumber,
      page_size: rowsPerPageOption,
      search_by: searchValue !== "" ? searchValue : null,
      dept_id: selectedDepartment !== undefined ? selectedDepartment : null,
      assigned_status_id: selectedStatus !== undefined ? selectedStatus : null,
    };

    const response = await getDriverList(payload);
    const countResponse = await getDriverCount(payload);

    if (response.status === 200) {
      dispatch(setLoading(false));
      setStaffListState(response?.data);
      setRecords(countResponse?.data?.count);
    }else{
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    dispatch(setLoading(true));
    const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    if (authTokens?.user?.id) {
      setUserId(authTokens?.user?.id);
      setCompanyId(authTokens?.user?.company_id);
      setDepartmentId(authTokens?.user?.department_id);
    }

    if (userId) {
      fetchDriverList();
      fetchPredefined();
    }
  }, [userId, pageNumber, selectedDepartment, selectedStatus]);

  // Generic input change handler for the form
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: e.target.value,
    }));
    // Clear errors when the user starts typing
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };

  // Validation logic
  const validateForm = () => {
    const errors = {
      fullName: "",
      vehicleNumber: "",
      email: "",
      phone: "",
      dl: ""
    };
    let isValid = true;

    if (!formValues.fullName.trim()) {
      errors.fullName = "Full name is required";
      isValid = false;
    }
    if (!formValues.dl.trim()) {
      errors.dl = "Driving License is required";
      isValid = false;
    }else if (!/^[A-Z]{2}\d{2}\s?\d{11}$/.test(formValues.dl)) {
      errors.dl = "Driving License is invalid";
      isValid = false;
    }
    // if (!formValues.vehicleNumber.trim()) {
    //   errors.vehicleNumber = "Vehicle Number is required";
    //   isValid = false;
    // }
    if (!formValues.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }
    if (!formValues.phone.trim()) {
      errors.phone = "Phone number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formValues.phone)) {
      errors.phone = "Invalid phone number";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const payload = {
        email: formValues?.email,
        roles: ["ROLE_DRIVER"],
        full_name: formValues?.fullName,
        username: formValues?.vehicleNumber ?? null,
        company_id: companyId ?? null,
        department_id: departmentId ?? null,
        phone: formValues?.phone ?? null,
        dl: formValues?.dl ?? null,
      };

      if (staff.driver_id) {
        const response = await updateDriver(staff.driver_id, payload);
        if (response.status === 201) {
          console.log(response);
          setVisible(false);
          setFormValues({
            fullName: "",
            vehicleNumber: "",
            email: "",
            phone: "",
            dl: ""
          });
          fetchDriverList();
        } else {
          console.log(response);
        }
      } else {
        const response = await saveDriver(payload);
        if (response.status === 201) {
          console.log(response);
          setVisible(false);
          setFormValues({
            fullName: "",
            vehicleNumber: "",
            email: "",
            phone: "",
            dl: ""
          });
          fetchDriverList();
          return toast?.current?.show({
            severity: "success",
            summary: "Success",
            detail: `Driver Saved Successfully`,
          });
        } else {
          return toast?.current?.show({
            severity: "error",
            summary: "Failed",
            detail: `Failed To Save Driver`,
          });
        }
      }
    }
  };

  const editProduct = async (staff: any) => {
    setFormValues({
      fullName: staff?.full_name || "",
      vehicleNumber: staff?.username || "",
      email: staff?.email || "",
      phone: staff?.phone || "",
      dl: staff?.dl || ""
    });
    setFormErrors({
      fullName: "",
      vehicleNumber: "",
      email: "",
      phone: "",
      dl: ""
    });
    setStaff(staff);
    setVisible(true);
    setIsAddingNewStaff(false);
    setIsViewMode(false);
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="rounded-button mr-2 edit-btn"
          onClick={() => editProduct(rowData)}
        />
        {/* <Button icon="pi pi-trash" className="rounded-button mr-2 delete-btn" /> */}
      </React.Fragment>
    );
  };

  const onPageChange = (e: any) => {
    const newPageNumber = e.first / e.rows + 1;
    setPageNumber(newPageNumber);
    // setCurrentPage(newPageNumber);
    setRowsPerPageOption(e.rows);
  };

  const paginatorTemplate = {
    layout:
      "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",

    CurrentPageReport: (options: any) => {
      return (
        <span
          style={{
            color: "#6c757d",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
            display: "block",
          }}
        >
          {options.first} to {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  const debouncedFetchDriverList = debounce((searchValue: string) => {
    fetchDriverList(searchValue);
  }, 500);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedFetchDriverList(e.target.value);
  };

  const handleAddStaff = () => {
    setFormValues({
      fullName: "",
      vehicleNumber: "",
      email: "",
      phone: "",
      dl: ""
    });
    setStaff([]);
    setVisible(true);
    setIsViewMode(false);
    setIsAddingNewStaff(true);
  };

  const handleImportDriverClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files[0]) {
      const formData = new FormData();
      formData.append("file", files[0]);

      const response = await importDriverUpload(formData);
      if (response.status === 200 || response.status === 201) {
        event.target.value = "";

        return toast?.current?.show({
          severity: "success",
          summary: "Success",
          detail: `${response?.data?.message}`,
        });
      }
    }
  };

  return (
    <div className="driver-mgmt-wrapper">
      {loading && <div className="loading-overlay">Loading...</div>}
      <Toast ref={toast} />
      <div className="driver-mgmt-header">
        <h1 className="driver-mgmt-title">Driver Management</h1>
        <div className="driver-mgmt-stats-wrapper">
          <div className="driver-mgmt-status-wrapper">
            <div className="total-driver-wrapper">
              <div className="total-driver-title">Total No. Drivers:</div>
              <div className="total-driver-content">0</div>
            </div>
          </div>
          <div className="driver-mgmt-status-wrapper">
            <div className="total-driver-wrapper">
              <div className="total-driver-title">Total No of Driver:</div>
              <div className="total-driver-content">0</div>
            </div>
          </div>
          <div className="driver-mgmt-status-wrapper">
            <div className="total-driver-wrapper">
              <div className="total-driver-title">
                Total Number of appointments:
              </div>
              <div className="total-driver-content">0</div>
            </div>
          </div>
        </div>
      </div>
      <div className="driver-mgmt-section-two">
        <div className="driver-mgmt-filters-wrapper">
          <div className="driver-mgmt-container">
            <div className="driver-search-dropdowns-wrapper">
              <div className="driver-search-wrapper">
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    placeholder="Search"
                    className="driver-search-input"
                    onChange={handleSearchInputChange}
                  />
                </span>
              </div>
            </div>
            <div className="driver-mgmt-import-btn-container">
              <div className="all-driver-status">
                <Dropdown
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.value)}
                  options={statusOptions}
                  optionLabel="name"
                  optionValue="predefined_id"
                  placeholder="Status"
                  className="w-full driver-mgmt-filters"
                  showClear
                />
              </div>
              <div className="driver-mgmt-add-btn-wrapper">
                <Button
                  className="driver-mgmt-add-btn"
                  onClick={handleAddStaff}
                >
                  <FaPlus /> Add Driver
                </Button>
              </div>
              <div className="staff-mgmt-import-btn-wrapper">
                <input
                  type="file"
                  ref={fileInputRef}
                  id="fileUpload"
                  style={{ display: "none" }}
                  accept=".xlsx, .xls"
                  onChange={handleFileUpload}
                />
                <Button
                  label="Import Driver"
                  className="staff-mgmt-import-btn"
                  onClick={handleImportDriverClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="driver-mgmg-list-wrapper">
        <div className="card">
          <DataTable value={staffListState || []} dataKey="id">
            {columns.map((col, index) => (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                body={col.body}
                style={col.style}
                className="driver-mgmt-table-data"
              />
            ))}
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ minWidth: "12rem" }}
            ></Column>
          </DataTable>
          <Paginator
            first={(pageNumber - 1) * rowsPerPageOption}
            rows={rowsPerPageOption}
            totalRecords={records}
            rowsPerPageOptions={[5, 10, 25, records]}
            onPageChange={onPageChange}
            template={paginatorTemplate}
          />
        </div>
      </div>
      <Dialog
        visible={visible}
        modal
        style={{ width: "40%" }}
        onHide={() => setVisible(false)}
        header={
          isViewMode
            ? "View Driver Details"
            : isAddingNewStaff
            ? "Add Driver"
            : "Edit Driver"
        }
        className="add-driver-dialog"
      >
        <div className="driver-add-form-wrapper">
          <div className="driver-last-name-wrapper">
            <InputText
              disabled={isViewMode}
              value={formValues?.fullName}
              onChange={(e) => handleInputChange(e, "fullName")}
              placeholder="Full Name"
              className="driver-add-form-input driver-add-form-last-name"
            />
            {formErrors?.fullName && (
              <small className="p-error">{formErrors?.fullName}</small>
            )}
          </div>
          {/* <div className="driver-vehicle-number-wrapper">
            <InputText
              disabled={isViewMode}
              value={formValues?.vehicleNumber}
              onChange={(e) => handleInputChange(e, "vehicleNumber")}
              placeholder="Vehicle Number"
              className="driver-add-form-input driver-add-form-last-name"
            />
            {formErrors?.vehicleNumber && (
              <small className="p-error">{formErrors?.vehicleNumber}</small>
            )}
          </div> */}
          <div className="driver-phone-wrapper">
            <InputText
              disabled={isViewMode}
              value={formValues.phone}
              onChange={(e) => handleInputChange(e, "phone")}
              placeholder="Phone Number"
              className="driver-add-form-input driver-add-form-phone"
              maxLength={10}
            />
            {formErrors.phone && (
              <small className="p-error">{formErrors.phone}</small>
            )}
          </div>
          <div className="driver-email-wrapper">
            <InputText
              disabled={isViewMode}
              value={formValues?.email}
              onChange={(e) => handleInputChange(e, "email")}
              placeholder="Email"
              className="driver-add-form-input driver-add-form"
            />
            {formErrors?.email && (
              <small className="p-error">{formErrors?.email}</small>
            )}
          </div>
          <div className="driver-dl-wrapper">
            <InputText
              disabled={isViewMode}
              value={formValues?.dl}
              onChange={(e) => handleInputChange(e, "dl")}
              placeholder="Driving License"
              className="driver-add-form-input driver-add-form dl"
            />
            {formErrors?.dl && (
              <small className="p-error">{formErrors?.dl}</small>
            )}
          </div>
          {!isViewMode && (
            <Button
              className="driver-add-form-btn"
              type="submit"
              label="Submit"
              onClick={handleSubmit}
            />
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default DriverManagement;
