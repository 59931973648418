import axios from "axios";
import useAxios from "../hooks/useAxios";

const baseURL = process.env.REACT_APP_BASE_URL;

let url = "/coupon"
let couponListUrl = "/coupon-list";

const useCouponAPI = () => {
  const { axiosInstance } = useAxios();

  const getCouponList = async (data: any) => {
    try {
      let response = await axiosInstance.post(baseURL + `${url}${couponListUrl}`, data);
      return response;
    }
    catch (error) {
      return error
    }
  };


  return {
    getCouponList
  };
};

export default useCouponAPI;
