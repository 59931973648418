import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from 'redux';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

import cartReducer from '../features/cart/cartSlice';
import loadingReducer from '../redux/loadingSlice';
import dialogReducer from '../redux/dialogSlice';
import userReducer from '../redux/userState';
import tabIndexReducer from '../redux/tabIndexSlice';
import productReducer from '../redux/productSlice';
import userSlice from '../redux/userSlice';
import cityReducer from '../redux/citySlice';


const rootReducer = combineReducers({
  cart: cartReducer,
  loading: loadingReducer,
  dialog: dialogReducer,
  userState: userReducer,
  tabIndex: tabIndexReducer,
  product: productReducer,
  user: userSlice,
  city: cityReducer,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;