import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Image } from "primereact/image";
import sampleTyreImage from "../../assets/images/sample_tyre.png";
import carSuvImg from "../../assets/images/car_suv.png";
import bikeImg from "../../assets/images/bike_scooter.png";
import threeWheelerImg from "../../assets/images/three_wheeler.png";
import commercialImg from "../../assets/images/commercial.png";
import { Product } from "../Product/Product";
import ceatMini from "../../assets/images/TyreBrandLogos/ceat_mini.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { setTabIndex } from "../../redux/tabIndexSlice";
import useHeroAPI from "../../api/useHeroAPI";
import useProductsAPI from "../../api/useProductsAPI";
import { useNavigate } from "react-router-dom";
import { selectCity } from "../../redux/citySlice";
import { selectRefreshTrigger } from "../../redux/productSlice";

const PopularProducts = (props: any) => {
  const { code } = props;
  const basePath = process.env.REACT_APP_BASE_PATH;

  const refreshTrigger = useSelector(selectRefreshTrigger);

  const cityId = useSelector(selectCity);
  const tabIndex = useSelector((state: RootState) => state.tabIndex);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { predefinedFunc } = useHeroAPI();
  const { getProductList } = useProductsAPI();

  const [popularProductsArray, setPopularProductsArray] = useState([]) as any;
  const [userId, setUserId] = useState(null) as any;
  const [productType, setProductType] = useState({} as any);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [tyreType, setTyreType] = useState(null) as any;
  const [typesPreDefinedState, setTypesPreDefinedState] = useState([]);

  useEffect(() => {
    window.scrollTo(0, scrollPosition);
  }, [scrollPosition]);

  useEffect(() => {
    const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    if (authTokens?.user?.id) {
      setUserId(authTokens?.user?.id);
    }
  }, []);

  const fetchPredefined = async () => {
    const predefinedParentPayload = {
      entity_type: "CATEGORY",
    };
    const predefinedResponse = await predefinedFunc(predefinedParentPayload);
    if (predefinedResponse.status === 200) {
      setTypesPreDefinedState(predefinedResponse?.data?.data);
      const defaultTyreType = predefinedResponse?.data?.data?.find(
        (type: any) => type?.code === "TWO-WHEELER"
      );
      setTyreType(defaultTyreType);

      const twoWheelerIndex = predefinedResponse?.data?.data.findIndex(
        (type: any) => type.code === "TWO-WHEELER"
      );
      if (twoWheelerIndex !== -1) {
        dispatch(setTabIndex(twoWheelerIndex));
      }
    }
    // fetchChildPredefined();
  };

  const fetchProductType = async (code: string) => {
    const predefinedParentPayload = {
      entity_type: "PRODUCT-TYPE",
      code: code,
    };
    const predefinedResponse = await predefinedFunc(predefinedParentPayload);
    if (predefinedResponse.status === 200) {
      setProductType(predefinedResponse.data.data[0]);
      productListFunc(predefinedResponse.data.data[0].predefined_id);
    }

    // fetchChildPredefined();
  };
  const productListFunc = async (product_type: number) => {
    const payload = {
      entity_type: "POPULAR",
      // city_id: cityId ? cityId : null,
      page_size: 6,
      page_number: 1,
      user_id: userId,
      product_type_id: product_type,
      category_ids: tyreType?.predefined_id ? [tyreType?.predefined_id] : null,
    };

    const response: any = await getProductList(payload);
    if (response?.status === 200 || response?.status === 201) {
      setPopularProductsArray(response?.data);
    }
  };

  useEffect(() => {
    // if(userId){
    fetchPredefined();
    fetchProductType(code);
    // }
  }, [userId, code]);

  useEffect(() => {
    if (productType?.predefined_id) {
      productListFunc(productType.predefined_id);
    }
  }, [refreshTrigger, tyreType]);

  // const [tabIndex, setTabIndex] = useState(0);
  const [popularProdsTabIndex, setPopularProdsTabIndex] = useState(0);

  const handlePopularProdsTabSelect = (
    index?: any,
    last?: any,
    event?: any
  ) => {
    event?.preventDefault();
    event?.stopPropagation();
    const newVehicleType: any = typesPreDefinedState[index];
    // setTyreType((event.target as Element).textContent);
    setTyreType(newVehicleType);
    setScrollPosition(window.pageYOffset);
    dispatch(setTabIndex(index));
  };

  useEffect(() => {
    handlePopularProdsTabSelect(tabIndex, null, null);
  }, [tabIndex]);

  // const handlePopularProdsTabSelect = (index: number) => {
  //   setPopularProdsTabIndex(index);
  // };

  const predefinedImgArr = [carSuvImg, bikeImg, threeWheelerImg, commercialImg];

  const redirectToProducts = () => {
    // navigate("/mechmiles-customer/products");
    navigate(`${basePath}products`, {
      state: {
        code: code,
        type_id: productType.predefined_id,
        tyreType: tyreType,
        productType: productType,
      },
    });
  };

  return (
    <>
      <div className="popular-products-title">Popular products</div>
      <Container className="popular-products-tab">
        {/* <Tabs selectedIndex={tabIndex} onSelect={handlePopularProdsTabSelect}> */}
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(index: any, lastIndex: number, event: Event) =>
            handlePopularProdsTabSelect(index, lastIndex, event)
          }
        >
          <TabList>
            <div className="internal-popular-products-tab flex w-full justify-content-center align-item-center">
              <div>
                {typesPreDefinedState.map((types: any, index: number) => {
                  return (
                    <Tab key={index}>
                      <Image
                        src={
                          tabIndex === index
                            ? types?.active_thumbnail
                            : types?.thumbnail
                        }
                        className="tyre-type-images"
                      />
                      {types.name}
                    </Tab>
                  );
                })}
              </div>
            </div>
          </TabList>

          <TabPanel>
            <div className="product-container">
              <div className="product-wrapper">
                {popularProductsArray?.map((product: any, index: any) => (
                  <Product key={index} product={product} />
                ))}
              </div>
              <div className="view-all-wrapper">
                <span className="view-all-btn" onClick={redirectToProducts}>
                  View All
                </span>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="product-container">
              <div className="product-wrapper">
                {popularProductsArray?.map((product: any, index: any) => (
                  <Product key={index} product={product} />
                ))}
              </div>
              <div className="view-all-wrapper" onClick={redirectToProducts}>
                <span className="view-all-btn">View All</span>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="product-container">
              <div className="product-wrapper">
                {popularProductsArray?.map((product: any, index: any) => (
                  <Product key={index} product={product} />
                ))}
              </div>
              <div className="view-all-wrapper">
                <span className="view-all-btn">View All</span>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="product-container">
              <div className="product-wrapper">
                {popularProductsArray?.map((product: any, index: any) => (
                  <Product key={index} product={product} />
                ))}
              </div>
              <div className="view-all-wrapper">
                <span className="view-all-btn">View All</span>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </Container>
    </>
  );
};

export default PopularProducts;
