import React, { useEffect, useRef, useState } from "react";
import "./AllOrders.css";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Image } from "primereact/image";
import useCartAPI from "../../api/useCartAPI";
import yokohamaMini from "../../assets/images/TyreBrandLogos/yokohama_mini.png";
import miniDot from "../../assets/images/mini_dot.png";
import serviceImgMini from "../../assets/images/service-img-mini.png";
import { Button } from "primereact/button";
import useOrderAPI from "../../api/useOrderAPI";
import { Toast } from "primereact/toast";
import useHeroAPI from "../../api/useHeroAPI";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/loadingSlice";
import useLoginAPI from "../../api/useLogin";
import { Dialog } from "primereact/dialog";
import { Paginator } from "primereact/paginator";

interface Item {
  id: number;
  name: string;
  rim: string;
  price: string;
  quantity: number;
  total: string;
}

const AllOrders = () => {
  const corporateAdminRole = "ROLE_CORPORATE_ADMIN";

  const loading = useSelector((state: any) => state.loading);
  const dispatch = useDispatch();

  const toast = useRef<Toast>(null);
  const calendarRef = useRef(null);

  useEffect(() => {
    const authToken: any = JSON.parse(
      localStorage.getItem("authTokens") || "{}"
    );

    authToken?.user?.roles.map((role: any) => {
      if (role.code === corporateAdminRole) {
        setUserRole(role);
      }
    });
  }, []);

  const {
    allOrders,
    changeOrderStatus,
    getAllOrdersStats,
    exportAllOrders,
    allOrdersCount,
  } = useOrderAPI();
  const { getDepartmentNames } = useLoginAPI();

  const { predefinedFunc } = useHeroAPI();

  const [userRole, setUserRole] = useState("") as any;

  const [allOrdersStats, setAllOrdersStats] = useState({}) as any;
  const [dates, setDates] = useState<(Date | null)[] | null>(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null) as any;
  const [selectedStatus, setSelectedStatus] = useState(null) as any;
  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState(
    null
  ) as any;
  const [items, setItems] = useState<Item[]>([]);
  const [approveRejectTriggered, setApproveRejectTriggered] = useState(false);
  const [showReject, setShowReject] = useState(null);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [approvalOptions, setApprovalOptions] = useState([]);
  const [userCompanyId, setUserCompanyId] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPageOption, setRowsPerPageOption] = useState(10);
  const [records, setRecords] = useState(20);

  const [searchTerm, setSearchTerm] = useState("");
  const [orderId, setOrderId] = useState(0);
  const [debounceTimeoutId, setDebounceTimeoutId] =
    useState<NodeJS.Timeout | null>(null);

  const [isRejecting, setIsRejecting] = useState(false);

  const fetchAllOrders = async (
    searchTerm?: string,
    startDate?: Date,
    endDate?: Date
  ) => {
    dispatch(setLoading(true));
    const payload = {
      search_by: searchTerm ? searchTerm : null,
      start_date: startDate ? startDate : null,
      end_date: endDate ? endDate : null,
      department_id: selectedDepartment ?? null,
      status_id: selectedStatus ?? null,
      company_id: userCompanyId ?? null,
      page_number: pageNumber,
      page_size: rowsPerPageOption,
      approval_status_id: selectedApprovalStatus ?? null,
    };

    const response: any = await allOrders(payload);
    const countResponse: any = await allOrdersCount(payload);
    const statsResponse: any = await getAllOrdersStats(payload);

    if (response.status === 200 || response.status === 201) {
      dispatch(setLoading(false));
      setItems(response?.data);
      setRecords(countResponse?.data?.data);
    } else {
      dispatch(setLoading(false));
    }

    if (statsResponse.status === 200 || statsResponse.status === 201) {
      dispatch(setLoading(false));
      setAllOrdersStats(statsResponse?.data);
    } else {
      dispatch(setLoading(false));
    }
  };

  const fetchPredefined = async () => {
    dispatch(setLoading(true));
    const departmentPayload = {
      entity_type: "DEPARTMENT",
    };
    const statusPayload = {
      entity_type: "ORDER-STATUS",
    };
    const approvalPayload = {
      entity_type: "APPROVAL-STATUS",
    };
    const departmentResponse = await predefinedFunc(departmentPayload);
    let response: any;
    if (userCompanyId) {
      response = await getDepartmentNames(userCompanyId);
    }

    if (response?.status === 200) {
      setDepartmentOptions(response?.data);
    }
    const statusResponse = await predefinedFunc(statusPayload);
    const approvalResponse = await predefinedFunc(approvalPayload);
    if (statusResponse.status === 200 && approvalResponse.status === 200) {
      dispatch(setLoading(false));
      // setDepartmentOptions(departmentResponse.data.data);
      setStatusOptions(statusResponse.data.data);
      setApprovalOptions(approvalResponse.data.data);
    } else {
      dispatch(setLoading(false));
    }
    // fetchChildPredefined();
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    if (debounceTimeoutId) clearTimeout(debounceTimeoutId);
    setDebounceTimeoutId(setTimeout(() => fetchAllOrders(newSearchTerm), 300));
  };

  // useEffect(() => {
  //   if (userCompanyId === null) {
  //     const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
  //     if (authTokens?.user) {
  //       setUserCompanyId(authTokens?.user?.company_id)
  //     }
  //   }
  //   if(userCompanyId !== null){
  //     fetchPredefined();
  //   }
  // }, [userCompanyId])

  useEffect(() => {
    if (userCompanyId === null) {
      const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
      if (authTokens?.user) {
        setUserCompanyId(authTokens?.user?.company_id);
      }
    }
    if (userCompanyId !== null) {
      fetchPredefined();
      fetchAllOrders();
      // fetchAllOrdersStats();
    }
  }, [
    approveRejectTriggered,
    selectedDepartment,
    selectedStatus,
    selectedApprovalStatus,
    userCompanyId,
    pageNumber,
    rowsPerPageOption,
  ]);

  // const fetchAllOrders = async () => {
  //   const payload = {};

  //   const response: any = await allOrders(payload);
  //   if (response.status === 200 || response.status === 201) {
  //     setItems(response?.data);
  //   }
  // };
  // const fetchAllOrdersStats = async () => {
  //   dispatch(setLoading(true));
  //   const payload = {}

  //   const response: any = await getAllOrdersStats(payload);
  //   if (response.status === 200 || response.status === 201) {
  //     dispatch(setLoading(false));
  //     setAllOrdersStats(response?.data);
  //   }
  // };

  const handleAcceptReject = async (order_id: number, type: string) => {
    const payload = {
      order_id: order_id,
      status_code: type,
    };

    const response: any = await changeOrderStatus(payload);
    if (response.status === 200 || response.status === 201) {
      // fetchAllOrders();
      setApproveRejectTriggered(!approveRejectTriggered);
      setIsRejecting(false);
      return toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `${response?.data?.message}`,
      });
    } else {
      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Failed to change status`,
      });
    }
  };
  const handleRejectClick = async (order_id: number) => {
    setOrderId(order_id);
    setIsRejecting(true);
  };
  const handleRejectOrder = () => {
    handleAcceptReject(orderId, "REJECTED");
  };

  const handleDateChange = (e: any) => {
    const newDates = e.value;
    setDates(newDates);
    if (Array.isArray(newDates) && newDates[1]) {
      // Assuming the second date in the array is the end date
      fetchAllOrders(searchTerm, newDates[0], newDates[1]);
    } else if (newDates === null) {
      fetchAllOrders(searchTerm);
    }
  };

  const formatDate = (date: Date) => {
    const day = date.getDate();
    let suffix = "th";
    if (day % 10 === 1 && day !== 11) suffix = "st";
    else if (day % 10 === 2 && day !== 12) suffix = "nd";
    else if (day % 10 === 3 && day !== 13) suffix = "rd";

    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();

    return `${day}${suffix} ${month} ${year}`;
  };

  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setIsRejecting(false)}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={() => handleRejectOrder()}
        autoFocus
      />
    </div>
  );

  const exportAllOrdersFunc = async () => {
    const payload = {
      search_by: searchTerm ? searchTerm : null,
      start_date: dates ? dates[0] : null,
      end_date: dates ? dates[1] : null,
      department_id: selectedDepartment ?? null,
      status_id: selectedStatus ?? null,
      company_id: userCompanyId ?? null,
      approval_status_id: selectedApprovalStatus ?? null,
    };

    const response: any = await exportAllOrders(payload);

    if (response.status === 200 || response.status === 201) {
      const url = response?.data.message;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "all_orders.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  const downloadFile = (url: string, fileName: string) => {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = url;
    a.download = fileName || 'download';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};


  const onPageChange = (e: any) => {
    const newPageNumber = e.first / e.rows + 1;
    setPageNumber(newPageNumber);
    // setCurrentPage(newPageNumber);
    setRowsPerPageOption(e.rows);
  };

  const paginatorTemplate = {
    layout:
      "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",

    CurrentPageReport: (options: any) => {
      return (
        <span
          style={{
            color: "#6c757d",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
            display: "block",
          }}
        >
          {options.first} to {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <>
      {loading && <div className="loading-overlay">Loading...</div>}
      <Toast ref={toast} />
      <div className="all-orders-header">
        <h1 className="all-orders-title">All Orders</h1>
        <div className="all-orders-status-wrapper">
          <div className="total-orders-wrapper">
            <div className="total-orders-title">Total Orders:</div>
            <div className="total-orders-content">
              {allOrdersStats?.total_order ?? 0}
            </div>
          </div>
          <div className="approval-status-wrapper">
            <div className="approval-status-title">Approval Status</div>
            <div className="approval-status-content">
              <div className="approval-status-content-data">
                <div className="order-status-data">
                  Pending: {allOrdersStats?.pending_approval ?? 0}
                </div>
                <div className="order-status-data">
                  {/* In Progress: {allOrdersStats?.in_progress_order ?? 0} */}
                </div>
                <div className="order-status-data">
                  Accepted: {allOrdersStats?.accepted_approval ?? 0}
                </div>
              </div>
            </div>
          </div>
          <div className="order-status-wrapper">
            <div className="order-status-title">Accepted Order Status</div>
            <div className="order-status-content">
              <div className="order-status-content-data">
                <div className="order-status-data">
                  Pending: {allOrdersStats?.pending_order ?? 0}
                </div>
                <div className="order-status-data">
                  {/* In Progress: {allOrdersStats?.in_progress_approval ?? 0} */}
                </div>
                <div className="order-status-data">
                  Completed: {allOrdersStats?.accepted_order ?? 0}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="all-orders-status-wrapper">
          <div className="total-orders-wrapper">
            <div className="total-orders-title">Total Amount:</div>
            <div className="total-orders-content">
              {allOrdersStats?.total_order_amount ?? 0}
            </div>
          </div>
          {/* <div className="approval-status-wrapper">
            <div className="approval-status-title">Approval amount</div>
            <div className="approval-status-content">
              <div className="approval-status-content-data">
                <div className="order-status-data">
                  Pending: {allOrdersStats?.pending_approval_amount ?? 0}
                </div>
                <div className="order-status-data">
                  // In Progress: {allOrdersStats?.in_progress_approval_amount ?? 0}
                </div>
                <div className="order-status-data">
                  Accepted: {allOrdersStats?.accepted_approval_amount ?? 0}
                </div>
              </div>
            </div>
          </div> */}
          <div className="order-status-wrapper">
            <div className="order-status-title">Accepted Order Amount</div>
            <div className="order-status-content">
              <div className="order-status-content-data">
                <div className="order-status-data">
                  Pending: &#8377; {allOrdersStats?.pending_order_amount ?? 0}
                </div>
                <div className="order-status-data">
                  {/* In Progress: &#8377; {allOrdersStats?.in_progress_order_amount ?? 0} */}
                </div>
                <div className="order-status-data">
                  Completed: &#8377;{" "}
                  {allOrdersStats?.accepted_order_amount ?? 0}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="all-orders-container">
        <div className="all-orders-export-btn-wrapper">
          <Button
            label="Export"
            className="all-orders-export"
            onClick={exportAllOrdersFunc}
          />
        </div>
        <div className="all-order-filter-wrapper">
          <div className="all-order-filter-left">
            <div className="orders-search-input">
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </span>
            </div>
          </div>
          <div className="all-order-filter-right">
            <div className="all-order-date-range">
              <Calendar
                value={dates}
                onChange={handleDateChange}
                selectionMode="range"
                readOnlyInput
                placeholder="Date Range"
                ref={calendarRef}
                showButtonBar
              />
            </div>
            <div className="all-orders-department">
              <Dropdown
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.value)}
                options={departmentOptions}
                optionLabel="department_name"
                optionValue="company_department_id"
                placeholder="Department"
                className="w-full"
                showClear
                filter
              />
            </div>
            <div className="all-orders-status">
              <Dropdown
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.value)}
                options={statusOptions}
                optionLabel="name"
                optionValue="predefined_id"
                placeholder="Order Status"
                className="w-full"
                showClear
              />
            </div>
            <div className="all-orders-status">
              <Dropdown
                value={selectedApprovalStatus}
                onChange={(e) => setSelectedApprovalStatus(e.value)}
                options={approvalOptions}
                optionLabel="name"
                optionValue="predefined_id"
                placeholder="Approval Status"
                className="w-full"
                showClear
              />
            </div>
          </div>
        </div>
        <div className="all-order-accordion">
          {items.length === 0 && (
            <div className="no-items">No Orders Are Available</div>
          )}
          <Accordion activeIndex={0} className="order-accordion">
            {items.map((item: any) => (
              <AccordionTab
                key={item.order_id}
                header={
                  <div className="accordion-header-custom">
                    <div className="order-id-container all-order-accordion-header-container">
                      <div className="order-id-title accordion-inner-title">
                        ORDERID:
                      </div>
                      <div className="order-id-content accordion-inner-content">
                        {item?.order_id}
                      </div>
                    </div>
                    <div className="date-container all-order-accordion-header-container">
                      <div className="date-title accordion-inner-title">
                        Order Date
                      </div>
                      <div className="date-content accordion-inner-content">
                        {new Date(item?.created_at).toLocaleDateString() ||
                          "Not Available"}
                      </div>
                    </div>
                    <div className="date-container all-order-accordion-header-container">
                      <div className="date-title accordion-inner-title">
                        Service Date
                      </div>
                      <div className="date-content accordion-inner-content">
                        {new Date(
                          item?.service_start_date
                        ).toLocaleDateString() || "Not Available"}
                      </div>
                    </div>
                    <div className="date-container all-order-accordion-header-container">
                      <div className="date-title accordion-inner-title">
                        Service Time
                      </div>
                      <div className="date-content accordion-inner-content">
                        {item?.service_start_time?.name || "Not Available"}
                      </div>
                    </div>
                    <div className="approval-status-container all-order-accordion-header-container">
                      <div className="approval-title accordion-inner-title">
                        APPROVAL:
                      </div>
                      <div
                        className={`approval-content accordion-inner-content ${item?.approval_status?.name
                          .toLowerCase()
                          .replace(/\s/g, "-")}`}
                      >
                        {item?.approval_status?.name || "Not Available"}
                      </div>
                    </div>
                    <div className="order-status-container all-order-accordion-header-container">
                      <div className="accordion-order-status-title accordion-inner-title">
                        ORDER:
                      </div>
                      <div
                        className={`accordion-order-status-content accordion-inner-content ${item?.status?.name
                          .toLowerCase()
                          .replace(/\s/g, "-")}`}
                      >
                        {item?.status?.name || "Not Available"}
                      </div>
                    </div>
                    <div className="items-count-container all-order-accordion-header-container">
                      <div className="item-count-title accordion-inner-title">
                        Items:
                      </div>
                      <div className="item-count-content accordion-inner-content">
                        {item?.order_items.length || 0}
                      </div>
                    </div>

                    {/* <div className="employee-name-container all-order-accordion-header-container">
                      <div className="employee-name-title accordion-inner-title">
                        Employee
                      </div>
                      <div className="employee-name-content accordion-inner-content">
                        Rohit Raj
                      </div>
                    </div>
                    <div className="department-name-container all-order-accordion-header-container">
                      <div className="department-name-title accordion-inner-title">
                        DEPT
                      </div>
                      <div className="department-name-content accordion-inner-content">
                        Finance
                      </div>
                    </div> */}
                    <div className="total-number-container all-order-accordion-header-container">
                      <div className="total-number-title accordion-inner-title">
                        TOTAL
                      </div>
                      <div className="total-number-content accordion-inner-content">
                        {item?.payable_amount || 0}
                      </div>
                    </div>
                    <div className="total-number-container all-order-accordion-header-container">
                      <div className="total-number-title accordion-inner-title">
                        Department
                      </div>
                      <div className="total-number-content accordion-inner-content">
                        {item?.department?.department_name || "N/A"}
                      </div>
                    </div>
                    
                  </div>
                }
              >
                <div className="all-order-single-order">
                  <div className="items-container">
                    <div className="item-header">
                      <span>Items</span>
                    </div>
                    <ul className="item-list">
                      {item?.order_items?.map((itm: any, index: any) => (
                        <li key={itm.id} className="item">
                          <div className="item-title-wrapper">
                            <Image
                              src={itm?.product?.brand?.thumbnail}
                              alt="Yokohama Brand Logo"
                              className="item-title-image"
                            />
                            <div className="item-title-container">
                              <div className="item-name">
                                {itm.product.product_name || "Not Available"}
                              </div>
                            </div>
                          </div>
                          <Image
                            src={miniDot}
                            alt="Mini Dot"
                            className="item-dot"
                          />
                          {/* <div className="item-details">
                            {itm.product.product_rim || "Not Available"}
                          </div>
                          <Image
                            src={miniDot}
                            alt="Mini Dot"
                            className="item-dot"
                          /> */}
                          <div className="item-price">
                            Price: &#8377;
                            {itm.amount || "Not Available"}
                          </div>
                          <Image
                            src={miniDot}
                            alt="Mini Dot"
                            className="item-dot"
                          />
                          <div className="item-quantity">
                            Quantity: {itm.item_quantity || 0}
                          </div>
                          <Image
                            src={miniDot}
                            alt="Mini Dot"
                            className="item-dot"
                          />
                          <div className="item-total">
                            &#8377;{itm.total_amount || 0}
                          </div>
                          <div className="all-orders-export-btn-wrapper">
                      {itm?.invoice_url && <Button
                        label="Download Invoice"
                        className="all-orders-export"
                        onClick={()=>downloadFile(itm?.invoice_url, `order-id-${itm.order_id}`)}
                      />}
                    </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* <div className="all-order-single-order">
                  <div className="items-container">
                    <div className="item-header">
                      <span>Selected Service</span>
                    </div>
                    <div className="all-order-selected-services">
                      <div className="all-order-single-service">
                        <div className="selected-service-wrapper">
                          <Image
                            src={serviceImgMini}
                            alt="serviceImgMini"
                            className="service-img-mini"
                            height="50px"
                            width="60px"
                          />
                        </div>
                        <div className="selected-service-point-meta">
                          <div className="selected-service-point-title">
                            Tyre opening & fitting
                          </div>
                          <div className="selected-service-point-price">
                            &#8377; 40
                          </div>
                        </div>
                      </div>
                      <div className="all-order-single-service">
                        <div className="selected-service-wrapper">
                          <Image
                            src={serviceImgMini}
                            alt="serviceImgMini"
                            className="service-img-mini"
                            height="50px"
                            width="60px"
                          />
                        </div>
                        <div className="selected-service-point-meta">
                          <div className="selected-service-point-title">
                            Tyre opening & fitting
                          </div>
                          <div className="selected-service-point-price">
                            &#8377; 40
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="all-order-single-order">
                  <div className="all-orders-service-point-data">
                    <div className="all-orders-service-point-name">
                      {item?.service_point?.service_point_name}
                    </div>
                    {/* <div className="all-orders-service-point-date">
                      <div className="all-orders-appointment-id">
                        Appointment ID : 1028987675
                      </div>
                      <div className="all-orders-appointment-date">
                        2023-12-23 (14:00 - 17:00)
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="all-order-single-order">
                  <div className="single-orders-data">
                    {/* <div className="order-id-container">
                      <div className="order-id-title">Order Date</div>
                      <div className="order-id-content">
                        {item?.created_at ? formatDate(new Date(item.created_at)) : "Not Available"}
                      </div>
                    </div>
                    <div className="order-id-container">
                      <div className="order-id-title">Order Time</div>
                      <div className="order-id-content">
                        {new Date(item?.created_at).toLocaleTimeString() ||
                          "Not Available"}
                      </div>
                    </div> */}
                    <div className="order-id-container">
                      <div className="order-id-title">Customer Name</div>
                      <div className="order-id-content">
                        {item?.user?.full_name || "Not Available"}
                      </div>
                    </div>
                    <div className="order-id-container">
                      <div className="order-id-title">Email</div>
                      <div className="order-id-content">
                        {item?.user?.email || "Not Available"}
                      </div>
                    </div>
                    <div className="order-id-container">
                      <div className="order-id-title">Mobile</div>
                      <div className="order-id-content">
                        {item?.user?.phone || "Not Available"}
                      </div>
                    </div>
                    <div className="order-id-container">
                      <div className="order-id-title">Vehicle Number</div>
                      <div className="order-id-content">
                        {item?.vehicle?.vehicle_number || "Not Available"}
                      </div>
                    </div>
                    <div className="order-id-container">
                      <div className="order-id-title">Vehicle Make</div>
                      <div className="order-id-content">
                        {item?.vehicle?.make?.name || "Not Available"}
                      </div>
                    </div>
                    <div className="order-id-container">
                      <div className="order-id-title">Vehicle Model</div>
                      <div className="order-id-content">
                        {item?.vehicle?.model?.name || "Not Available"}
                      </div>
                    </div>
                    <div className="order-id-container">
                      <div className="order-id-title">Vehicle Variant</div>
                      <div className="order-id-content">
                        {item?.vehicle?.variant?.name || "Not Available"}
                      </div>
                    </div>
                    {/* <div className="order-id-container">
                      <div className="order-id-title">Department</div>
                      <div className="order-id-content">Finance</div>
                    </div> */}
                  </div>
                </div>
                <div
                  className="accept-reject-wrapper"
                  // style={{
                  //   display: item?.status?.name.toLowerCase() === "confirmed" || item?.status?.name.toLowerCase() === "rejected"
                  //     ? "none"
                  //     : "block",
                  // }}
                >
                  <div className="action-buttons-wrapper">
                    {item?.approval_status?.name.toLowerCase() ===
                      "pending" && (
                      <Button
                        className="accept-button"
                        onClick={() => {
                          handleAcceptReject(item?.order_id, "APPROVED");
                        }}
                        // onClick={() => {
                        //   handleAcceptReject();
                        // }}
                      >
                        Accept
                      </Button>
                    )}
                    {item?.approval_status?.name.toLowerCase() ===
                      "pending" && (
                      <Button
                        className="reject-button"
                        // onClick={() => {
                        //   handleAcceptReject(item?.order_id, "REJECTED");
                        // }}
                        onClick={() => {
                          handleRejectClick(item?.order_id);
                        }}
                      >
                        Reject
                      </Button>
                    )}
                  </div>
                </div>
              </AccordionTab>
            ))}
          </Accordion>
          <Paginator
            first={(pageNumber - 1) * rowsPerPageOption}
            rows={rowsPerPageOption}
            totalRecords={records}
            rowsPerPageOptions={[5, 10, 25, records]}
            onPageChange={onPageChange}
            template={paginatorTemplate}
          />
        </div>
      </div>
      <Dialog
        header="Header"
        visible={isRejecting}
        style={{ width: "50vw" }}
        onHide={() => setIsRejecting(false)}
        footer={footerContent}
      >
        <p className="m-0">Are You Sure You Want To Reject This Order?</p>
      </Dialog>
    </>
  );
};

export default AllOrders;
