import { createSlice } from '@reduxjs/toolkit';

interface DialogState {
  visible: boolean;
  userVisible: boolean;
  alreadyRegistered: boolean;
  userAlreadyRegistered: boolean;
  showLoginDialog: boolean,
  loginType: string,
  showUserRegister: boolean,
  showRegister: boolean,
}

const initialState: DialogState = {
  visible: false,
  userVisible: false,
  alreadyRegistered: false,
  userAlreadyRegistered: false,
  showLoginDialog: false,
  loginType: '',
  showUserRegister: false,
  showRegister: false,
};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    showDialog: (state) => {
      state.visible = true;
    },
    hideDialog: (state) => {
      state.visible = false;
    },
    showUserDialog: (state) => {
      state.userVisible = true;
    },
    hideUserDialog: (state) => {
      state.userVisible = false;
    },
    goToLogin: (state) => {
      state.alreadyRegistered = true;
    },
    goToRegister: (state) => {
      state.alreadyRegistered = false;
    },
    goToUserLogin: (state) => {
      state.userAlreadyRegistered = true;
    },
    goToUserRegister: (state) => {
      state.userAlreadyRegistered = false;
    },
    setShowLoginDialog: (state, action) => {
      state.showLoginDialog = action.payload;
    },
    setLoginType: (state, action) => {
      state.loginType = action.payload;
    },
    setShowUserRegister: (state, action) => {
      state.showUserRegister = action.payload;
    },
    setShowRegister: (state, action) => {
      state.showRegister = action.payload;
    },
  },
});

export const { showDialog, hideDialog, goToLogin, goToRegister, goToUserLogin, goToUserRegister, showUserDialog, hideUserDialog } = dialogSlice.actions;

export const { setShowLoginDialog, setLoginType, setShowUserRegister, setShowRegister } = dialogSlice.actions;

export const selectDialog = (state: { dialog: DialogState }) => state.dialog.visible;
export const selectUserDialog = (state: { dialog: DialogState }) => state.dialog.userVisible;

export const alreadyRegistered = (state: { dialog: DialogState }) => state.dialog.alreadyRegistered;
export const userAlreadyRegistered = (state: { dialog: DialogState }) => state.dialog.userAlreadyRegistered;

export default dialogSlice.reducer;