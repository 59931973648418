import React, { createContext, useState, useEffect, useRef } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
// import useLoginAPI from '../api/useLoginAPI';

type AuthContextType = {
    user: any;
    authTokens: any;
    setAuthTokens: React.Dispatch<any>;
    setUser: React.Dispatch<any>;
    loginUser: (e: any) => Promise<void>;
    logoutUser: () => void;
};
const baseURL = process.env.REACT_APP_BASE_URL;

const AuthContext = createContext<AuthContextType | null>(null);


export default AuthContext;

export const AuthProvider = (props: any) => {
    // const { roleAndUser } = useLoginAPI();

    const toast = useRef<Toast>(null);

    let [authTokens, setAuthTokens] = useState(() => {
        const storedTokens = localStorage?.getItem('authTokens');
        if(storedTokens === 'undefined'){
            localStorage.clear();
            navigate('/');
        }
        return storedTokens ? JSON.parse(storedTokens) : null;
    });
    let [user, setUser] = useState(() => {
        const storedTokens = localStorage.getItem('authTokens');
        return storedTokens ? storedTokens : null;
    });
    let [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    let loginUser = async (e: any) => {
        // e.preventDefault();
        let response: any = await fetch(baseURL + '/auth/generate-token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: e.username.trim(),
            })
        });
        let data = await response.json();

        if (response.status === 200) {
            setAuthTokens(data?.tokens);

            // Decode accessToken and 'id' in local storage as 'id'
            const decodedToken: any = jwt_decode(data.tokens?.access);
            localStorage.setItem('id', decodedToken?.id);

            // setUser(jwt_decode(data.data.accessToken));
            localStorage.setItem('authTokens', JSON.stringify(data?.tokens));

            // const payload = {
            //     username: e.target.username.value,
            //     password: e.target.password.value,
            //     isAdminLogin: true
            // };
            // const header = {
            //     Authorization: `Bearer ${data.data.accessToken || ''}`
            // };
            // let roleResponse: any = await roleAndUser(payload, header);
            // if (roleResponse) {
            //     let accessControlMasterMap: { [moduleCode: string]: any } = {};
            //     for (let access of roleResponse.data) {
            //         accessControlMasterMap[access.moduleName] = access;
            //     }
            //     // console.log(JSON.stringify(accessControlMasterMap));
            //     localStorage.setItem('ACL', JSON.stringify(accessControlMasterMap));
            //     localStorage.setItem('ACList', JSON.stringify(roleResponse.data));
            // }

            navigate('/products');
        } else if (response?.status === 400) {
            // alert('Invalid Login');
            toast?.current?.show({ severity: 'error', summary: 'Login Failed', detail: `${data?.error}` });
        } else if (response?.status === 404) {
            // alert('Invalid Login');
            toast?.current?.show({ severity: 'error', summary: 'Login Failed', detail: `${data?.error}` });
        } else if (response?.status === 500) {
            // alert('Invalid Login');
            toast?.current?.show({ severity: 'error', summary: 'Login Failed', detail: 'Password is incorrect' });
        } else {
            toast?.current?.show({ severity: 'error', summary: 'Login Failed', detail: 'Failed to login' });
        }
    };

    let logoutUser = () => {
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem('authTokens');
        navigate('/login');
    };

    let contextData: {
        user: any;
        authTokens: any;
        setAuthTokens: React.Dispatch<any>;
        setUser: React.Dispatch<any>;
        loginUser: (e: any) => Promise<void>;
        logoutUser: () => void;
    } = {
        user,
        authTokens,
        setAuthTokens,
        setUser,
        loginUser,
        logoutUser
    };

    useEffect(() => {
        if (authTokens) {
            // TODO(Raju) : Uncomment
            // setUser(jwt_decode(authTokens!.accessToken));
        }
        setLoading(false);
    }, [authTokens, loading]);

    return (
        <AuthContext.Provider value={contextData}>
            <div>
                <Toast ref={toast} /> {loading ? null : props.children}
            </div>
        </AuthContext.Provider>
    );
};
