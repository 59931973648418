import axios from "axios";
import useAxios from "../hooks/useAxios";

const baseURL = process.env.REACT_APP_BASE_URL;

let url = "/appointment"
let appointmentListUrl = "/appointment-list";
let appointmentListCountUrl = "/appointment-count";

const useAppointmentAPI = () => {
  const { axiosInstance } = useAxios();

  const getAppointmentList = async (data: any) => {
    try {
      let response = await axiosInstance.post(baseURL + `${url}${appointmentListUrl}`, data);
      return response;
    }
    catch (error) {
      return error
    }
  };
  const getAppointmentListCount = async (data: any) => {
    try {
      let response = await axiosInstance.post(baseURL + `${url}${appointmentListCountUrl}`, data);
      return response;
    }
    catch (error) {
      return error
    }
  };


  return {
    getAppointmentList,
    getAppointmentListCount
  };
};

export default useAppointmentAPI;
