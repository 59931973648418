import axios from "axios";
import useAxios from "../hooks/useAxios";

const baseURL = process.env.REACT_APP_BASE_URL;

let preDefinedUrl = "/service-management/services/list";

const usePackageAPI = () => {
  const { axiosInstance } = useAxios();

  const getPackageFunc = async (data: any) => {
    let response = await axiosInstance.post(baseURL + `${preDefinedUrl}`, data);
    return response;
  };


  return {
    getPackageFunc
  };
};

export default usePackageAPI;
