import useAxios from '../hooks/useAxios';

const baseURL = process.env.REACT_APP_BASE_URL;
let url = '/predefined/all';
let servicePointUrl = '/service-point/service_point-list';

const usePreDefinedListAPI = () => {
    const { axiosInstance } = useAxios();

    const getPredefinedList = async (pageNo?:string, pageSize?: string, data?: any) => {
        try {
            const response = await axiosInstance.post(`${baseURL}${url}?page_number=${pageNo}&page_size=${pageSize}`, data);
            return response.data;
        } catch (error) {
            return error;
        }
    };
    const getServicePointList = async (data?: any, pageNo?:string, pageSize?: string) => {
        try {
            const response = await axiosInstance.post(`${baseURL}${servicePointUrl}?page_number=${pageNo}&page_size=${pageSize}`, data);
            return response;
        } catch (error) {
            return error;
        }
    };
    const getSPList = async (data?: any) => {
        try {
            const response = await axiosInstance.post(`${baseURL}/service-point/list`, data);
            return response;
        } catch (error) {
            return error;
        }
    };

    return { getPredefinedList, getServicePointList, getSPList };
};

export default usePreDefinedListAPI;
