import React from 'react'

import './Franchise.css'

import franchiseImage from '../../assets/images/franchise-img.png'
import { Image } from 'primereact/image'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'

const Franchise = () => {

  const navigate = useNavigate();

  const handleApplyNow = () => {
    navigate('/contact-us');
  }
  return (
    <div>
        <div className="franchise-wrapper">
            <div className="franchise-img">
                <Image src={franchiseImage} alt='franchise Image'/>
            </div>
            <div className="franchise-text-container">
                <div className="franchise-text">
                Become a franchise
                </div>
                <div className="franchise-text-desc">
                Explore a partnership with MechMiles as a franchisee and join us in serving together. Your complete engagement is highly valued.
                </div>
                {/* <a href='/contact' className="franchise-btn-wrapper"><Button label='Contact Us' className='franchise-apply-now-btn' /></div> */}
                <a href="/contact-us" className="franchise-btn-wrapper" title='Contact us'><Button label='Contact Us' className='franchise-apply-now-btn' /></a>
            </div>
        </div>
    </div>
  )
}

export default Franchise