import { Image } from "primereact/image";
import Container from "react-bootstrap/Container";
import "react-tabs/style/react-tabs.css";
import { Button } from "primereact/button";
import Counter from "../../components/Counter/Counter";
import SimpleSlider from "../../components/SimpleSlider/SimpleSlider";
import FAQ from "../../components/FAQ/FAQ";
import Franchise from "../../components/Franchise/Franchise";
import DownloadApp from "../../components/DownloadApp/DownloadApp";
import Hero from "../../components/Hero/Hero";
import PopularProducts from "../../components/PopularProducts/PopularProducts";
import Services from "../../components/Services/Services";
import QuickSteps from "../../components/QuickSteps/QuickSteps";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../redux/loadingSlice";
import { useEffect, useState } from "react";
import "./HomePage.css";
import { Dialog } from "primereact/dialog";
import {
  alreadyRegistered,
  hideDialog,
  selectDialog,
} from "../../redux/dialogSlice";
import Login from "../Login/Login";
import loginBannerImg from "../../assets/images/login-banner-img.png";
import Register from "../Register/Register";

const HomePage = () => {

  const loading = useSelector((state: any) => state.loading);
  const dispatch = useDispatch();
  const visible = useSelector(selectDialog);
  const alreadyRegState = useSelector(alreadyRegistered);

  // const [visible, setVisible] = useState(false);

  const headerElement = (
    <div>
      <Image
        src={loginBannerImg}
        alt="Mechmiles Banner"
        className="login-banner-img"
      />
    </div>
  );

  useEffect(() => {
    // window.scrollTo(0, 0);

    dispatch(setLoading(true));
    // Fetch data here...
    // After data is fetched, set loading to false
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 300);
  }, [dispatch]);

  return (
    <>
      {loading && <div className="loading-overlay">Loading...</div>}
      {/* Add "react-loading-overlay-nextgen" Loader */}
      <div className="home-wrapper">
        <section className="banner-section">
          <Hero code='TYRE'/>
        </section>
        <section className="popular-products-section">
          <PopularProducts code='TYRE' />
        </section>
        <section className="services-section">
          <Services />
        </section>
        <section className="counter-section">
          <Counter />
        </section>
        <section className="quick-steps-section">
          <QuickSteps />
        </section>
        <section className="testimonials-section">
          <SimpleSlider />
        </section>
        <section className="faq-section">
          <FAQ />
        </section>
        <section className="franchise-section">
          <Franchise />
        </section>
        <section className="download-app-section">
          <DownloadApp />
        </section>
      </div>
      {/* <div className="login-dialog">
        <Dialog
          visible={visible}
          modal
          style={{ width: "30%" }}
          onHide={() => dispatch(hideDialog())}
          header={headerElement}
          className="login-dialog-box"
        >
          {alreadyRegState ? <Login/> : <Register/>}
        </Dialog>
      </div> */}
    </>
  );
};

export default HomePage;
