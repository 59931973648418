import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import ProductList from "./pages/ProductList/ProductList";
import Register from "./pages/Register/Register";
import Login from "./pages/Login/Login";
import "./App.css";
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import "primeflex/primeflex.css"; // css utility
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import AuthGuard from "./auth/AuthGuard";
import HomePage from "./pages/HomePage/HomePage";
import Footer from "./components/Footer/Footer";
import Cart from "./pages/Cart/Cart";
import Summary from "./pages/Summery/Summary";
import MyProfile from "./pages/MyProfile/MyProfile";
import PageNotFound from "./pages/PageNotFount/PageNotFound";
import ServicesAndRepairs from "./pages/ServicesAndRepairs/ServicesAndRepairs";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Batteries from "./pages/Batteries/Batteries";
import ServicePackages from "./components/ServicePackages/ServicePackages";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import StoreLocator from "./components/StoreLocator/StoreLocator";
import ContactUs from "./pages/ContactUs/ContactUs";
import ShippingAndDelivery from "./pages/ShippingAndDelivery/ShippingAndDelivery";
import RefundPolicy from "./components/refundPolicy/refundPolicy";

const App = () => {
  const basePath = process.env.REACT_APP_BASE_PATH;

  // const { user, login, logout } = useAuth();
  // const isAuthenticated = Boolean(user);
  const isAuthenticated = true;
  return (
    <div>
      <Header />
      {/* <AuthRedirect isAuthenticated={isAuthenticated} /> */}
      <div>
        <Routes>
          <Route path={`${basePath}*`} element={<PageNotFound />} />
          <Route path={`${basePath}*`} element={<PageNotFound />} />
          <Route path={`${basePath}`} element={<HomePage />} />
          <Route path={`${basePath}batteries`} element={<Batteries />} />
          <Route path={`${basePath}register`} element={<Register />} />
          <Route path={`${basePath}login`} element={<Login />} />
          <Route path={`${basePath}products`} element={<ProductList />} />
          <Route path={`${basePath}privacy`} element={<PrivacyPolicy />} />
          <Route path={`${basePath}refund`} element={<RefundPolicy />} />

          <Route
            path={`${basePath}privacy-customer`}
            element={<PrivacyPolicy />}
          />
          <Route path={`${basePath}terms`} element={<TermsAndConditions />} />
          <Route
            path={`${basePath}shipping-and-delivery-policy`}
            element={<ShippingAndDelivery />}
          />
          <Route
            path={`${basePath}terms-customer`}
            element={<TermsAndConditions />}
          />
          <Route
            path={`${basePath}findservicepoints`}
            element={<StoreLocator />}
          />
          <Route path={`${basePath}contact-us`} element={<ContactUs />} />
          <Route path="/productdetails/:slug" element={<ProductDetails />} />
          <Route
            path={`${basePath}servicesandrepair`}
            element={<ServicesAndRepairs />}
          />
          <Route
            path={`${basePath}servicepackages`}
            element={<ServicePackages />}
          />
          <Route
            path={`${basePath}cart`}
            element={
              <AuthGuard component={Cart} isAuthenticated={isAuthenticated} />
            }
          />
          <Route
            path={`${basePath}summary`}
            element={
              <AuthGuard
                component={Summary}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path={`${basePath}profile`}
            element={
              <AuthGuard
                component={MyProfile}
                isAuthenticated={isAuthenticated}
              />
            }
          />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
