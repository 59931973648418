import React from 'react'
import './ContactUs.css'
import { GrLocation, GrMailOption, GrPhone } from "react-icons/gr";



const ContactUs = () => {
    return (
        <div className="contact-us-container">
            <div className="contact-us-info">
                <div className="contact-us-header">
                    <div className="text-900 font-medium mb-4">Contact Us</div>
                    <h1 className='text-900 text-2xl font-bold mb-4'>GET IN TOUCH</h1>
                    <p className='text-700 line-height-3 pr-0 lg:pr-5'>Whether you're looking for the perfect tires for your vehicle, need a battery replacement, or require our expert services, we're here to help. Reach out to us with your questions or schedule a service appointment today. Your journey to a safer and smoother ride begins here.</p>
                </div>
                <div className="contact-us-address">
                    <span className='flex align-items-center justify-content-center surface-100 border-round mr-3 p-2'>
                        <GrLocation className='pi pi-map-marker text-900 text-4xl' />
                    </span>
                    <div>
                        <h2 className='text-xl text-900 mb-2 font-medium'>Corporate Address :</h2>
                        <p className='text-700 line-height-3'>
                            Junction of Tyres Private Limited <br />
                            Adj Dhanajwadi Shop No. 410 B Wingsra Rehab Building Rani Sati Malad(E)-400097, Mumbai, Maharashtra
</p>
                    </div>
                </div>
                <div className="contact-us-email flex-column">
                    <div className="contact-us-email">
                        <span className='flex align-items-center justify-content-center surface-100 border-round mr-3 p-2'>
                            <GrMailOption className='pi pi-inbox text-900 text-4xl' />
                        </span>
                        <div>
                            <h2 className='text-xl text-900 mb-2 font-medium'>Email</h2>
                            <p className='text-700 line-height-3'>Info@mechmiles.com</p>
                        </div>
                    </div>
                    <div className="contact-us-email">
                        <span className='flex align-items-center justify-content-center surface-100 border-round mr-3 p-2'>
                            <GrPhone className='pi pi-inbox text-900 text-4xl' />
                        </span>
                        <div>
                            <h2 className='text-xl text-900 mb-2 font-medium'>Company Official No: </h2>
                            <p className='text-700 line-height-3 white-space-nowrap'>+91 8104575898</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235.51762948425554!2d72.85771049559118!3d19.182878457664092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b704712a2615%3A0xe8840b1ea2a0ef27!2sAutomiles%20Showroom%20-%20Hero!5e0!3m2!1sen!2sin!4v1713272362296!5m2!1sen!2sin" width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen={false}
                    loading="lazy"></iframe>
            </div>

        </div>
    )
}

export default ContactUs