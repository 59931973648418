import axios from "axios";
import useAxios from "../hooks/useAxios";

const baseURL = process.env.REACT_APP_BASE_URL;

let preDefinedUrl = "/predefined/by-type-and-code";

const useHeroAPI = () => {
  const { axiosInstance } = useAxios();

  const predefinedFunc = async (data: any) => {
    let response = await axiosInstance.post(baseURL + `${preDefinedUrl}`, data);
    return response;
  };


  return {
    predefinedFunc
  };
};

export default useHeroAPI;
