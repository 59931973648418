import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Image } from "primereact/image";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import useHeroAPI from "../../api/useHeroAPI";
import { RootState } from "../../app/store";
import bannerImage from "../../assets/images/banner-image.jpg";
import bikeImg from "../../assets/images/bike_scooter.png";
import carSuvImg from "../../assets/images/car_suv.png";
import commercialImg from "../../assets/images/commercial.png";
import threeWheelerImg from "../../assets/images/three_wheeler.png";
import { setLoading } from "../../redux/loadingSlice";
import { setTabIndex } from "../../redux/tabIndexSlice";
import "./Hero.css";
interface Option {
  name: string;
  code: string;
}

interface DropdownOptionsProps {
  brand: Option | null;
  setBrand: React.Dispatch<React.SetStateAction<Option | null>>;
  model: Option | null;
  setModel: React.Dispatch<React.SetStateAction<Option | null>>;
  variant: Option | null;
  setVariant: React.Dispatch<React.SetStateAction<Option | null>>;
  searchData: () => void;
  brands: Option[];
  models: Option[];
  variants: Option[];
}

interface SizeDropdownOptionsProps {
  width: Option | null;
  setWidth: React.Dispatch<React.SetStateAction<Option | null>>;
  aspect: Option | null;
  setAspect: React.Dispatch<React.SetStateAction<Option | null>>;
  rim: Option | null;
  setRim: React.Dispatch<React.SetStateAction<Option | null>>;
  searchData: () => void;
  widths: Option[];
  aspects: Option[];
  rims: Option[];
}

const Hero = (props: any) => {
  const { url, code } = props;
  const basePath = process.env.REACT_APP_BASE_PATH;

  const loading = useSelector((state: any) => state.loading);

  const tabIndex = useSelector((state: RootState) => state.tabIndex);
  const dispatch = useDispatch();

  const { predefinedFunc } = useHeroAPI();

  const navigate = useNavigate();

  const [tyreType, setTyreType] = useState<Option | null | string>(null) as any;
  const [productByState, setProductByState] = useState<Option | null | string>(
    null
  );
  const [typesPreDefinedState, setTypesPreDefinedState] = useState([]);
  const [selectedTyreType, setSelectedTyreType] = useState("");
  const [tyreBrandImgs, setTyreBrandImgs] = useState([]) as any;

  const [brands, setBrands] = useState<Array<{ name: string; code: string }>>(
    []
  ) as any;
  const [brand, setBrand] = useState<Option | null>(null);
  const [models, setModels] = useState<Array<{ name: string; code: string }>>(
    []
  ) as any;
  const [model, setModel] = useState<Option | null>(null);
  const [variant, setVariant] = useState<Option | null>(null);
  const [variants, setVariants] = useState<
    Array<{ name: string; code: string }>
  >([]) as any;

  const [widths, setWidths] = useState<Array<{ name: string; code: string }>>(
    []
  ) as any;
  const [width, setWidth] = useState<Option | null>(null);
  const [aspects, setAspects] = useState<Array<{ name: string; code: string }>>(
    []
  ) as any;
  const [aspect, setAspect] = useState<Option | null>(null);
  const [rim, setRim] = useState<Option | null>(null);
  const [rims, setRims] = useState<Array<{ name: string; code: string }>>(
    []
  ) as any;
  const [productType, setProductType] = useState({} as any);
  const [userId, setUserId] = useState(null as any);

  const DropdownOptions: React.FC<DropdownOptionsProps> = ({
    brand,
    setBrand,
    model,
    setModel,
    variant,
    setVariant,
    searchData,
    brands,
    models,
    variants,
  }) => (
    <div className="pb-4 pl-4 pr-4 dropdown-options">
      <Dropdown
        filter
        value={brand}
        onChange={(e) => onMakeChange(e)}
        options={brands}
        optionLabel="name"
        placeholder={"Select a Make"}
        className="w-3 banner-dropdown"
      />
      <Dropdown
        filter
        value={model}
        onChange={(e) => onModelChange(e)}
        options={models}
        optionLabel="name"
        placeholder={"Select a Model"}
        className="w-3 banner-dropdown"
      />
      <Dropdown
        filter
        value={variant}
        onChange={(e: { value: Option }) => setVariant(e.value)}
        options={variants}
        optionLabel="name"
        placeholder={"Select a Variant"}
        className="w-3 banner-dropdown"
      />
      <Button
        icon="pi pi-search"
        label="Search"
        onClick={searchData}
        className="w-2 search-button"
      ></Button>
    </div>
  );

  const SizeDropdownOptions: React.FC<SizeDropdownOptionsProps> = ({
    width,
    setWidth,
    aspect,
    setAspect,
    rim,
    setRim,
    searchData,
    widths,
    aspects,
    rims,
  }) => (
    <div className="pb-4 pl-4 pr-4 dropdown-options">
      <Dropdown
        filter
        value={width}
        onChange={(e) => onWidhtChange(e)}
        options={widths}
        optionLabel="name"
        placeholder={"Select a Width"}
        className="w-3 banner-dropdown"
      />
      <Dropdown
        filter
        value={aspect}
        onChange={(e) => onAspectChange(e)}
        options={aspects}
        optionLabel="name"
        placeholder={"Select a Aspect"}
        className="w-3 banner-dropdown"
      />
      <Dropdown
        filter
        value={rim}
        onChange={(e: { value: Option }) => setRim(e.value)}
        options={rims}
        optionLabel="name"
        placeholder={"Select a Rim"}
        className="w-3 banner-dropdown"
      />
      <Button
        icon="pi pi-search"
        label="Search"
        onClick={searchData}
        className="w-2 search-button"
      ></Button>
    </div>
  );

  const fetchChildPredefined = async () => {
    const foundTyreType: any = typesPreDefinedState.find(
      (type: any) => type?.name === tyreType?.name
    );

    setSelectedTyreType(foundTyreType?.predefined_id);

    if (
      foundTyreType &&
      foundTyreType?.name !== undefined &&
      foundTyreType?.name !== ""
    ) {
      // let parent_id = null;
      // if (location.pathname === "/") {
      //   parent_id = 8151;
      // } else if (location.pathname === "/batteries") {
      //   parent_id = 8152;
      // }

      // const brandPredefinedPayload = {
      //   entity_type: "BRAND",
      // };
      const makePredefinedPayload = {
        entity_type: "MAKE",
        parent_id: foundTyreType?.predefined_id ?? null,
      };

      // const brandPredefinedResponse = await predefinedFunc(
      //   brandPredefinedPayload
      // );
      const makePredefinedResponse = await predefinedFunc(
        makePredefinedPayload
      );

      const makeData = makePredefinedResponse?.data?.data.map((child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      });

      // if (brandPredefinedResponse.status === 200) {
      //   dispatch(setLoading(false));
      // }

      if (makeData) {
        setBrands(makeData);
        dispatch(setLoading(false));
      }
      // if (brand !== null) {
      //   setModels(modelData);
      // }

      // if (model !== null) {
      //   setVariants(variantData);
      // }
      // setTyreBrandImgs(brandPredefinedResponse?.data?.data);
    }
  };

  const onMakeChange = async (e: any) => {
    setBrand(e.value);
    const modelPredefinedPayload = {
      entity_type: "MODEL",
      parent_id: e.value?.code ?? "",
    };
    const modelPredefinedResponse = await predefinedFunc(
      modelPredefinedPayload
    );
    const modelData = modelPredefinedResponse?.data?.data.map((child: any) => {
      return {
        name: child.name,
        code: child.predefined_id,
      };
    });
    setModels(modelData);
  };

  const onAspectChange = async (e: any) => {
    setAspect(e.value);
    // callAndSetRims();
  };

  const callAndSetRims = async () => {
    const rimPredefinedPayload = {
      entity_type: "RIM",
      // parent_id: e.value?.code ?? "",
    };

    const rimPredefinedResponse = await predefinedFunc(rimPredefinedPayload);

    const rimData = rimPredefinedResponse?.data?.data.map((child: any) => {
      return {
        name: child.name,
        code: child.predefined_id,
      };
    });
    setRims(rimData);
  };

  const onWidhtChange = async (e: any) => {
    setWidth(e.value);
    // callAndSetAspects();
  };

  const callAndSetAspects = async () => {
    const aspectPredefinedPayload = {
      entity_type: "ASPECT",
      // parent_id: e.value?.code ?? "",
    };
    const aspectPredefinedResponse = await predefinedFunc(
      aspectPredefinedPayload
    );
    const aspectData = aspectPredefinedResponse?.data?.data.map(
      (child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      }
    );
    setAspects(aspectData);
  };

  const onModelChange = async (e: any) => {
    setModel(e.value);
    const variantPredefinedPayload = {
      entity_type: "VARIANT",
      parent_id: e.value?.code ?? "",
    };

    const variantPredefinedResponse = await predefinedFunc(
      variantPredefinedPayload
    );

    const variantData = variantPredefinedResponse?.data?.data.map(
      (child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      }
    );
    setVariants(variantData);
  };

  const fetchDependentData = async () => {
    dispatch(setLoading(true));
    const modelPredefinedPayload = {
      entity_type: "MODEL",
      parent_id: brand?.code ?? null,
    };
    const variantPredefinedPayload = {
      entity_type: "VARIANT",
      parent_id: model?.code ?? null,
    };
    const modelPredefinedResponse = await predefinedFunc(
      modelPredefinedPayload
    );
    const variantPredefinedResponse = await predefinedFunc(
      variantPredefinedPayload
    );

    if (
      modelPredefinedResponse.status === 200 &&
      variantPredefinedResponse.status === 200
    ) {
      dispatch(setLoading(false));
    }

    if (brand !== null) {
      const modelData = modelPredefinedResponse?.data?.data.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      setModels(modelData);
    }

    if (model !== null) {
      const variantData = variantPredefinedResponse?.data?.data.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      setVariants(variantData);
    }
  };

  // useEffect(() => {
  //   if (brand !== null || model !== null) {
  //     fetchDependentData();
  //   }
  // }, [brand, model]);

  const fetchPredefined = async () => {
    const predefinedParentPayload = {
      entity_type: "CATEGORY",
    };
    const predefinedResponse = await predefinedFunc(predefinedParentPayload);
    if (predefinedResponse.status === 200) {
      setTypesPreDefinedState(predefinedResponse?.data?.data);
      const defaultTyreType = predefinedResponse?.data?.data?.find(
        (type: any) => type?.code === "TWO-WHEELER"
      );
      setTyreType(defaultTyreType);

      const twoWheelerIndex = predefinedResponse?.data?.data.findIndex(
        (type: any) => type.code === "TWO-WHEELER"
      );
      if (twoWheelerIndex !== -1) {
        dispatch(setTabIndex(twoWheelerIndex));
      }
    }
    fetchChildPredefined();
  };

  const fetchPopularBrands = async (product_type?: any) => {
    const payload = {
      entity_type: "BRAND",
      parent_id: product_type,
      user_id: userId,
    };

    const response = await predefinedFunc(payload);
    if (response.status === 200) {
      setTyreBrandImgs(response.data.data);
    }
  };

  const fetchProductType = async (code: string) => {
    const predefinedParentPayload = {
      entity_type: "PRODUCT-TYPE",
      code: code,
    };
    const predefinedResponse = await predefinedFunc(predefinedParentPayload);
    if (predefinedResponse.status === 200) {
      setProductType(predefinedResponse.data.data[0]);
      if (predefinedResponse.data.data[0].predefined_id) {
        fetchPopularBrands(predefinedResponse.data.data[0].predefined_id);
      }
    }

    // fetchChildPredefined();
  };

  useEffect(() => {
    console.log("useEffect Called");

    fetchPredefined();
    fetchProductType(code);
    // const twoWheelerIndex = typesPreDefinedState.findIndex((type: any) => type.code === "TWO-WHEELER");
    // if (twoWheelerIndex !== -1) {
    //   dispatch(setTabIndex(twoWheelerIndex));
    // }

    const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    if (authTokens && authTokens?.user?.id) {
      setUserId(authTokens?.user?.id);
    }
  }, []);

  useEffect(() => {
    if (tyreType !== null && tyreType?.name !== "") {
      fetchChildPredefined();
    }
  }, [tyreType]);

  const handleTabSelect = async (
    index: number,
    lastIndex: number,
    event: Event
  ) => {
    event.preventDefault();
    // event.stopPropagation();
    const newVehicleType: any = typesPreDefinedState[index];
    // setTyreType((event.target as Element).textContent);
    setTyreType(newVehicleType);
    dispatch(setTabIndex(index));
    setBrands(null);
    setModels(null);
    setVariants(null);
    setBrand(null);
    setModel(null);
    setVariant(null);
    dispatch(setLoading(true));
    if (index == 0) {
      fetchChildPredefined();
    } else if (index == 1) {
      callAndSetWidth();
      callAndSetRims();
      callAndSetAspects();
    }
  };

  const callAndSetWidth = async () => {
    const foundTyreType: any = typesPreDefinedState.find(
      (type: any) => type?.name === tyreType?.name
    );
    const widthPredefinedPayload = {
      entity_type: "WIDTH",
      // parent_id: foundTyreType?.predefined_id ?? null,
    };

    // const brandPredefinedResponse = await predefinedFunc(
    //   brandPredefinedPayload
    // );
    const widthPredefinedResponse = await predefinedFunc(
      widthPredefinedPayload
    );

    const widthData = widthPredefinedResponse?.data?.data.map((child: any) => {
      return {
        name: child.name,
        code: child.predefined_id,
      };
    });

    setWidths(widthData);
  };

  const handleByTabSelect = async (
    index: any,
    lastIndex: number,
    event: Event
  ) => {
    event.preventDefault();
    setProductByState((event.target as Element).textContent);
    if (index == 1) {
      const foundTyreType: any = typesPreDefinedState.find(
        (type: any) => type?.name === tyreType?.name
      );

      setSelectedTyreType(foundTyreType?.predefined_id);
      callAndSetWidth();
      callAndSetRims();
      callAndSetAspects();
    }
  };

  const searchData = () => {
    // navigate("/mechmiles-customer/products", {
    //   state: { brand, model, variant, tyreType, productByState },
    // });

    navigate(`${basePath}products`, {
      state: {
        brand,
        model,
        variant,
        tyreType,
        productByState,
        code,
        productType,
        width,
        aspect,
        rim,
      },
    });
  };

  const navigateToProductList = (predefined_id: number) => {
    navigate(`${basePath}products`, {
      state: { predefined_id, code },
    });
    // navigate("/mechmiles-customer/products", {
    //   state: { predefined_id },
    // });
  };

  const predefinedImgArr = [carSuvImg, bikeImg, threeWheelerImg, commercialImg];

  return (
    <div>
      {loading && <div className="loading-overlay">Loading...</div>}
      <div className="banner-image-wrapper">
        <Image
          src={
            url != undefined
              ? "https://abbott.mechmiles.com/brand_logos/banner-image.jpg"
              : bannerImage
          }
        />
      </div>
      <Container className="home-tab">
        <Tabs
          forceRenderTabPanel
          defaultIndex={0}
          onSelect={(index: any, lastIndex: number, event: Event) =>
            handleByTabSelect(index, lastIndex, event)
          }
        >
          <TabList>
            <Tab>By Vehicle</Tab>
            <Tab>By Size</Tab>
            {/* <Tab>By Vehicle Number</Tab> */}
          </TabList>
          <TabPanel>
            <Tabs
              forceRenderTabPanel
              onSelect={(index: any, lastIndex: number, event: Event) =>
                handleTabSelect(index, lastIndex, event)
              }
              selectedIndex={tabIndex}
            >
              <TabList>
                <div className="internal-home-tab flex w-full justify-content-between">
                  <div className="tab-internal-title">
                    Find the right {code === "TYRE" ? "tyre" : "battery"}
                  </div>
                  <div className="tab-internal-options">
                    {typesPreDefinedState?.map((types: any, index: number) => {
                      return (
                        <Tab key={index}>
                          <Image
                            src={
                              tabIndex === index
                                ? types?.active_thumbnail
                                : types?.thumbnail
                            }
                            className="tyre-type-images"
                          />
                          {types.name}
                        </Tab>
                      );
                    })}
                  </div>
                </div>
              </TabList>
              <TabPanel>
                <DropdownOptions
                  brand={brand}
                  setBrand={setBrand}
                  model={model}
                  setModel={setModel}
                  variant={variant}
                  setVariant={setVariant}
                  searchData={searchData}
                  brands={brands}
                  models={models}
                  variants={variants}
                />
              </TabPanel>
              <TabPanel>
                <DropdownOptions
                  brand={brand}
                  setBrand={setBrand}
                  model={model}
                  setModel={setModel}
                  variant={variant}
                  setVariant={setVariant}
                  searchData={searchData}
                  brands={brands}
                  models={models}
                  variants={variants}
                />
              </TabPanel>
              <TabPanel>
                <DropdownOptions
                  brand={brand}
                  setBrand={setBrand}
                  model={model}
                  setModel={setModel}
                  variant={variant}
                  setVariant={setVariant}
                  searchData={searchData}
                  brands={brands}
                  models={models}
                  variants={variants}
                />
              </TabPanel>
              <TabPanel>
                <DropdownOptions
                  brand={brand}
                  setBrand={setBrand}
                  model={model}
                  setModel={setModel}
                  variant={variant}
                  setVariant={setVariant}
                  searchData={searchData}
                  brands={brands}
                  models={models}
                  variants={variants}
                />
              </TabPanel>
            </Tabs>
          </TabPanel>
          <TabPanel>
            <Tabs
              forceRenderTabPanel
              onSelect={(index: any, lastIndex: number, event: Event) =>
                handleTabSelect(index, lastIndex, event)
              }
              selectedIndex={tabIndex}
            >
              <TabList>
                <div className="internal-home-tab flex w-full justify-content-between">
                  <div className="tab-internal-title">
                    Find the right {code === "TYRE" ? "tyre" : "battery"}
                  </div>
                  <div className="tab-internal-options">
                    {typesPreDefinedState?.map((types: any, index: number) => {
                      return (
                        <Tab key={index}>
                          <Image
                            src={
                              tabIndex === index
                                ? types?.active_thumbnail
                                : types?.thumbnail
                            }
                            className="tyre-type-images"
                          />
                          {types.name}
                        </Tab>
                      );
                    })}
                  </div>
                </div>
              </TabList>
              <TabPanel>
                <SizeDropdownOptions
                  width={width}
                  setWidth={setWidth}
                  aspect={aspect}
                  setAspect={setAspect}
                  rim={rim}
                  setRim={setRim}
                  searchData={searchData}
                  widths={widths}
                  aspects={aspects}
                  rims={rims}
                />
              </TabPanel>
              <TabPanel>
                <SizeDropdownOptions
                  width={width}
                  setWidth={setWidth}
                  aspect={aspect}
                  setAspect={setAspect}
                  rim={rim}
                  setRim={setRim}
                  searchData={searchData}
                  widths={widths}
                  aspects={aspects}
                  rims={rims}
                />
              </TabPanel>
              <TabPanel>
                <SizeDropdownOptions
                  width={width}
                  setWidth={setWidth}
                  aspect={aspect}
                  setAspect={setAspect}
                  rim={rim}
                  setRim={setRim}
                  searchData={searchData}
                  widths={widths}
                  aspects={aspects}
                  rims={rims}
                />
              </TabPanel>
            </Tabs>
          </TabPanel>
        </Tabs>
        <div className="popular-brands">
          <div className="popular-brands-title">Popular Brands</div>
          <div className="popular-brands-images">
            {tyreBrandImgs?.map((img: any, index: number) => {
              return (
                <div
                  className="popular-brands-image cursor-pointer"
                  key={index}
                  onClick={() => navigateToProductList(img?.predefined_id)}
                  style={{ display: `${!img?.url ? "none" : "block"}` }}
                >
                  <Image src={img?.url} alt={img?.name} />
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Hero;
