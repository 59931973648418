import React, { useContext, useEffect, useRef, useState } from "react";
import "./UserLogin.css";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AuthContext from "../../context/AuthContext";
import useLoginAPI from "../../api/useLogin";
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";
import { goToRegister, goToUserRegister, hideDialog, hideUserDialog, setShowLoginDialog, setShowUserRegister } from "../../redux/dialogSlice";
import { logInUserFunc } from "../../redux/userState";

const UserLogin: React.FC = () => {
  const basePath = process.env.REACT_APP_BASE_PATH;

  const { sendOTP, verifyOTP, saveUserAPI, generateToken } = useLoginAPI();

  const toast = useRef<Toast>(null);
  const dispatch = useDispatch();

  const handleShowRegister = () => {
    // dispatch(goToUserRegister());
    dispatch(setShowUserRegister(true));
  };

  const { loginUser } = useContext(AuthContext) || {};
  const [phoneNumber, setPhoneNumber] = useState(""); // Changed variable name from username to phoneNumber
  const [OTP, setOTP] = useState("");
  const [phoneError, setPhoneError] = useState(""); // Changed variable name from emailError to phoneError
  const [OTPError, setOTPError] = useState("");
  const [displayOTP, setDisplayOTP] = useState("");
  const [otpReceived, setOtpReceived] = useState(false);
  const [sendingOTP, setSendingOTP] = useState(false);
  const navigate = useNavigate();
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(30);

  let [authTokens, setAuthTokens] = useState(() => {
    const storedTokens = localStorage.getItem("authTokens");
    if (storedTokens === "undefined") {
      localStorage.clear();
      navigate(`${basePath}`);
    }
    return storedTokens ? JSON.parse(storedTokens) : null;
  });

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isResendDisabled && countdown > 0) {
      interval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (!countdown) {
      setIsResendDisabled(false);
      setCountdown(30);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isResendDisabled, countdown]);

  const resendOTP = (e: any) => {
    setIsResendDisabled(true);
    setOTP("");
    setDisplayOTP("");
    sendOTPFunc(e);
  };

  const validatePhone = (input: string) => {
    const phoneRegex = /^\d{10}$/; // Removed email validation as we are focusing on phone numbers now
    return phoneRegex.test(input);
  };

  const sendOTPFunc = async (e?: any) => {
    e.preventDefault();
    setOTP("");
    if (!validatePhone(phoneNumber)) {
      setPhoneError("Invalid Phone Number"); // Updated error message to reflect phone number validation

      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Please enter a valid phone number`, // Updated error detail to reflect phone number validation
      });
    }

    setSendingOTP(true);
    const payload = {
      username: phoneNumber,
      roles: ["ROLE_CUSTOMER"],
      is_for_login: true,
    };
    const response: any = await sendOTP(payload);
    if (response?.response?.status === 400) {
      toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `${response?.response?.data?.error}`,
      });
    } else if (response.status === 200) {
      setOtpReceived(true);
      setDisplayOTP(response?.data?.otp);
      toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `${response?.data?.message}`,
      });
    } else if (response?.response?.status === 404) {
      setOtpReceived(false);
      setDisplayOTP(response?.response?.data?.error);
      toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `${response?.response?.data?.error}`,
      });
    } else {
      toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Failed to send OTP`,
      });
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneError(""); // Changed variable name from setEmailError to setPhoneError
    setDisplayOTP("");
    const regex = /^[0-9]*$/;

    const value = e.target.value;
    if (regex.test(value)) {
      setPhoneNumber(value); // Changed method from setUsername to setPhoneNumber
    }
  };

  const handleOTPInput = (e: any) => {
    setOTPError("");
    const inputOTP = e.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(inputOTP)) {
      setOTP(inputOTP);
    }
  };

  const handleRegister = () => {
    dispatch(goToUserRegister());
  };

  const verifyOTPFunc = async (e: any) => {
    e.preventDefault();
    if (OTP === "") {
      setOTPError("Please Enter OTP");
    }
    const generateTokenPayload = {
      username: phoneNumber,
      login_panel: "WEB",
      otp: OTP,
    };
    const generateTokenResponse: any = await generateToken(
      generateTokenPayload
    );
    if (
      generateTokenResponse.status === 200 ||
      generateTokenResponse.status === 201
    ) {
      setAuthTokens(generateTokenResponse?.data?.tokens);
      localStorage.setItem(
        "authTokens",
        JSON.stringify(generateTokenResponse?.data?.tokens)
      );
      dispatch(hideUserDialog());
      dispatch(logInUserFunc());
      dispatch(setShowLoginDialog(false));
      toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `${generateTokenResponse?.data?.message}`,
      });
      // navigate("/dashboard");
    } else {
      toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `${generateTokenResponse?.response?.data?.error}`,
      });
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="registration-form-wrapper">
        <div className="registration-wrapper">
          <div className="register-title">User Login In</div>
        </div>
        <form>
          <div>
            <div className="p-inputgroup">
              <InputText
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => onInputChange(e)}
                placeholder="Phone Number"
                className="email-input"
                maxLength={10}
              />
              <Button label="Send OTP" onClick={sendOTPFunc} />
            </div>
            {phoneError && <small style={{ color: "red" }}>{phoneError}</small>}
          </div>
          {/* {displayOTP && <small style={{ color: "red" }}>{displayOTP}</small>} */}
          {sendingOTP && (
            <div className="otp-wrapper">
              {otpReceived && (
                <div>
                  <InputText
                    id="OTP"
                    type="OTP"
                    value={OTP}
                    onChange={(e) => handleOTPInput(e)}
                    className="w-full otp-input"
                    placeholder="OTP"
                    maxLength={6}
                  />
                  {OTPError && (
                    <small style={{ color: "red" }}>{OTPError}</small>
                  )}
                  <div className="resend-otp">
                    <button
                      disabled={isResendDisabled}
                      onClick={(e: any) => resendOTP(e)}
                      className={isResendDisabled ? "text-disabled" : ""}
                    >
                      Resend OTP
                    </button>
                    {isResendDisabled && <span>({countdown})</span>}
                  </div>
                </div>
              )}
              <Button
                label="Continue"
                icon="pi"
                className="w-full verify-otp-btn"
                onClick={verifyOTPFunc}
                disabled={OTP.length !== 6}
              />
            </div>
          )}

          <div className="by-continuing">
            By Continuing, You Agree To Mechmiles Terms & Conditions And Privacy
            Policy
          </div>
        </form>
      </div>
      <div className="create-account-wrapper">
        <span className="create-account-text">Don't have an account?</span>
        <a
          onClick={handleShowRegister}
          className="create-account-text create-account-link cursor-pointer"
        >
          Register
        </a>
      </div>
    </div>
  );
};

export default UserLogin;
