import axios from "axios";
import useAxios from "../hooks/useAxios";

const baseURL = process.env.REACT_APP_BASE_URL;

const url = '/vehicle'

const useVehicleAPI = () => {
  const { axiosInstance } = useAxios();

  const getVehicleList = async (data: any) => {
    let response = await axiosInstance.post(baseURL + `${url}/vehicle-list`, data);
    return response;
  };
  const saveVehicle = async (data: any) => {
    let response = await axiosInstance.post(baseURL + `${url}/vehicle-save`, data);
    return response;
  };
  const deleteVehicle = async (vehicleId: any, data: any) => {
    let response = await axiosInstance.delete(baseURL + `${url}/delete/${vehicleId}`, data);
    return response;
  };


  return {
    getVehicleList,
    saveVehicle,
    deleteVehicle
  };
};

export default useVehicleAPI;
