import { Image } from "primereact/image";
import sampleTyreImage from "../../assets/images/sample_tyre.png";
import ceatMini from "../../assets/images/TyreBrandLogos/ceat_mini.png";
import { isLoggedInState, isLoggedOutState } from "../../redux/userState";
import { FaHeart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { StarPicker } from "react-star-picker";
import { addToProduct, refreshProductList } from "../../redux/productSlice";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import useCartAPI from "../../api/useCartAPI";
import { addToCart } from "../../features/cart/cartSlice";
import { useNavigate } from "react-router-dom";
import { removeTags } from "../ProductDetails/ProductDetails";
import { setShowLoginDialog } from "../../redux/dialogSlice";

export default function Popup({ product }: any) {
  const navigate = useNavigate();
  const basePath = process.env.REACT_APP_BASE_PATH;

  const isLoggedIn = useSelector(isLoggedInState);
  const isLoggedOut = useSelector(isLoggedOutState);
  //   console.log("product", product);
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const { cartSave, moveToWishlist } = useCartAPI();
  const [userId, setUserId] = useState(null) as any;

  useEffect(() => {
    const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    setUserId(authTokens?.user?.id);
  }, [isLoggedIn, isLoggedOut, userId]);

  const handleAddToCart = async () => {
    if (!userId) {
      setTimeout(() => {
        dispatch(setShowLoginDialog(true));
      }, 1000);
      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Please Login`,
      });
    }

    // navigate("/mechmiles-customer/cart", { state: { product } });

    const payload = {
      product_id: product.product_id,
      item_quantity: 1,
      is_wishList: false,
    };

    const response: any = await cartSave(payload);

    if (response.status === 201) {
      dispatch(addToCart(product));
      dispatch(addToProduct(product));
      dispatch(refreshProductList());
      return toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `${product?.product_name} Added to cart`,
      });
    }
  };

  const moveToWishListFunc = async (product: any) => {
    const payload = {
      product_id: product.product_id,
      item_quantity: 1,
      is_wishList: true,
    };

    const response: any = await cartSave(payload);
    if (response.status === 201) {
      // dispatch(addToProduct(product));
      dispatch(refreshProductList());
      return toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `${product?.product_name} Added to wishlist`,
      });
    }
  };

  const directToDetail = (slug: any) => {
    navigate(`${basePath}productdetails/${slug}`, {
      state: { product },
    });
  }

  return (
    <div className="popup ">
      <Toast ref={toast} />
      <div className="popup-top">
        <div className="cursor-pointer">
          <div className="popup-top-content">
            <div className="product-image-wrapper" onClick={() => {directToDetail(product?.slug)}}>
              <Image
                src={
                  product?.document_as_product[0]?.document?.file_path ||
                  sampleTyreImage
                }
                className="prod-image"
                height="72"
                width="74"
              />
            </div>
            <div className="product-top-section-right" onClick={() => {directToDetail(product?.slug)}}>
              <div className="product-manufacturer-logo">
                <Image src={product?.brand?.thumbnail || ceatMini} />
              </div>
              <div className="prod-name" title={product.product_name}>
                {product.product_name}
              </div>
              <div className="prod-model">{product.rim?.name}</div>
              {/* <div className="flex">
                <div className="prod-ratings" >
                  <StarPicker value={4.5} halfStars disabled={true} size={20} />
                </div>
                {product.prodReviewCount && (
                  <div className="prod-review">
                    {product.prodReviewCount} Reviews
                  </div>
                )}
              </div> */}
            </div>
            {isLoggedIn && (
              <FaHeart
                className={`${product.is_wishlist ? "wish-list-on" : "wish-list-off"
                  }`}
                onClick={() => moveToWishListFunc(product)}
              />
            )}
          </div>
          <div className="popup-divider" onClick={() => {directToDetail(product?.slug)}}></div>
          <div className="popup-mid" onClick={() => {directToDetail(product?.slug)}}>
            <p className="popup-desc">
              <strong className="popup-title">Compatible Vehicles :</strong>
              {product?.compatible_vehicles !== null
                ? <span
                  className="section-para"
                  dangerouslySetInnerHTML={{ __html: product?.compatible_vehicles || '' }}
                ></span>
                : "Not Available"}
            </p>
            <ul className="warranty-list">
              {product?.warranty !== null ? (
                <li className="warranty">{product?.warranty}</li>
              ) : null}
            </ul>
            {/* {product?.warranty !== null ? (
              <ul>
                {product?.warranty.split(", ").map((item: any, i: number) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            ) : null} */}
            {product?.key_feature !== null ? (
              <>
                {/* <ul className="key-points"> */}
                <span className="popup-title">Key Points:</span>
                {/* {product?.key_feature?.split(", ")?.map((item: any, i: number) => (
                    <li key={i}>{removeTags(item)}</li>
                  ))}
                </ul> */}
                <div
                  className="section-para"
                  dangerouslySetInnerHTML={{ __html: product?.key_feature || '' }}
                ></div>
              </>
            ) : null}
          </div>
        </div>

        <div className="popup-bottom grid-layout">
          {!product.is_goto && (
            <div className="product-add-to-cart" onClick={handleAddToCart}>
              Add to cart
            </div>
          )}
          <div
            onClick={() => {
              directToDetail(product?.slug)
            }}
            className="view-detail cursor-pointer"
          >
            View Details
          </div>
        </div>
      </div>
    </div>
  );
}
