import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AuthContext from "../../context/AuthContext";
import useLoginAPI from "../../api/useLogin";
import { Toast } from "primereact/toast";
import "./Register.css";
import { useDispatch } from "react-redux";
import { goToLogin, hideDialog, setShowLoginDialog, setShowRegister } from "../../redux/dialogSlice";
import { logInUserFunc } from "../../redux/userState";
import { Dropdown } from "primereact/dropdown";

const Register: React.FC = () => {
  const basePath = process.env.REACT_APP_BASE_PATH;

  const {
    sendOTP,
    verifyOTP,
    saveUserAPI,
    generateToken,
    getCompanyNames,
    getDepartmentNames,
  } = useLoginAPI();

  const toast = useRef<Toast>(null);
  const dispatch = useDispatch();

  const { loginUser } = useContext(AuthContext) || {};
  const [username, setUsername] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [userFullNameError, setUserFullNameError] = useState("");
  const [OTP, setOTP] = useState("");
  const [emailError, setEmailError] = useState("");
  const [OTPError, setOTPError] = useState("");
  const [displayOTP, setDisplayOTP] = useState("");
  const [sendingOTP, setSendingOTP] = useState(false);
  const navigate = useNavigate();
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(30);
  let [authTokens, setAuthTokens] = useState(() => {
    const storedTokens = localStorage.getItem("authTokens");
    if (storedTokens === "undefined") {
      localStorage.clear();
      navigate(`${basePath}`);
    }
    return storedTokens ? JSON.parse(storedTokens) : null;
  });

  const [companyListOptions, setCompanyListOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [isSelectingCompany, setIsSelectingCompany] = useState(false);
  const [departmentListOptions, setDepartmentListOptions] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [isSelectingDepartment, setIsSelectingDepartment] = useState(false);
  const [registrationError, setRegistrationError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isResendDisabled && countdown > 0) {
      interval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (!countdown) {
      setIsResendDisabled(false);
      setCountdown(30);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isResendDisabled, countdown]);

  const resendOTP = (e: any) => {
    setIsResendDisabled(true);
    setOTP("");
    setDisplayOTP("");
    setRegistrationError("");
    sendOTPFunc(e);
  };

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateFullName = (fullName: string) => {
    const re = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    return re.test(fullName);
  };

  const sendOTPFunc = async (e?: any) => {
    e.preventDefault();
    setOTP("");
    setDisplayOTP("");
    setRegistrationError("");
    setIsSelectingCompany(false);
    setIsSelectingDepartment(false);
    if (!validateEmail(username) || userFullName === "") {
      if (!validateEmail(username)) {
        setEmailError("Invalid email");
      }

      if (userFullName === "") {
        setUserFullNameError("Please enter full name");
      }

      if (!validatePhoneNumber(phoneNumber)) {
        setPhoneNumberError("Invalid phone number");
      }

      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Please enter valid email`,
      });
    }
    // setSendingOTP(true);
    // resendOTP();
    const payload = {
      username: username,
      roles: ["ROLE_CORPORATE_USER"],
      is_for_login: false,
    };
    const response: any = await sendOTP(payload);
    if (response?.response?.status === 400) {
      // setDisplayOTP(response.otp);
      toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `${response?.response?.data?.error}`,
      });
    } else if (response?.response?.status === 404) {
      // setDisplayOTP(response.otp);
      setSendingOTP(false);
      toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `${response?.response?.data?.error}`,
      });
    } else if (response.status === 200) {
      setSendingOTP(true);
      setDisplayOTP(response?.data?.otp);
      toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `${response?.data?.message}`,
      });
    } else {
      toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Failed to sent OTP`,
      });
    }
  };
  const verifyOTPFunc = async (e: any) => {
    e.preventDefault();
    if (OTP === "") {
      setOTPError("Please Enter OTP");
    }
    const payload = {
      username: username,
      otp: OTP,
    };
    const response: any = await verifyOTP(payload);
    if (response.status === 200) {
      toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `${response?.data?.message}`,
      });

      const saveUserPayload = {
        email: username,
        username: username,
        phone: phoneNumber,
        roles: ["ROLE_CORPORATE_USER"],
        company_id: selectedCompany.length === 0 ? null : selectedCompany,
        department_id: selectedDepartment.length === 0 ? null : selectedDepartment,
        full_name: userFullName === '' ? null : userFullName,
      };
      const saveUserResponse: any = await saveUserAPI(saveUserPayload);
      if (saveUserResponse.status === 200 || saveUserResponse.status === 201) {
        if (saveUserResponse?.data?.registration_type === "MULTI-COMPANY") {
          const payload = {
            domain: username,
          };
          const response = await getCompanyNames(payload);

          if (response.status === 200) {
            setIsSelectingCompany(true);
            console.log("Company List Response:", response?.data); // Logging company list response
 
            setCompanyListOptions(response?.data);
          }
        } else if (saveUserResponse?.data?.registration_type === "MANY-DEPT") {
          const response = await getDepartmentNames(selectedCompany);

          if (response.status === 200) {
            setIsSelectingDepartment(true);
            setDepartmentListOptions(response?.data);
          }
        } else if (saveUserResponse?.status === 201) {
          const generateTokenPayload = {
            username: username,
            login_panel: "WEB",
            otp: OTP,
          };
          const generateTokenResponse: any = await generateToken(
            generateTokenPayload
          );
          if (
            generateTokenResponse.status === 200 ||
            generateTokenResponse.status === 201
          ) {
            setAuthTokens(generateTokenResponse?.data?.tokens);
            localStorage.setItem(
              "authTokens",
              JSON.stringify(generateTokenResponse?.data?.tokens)
            );
            dispatch(hideDialog());
            dispatch(logInUserFunc());
            dispatch(setShowLoginDialog(false));
            return toast?.current?.show({
              severity: "success",
              summary: "Success",
              detail: `${generateTokenResponse?.data?.message}`,
            });
          } else {
            toast?.current?.show({
              severity: "error",
              summary: "Failed",
              detail: `${generateTokenResponse?.response?.data?.error}`,
            });
          }
        }
      } else if (saveUserResponse?.response?.status === 400) {
        const generateTokenPayload = {
          username: username,
          login_panel: "WEB",
          otp: OTP,
        };
        const generateTokenResponse: any = await generateToken(
          generateTokenPayload
        );
        if (
          generateTokenResponse.status === 200 ||
          generateTokenResponse.status === 201
        ) {
          setAuthTokens(generateTokenResponse?.data?.tokens);
          localStorage.setItem(
            "authTokens",
            JSON.stringify(generateTokenResponse?.data?.tokens)
          );
          dispatch(hideDialog());
          dispatch(logInUserFunc());
          dispatch(setShowLoginDialog(false));
          toast?.current?.show({
            severity: "success",
            summary: "Success",
            detail: `${generateTokenResponse?.data?.message}`,
          });
        } else {
          setRegistrationError(generateTokenResponse?.response?.data?.error);
          toast?.current?.show({
            severity: "error",
            summary: "Failed",
            detail: `${generateTokenResponse?.response?.data?.error}`,
          });
        }
        return toast?.current?.show({
          severity: "error",
          summary: "Failed",
          detail: `${saveUserResponse?.response?.data?.message}`,
        });

        // dispatch(goToLogin())
      } else {
        toast?.current?.show({
          severity: "error",
          summary: "Failed",
          detail: `${saveUserResponse?.response?.data?.error}`,
        });
      }
    } else {
      toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `${response?.response?.data?.message}`,
      });
    }
  };

  const onInputChang = (e: any) => {
    setEmailError("");
    setUsername(e.target.value);
    // if (!validateEmail(e.target.value)) {
    //   setEmailError("Invalid email");
    // } else {
    //   setEmailError("");
    // }
  };

  const validatePhoneNumber = (number: string) => {
    const re = /^\d{10}$/;
    return re.test(number);
  };

  const onPhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumberError('');
    const newPhoneNumber = e.target.value;
    if (/^\d+$/.test(newPhoneNumber)) {
      setPhoneNumber(newPhoneNumber);
    }
  };

  const onFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserFullNameError('')
    const fullNameRegex = /^[a-zA-Z.,_'"" ]*$/;
    const fullName = e.target.value;
    if (fullNameRegex.test(fullName)) {
      setUserFullName(fullName);
    }
  };

  const handleOTPInput = (e: any) => {
    setOTPError("");
    const inputOTP = e.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(inputOTP)) {
      setOTP(inputOTP);
    }
  };

  const handleLogin = () => {
    // dispatch(goToLogin());
    // dispatch(logInUserFunc());
    dispatch(setShowRegister(false));
  };

  const handleCompanyChange = (e: any) => {
    setIsSelectingDepartment(false)
    setSelectedDepartment([]);
    setSelectedCompany(e.target.value);
  }
  const handleDepartmentChange = (e: any) => {
    setSelectedDepartment(e.target.value);
  }
  return (
    <div>
      <Toast ref={toast} />
      <div className="registration-form-wrapper">
        <div className="registration-wrapper">
          <div className="register-title">Corporate User Register</div>
        </div>
        <form>
          <div>
            <div className="mb-2">
              <InputText
                id="fullname"
                value={userFullName}
                onChange={(e) => onFullNameChange(e)}
                placeholder="Name"
                className="email-input w-full"
              />
              {userFullNameError && (
                <small style={{ color: "red" }}>{userFullNameError}</small>
              )}
            </div>
            <div className="mb-2">
              <InputText
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => onPhoneNumberChange(e)}
                placeholder="Phone Number"
                className="phone-input w-full"
                maxLength={10}
              />
              {phoneNumberError && (
                <small style={{ color: "red" }}>{phoneNumberError}</small>
              )}
            </div>
            <div className="p-inputgroup">
              <InputText
                id="email1"
                value={username}
                onChange={(e) => onInputChang(e)}
                placeholder="Email"
                className="email-input"
              />
              <Button label="Send OTP" onClick={sendOTPFunc} />
            </div>
            {emailError && <small style={{ color: "red" }}>{emailError}</small>}
          </div>

          {sendingOTP && (
            <div className="otp-wrapper">
              {/* {displayOTP && (
                <small style={{ color: "red" }}>{displayOTP}</small>
              )} */}
              <InputText
                id="OTP"
                type="OTP"
                value={OTP}
                onChange={(e) => handleOTPInput(e)}
                className="w-full otp-input"
                placeholder="OTP"
                maxLength={6}
              />
              {OTPError && <small style={{ color: "red" }}>{OTPError}</small>}
              {registrationError && (
                <small style={{ color: "red" }}>{registrationError}</small>
              )}
              <div className="resend-otp">
                <button
                  disabled={isResendDisabled}
                  onClick={(e: any) => resendOTP(e)}
                  className={isResendDisabled ? "text-disabled" : ""}
                >
                  Resend OTP
                </button>
                {isResendDisabled && <span>({countdown})</span>}
              </div>
              <div className="companies-department-options-wrapper">
                {isSelectingCompany && (
                  <div className="companies-options-wrapper">
                    <label htmlFor="companyDropdown">
                      Please Select A Company
                    </label>
                    
                       
                    <Dropdown
                      id="companyDropdown"
                      value={selectedCompany}
                      onChange={(e) => {
          
          handleCompanyChange(e);
        }}
                      options={companyListOptions}
                      optionLabel="company.organization_name"
                      optionValue="company_id"
                      placeholder="Select a Company"
                      className="w-full"
                    />
                  
                  </div>
                )}
                {isSelectingDepartment && (
                  <div className="departments-options-wrapper">
                    <label htmlFor="departmentDropdown">
                      Please Select A Department
                    </label>
                    <Dropdown
                      id="departmentDropdown"
                      value={selectedDepartment}
                      onChange={(e) => handleDepartmentChange(e)}
                      options={departmentListOptions}
                      optionLabel="department_name"
                      optionValue="company_department_id"
                      placeholder="Select a Department"
                      className="w-full"
                    />
                  </div>
                )}
              </div>
              <Button
                label="Continue"
                icon="pi"
                className="w-full verify-otp-btn"
                onClick={verifyOTPFunc}
                disabled={OTP.length !== 6}
              />
            </div>
          )}
          <div className="by-continuing">
            By Continuing, You Agree To Mechmiles Terms & Conditions And Privacy
            Policy
          </div>
        </form>
      </div>
      <div className="create-account-wrapper">
        <span className="create-account-text">Already Have An Account?</span>
        <a
          onClick={handleLogin}
          className="create-account-text create-account-link cursor-pointer"
        >
          Login
        </a>
      </div>
    </div>
  );
};

export default Register;
