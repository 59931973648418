import axios from "axios";
import useAxios from "../hooks/useAxios";

const baseURL = process.env.REACT_APP_BASE_URL;

let url = "/cart_master"
let cartSaveUrl = "/cart-save";
let cartListUrl = "/cart-list";
let cartRemoveUrl = "/remove-item";
let cartReduceUrl = "/cart-remove";
let cartSummeryUrl = "/get-summary";
let cartCountUrl = "/cart-count";
let wishlistUrl = "/move-to-wishlist";
let paymentUrl = "/payment/get-web-payment-link";
let paymentStatusUrl = "/payment/get-payment-transaction";

const useCartAPI = () => {
  const { axiosInstance } = useAxios();

  const cartSave = async (data: any) => {
    try {
      let response = await axiosInstance.post(baseURL + `${url}${cartSaveUrl}`, data);
      return response;
    }
    catch (error) {
      return error
    }
  };

  const cartList = async (data: any) => {
    try {
      let response = await axiosInstance.post(baseURL + `${url}${cartListUrl}`, data);
      return response;
    } catch (error) {
      return error;
    }
  };

  const cartRemoveItem = async (data: any) => {
    try {
      let response = await axiosInstance.post(baseURL + `${url}${cartRemoveUrl}/${data}`, {});
      return response;
    } catch (error) {
      return error;
    }
  };
  const cartReduceItem = async (data: any) => {
    try {
      let response = await axiosInstance.post(baseURL + `${url}${cartReduceUrl}`, data);
      return response;
    } catch (error) {
      return error;
    }
  };
  const cartSummery = async (code?: any, isWallet?: any) => {
    try {
      let response = await axiosInstance.get(baseURL + `${url}${cartSummeryUrl}?code=${code ?? null}&is_wallet=${isWallet}`);
      return response;
    } catch (error) {
      return error;
    }
  };
  const cartSummeryUsingWallet = async (code?: any) => {
    try {
      let response = await axiosInstance.get(baseURL + `${url}${cartSummeryUrl}?is_wallet=${code ?? null}`);
      return response;
    } catch (error) {
      return error;
    }
  };
  const cartCount = async (data: any) => {
    try {
      let response = await axiosInstance.post(baseURL + `${url}${cartCountUrl}`, data);
      return response;
    } catch (error) {
      return error;
    }
  };
  const moveToWishlist = async (data: any) => {
    try {
      let response = await axiosInstance.patch(baseURL + `${url}${wishlistUrl}`, data);
      return response;
    } catch (error) {
      return error;
    }
  };
  const getPaymentLink = async (data: any) => {
    try {
      let response = await axiosInstance.post(baseURL + `${paymentUrl}`, data);
      return response;
    } catch (error) {
      return error;
    }
  };
  const getPaymentStatus = async (data: any) => {
    try {
      let response = await axiosInstance.get(baseURL + `${paymentStatusUrl}/${data}`);
      return response;
    } catch (error) {
      return error;
    }
  };

  return {
    cartSave,
    cartList,
    cartRemoveItem,
    cartReduceItem,
    cartSummery, 
    cartSummeryUsingWallet,
    cartCount,
    moveToWishlist,
    getPaymentLink,
    getPaymentStatus,
  };
};

export default useCartAPI;
