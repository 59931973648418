import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export const citySlice = createSlice({
  name: 'city',
  initialState: {
    selectedCity: JSON.parse(localStorage.getItem('selectedCity') || "{}") || null,
  },
  reducers: {
    setSelectedCity: (state, action) => {
      state.selectedCity = action.payload;
    },
  },
});

export const { setSelectedCity } = citySlice.actions;

export const selectCity = (state: RootState) => state.city.selectedCity;

export default citySlice.reducer;