import { createSlice } from '@reduxjs/toolkit';

interface UserState {
  isLoggedin: boolean;
  isLoggedOut: boolean;
}

const initialState: UserState = {
  isLoggedin: false,
  isLoggedOut: true
};

export const userState = createSlice({
  name: 'userstate',
  initialState,
  reducers: {
    logInUserFunc: (state) => {
      state.isLoggedin = true;
      state.isLoggedOut = false;
    },
    logOutUserFunc: (state) => {
      state.isLoggedin = false;
      state.isLoggedOut = true;
    },
  },
});

export const { logInUserFunc, logOutUserFunc } = userState.actions;

export const isLoggedInState = (state: { userState: UserState }) => state.userState.isLoggedin;

export const isLoggedOutState = (state: { userState: UserState }) => state.userState.isLoggedOut;

export default userState.reducer;