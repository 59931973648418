import React, { useEffect, useState } from "react";
import { Image } from "primereact/image";

import testimonialImage from "../../assets/images/testimonial-img.png";
import testimonialQuote from "../../assets/images/testimonial-quotes.png";
import testimonialDivider from "../../assets/images/testimonial-divider.png";

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./SimpleSlider.css";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { Container } from "react-bootstrap";
import { IoPersonCircle } from "react-icons/io5";

// Define a type for your slide data
type Testimonial = {
  quote: string;
  name: string;
  title: string;
  imageUrl: string;
};

// Example dynamic data array
const testimonials: Testimonial[] = [
  {
    quote:
      "I recently used Mechmiles for my tire replacement, and I am beyond impressed! The convenience of booking through their website and the expert recommendations for my vehicle made the entire process seamless. The service was quick, and I feel much safer on the road with my new, top-quality tires. Mechmiles is now my go-to for all things tire-related!",
    name: "Nikhil Mistry",
    title: "Entrepreneur",
    imageUrl: "https://www.faceplusplus.com/demo/images/demo-pic34.jpg",
  },
  {
    quote:
      "Kudos to the Mechmiles team for their exceptional tire service! I appreciated the detailed information on tire options available for my car. The professionalism and efficiency displayed during the tire replacement were remarkable. My driving experience has been significantly improved, and I highly recommend Mechmiles for their reliable and customer-centric approach.",
    name: "Siddharth Sharma",
    title: "Manager",
    imageUrl: "https://www.faceplusplus.com/demo/images/demo-pic34.jpg",
  },
  {
    quote:
      "I cannot thank Mechmiles enough for their outstanding tire-related services. From tire selection to the installation process, everything was smooth and hassle-free. The team's knowledge and attention to detail instilled confidence in their recommendations. I am extremely satisfied with the quality of the tires and the overall service. Mechmiles has earned a loyal customer in me!",
    name: "Raju Rathod",
    title: "Engineer",
    imageUrl: "https://www.faceplusplus.com/demo/images/demo-pic34.jpg",
  },
  {
    quote:
      "Choosing the right tires for my vehicle used to be a daunting task until I discovered Mechmiles. Their user-friendly website simplified the entire process. The tire options were well-explained, and the recommended tires have made a noticeable difference in my car's performance. Mechmiles delivers not just tires but a comprehensive and reliable service.",
    name: "Fairoz Logde",
    title: "Tech Lead",
    imageUrl: "https://www.faceplusplus.com/demo/images/demo-pic34.jpg",
  },
];

export default function SimpleSlider() {
  const [slideCount, setSlideCount] = useState(3);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    function updateSlideCount() {
      const width = window.innerWidth;
      if (width < 768) {
        setSlideCount(1);
      } else if (width < 1200) {
        setSlideCount(2);
      } else {
        setSlideCount(3);
      }
    }

    // Set the number of slides when the component mounts
    updateSlideCount();

    // Add event listener for window resize
    window.addEventListener("resize", updateSlideCount);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", updateSlideCount);
  }, []);

  return (
    <>
      <h3 className="testimonials-section-title">
        What our clients say <br />
        about us
      </h3>
      <Container>
        <div className="testimonials-slider">
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={100}
            totalSlides={testimonials.length}
            visibleSlides={slideCount}
            step={1}
            currentSlide={currentSlide}
            isIntrinsicHeight={true}
          >
            <div className="slider-controls">
              <ButtonBack className="slider-button back">
                <MdArrowBackIosNew />
              </ButtonBack>
              <ButtonNext className="slider-button next">
                <MdArrowForwardIos />
              </ButtonNext>
            </div>
            <Slider>
              {testimonials.map((testimonial, index) => (
                <Slide key={index} index={index} className="testimonial-slide">
                  <div className={`single-testimonial testimonial-${index}`}>
                    <div className="testimonial-content">
                      <Image
                        src={testimonialQuote}
                        alt={testimonial.name}
                        className="testimonial-quote"
                        height="35"
                        width="35"
                      />
                      <p className="testimonial-paragraph">
                        {testimonial.quote}
                      </p>
                      <Image
                        src={testimonialDivider}
                        alt={testimonial.name}
                        className="testimonial-divider"
                      />
                      <div className="testimonial-footer">
                        {/* <Image
                          src={testimonialImage}
                          alt={testimonial.name}
                          className="testimonial-image"
                          height="77"
                          width="77"
                        /> */}

                        <IoPersonCircle className="testimonial-image" />
                        <div className="testimonial-meta">
                          <div className="testimonial-name">
                            {testimonial.name}
                          </div>
                          <div className="testimonial-title">
                            {testimonial.title}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slide>
              ))}
            </Slider>
          </CarouselProvider>
        </div>
      </Container>
    </>
  );
}
