import axios from "axios";
import useAxios from "../hooks/useAxios";

const baseURL = process.env.REACT_APP_BASE_URL;

let url = '/user-address'

const useUserAPI = () => {
  const { axiosInstance } = useAxios();

  const getUserAddress = async (data: any) => {
    let response = await axiosInstance.post(baseURL + `${url}/user_address-list`, data);
    return response;
  };
  const getUserAddressCount = async (data: any) => {
    let response = await axiosInstance.post(baseURL + `${url}/user_address-count`, data);
    return response;
  };

  const saveUserAddress = async (data: any) => {
    let response = await axiosInstance.post(baseURL + `${url}/address-save`, data);
    return response;
  };
  const markAsDefaultUserAddress = async (data: any) => {
    let response = await axiosInstance.patch(baseURL + `${url}/mark-address-default`, data);
    return response;
  };
  const updateUserAddress = async (address_id: any, data: any) => {
    let response = await axiosInstance.put(baseURL + `${url}/update/${address_id}`, data);
    return response;
  };
  const deleteUserAddress = async (address_id: any, data: any) => {
    let response = await axiosInstance.delete(baseURL + `${url}/delete/${address_id}`, data);
    return response;
  };


  return {
    getUserAddress,
    saveUserAddress,
    markAsDefaultUserAddress,
    updateUserAddress,
    deleteUserAddress,
    getUserAddressCount
  };
};

export default useUserAPI;
