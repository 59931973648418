import axios from "axios";
import useAxios from "../hooks/useAxios";

const baseURL = process.env.REACT_APP_BASE_URL;

let url = "/company_master";
let saveUserUrl = "/register-corporate-user";
let saveCustomerUrl = "/register-customer";
let listCompaniesUrl = "/company-list";
let sendOTPUrl = "/user/send-otp";
let verifyOTPUrl = "/user/verify-otp";
let generateTokenUrl = "/auth/generate-token";
let departmentUrl = "/departments";
let staffListUrl = "/company-user-list";
let userUrl = "/user"
let myProfileUrl = "/my-profile"
let userUpdateUrl = "/update-user"
let uploadFileUrl = "/company-user-import"

const useLoginAPI = () => {
  const { axiosInstance } = useAxios();

  const sendOTP = async (data: any) => {
    let response = await axiosInstance.post(baseURL + `${sendOTPUrl}`, data);
    return response;
  };
  const verifyOTP = async (data: any) => {
    let response = await axiosInstance.post(baseURL + `${verifyOTPUrl}`, data);
    return response;
  };

  const saveUserAPI = async (data: any) => {
    let response = await axiosInstance.post(baseURL + `${url}${saveUserUrl}`, data);
    return response;
  }
  const saveCustomerAPI = async (data: any) => {
    let response = await axiosInstance.post(baseURL + `${url}${saveCustomerUrl}`, data);
    return response;
  }
  const generateToken = async (data: any) => {
    let response = await axiosInstance.post(baseURL + `${generateTokenUrl}`, data);
    return response;
  }
  const getCompanyNames = async (data: any) => {
    let response = await axiosInstance.post(baseURL + `${url}${listCompaniesUrl}`, data);
    return response;
  }
  const getDepartmentNames = async (companyId: any) => {
    let response = await axiosInstance.get(baseURL + `${url}${departmentUrl}/${companyId}`);
    return response;
  }
  const getStaffList = async (data: any) => {
    let response = await axiosInstance.post(baseURL + `${url}${staffListUrl}`, data);
    return response;
  }
  const getStaffCount = async (data: any) => {
    let response = await axiosInstance.post(baseURL + `${url}/company-user-count`, data);
    return response;
  }
  const updateStaffById = async (userId: any,data: any) => {
    let response = await axiosInstance.put(baseURL + `${userUrl}${userUpdateUrl}/${userId}`, data);
    return response;
  }
  const getUserById = async (data: any) => {
    let response = await axiosInstance.get(baseURL + `${url}${myProfileUrl}`, data);
    return response;
  }
  const importStaffUpload = async (data: any) => {
    let response = await axiosInstance.post(baseURL + `${url}${uploadFileUrl}`, data);
    return response;
  }
  const getStaffStats = async (companyId: any) => {
    let response = await axiosInstance.get(baseURL + `${url}/staff-stats/${companyId}`);
    return response;
  }


  return {
    sendOTP,
    verifyOTP,
    generateToken,
    saveUserAPI,
    getCompanyNames,
    getDepartmentNames,
    getStaffList,
    updateStaffById,
    getUserById,
    saveCustomerAPI,
    importStaffUpload,
    getStaffStats,
    getStaffCount
  };
};

export default useLoginAPI;
