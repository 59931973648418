import React from 'react'
import './PageNotFound.css'

import underConstruction from '../../assets/images/development_inprogress.png'
import { Image } from 'primereact/image'

const PageNotFound = () => {
  return (
    <div className='page-not-found-wrapper'>
      <Image src={underConstruction} alt="Under Construction Image" className='development_inprogress_img'/>
      <div className="page-not-found-text">Page is under construction</div>
    </div>
  )
}

export default PageNotFound