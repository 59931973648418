import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import { Slider } from "primereact/slider";
import { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import useHeroAPI from "../../api/useHeroAPI";
import useProductsAPI from "../../api/useProductsAPI";
import { RootState } from "../../app/store";
import bikeImg from "../../assets/images/bike_scooter.png";
import carSuvImg from "../../assets/images/car_suv.png";
import commercialImg from "../../assets/images/commercial.png";
import noProductFoundImage from "../../assets/images/product-not-found.png";
import threeWheelerImg from "../../assets/images/three_wheeler.png";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { Product } from "../../components/Product/Product";
import { selectCity } from "../../redux/citySlice";
import { setLoading } from "../../redux/loadingSlice";
import { selectRefreshTrigger } from "../../redux/productSlice";
import { setTabIndex } from "../../redux/tabIndexSlice";
import "./ProductList.css";

interface Category {
  key: string;
  name: string;
}

interface Option {
  name: string;
  code: string;
}
const ProductList = () => {
  const loading = useSelector((state: any) => state.loading);
  const refreshTrigger = useSelector(selectRefreshTrigger);

  const corporateUserRole = "ROLE_CORPORATE_USER";

  const cityId = useSelector(selectCity);

  const navigate = useNavigate();
  const location = useLocation();
  const { getProductList, getProductsListCount } = useProductsAPI();
  const { predefinedFunc } = useHeroAPI();

  const tabIndex = useSelector((state: RootState) => state.tabIndex);
  const dispatch = useDispatch();

  const [popularProdsTabIndex, setPopularProdsTabIndex] = useState(0);
  const [typesPreDefinedState, setTypesPreDefinedState] = useState(null) as any;
  const [tyreType, setTyreType] = useState<Option | null | string>(null) as any;
  const [brands, setBrands] = useState<Array<{
    name: string;
    code: string;
  }> | null>([]) as any;
  const [makes, setMakes] = useState<Array<{
    name: string;
    code: string;
  }> | null>([]) as any;
  const [models, setModels] = useState<Array<{
    name: string;
    code: string;
  }> | null>([]) as any;
  const [variants, setVariants] = useState<Array<{
    name: string;
    code: string;
  }> | null>([]) as any;
  const [tyreTypes, setTyreTypes] = useState<Array<{
    name: string;
    code: string;
  }> | null>(null) as any;
  const [tyrePositions, setTyrePositions] = useState<Array<{
    name: string;
    code: string;
  }> | null>(null) as any;
  const [tyreSizes, setTyreSizes] = useState<Array<{
    name: string;
    code: string;
  }> | null>(null) as any;
  const [prices, setPrices] = useState<Array<{
    name: string;
    code: string;
  }> | null>(null) as any;
  const [widthOptions, setWidthOptions] = useState<Array<{
    name: string;
    code: string;
  }> | null>(null) as any;
  const [aspectOptions, setAspectOptions] = useState<Array<{
    name: string;
    code: string;
  }> | null>(null) as any;
  const [rimOptions, setRimOptions] = useState<Array<{
    name: string;
    code: string;
  }> | null>(null) as any;
  const [brand, setBrand] = useState<Array<{
    name: string;
    code: string;
  }> | null>(null) as any;
  const [make, setMake] = useState<Array<{
    name: string;
    code: string;
  }> | null>(null) as any;
  const [model, setModel] = useState<Array<{
    name: string;
    code: string;
  }> | null>(null) as any;
  const [variant, setVariant] = useState<Array<{
    name: string;
    code: string;
  }> | null>(null) as any;

  const [brandSearchTerm, setBrandSearchTerm] = useState("");
  const [makeSearchTerm, setMakeSearchTerm] = useState("");
  const [modelSearchTerm, setModelSearchTerm] = useState("");
  const [typeSearchTerm, setTypeSearchTerm] = useState("");
  const [priceSearchTerm, setPriceSearchTerm] = useState("");
  const [sizeSearchTerm, setSizeSearchTerm] = useState("");
  const [userId, setUserId] = useState(null) as any;
  const [userRole, setUserRole] = useState(null) as any;
  const [productType, setProductType] = useState(null) as any;

  const [priseSliderValue, setPriseSliderValue] = useState(0);

  const [priceRange, setPriceRange] = useState({ from: 0, to: 0 });
  const [debouncedPriceRange, setDebouncedPriceRange] = useState(priceRange);
  const debounceTimerRef = useRef() as any;
  const [selectedSortBy, setSelectedSortBy] = useState(null) as any;
  const [selectedWidth, setSelectedWidth] = useState(null) as any;
  const [selectedAspect, setSelectedAspect] = useState(null) as any;
  const [selectedRim, setSelectedRim] = useState(null) as any;

  const [bikeTyres, setBikeTyres] = useState([]);

  const [isVehicleTypeVisible, setIsVehicleTypeVisible] = useState(true);
  const [isTypeBrandVisible, setIsTypeBrandVisible] = useState(true);
  const [isSearchByVehicle, setIsSearchByVehicle] = useState(true);
  const [isPriceVisible, setIsPriceVisible] = useState(true);
  const [isTyreTypeVisible, setIsTyreTypeVisible] = useState(true);
  const [isTyrePositionVisible, setIsTyrePositionVisible] = useState(true);
  const [isTyreSizeVisible, setIsTyreSizeVisible] = useState(true);
  const [visibleBrandsCount, setVisibleBrandsCount] = useState(4);

  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPageOption, setRowsPerPageOption] = useState(9);
  const [records, setRecords] = useState(20);

  const [filters, setFilters] = useState(location?.state);

  const [selectedFilterIds, setSelectedFilterIds] = useState<any>({
    brand_ids: [],
    tyre_type_ids: [],
    tyer_ps_ids: [],
    tyer_sizes_ids: [],
    tyer_types_ids: [],
    tyer_positions_ids: [],
    price_ids: [],
    width_ids: [],
    aspect_ids: [],
    rim_ids: [],
  });

  const filteredBrands = brands?.filter((brand: any) =>
    brand.name.toLowerCase().includes(brandSearchTerm.toLowerCase())
  );
  const filteredMakes = makes?.filter((make: any) =>
    make.name.toLowerCase().includes(makeSearchTerm.toLowerCase())
  );
  const filteredModels = models?.filter((model: any) =>
    model.name.toLowerCase().includes(modelSearchTerm.toLowerCase())
  );
  const filteredTypes = tyreTypes?.filter((type: any) =>
    type.name.toLowerCase().includes(typeSearchTerm.toLowerCase())
  );
  const filteredPositions = tyrePositions?.filter((type: any) =>
    type.name.toLowerCase().includes(typeSearchTerm.toLowerCase())
  );

  const handleSetMake = (e: any) => {
    if (e.target.value == undefined) {
      e.target.value = null;
      const payload = {
        sort_by: selectedSortBy ? selectedSortBy.code : null,
        page_number: pageNumber,
        page_size: rowsPerPageOption,
        user_id: userId,
      };
      getProductListWithPayload(payload);

      removeTag("brand");
      removeTag("model");
      removeTag("variant");

      setMake(e.target.value);
      setModel(null);
      setModels([]);
      setVariants([]);
      setVariant(null);
      return;
    }

    const currentMake = makes?.find((m: any) => m.code === e.target.value);
    const currentModel = models?.find((m: any) => m.code === model);
    const currentVariant = variants?.find((v: any) => v.code === variant);

    const hasMakeChanged = currentMake?.code !== filters?.brand?.code;
    const hasModelChanged = currentModel?.code !== filters?.model?.code;
    const hasVariantChanged = currentVariant?.code !== filters?.variant?.code;

    if (hasMakeChanged || hasModelChanged || hasVariantChanged) {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        brand: currentMake,
        model: currentModel,
        variant: currentVariant,
      }));
    }
    setMake(e.target.value);
    setModel(null);
    setVariant(null);
    fetchDependentData(e.target.value, null);
  };

  const handleSetModel = (e: any) => {
    if (e.target.value == undefined) {
      e.target.value = null;
      const payload = {
        sort_by: selectedSortBy ? selectedSortBy.code : null,
        page_number: pageNumber,
        page_size: rowsPerPageOption,
        user_id: userId,
      };

      getProductListWithPayload(payload);
      setModel(e.target.value);
      setVariant(null);
      setVariants([] as any);
      removeTag("model");
      removeTag("variant");
      return;
    }

    const currentMake = makes?.find((m: any) => m.code === make);
    const currentModel = models?.find((m: any) => m.code === e.target.value);
    const currentVariant = variants?.find((v: any) => v.code === variant);

    const hasMakeChanged = currentMake?.code !== filters?.brand?.code;
    const hasModelChanged = currentModel?.code !== filters?.model?.code;
    const hasVariantChanged = currentVariant?.code !== filters?.variant?.code;

    if (hasMakeChanged || hasModelChanged || hasVariantChanged) {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        brand: currentMake,
        model: currentModel,
        variant: currentVariant,
      }));
    }
    setModel(e.target.value);
    setVariant(null);
    fetchDependentData(make, e.target.value);
  };

  // const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement>, product: object) => {
  //  setHoveredProduct(product)
  // };
  // const handleMouseLeave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) => {
  //  setHoveredProduct(null)
  // };

  const fetchProductListFunc = async (
    type_id?: any,
    category_id?: any,
    variant_id?: any
  ) => {
    dispatch(setLoading(true));
    const locationData = location?.state;
    console.log(locationData,"locationData");
    const categoryIds = category_id
      ? [category_id]
      : tyreType?.predefined_id
      ? [tyreType?.predefined_id]
      : [];

    const payload = {
      make_ids: locationData?.brand?.code
        ? [locationData?.brand?.code]
        : selectedFilterIds?.make_ids,
      model_ids: locationData?.model?.code
        ? [locationData?.model?.code]
        : selectedFilterIds?.model_ids,
      ...selectedFilterIds,
      variant_ids: locationData?.variant?.code
        ? [locationData?.variant?.code]
        : variant_id
        ? variant_id
        : [variant],
      brand_ids: locationData?.predefined_id
        ? [locationData?.predefined_id]
        : selectedFilterIds?.brand_ids,
      sort_by: selectedSortBy ? selectedSortBy.code : null,
      page_number: pageNumber,
      page_size: rowsPerPageOption,
      user_id: userId,
      product_type_id: type_id ? type_id : productType?.predefined_id,
      category_ids: categoryIds.length > 0 ? categoryIds : [],
      // city_id: cityId ? cityId : null
      price_range_from:
        debouncedPriceRange.from === 0 ? null : debouncedPriceRange.from,
      price_range_to:
        debouncedPriceRange.to === 0 ? null : debouncedPriceRange.to,
    };

    const response = await getProductList(payload);
    const countResponse: any = await getProductsListCount(payload);
    if (response.status === 200 || response.status === 201) {
      dispatch(setLoading(false));
      setBikeTyres(response?.data);
      setRecords(countResponse?.data?.count);
      if (locationData?.variant) {
        locationData.variant.code = null;
      }
    }
  };

  // const fetchFilteredProducts = async () => {
  //   const filterPayload = {
  //     ...selectedFilterIds,
  //     price_range_from:
  //       debouncedPriceRange.from === 0 ? null : debouncedPriceRange.from,
  //     price_range_to:
  //       debouncedPriceRange.to === 0 ? null : debouncedPriceRange.to,
  //     page_number: pageNumber,
  //     page_size: rowsPerPageOption,
  //     product_type_id: location?.state?.type_id,
  //     // city_id: cityId ? cityId : null
  //   };

  //   const response = await getProductList(filterPayload);
  //   if (response.status === 200 || response.status === 201) {
  //     setBikeTyres(response?.data);
  //   }
  // };

  // const fetchPredefined = async () => {
  //   const predefinedParentPayload = {
  //     entity_type: "CATEGORY",
  //   };
  //   const predefinedResponse = await predefinedFunc(predefinedParentPayload);
  //   if (predefinedResponse.status === 200) {
  //     setTypesPreDefinedState(predefinedResponse.data.data);
  //     setTyreType(predefinedResponse.data.data[0]);
  //   }
  //   fetchChildPredefined();
  // };

  const fetchPredefined = async () => {
    const predefinedParentPayload = {
      entity_type: "CATEGORY",
    };
    const predefinedResponse = await predefinedFunc(predefinedParentPayload);
    if (predefinedResponse.status === 200) {
      setTypesPreDefinedState(predefinedResponse?.data?.data);
      const defaultTyreType = predefinedResponse?.data?.data?.find(
        (type: any) => location?.state?.tyreType?.code === type?.code
      );
      setTyreType(defaultTyreType);

      const twoWheelerIndex = predefinedResponse?.data?.data.findIndex(
        (type: any) => location?.state?.tyreType?.code === type?.code
      );
      if (twoWheelerIndex !== -1) {
        dispatch(setTabIndex(twoWheelerIndex));
      }
      fetchSubPredefined(predefinedResponse?.data?.data, defaultTyreType);
    }
  };

  const fetchProductType = async () => {
    const predefinedParentPayload = {
      entity_type: "PRODUCT-TYPE",
      code: location?.state?.code,
    };
    const predefinedResponse = await predefinedFunc(predefinedParentPayload);
    if (predefinedResponse.status === 200) {
      // fetchProductListFunc(predefinedResponse.data.data[0].predefined_id);

      setProductType(predefinedResponse.data.data[0]);
    }

    // fetchChildPredefined();
  };

  const fetchChildPredefined = async (typesPreDefinedStateParam: any) => {
    dispatch(setLoading(true));
    console.log(typesPreDefinedState);

    const foundTyreType: any = typesPreDefinedStateParam?.find(
      (type: any) => type?.name === tyreType?.name
    );

    // setSelectedTyreType(foundTyreType?.predefined_id)

    if (
      foundTyreType &&
      foundTyreType?.name !== undefined &&
      foundTyreType?.name !== ""
    ) {
      const brandPredefinedPayload = {
        entity_type: "BRAND",
        // parent_id: foundTyreType?.predefined_id ?? "",
        parent_id: location?.state?.productType?.predefined_id,
      };
      const makePredefinedPayload = {
        entity_type: "MAKE",
        parent_id: tyreType?.predefined_id ?? "",
      };

      const tyreSizePredefinedPayload = {
        entity_type: "TYRE-SIZE",
        parent_id: foundTyreType?.predefined_id ?? "",
      };
      const tyreTypePredefinedPayload = {
        entity_type: "TYRE-TYPE",
        // parent_id: foundTyreType?.predefined_id ?? "",
      };
      const tyrePositionPredefinedPayload = {
        entity_type: "TYRE-POSITION",
        // parent_id: foundTyreType?.predefined_id ?? "",
      };
      const pricePredefinedPayload = {
        entity_type: "PRICE",
        parent_id: foundTyreType?.predefined_id ?? "",
      };
      const widthPredefinedPayload = {
        entity_type: "WIDTH",
      };
      const aspectPredefinedPayload = {
        entity_type: "ASPECT",
      };
      const rimPredefinedPayload = {
        entity_type: "RIM",
      };

      const brandPredefinedResponse = await predefinedFunc(
        brandPredefinedPayload
      );
      const makePredefinedResponse = await predefinedFunc(
        makePredefinedPayload
      );

      const tyreSizePredefinedResponse = await predefinedFunc(
        tyreSizePredefinedPayload
      );
      const tyreTypePredefinedResponse = await predefinedFunc(
        tyreTypePredefinedPayload
      );
      const tyrePositionPredefinedResponse = await predefinedFunc(
        tyrePositionPredefinedPayload
      );
      const pricePredefinedResponse = await predefinedFunc(
        pricePredefinedPayload
      );
      const widthPredefinedResponse = await predefinedFunc(
        widthPredefinedPayload
      );
      const aspectPredefinedResponse = await predefinedFunc(
        aspectPredefinedPayload
      );
      const rimPredefinedResponse = await predefinedFunc(rimPredefinedPayload);

      const brandData = brandPredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      const makeData = makePredefinedResponse?.data?.data?.map((child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      });
      const tyreSizeData = tyreSizePredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      const tyreTypeData = tyreTypePredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      const tyrePositionData = tyrePositionPredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      const priceData = pricePredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      const widthData = widthPredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      const aspectData = aspectPredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      const rimData = rimPredefinedResponse?.data?.data?.map((child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      });

      setBrands(brandData);
      setMakes(makeData);

      setTyreSizes(tyreSizeData);
      setTyreTypes(tyreTypeData);
      setTyrePositions(tyrePositionData);
      setPrices(priceData);
      setWidthOptions(widthData);
      setAspectOptions(aspectData);
      setRimOptions(rimData);
      dispatch(setLoading(false));
    }
  };

  const fetchSubPredefined = async (
    typesPreDefinedStateParam: any,
    tyreTypeParam: any
  ) => {
    dispatch(setLoading(true));
    console.log(typesPreDefinedState);

    const foundTyreType: any = typesPreDefinedStateParam?.find(
      (type: any) => type?.name === tyreTypeParam?.name
    );

    // setSelectedTyreType(foundTyreType?.predefined_id)

    if (
      foundTyreType &&
      foundTyreType?.name !== undefined &&
      foundTyreType?.name !== ""
    ) {
      const brandPredefinedPayload = {
        entity_type: "BRAND",
        // parent_id: foundTyreType?.predefined_id ?? "",
        parent_id: location?.state?.productType?.predefined_id,
      };
      const makePredefinedPayload = {
        entity_type: "MAKE",
        parent_id: tyreType?.predefined_id ?? "",
      };

      const tyreSizePredefinedPayload = {
        entity_type: "TYRE-SIZE",
        parent_id: foundTyreType?.predefined_id ?? "",
      };
      const tyreTypePredefinedPayload = {
        entity_type: "TYRE-TYPE",
        // parent_id: foundTyreType?.predefined_id ?? "",
      };
      const tyrePositionPredefinedPayload = {
        entity_type: "TYRE-POSITION",
        // parent_id: foundTyreType?.predefined_id ?? "",
      };
      const pricePredefinedPayload = {
        entity_type: "PRICE",
        parent_id: foundTyreType?.predefined_id ?? "",
      };
      const widthPredefinedPayload = {
        entity_type: "WIDTH",
      };
      const aspectPredefinedPayload = {
        entity_type: "ASPECT",
      };
      const rimPredefinedPayload = {
        entity_type: "RIM",
      };
      let brandPredefinedResponse = null;
      if (brands == null || brands.length == 0) {
        brandPredefinedResponse = await predefinedFunc(brandPredefinedPayload);
      }

      const makePredefinedResponse = await predefinedFunc(
        makePredefinedPayload
      );

      const tyreSizePredefinedResponse = await predefinedFunc(
        tyreSizePredefinedPayload
      );
      const tyreTypePredefinedResponse = await predefinedFunc(
        tyreTypePredefinedPayload
      );

      let tyrePositionPredefinedResponse = null;
      if (tyrePositions == null || tyrePositions.length == 0) {
        tyrePositionPredefinedResponse = await predefinedFunc(
          tyrePositionPredefinedPayload
        );
      }

      const pricePredefinedResponse = await predefinedFunc(
        pricePredefinedPayload
      );
      const widthPredefinedResponse = await predefinedFunc(
        widthPredefinedPayload
      );
      const aspectPredefinedResponse = await predefinedFunc(
        aspectPredefinedPayload
      );
      const rimPredefinedResponse = await predefinedFunc(rimPredefinedPayload);

      const brandData = brandPredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      const makeData = makePredefinedResponse?.data?.data?.map((child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      });
      const tyreSizeData = tyreSizePredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      const tyreTypeData = tyreTypePredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      const tyrePositionData = tyrePositionPredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      const priceData = pricePredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      const widthData = widthPredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      const aspectData = aspectPredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      const rimData = rimPredefinedResponse?.data?.data?.map((child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      });

      if (brandData) {
        setBrands(brandData);
      }
      setMakes(makeData);

      setTyreSizes(tyreSizeData);
      setTyreTypes(tyreTypeData);
      if (tyrePositionData) {
        setTyrePositions(tyrePositionData);
      }
      setPrices(priceData);
      setWidthOptions(widthData);
      setAspectOptions(aspectData);
      setRimOptions(rimData);
      dispatch(setLoading(false));
    }
  };

  const fetchDependentData = async (makeParam: any, modelParam: any) => {
    dispatch(setLoading(true));
    if (makeParam) {
      const modelPredefinedPayload = {
        entity_type: "MODEL",
        parent_id: makeParam ?? null,
      };
      const modelPredefinedResponse = await predefinedFunc(
        modelPredefinedPayload
      );
      if (
        make !== null ||
        location?.state?.brand != null ||
        makeParam != null
      ) {
        dispatch(setLoading(false));
        const modelData = modelPredefinedResponse?.data?.data?.map(
          (child: any) => {
            return {
              name: child.name,
              code: child.predefined_id,
            };
          }
        );
        setModels(modelData);
      }
    }

    if (modelParam) {
      const variantPredefinedPayload = {
        entity_type: "VARIANT",
        parent_id: modelParam ?? null,
      };

      const variantPredefinedResponse = await predefinedFunc(
        variantPredefinedPayload
      );

      if (
        model !== null ||
        location?.state?.model != null ||
        modelParam != null
      ) {
        dispatch(setLoading(false));
        const variantData = variantPredefinedResponse?.data?.data?.map(
          (child: any) => {
            return {
              name: child.name,
              code: child.predefined_id,
            };
          }
        );
        setVariants(variantData);
      }
    }
  };

  // useEffect(() => {
  // const currentMake = makes?.find((m: any) => m.code === make);
  // const currentModel = models?.find((m: any) => m.code === model);
  // const currentVariant = variants?.find((v: any) => v.code === variant);

  // const hasMakeChanged = currentMake?.code !== filters?.brand?.code;
  // const hasModelChanged = currentModel?.code !== filters?.model?.code;
  // const hasVariantChanged = currentVariant?.code !== filters?.variant?.code;

  // if (hasMakeChanged || hasModelChanged || hasVariantChanged) {
  //   setFilters((prevFilters: any) => ({
  //     ...prevFilters,
  //     brand: currentMake,
  //     model: currentModel,
  //     variant: currentVariant,
  //   }));

  //   const variantIds = currentVariant ? [currentVariant.code] : [];

  //   // if (hasVariantChanged) {
  //   //   fetchProductListFunc(null, null, variantIds);
  //   // }
  // }
  // }, [variant, variants]);

  // useEffect(() => {
  //   fetchProductType();
  // }, [refreshTrigger]);

  useEffect(() => {
    if (makes && makes.length > 0 && location?.state?.brand != null) {
      setMake(location?.state?.brand?.code);
    }
    if (models && models.length > 0 && location?.state?.model != null) {
      setModel(location?.state?.model?.code);
    }
    if (variants && variants.length > 0 && location?.state?.variant != null) {
      const currentVariant = variants?.find(
        (v: any) => v.name === location.state.variant.name
      );
      setVariant(currentVariant.code);
    }
  }, [makes, models, variants]);

  useEffect(() => {
    const authTokens: any = JSON.parse(
      localStorage.getItem("authTokens") || "{}"
    );
    if (authTokens?.user?.id) {
      const userId = authTokens?.user?.id;
      const userRole = authTokens?.user?.roles[0]?.code;
      setUserId(userId);
      setUserRole(userRole);
    }
    fetchPredefined();

    // if (selectedSortBy) {
    // if (userId) {
    fetchProductType();
    const payload = {
      sort_by: selectedSortBy ? selectedSortBy.code : null,
      page_number: pageNumber,
      page_size: rowsPerPageOption,
      user_id: authTokens?.user?.id,
      product_type_id: location?.state?.productType?.predefined_id || null,
    };
    getProductListWithPayload(payload);
    // }
    // }
  }, [selectedSortBy]);

  useEffect(() => {
    if (location?.state) {
      setMake(location?.state?.brand?.code);
      setModel(location?.state?.model?.code);
      setVariant(location?.state?.variant?.code);

      setSelectedWidth(location?.state?.width?.code);
      setSelectedRim(location?.state?.rim?.code);
      setSelectedAspect(location?.state?.aspect?.code);
      if (location?.state?.productType) {
        setProductType(location?.state?.productType);
      }

      fetchDependentData(
        location?.state?.brand?.code,
        location?.state?.model?.code
      );
    }
  }, [location?.state]);

  // useEffect(() => {
  //   if (make !== null || model !== null) {
  //     fetchDependentData();
  //   }
  // }, [make, model]);

  // useEffect(() => {
  //   dispatch(setLoading(true));

  //   if (!filters) {
  //     return;
  //   }
  //   fetchProductType();
  //   fetchPredefined();
  // }, [filters, pageNumber, rowsPerPageOption]);

  // useEffect(() => {
  //   if (tyreType !== null && tyreType?.name !== "") {
  //     // fetchProductType();
  //     fetchProductListFunc(null, tyreType?.predefined_id, null);
  //     fetchChildPredefined();
  //   }
  // }, [tyreType]);

  // useEffect(() => {
  //   if (typesPreDefinedState) {
  //     fetchChildPredefined();
  //   }
  // }, [typesPreDefinedState]);

  useEffect(() => {
    const isEmpty = (filters: any): boolean => {
      return Object.values(filters).every((array: any) => array.length === 0);
    };
    if (!isEmpty(selectedFilterIds)) {
      fetchProductListFunc();
    }
  }, [selectedFilterIds]);

  useEffect(() => {
    if (debouncedPriceRange?.to !== 0) {
      const fetchFilteredProducts = async (
        type_id?: any,
        category_id?: any,
        variant_id?: any
      ) => {
        dispatch(setLoading(true));
        const locationData = location?.state;

        const categoryIds = category_id
          ? [category_id]
          : tyreType?.predefined_id
          ? [tyreType?.predefined_id]
          : [];

        const payload = {
          make_ids: locationData?.brand?.code
            ? [locationData?.brand?.code]
            : selectedFilterIds?.make_ids,
          model_ids: locationData?.model?.code
            ? [locationData?.model?.code]
            : selectedFilterIds?.model_ids,
          ...selectedFilterIds,
          variant_ids: locationData?.variant?.code
            ? [locationData?.variant?.code]
            : variant_id
            ? variant_id
            : [variant],
          brand_ids: locationData?.predefined_id
            ? [locationData?.predefined_id]
            : selectedFilterIds?.brand_ids,
          sort_by: selectedSortBy ? selectedSortBy.code : null,
          page_number: pageNumber,
          page_size: rowsPerPageOption,
          user_id: userId,
          product_type_id: type_id ? type_id : productType?.predefined_id,
          category_ids: categoryIds.length > 0 ? categoryIds : [],
          // city_id: cityId ? cityId : null
          price_range_from:
            debouncedPriceRange.from === 0 ? null : debouncedPriceRange.from,
          price_range_to:
            debouncedPriceRange.to === 0 ? null : debouncedPriceRange.to,
        };

        const response = await getProductList(payload);
        const countResponse: any = await getProductsListCount(payload);
        if (response.status === 200 || response.status === 201) {
          dispatch(setLoading(false));
          setBikeTyres(response?.data);
          setRecords(countResponse?.data?.count);
        }
      };

      fetchFilteredProducts();
    }
  }, [debouncedPriceRange]);

  const onCategoryChange = (e: any, categoryType: any) => {
    let updatedSelectedIds = selectedFilterIds[categoryType]
      ? [...selectedFilterIds[categoryType]]
      : [];

    if (e.checked) {
      // Add the selected value if checked
      updatedSelectedIds.push(e.value.code);
    } else {
      // Remove the selected value if unchecked
      updatedSelectedIds = updatedSelectedIds.filter(
        (id: any) => id !== e.value.code
      );
    }

    setSelectedFilterIds((prevState: any) => ({
      ...prevState,
      [categoryType]: updatedSelectedIds,
    }));
  };

  const toggleVehicleTypeList = () => {
    setIsVehicleTypeVisible(!isVehicleTypeVisible);
  };

  const toggleTypeBrandTabList = () => {
    setIsTypeBrandVisible(!isTypeBrandVisible);
  };
  const toggleSearchByVehicle = () => {
    setIsSearchByVehicle(!isSearchByVehicle);
  };
  const togglePrice = () => {
    setIsPriceVisible(!isPriceVisible);
  };
  const toggleTyreType = () => {
    setIsTyreTypeVisible(!isTyreTypeVisible);
  };
  const toggleTyrePosition = () => {
    setIsTyrePositionVisible(!isTyrePositionVisible);
  };
  const toggleTyreSize = () => {
    setIsTyreSizeVisible(!isTyreSizeVisible);
  };

  const sortByOptions = [
    // { name: "Recommended", code: "NY" },
    { name: "Popular", code: "POPULAR" },
    { name: "Price Low to High", code: "ASC" },
    { name: "Price High to Low", code: "DESC" },
  ];

  const handlePopularProdsTabSelect = (
    index: number,
    lastIndex: number,
    event: Event
  ) => {
    setPopularProdsTabIndex(index);
  };

  const getProductListWithPayload = async (payload: any) => {
    const response = await getProductList(payload);
    const countResponse: any = await getProductsListCount(payload);
    if (response.status === 200 || response.status === 201) {
      dispatch(setLoading(false));
      setBikeTyres(response?.data);
      setRecords(countResponse?.data?.count);
    }
    return response;
  };

  const removeTag = (tagType: any) => {
    setFilters((prevFilters: any) => {
      const newFilters = { ...prevFilters };
      delete newFilters[tagType];
      return newFilters;
    });

    if (location?.state && location?.state[tagType]) {
      // Create a copy of the location state object
      const updatedLocationState = { ...location?.state };
      // Delete the property from the location state
      delete updatedLocationState[tagType];
      // Update the location state
      location.state = updatedLocationState;
    }
  };

  const clearAllTags = () => {
    // setFilters({});
    location.state.variant = null;
    location.state.model = null;
    location.state.make = null;

    setFilters((prevData: any) => ({
      ...prevData,
      brand: null,
      model: null,
      variant: null,
    }));
    setBrand(null);
    setMake(null);
    setModel(null);
    setVariant(null);
    setSelectedFilterIds((prevState: any) => ({
      ...prevState,
      make_ids: [],
      model_ids: [],
      variant_ids: [],
    }));
  };

  const predefinedImgArr = [carSuvImg, bikeImg, threeWheelerImg, commercialImg];

  const handlePriceSliderChange = (e: any) => {
    const newPriceValue = e.value;
    setPriseSliderValue(newPriceValue);
    setPriceRange((prev) => ({ ...prev, to: newPriceValue }));

    clearTimeout(debounceTimerRef.current);
    debounceTimerRef.current = setTimeout(() => {
      setDebouncedPriceRange({ from: priceRange.from, to: newPriceValue });
    }, 500);
  };

  const handleWidthChange = (e: any) => {
    setSelectedWidth(e.value);
    if (!e.value) {
      return setSelectedFilterIds((prevState: any) => ({
        ...prevState,
        width_ids: [],
      }));
    }
    setSelectedFilterIds((prevState: any) => ({
      ...prevState,
      width_ids: [e?.value],
    }));
  };

  const handleAspectChange = (e: any) => {
    setSelectedAspect(e.value);
    if (!e.value) {
      return setSelectedFilterIds((prevState: any) => ({
        ...prevState,
        aspect_ratio_ids: [],
      }));
    }
    setSelectedFilterIds((prevState: any) => ({
      ...prevState,
      aspect_ratio_ids: [e?.value],
    }));
  };

  const handleRimChange = (e: any) => {
    setSelectedRim(e.value);
    if (!e.value) {
      return setSelectedFilterIds((prevState: any) => ({
        ...prevState,
        rim_ids: [],
      }));
    }
    setSelectedFilterIds((prevState: any) => ({
      ...prevState,
      rim_ids: [e?.value],
    }));
  };

  const handleViewMoreBrands = () => {
    setVisibleBrandsCount((prevCount) => prevCount + 4);
  };

  const onPageChange = (e: any) => {
    const newPageNumber = e.first / e.rows + 1;
    setPageNumber(newPageNumber);
    // setCurrentPage(newPageNumber);
    setRowsPerPageOption(e.rows);
  };

  const paginatorTemplate = {
    layout:
      "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",

    CurrentPageReport: (options: any) => {
      return (
        <span
          style={{
            color: "#6c757d",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
            display: "block",
          }}
        >
          {options.first} to {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  const handleVariantChange = (e: any) => {
    if (e.target.value == undefined) {
      removeTag("variant");
      setVariant(null);
      fetchProductListFunc(null, null, null);
      return;
    }
    setVariant(e.target.value);
    const currentMake = makes?.find((m: any) => m.code === make);
    const currentModel = models?.find((m: any) => m.code === model);
    const currentVariant = variants?.find(
      (v: any) => v.code === e.target.value
    );

    const hasMakeChanged = currentMake?.code !== filters?.brand?.code;
    const hasModelChanged = currentModel?.code !== filters?.model?.code;
    const hasVariantChanged = currentVariant?.code !== filters?.variant?.code;

    if (hasMakeChanged || hasModelChanged || hasVariantChanged) {
      setFilters((prevFilters: any) => ({
        ...prevFilters,
        brand: currentMake,
        model: currentModel,
        variant: currentVariant,
      }));

      const variantIds = currentVariant ? [currentVariant.code] : [];

      fetchProductListFunc(null, null, variantIds);
    }
  };

  const handleTabSelect = (index: number, lastIndex: number, event: Event) => {
    event.preventDefault();
    // event.stopPropagation();
    const newVehicleType: any = typesPreDefinedState[index];
    // setTyreType((event.target as Element).textContent);
    navigate(location.pathname, {
      replace: true,
      state: {
        ...location.state,
        tyreType: newVehicleType,
      },
    });
    setTyreType(newVehicleType);
    dispatch(setTabIndex(index));
    // setBrands(null);
    setModels(null);
    setVariants(null);
    setBrand(null);
    setModel(null);
    setVariant(null);
    dispatch(setLoading(true));
    fetchProductListFunc(null, newVehicleType?.predefined_id);
    fetchSubPredefined(typesPreDefinedState, newVehicleType);
  };

  return (
    <>
      {loading && <div className="loading-overlay">Loading...</div>}
      <div className="product-list-wrapper">
        <div className="product-list-title-wrapper">
          <Container>
            <div className="breadcrumbs-wrapper">
              <BreadCrumbs />
            </div>
            <div className="product-list-title">
              {location?.state?.code == "TYRE" ? "Tyres" : "Batteries"}{" "}
              {!location?.state?.code ?? "Tyres"} In India
            </div>
            <div className="product-list-tag-sort-wrapper">
              <div className="product-list-tags-wrapper">
                <div className="filter-tags">
                  {filters?.brand && (
                    <div className="tag">
                      <span className="tag-label">{filters?.brand.name}</span>
                      <button
                        className="tag-close"
                        onClick={() => removeTag("brand")}
                      >
                        x
                      </button>
                    </div>
                  )}
                  {filters?.model && (
                    <div className="tag">
                      <span className="tag-label">{filters?.model.name}</span>
                      <button
                        className="tag-close"
                        onClick={() => removeTag("model")}
                      >
                        x
                      </button>
                    </div>
                  )}
                  {filters?.productByState && (
                    <div className="tag">
                      <span className="tag-label">
                        {filters?.productByState}
                      </span>
                      <button
                        className="tag-close"
                        onClick={() => removeTag("productByState")}
                      >
                        x
                      </button>
                    </div>
                  )}
                  {filters?.tyreType && (
                    <div className="tag">
                      <span className="tag-label">
                        {filters?.tyreType?.name}
                      </span>
                      <button
                        className="tag-close"
                        onClick={() => removeTag("tyreType")}
                      >
                        x
                      </button>
                    </div>
                  )}
                  {filters?.variant && (
                    <div className="tag">
                      <span className="tag-label">{filters?.variant.name}</span>
                      <button
                        className="tag-close"
                        onClick={() => removeTag("variant")}
                      >
                        x
                      </button>
                    </div>
                  )}
                  <button className="clear-all" onClick={clearAllTags}>
                    Clear All
                  </button>
                </div>
              </div>
              <div className="product-list-sort-by-wrapper">
                <Dropdown
                  value={selectedSortBy}
                  onChange={(e) => setSelectedSortBy(e.value)}
                  options={sortByOptions}
                  optionLabel="name"
                  showClear
                  placeholder="Sort By: "
                  className="sort-by-dropdown"
                />
              </div>
            </div>
          </Container>
        </div>
        <Container className="products-list-tab">
          <Tabs
            // onSelect={handlePopularProdsTabSelect}
            onSelect={(index: any, lastIndex: number, event: Event) =>
              handleTabSelect(index, lastIndex, event)
            }
          >
            <div className="product-list-container">
              <div className="product-list-left-wrapper">
                <div className="vehicle-type-wrapper">
                  <div
                    className="vehicle-type-title"
                    onClick={toggleVehicleTypeList}
                  >
                    Vehicle Type
                    <MdKeyboardArrowDown
                      className={isVehicleTypeVisible ? "rotate-arrow" : ""}
                    />
                  </div>
                  <TabList
                    className={`${
                      isVehicleTypeVisible
                        ? "tab-list-enter-active"
                        : "tab-list-enter"
                    } product-list-vehicle-type`}
                  >
                    <div className="internal-popular-products-tab flex w-full justify-content-center align-item-center">
                      <div className="product-list-tabs">
                        {typesPreDefinedState?.map(
                          (types: any, index: number) => {
                            return (
                              <Tab>
                                <Image
                                  src={
                                    tabIndex === index
                                      ? types?.active_thumbnail
                                      : types?.thumbnail
                                  }
                                  className="tyre-type-images"
                                />
                                {types.name}
                              </Tab>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </TabList>
                </div>
                <div className="type-brand-wrapper type-size-container">
                  <div className="type-brand-title" onClick={toggleTyreSize}>
                    Vehicle Details
                    <MdKeyboardArrowDown
                      className={isTyreSizeVisible ? "rotate-arrow" : ""}
                    />
                  </div>
                  <div
                    className={` tyre-size-content ${
                      isTyreSizeVisible
                        ? "tab-list-enter-active"
                        : "tab-list-enter"
                    }`}
                  >
                    <div className="tyre-size-dropdowns">
                      <Dropdown
                        filter
                        value={make}
                        onChange={handleSetMake}
                        options={makes}
                        optionValue="code"
                        optionLabel="name"
                        showClear
                        placeholder="Select Make"
                        className="tyre-size-dropdown"
                      />
                      <Dropdown
                        filter
                        value={model}
                        onChange={handleSetModel}
                        options={models}
                        optionValue="code"
                        optionLabel="name"
                        showClear
                        placeholder="Select Model"
                        className="tyre-size-dropdown"
                      />
                      {location?.state?.code == "TYRE" && (
                        <Dropdown
                          filter
                          value={variant}
                          onChange={(e: any) => handleVariantChange(e)}
                          options={variants}
                          optionValue="code"
                          optionLabel="name"
                          showClear
                          placeholder="Select Variant"
                          className="tyre-size-dropdown"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="type-brand-wrapper type-brands-container">
                  <div
                    className="type-brand-title"
                    onClick={toggleTypeBrandTabList}
                  >
                    Tyres Brand
                    <MdKeyboardArrowDown
                      className={isTypeBrandVisible ? "rotate-arrow" : ""}
                    />
                  </div>
                  <div
                    className={` type-brands-content ${
                      isTypeBrandVisible
                        ? "tab-list-enter-active"
                        : "tab-list-enter"
                    }`}
                  >
                    <span className="p-input-icon-left type-brand-search-box">
                      <i className="pi pi-search" />
                      <InputText
                        value={brandSearchTerm}
                        onChange={(e) => setBrandSearchTerm(e.target.value)}
                        placeholder="Search"
                      />
                    </span>
                    <div className="type-brands-checkboxes">
                      {filteredBrands
                        ?.slice(0, visibleBrandsCount)
                        .map((brand: any) => {
                          return (
                            <div
                              key={brand.code}
                              className="flex align-items-center type-brand-checkbox single-checkboxes"
                            >
                              <Checkbox
                                inputId={brand.code}
                                name="brand"
                                value={brand}
                                onChange={(e) =>
                                  onCategoryChange(e, "brand_ids")
                                }
                                checked={selectedFilterIds?.brand_ids?.includes(
                                  brand?.code
                                )}
                              />
                              <label htmlFor={brand.code} className="ml-2">
                                {brand?.name}
                              </label>
                            </div>
                          );
                        })}
                      {filteredBrands &&
                        filteredBrands.length > visibleBrandsCount && (
                          <div
                            className="type-brand-viewmore"
                            onClick={handleViewMoreBrands}
                          >
                            View More
                          </div>
                        )}
                      {/* <div className="type-brand-viewmore">View More</div> */}
                    </div>
                  </div>
                </div>
                {/* <div className="type-brand-wrapper type-model-container">
                  <div
                    className="type-brand-title"
                    onClick={toggleSearchByVehicle}
                  >
                    Search By Vehicle Brand OR Model
                    <MdKeyboardArrowDown
                      className={isSearchByVehicle ? "rotate-arrow" : ""}
                    />
                  </div>
                  <div
                    className={` type-model-content ${isSearchByVehicle
                      ? "tab-list-enter-active"
                      : "tab-list-enter"
                      }`}
                  >
                    <span className="p-input-icon-left type-brand-search-box">
                      <i className="pi pi-search" />
                      <InputText
                        value={makeSearchTerm}
                        onChange={(e) => setMakeSearchTerm(e.target.value)}
                        placeholder="Search" />
                    </span>
                    <div className="type-brands-checkboxes">
                      {filteredModels?.map((model) => {
                        return (
                          <div
                            key={model.code}
                            className="flex align-items-center type-brand-checkbox single-checkboxes"
                          >
                            <Checkbox
                              inputId={model.code}
                              name="model"
                              value={model}
                              onChange={(e) => onCategoryChange(e, 'model_ids')}
                              checked={selectedFilterIds.model_ids.includes(model.code)}
                            />
                            <label htmlFor={model.code} className="ml-2">
                              {model.name}
                            </label>
                          </div>
                        );
                      })}
                      <div className="type-brand-viewmore">View More</div>
                    </div>
                  </div>
                </div> */}
                {location?.state?.code == "TYRE" && (
                  <div className="type-brand-wrapper type-size-container">
                    <div className="type-brand-title" onClick={toggleTyreSize}>
                      Tyre Sizes
                      <MdKeyboardArrowDown
                        className={isTyreSizeVisible ? "rotate-arrow" : ""}
                      />
                    </div>
                    <div
                      className={` tyre-size-content ${
                        isTyreSizeVisible
                          ? "tab-list-enter-active"
                          : "tab-list-enter"
                      }`}
                    >
                      <div className="tyre-size-dropdowns">
                        <Dropdown
                          value={selectedWidth}
                          onChange={handleWidthChange}
                          options={widthOptions}
                          optionValue="code"
                          optionLabel="name"
                          showClear
                          placeholder="Width"
                          className="tyre-size-dropdown"
                        />
                        <Dropdown
                          value={selectedAspect}
                          onChange={handleAspectChange}
                          options={aspectOptions}
                          optionValue="code"
                          optionLabel="name"
                          showClear
                          placeholder="Aspect"
                          className="tyre-size-dropdown"
                        />
                        <Dropdown
                          value={selectedRim}
                          onChange={handleRimChange}
                          options={rimOptions}
                          optionValue="code"
                          optionLabel="name"
                          showClear
                          placeholder="Rim"
                          className="tyre-size-dropdown"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="type-brand-wrapper type-price-container">
                  <div
                    className="type-brand-title"
                    onClick={toggleSearchByVehicle}
                  >
                    Price
                    <MdKeyboardArrowDown
                      className={isSearchByVehicle ? "rotate-arrow" : ""}
                    />
                  </div>
                  <div
                    className={` tyre-size-content ${
                      isSearchByVehicle
                        ? "tab-list-enter-active"
                        : "tab-list-enter"
                    }`}
                  >
                    <div className="type-brands-checkboxes">
                      <div className="price-from_and_to-wrapper">
                        <div className="price-from_and_to">
                          <span>₹{priceRange.from.toLocaleString()}</span>
                          <span>₹{priseSliderValue.toLocaleString()}</span>
                        </div>
                        <Slider
                          value={priseSliderValue}
                          onChange={handlePriceSliderChange}
                          className="w-full"
                          min={0}
                          max={50000}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {location?.state?.code == "TYRE" && (
                  <div className="type-brand-wrapper type-type-container">
                    <div className="type-brand-title" onClick={toggleTyreType}>
                      Tyre Type
                      <MdKeyboardArrowDown
                        className={isTyreTypeVisible ? "rotate-arrow" : ""}
                      />
                    </div>
                    <div
                      className={` tyre-size-content ${
                        isTyreTypeVisible
                          ? "tab-list-enter-active"
                          : "tab-list-enter"
                      }`}
                    >
                      <span className="p-input-icon-left type-brand-search-box">
                        <i className="pi pi-search" />
                        <InputText
                          placeholder="Search"
                          value={typeSearchTerm}
                          onChange={(e) => setTypeSearchTerm(e.target.value)}
                        />
                      </span>
                      <div className="type-brands-checkboxes">
                        {filteredTypes?.map((tyreType: any) => {
                          return (
                            <div
                              key={tyreType.code}
                              className="flex align-items-center type-brand-checkbox single-checkboxes"
                            >
                              <Checkbox
                                inputId={tyreType.code}
                                name="tyreType"
                                value={tyreType}
                                onChange={(e) =>
                                  onCategoryChange(e, "tyre_type_ids")
                                }
                                checked={selectedFilterIds?.tyre_type_ids?.includes(
                                  tyreType?.code
                                )}
                              />
                              <label htmlFor={tyreType.code} className="ml-2">
                                {tyreType?.name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                {location?.state?.code == "TYRE" && (
                  <div className="type-brand-wrapper type-type-container">
                    <div
                      className="type-brand-title"
                      onClick={toggleTyrePosition}
                    >
                      Tyre Position
                      <MdKeyboardArrowDown
                        className={isTyrePositionVisible ? "rotate-arrow" : ""}
                      />
                    </div>
                    <div
                      className={` tyre-size-content ${
                        isTyrePositionVisible
                          ? "tab-list-enter-active"
                          : "tab-list-enter"
                      }`}
                    >
                      <span className="p-input-icon-left type-brand-search-box">
                        <i className="pi pi-search" />
                        <InputText
                          placeholder="Search"
                          value={typeSearchTerm}
                          onChange={(e) => setTypeSearchTerm(e.target.value)}
                        />
                      </span>
                      <div className="type-brands-checkboxes">
                        {filteredPositions?.map((tyrePosition: any) => {
                          return (
                            <div
                              key={tyrePosition.code}
                              className="flex align-items-center type-brand-checkbox single-checkboxes"
                            >
                              <Checkbox
                                inputId={tyrePosition.code}
                                name="tyrePosition"
                                value={tyrePosition}
                                onChange={(e) =>
                                  onCategoryChange(e, "tyer_ps_ids")
                                }
                                checked={selectedFilterIds?.tyer_ps_ids?.includes(
                                  tyrePosition?.code
                                )}
                              />
                              <label
                                htmlFor={tyrePosition.code}
                                className="ml-2"
                              >
                                {tyrePosition?.name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <TabPanel>
              <div className="product-container">
                {bikeTyres.length === 0 && (
                  <div className="no-product-found">
                    <Image
                      src={noProductFoundImage}
                      alt="No Product Found"
                      width={"200"}
                      height={"200"}
                      className="no-product-found-image"
                    />
                    <div className="no-product-found-image-text">
                      {userRole === corporateUserRole
                        ? "You are not eligible for other products"
                        : "No Product Found"}
                    </div>
                  </div>
                )}
                <div className="product-wrapper">
                  {bikeTyres?.map((product: any, index: any) => (
                    <>
                      <Product key={index} product={product} />
                      {/* {hoveredProduct === product && <Popup product={product} />} */}
                    </>
                  ))}
                </div>
                <Paginator
                  first={(pageNumber - 1) * rowsPerPageOption}
                  rows={rowsPerPageOption}
                  totalRecords={records}
                  rowsPerPageOptions={[10, 20, 30, records]}
                  onPageChange={onPageChange}
                  template={paginatorTemplate}
                />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="product-container">
                {bikeTyres.length === 0 && (
                  <div className="no-product-found">
                    <Image
                      src={noProductFoundImage}
                      alt="No Product Found"
                      width={"200"}
                      height={"200"}
                      className="no-product-found-image"
                    />
                    <div className="no-product-found-image-text">
                      No Product Found
                    </div>
                  </div>
                )}
                <div className="product-wrapper">
                  {bikeTyres?.map((product: any, index: any) => (
                    <>
                      <Product key={index} product={product} />
                      {/* {hoveredProduct === product && <Popup product={product} />} */}
                    </>
                  ))}
                </div>
                <Paginator
                  first={(pageNumber - 1) * rowsPerPageOption}
                  rows={rowsPerPageOption}
                  totalRecords={records}
                  rowsPerPageOptions={[10, 20, 30, records]}
                  onPageChange={onPageChange}
                  template={paginatorTemplate}
                />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="product-container">
                {bikeTyres.length === 0 && (
                  <div className="no-product-found">
                    <Image
                      src={noProductFoundImage}
                      alt="No Product Found"
                      width={"200"}
                      height={"200"}
                      className="no-product-found-image"
                    />
                    <div className="no-product-found-image-text">
                      No Product Found
                    </div>
                  </div>
                )}
                <div className="product-wrapper">
                  {bikeTyres?.map((product: any, index: any) => (
                    <>
                      <Product key={index} product={product} />
                      {/* {hoveredProduct === product && <Popup product={product} />} */}
                    </>
                  ))}
                </div>
                <Paginator
                  first={(pageNumber - 1) * rowsPerPageOption}
                  rows={rowsPerPageOption}
                  totalRecords={records}
                  rowsPerPageOptions={[10, 20, 30, records]}
                  onPageChange={onPageChange}
                  template={paginatorTemplate}
                />
              </div>
            </TabPanel>
          </Tabs>
        </Container>
      </div>
    </>
  );
};

export default ProductList;
