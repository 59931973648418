import React, { useEffect, useState } from 'react'
import './MyAppointments.css'
import useAppointmentAPI from '../../../api/useAppointementAPI'
import { Button } from 'primereact/button';
import { CiClock2, CiLocationOn } from 'react-icons/ci';
import GoToLocationImg from '../../../assets/images/go-to-location-img.png'
import { Image } from 'primereact/image';
import calenderImg from '../../../assets/images/calender.png'
import { Dialog } from 'primereact/dialog';
import miniDot from "../../../assets/images/mini_dot.png";
import { Paginator } from 'primereact/paginator';
import { setLoading } from '../../../redux/loadingSlice';
import { useDispatch, useSelector } from 'react-redux';

const MyAppointment = () => {

  const { getAppointmentList, getAppointmentListCount } = useAppointmentAPI();

  const [userId, setUserId] = useState(null) as any;
  const [appointmentList, setAppointmentList] = useState([]) as any;
  const [appointmentState, setAppointmentState] = useState([]) as any;
  const [viewDetailsDialogState, setViewDetailsDialogState] = useState(false) as any;
  const [isShowPrice, setIsShowPrice] = useState(false) as any;
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPageOption, setRowsPerPageOption] = useState(10);
  const [records, setRecords] = useState(20);

  const loading = useSelector((state: any) => state.loading);
  const dispatch = useDispatch();

  const fetchAppointmentList = async () => {
    const payload = {
      customer_id: userId, 
      page_number: pageNumber,
      page_size: rowsPerPageOption
    }

    const response: any = await getAppointmentList(payload);
    const responseCount: any = await getAppointmentListCount(payload);

    if (response.status === 200) {
      dispatch(setLoading(false));
      setAppointmentList(response?.data)
      setRecords(responseCount?.data?.count)
    }else{
      dispatch(setLoading(false));
    }
  }

  useEffect(() => {
    dispatch(setLoading(true));
    const authTokens = JSON.parse(localStorage.getItem('authTokens') || '{}');
    if (authTokens?.user?.id) {
      setUserId(authTokens?.user?.id);
    }

    if (authTokens?.user?.is_show_price === true) {
      setIsShowPrice(true);
    }
    if (userId) {
      fetchAppointmentList()
    }
  }, [userId, pageNumber, rowsPerPageOption])

  const viewAppointmentFunc = (appointment: any) => {
    setAppointmentState(appointment?.appointment_items);
    setViewDetailsDialogState(true);
  }

  const getDirectionFunc = (lat: any, lng: any) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.rel = "noopener noreferrer";

    // Simulate a click on the link
    document.body.appendChild(link);
    link.click();

    // Clean up the DOM
    document.body.removeChild(link);
  };

  const onPageChange = (e: any) => {
    const newPageNumber = e.first / e.rows + 1;
    setPageNumber(newPageNumber);
    // setCurrentPage(newPageNumber);
    setRowsPerPageOption(e.rows);
  };

  const paginatorTemplate = {
    layout:
      "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",

    CurrentPageReport: (options: any) => {
      return (
        <span
          style={{
            color: "#6c757d",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
            display: "block",
          }}
        >
          {options.first} to {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <div className="my-appointment-wrapper">
      {loading && <div className="loading-overlay">Loading...</div>}
      <div className="my-appointment-text">My Appointment</div>
      <div className="my-appointment-list-wrapper">
        {appointmentList?.length === 0 &&(
          <div className="no-appointments">No Appointments Are Available</div>)
        }
        {appointmentList?.map((appointment: any) => (
          <div className="appointment-card" key={appointment?.appointment_id}>
            <div className={
              `appointment-status ${appointment?.status?.name === 'Appointment Pending' ? 'appointment-status-pending' :
                appointment?.status?.name === 'Ongoing Appointment' ? 'appointment-status-ongoing' :
                  appointment?.status?.name === 'Appointment Completed' ? 'appointment-status-complete' :
                    ''}`
            }>
              {appointment?.status?.name}
            </div>
            <div className="appointment-data-wrapper">
              <div className="appointment-data-container">
                <div className="appointment-id">Appointment ID: {appointment?.appointment_id}</div>
                <div className="appointment-date-time">
                  <Image src={calenderImg} className='calender-img' />
                  {appointment?.service_start_date} ({appointment?.service_start_time?.name})
                </div>
              </div>
              <div className="appointment-otp-wrapper">Service End OTP : {appointment?.appointment_otp || 'N/A'}</div>
            </div>
            <div className="appointment-location">
              <div className="appointment-location-wrapper">
                <div className="location-name">{appointment?.service_point?.service_point_name}</div>
                <div className="location-opens"><CiClock2 /> Opens At {appointment?.service_point?.service_point_open_time?.split('-')[0]}</div>
                <div className="location-address"><CiLocationOn /> <strong>Address:</strong> {appointment?.service_point?.service_point_address} {appointment?.service_point?.city?.name} {appointment?.service_point?.state?.name}</div>
                <div className="location-address"><CiLocationOn /> <strong>City:</strong> {appointment?.service_point?.city?.name}</div>
                <div className="location-address"><CiLocationOn /> <strong>State:</strong> {appointment?.service_point?.state?.name}</div>
              </div>
              <a href="#" className="go-to-location-wrapper">
                <Image src={GoToLocationImg} className='go-to-location-image' onClick={() =>
                            getDirectionFunc(appointment?.service_point?.lat, appointment?.service_point?.lng)
                          } />
              </a>
            </div>
            <div className="my-appointment-footer-buttons">
              {/* <Button className="cancel-appointment-button">Cancel Appointment</Button> */}
              {/* <Button className="cancel-appointment-button">Cancel Appointment</Button> */}
              <Button className="view-details-button" onClick={()=> viewAppointmentFunc(appointment)}>View Details</Button>
            </div>
          </div>
        ))}
        <Paginator
          first={(pageNumber - 1) * rowsPerPageOption}
          rows={rowsPerPageOption}
          totalRecords={records}
          rowsPerPageOptions={[5, 10, 25, records]}
          onPageChange={onPageChange}
          template={paginatorTemplate}
        />
      </div>
      <Dialog header="Appointment Details" visible={viewDetailsDialogState} style={{ width: '75vw' }} onHide={() => setViewDetailsDialogState(false)}>
      {appointmentState?.map((item: any) => (
          <div
            key={`order_${item.order_id}`}
            className="my-order-single-order-container"
          >
            <div className="my-order-single-order">
              <div className="items-container">
                <div className="item-header">
                  <span>Items</span>

                  <div className="item-header-buttons-wrapper">
                    <div
                      className={`item-header-status-indicator ${item?.status?.name
                        .toLowerCase()
                        .replace(/\s/g, "-")}`}
                    >
                      {item?.approval_status?.name}
                    </div>
                    {/* <Button
                      label="Download Invoice"
                      className="p-button-text p-button-plain item-header-download-invoice"
                    /> */}
                  </div>
                </div>
                <ul className="item-list">
                  {[item?.order_item]?.map((itm: any) => (
                    <li key={`order_${itm?.order_id}`} className="item">
                      <div className="item-title-wrapper">
                        <Image
                          src={itm?.product?.brand?.thumbnail}
                          alt="Yokohama Brand Logo"
                          className="item-title-image"
                        />
                        <div className="item-title-container">
                          <div className="item-name">
                            {itm?.product?.product_name || "Not Available"}
                          </div>
                        </div>
                      </div>
                      {/* <Image
                        src={miniDot}
                        alt="Mini Dot"
                        className="item-dot"
                      />
                      <div className="item-details">
                        {itm?.product?.product_rim || "Not Available"}
                      </div> */}
                      <Image
                        src={miniDot}
                        alt="Mini Dot"
                        className="item-dot"
                      />
                      {isShowPrice && (
                        <>
                          <div className="item-price">
                            Price: &#8377;
                            {itm?.product?.product_price || "Not Available"}
                          </div>
                          <Image
                            src={miniDot}
                            alt="Mini Dot"
                            className="item-dot"
                          />
                        </>
                      )}
                      <div className="item-quantity">
                        Quantity: {itm?.item_quantity || 0}
                      </div>
                      {isShowPrice && (
                        <Image
                          src={miniDot}
                          alt="Mini Dot"
                          className="item-dot"
                        />
                      )}
                      {isShowPrice && (
                        <div className="item-total">
                          &#8377;{itm?.total_amount || 0}
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* <div className="all-order-single-order">
                      <div className="all-orders-service-point-data">
                        <div className="all-orders-service-point-name">
                          {item?.service_point?.service_point_name}
                        </div>
                      </div>
                    </div> */}
            <div className="all-order-single-order">
              <div className="single-orders-data-2">
                <div className="single-orders-data-left">
                  <div className="order-id-container">
                    <div className="order-id-title">Order ID</div>
                    <div className="order-id-content">
                      Order: {item?.order_item?.order_id || "Not Available"}
                    </div>
                  </div>
                </div>
                <div className="single-orders-data-right">
                  <div className="order-id-container">
                    <div className="order-id-title">Order Date</div>
                    <div className="order-id-content">
                      {new Date(item?.order_item?.created_at).toLocaleDateString("en-IN", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }) || "Not Available"}
                    </div>
                  </div>
                  <div className="order-id-container">
                    {isShowPrice && (
                      <>
                        <div className="order-id-title">Total</div>
                        <div className="order-id-content">
                          {item?.order_item?.product?.product_price || "Not Available"}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="my-order-action-buttons">
              <Button
                className="cancel-item-button"
                onClick={() => {
                  handleAcceptReject(item?.order_id, "REJECTED");
                }}
              >
                Cancel Item
              </Button>
              <Button className="view-appointment-button">
                View Appointment
              </Button>
            </div> */}
          </div>
        ))}
      </Dialog>
    </div>
  )
}

export default MyAppointment