import React, { useEffect, useRef, useState } from "react";
import "./MyProfileDetails.css";
import { Image } from "primereact/image";

import userImg from "../../../assets/images/user.png";
import emailImg from "../../../assets/images/mail.png";
import phoneImg from "../../../assets/images/phone.png";
import companyImg from "../../../assets/images/company-building.png";
import departmentImg from "../../../assets/images/department.png";
import idCardImg from "../../../assets/images/id-card.png";
import useLoginAPI from "../../../api/useLogin";
import { Button } from "primereact/button";
import { FaEdit, FaRegEdit } from "react-icons/fa";
import { IoDocumentOutline } from "react-icons/io5";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/loadingSlice";

const MyProfileDetails = () => {
  const customerUserRole = "ROLE_CUSTOMER";

  const loading = useSelector((state: any) => state.loading);
  const dispatch = useDispatch();

  const { updateStaffById } = useLoginAPI();

  const toast = useRef<Toast>(null) as any;

  const [userDetailsData, setUserDetailsData] = useState({}) as any;
  const [editProfileDialogState, setEditProfileDialogState] = useState(
    false
  ) as any;
  const [userId, setUserId] = useState(null) as any;
  const [userRole, setUserRole] = useState([]) as any;
  const [editFormData, setEditFormData] = useState({
    fullName: "",
    gstinCompany: "",
    gstin: "",
  });
  const [errors, setErrors] = useState({
    fullName: null,
    gstinCompany: null,
    gstin: null,
  });


  const { getUserById } = useLoginAPI();

  const fetchUserDetailsFunc = async () => {
    const payload = {
      user_id: userId,
    };

    const response = await getUserById(payload);
    if (response.status === 200 || response.status === 201) {
      dispatch(setLoading(false));
      setUserDetailsData(response?.data);
    }else{
      dispatch(setLoading(false));
    }
  };

  const validateForm = () => {
    let formIsValid = true;
    let errors: any = {};

    if (!editFormData.fullName) {
      errors.fullName = "Full name is required";
      formIsValid = false;
    }

    if (userRole?.code === customerUserRole) {
      if (!editFormData.gstinCompany) {
        errors.gstinCompany = "Company name as per GSTIN is required";
        formIsValid = false;
      }

      if (!editFormData.gstin) {
        errors.gstin = "GSTIN is required";
        formIsValid = false;
      } else if (!/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(editFormData.gstin)) {
        errors.gstin = "Invalid GSTIN format";
        formIsValid = false;
      }
    }


    setErrors(errors);
    return formIsValid;
  };

  useEffect(() => {
    dispatch(setLoading(true));
    const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    console.log(authTokens,"authTokens");
    if (authTokens) {
      setUserId(authTokens?.user?.id);
      setUserRole(authTokens?.user?.roles[0]);
    }

    if (userId !== null) {
      fetchUserDetailsFunc();
    }
  }, [userId]);

  const handleFormChange = (e: any) => {
    const { name, value } = e.target;
    setEditFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleFormSubmit = async () => {
    if (validateForm()) {
      const payload = {
        user_id: userId,
        full_name: editFormData.fullName,
        gstin_company: editFormData.gstinCompany,
        gstin: editFormData.gstin,
      };
      const response = await updateStaffById(userId, payload);
      if (response.status === 200 || response.status === 201) {
        fetchUserDetailsFunc();
        setEditProfileDialogState(false);
        setEditFormData({
          fullName: "",
          gstinCompany: "",
          gstin: "",
        });
      } else {
        return toast?.current?.show({
          severity: "error",
          summary: "Failed",
          detail: "Failed to update Profile",
          life: 3000,
        });
      }
    }
  };

  const handleDialogHide = () => {
    setEditProfileDialogState(false);
  };

  const handleEditProfile = () => {
    // Populate the form with existing data when opening the edit dialog
    setEditFormData({
      fullName: userDetailsData?.full_name || "",
      gstinCompany: userDetailsData?.gstin_company || "",
      gstin: userDetailsData?.gstin || ""
    });
    setEditProfileDialogState(true);
  };

  return (
    <>
      {loading && <div className="loading-overlay">Loading...</div>}
      <Toast ref={toast} />
      <div className="my-profiles-details-wrapper">
        <div className="personal-info-wrapper">
          <div className="profile-title-wrapper">
            <div className="personal-info-title">Personal Information</div>
            <Button
              onClick={handleEditProfile}
              className="personal-info-edit-btn"
            >
              <FaEdit /> Edit
            </Button>
          </div>
          <div className="personal-info-data">
            <div className="personal-info-data-item-wrapper">
              <Image
                src={userImg}
                className="personal-info-data-img personal-info-data-user"
              />
              <div className="personal-info-data-item-container">
                <div className="personal-info-data-item-title">Name</div>
                <div className="personal-info-data-item-value">
                  {userDetailsData?.full_name ?? "Not Available"}
                </div>
              </div>
            </div>
            <div className="personal-info-data-item-wrapper">
              <Image
                src={emailImg}
                className="personal-info-data-img personal-info-data-user"
              />
              <div className="personal-info-data-item-container">
                <div className="personal-info-data-item-title">Email</div>
                <div className="personal-info-data-item-value">
                  {userDetailsData?.email ?? "Not Available"}
                </div>
              </div>
            </div>
            <div className="personal-info-data-item-wrapper">
              <Image
                src={phoneImg}
                className="personal-info-data-img personal-info-data-user"
              />
              <div className="personal-info-data-item-container">
                <div className="personal-info-data-item-title">
                  Phone Number
                </div>
                <div className="personal-info-data-item-value">
                  {userDetailsData?.phone ?? "Not Available"}
                </div>
              </div>
            </div>
            {userRole?.code === customerUserRole && <>
              <div className="personal-info-data-item-wrapper">
                <Image
                  src={companyImg}
                  className="personal-info-data-img personal-info-data-user"
                />
                <div className="personal-info-data-item-container">
                  <div className="personal-info-data-item-title">
                    Company Name As Per GST
                  </div>
                  <div className="personal-info-data-item-value">
                    {userDetailsData?.gstin_company ?? "Not Available"}
                  </div>
                </div>
              </div>
              <div className="personal-info-data-item-wrapper">
                <IoDocumentOutline />
                <div className="personal-info-data-item-container">
                  <div className="personal-info-data-item-title">GSTIN</div>
                  <div className="personal-info-data-item-value">
                    {userDetailsData?.gstin ?? "Not Available"}
                  </div>
                </div>
              </div>
            </>}
          </div>
        </div>
        {userRole?.code !== customerUserRole &&
          <div className="personal-info-wrapper personal-info-wrapper-two">
            <div className="personal-info-title">Corporate/Fleet Owner</div>
            <div className="personal-info-data">
              <div className="personal-info-data-item-wrapper">
                <Image
                  src={companyImg}
                  className="personal-info-data-img personal-info-data-user"
                />
                <div className="personal-info-data-item-container">
                  <div className="personal-info-data-item-title">
                    Company Name
                  </div>
                  <div className="personal-info-data-item-value">
                    {userDetailsData?.company?.organization_name ??
                      "Not Available"}
                  </div>
                </div>
              </div>
              <div className="personal-info-data-item-wrapper">
                <Image
                  src={departmentImg}
                  className="personal-info-data-img personal-info-data-user"
                />
                <div className="personal-info-data-item-container">
                  <div className="personal-info-data-item-title">Department</div>
                  <div className="personal-info-data-item-value">
                    {userDetailsData?.department?.department_name ??
                      "Not Available"}
                  </div>
                </div>
              </div>
              <div className="personal-info-data-item-wrapper">
                <Image
                  src={idCardImg}
                  className="personal-info-data-img personal-info-data-user"
                />
                <div className="personal-info-data-item-container">
                  <div className="personal-info-data-item-title">Designation</div>
                  <div className="personal-info-data-item-value">
                    {userDetailsData?.designation?.name ?? "Not Available"}
                  </div>
                </div>
              </div>
            </div>
          </div>}
      </div>
      <Dialog
        header="Edit Profile"
        visible={editProfileDialogState}
        style={{ width: "50vw" }}
        onHide={handleDialogHide}
      >
        <div className="my-profile-form">
          <div className="full-name-input-wrapper edit-form-input-wrapper">
            <InputText
              name="fullName"
              value={editFormData.fullName}
              onChange={(e: any) => handleFormChange(e)}
              placeholder="Full Name"
            />
            {errors?.fullName && (
              <small className="p-error">{errors?.fullName}</small>
            )}
          </div>
          {userRole?.code === customerUserRole && <>
            <div className="gstin-company-input-wrapper edit-form-input-wrapper">
              <InputText
                name="gstinCompany"
                value={editFormData?.gstinCompany}
                onChange={(e: any) => handleFormChange(e)}
                placeholder="Company Name As Per GSTIN"
              />
              {errors?.gstinCompany && (
                <small className="p-error">{errors?.gstinCompany}</small>
              )}
            </div>
            <div className="gstin-input-wrapper edit-form-input-wrapper">
              <InputText
                name="gstin"
                value={editFormData?.gstin}
                onChange={(e: any) => handleFormChange(e)}
                placeholder="GSTIN"
              />
              {errors.gstin && <small className="p-error">{errors?.gstin}</small>}
            </div>
          </>}
          <Button label="Save" onClick={handleFormSubmit} />
        </div>
      </Dialog>
    </>
  );
};

export default MyProfileDetails;
