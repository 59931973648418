import React from "react";
import "./PrivacyPolicy.css";
import { Container } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-wrapper">
      <Container>
        <h1 style={{ color: "#6C17A4", marginTop: "20px" }}>Privacy Policy</h1>
        <div>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              We value your trust. In order to honour that trust, Junction of
              Tyres Private Limited (‘MechMiles’) adheres to ethical standards
              in gathering, using, and safeguarding any information you provide.
              This Privacy &amp; IPR policy ('Policy') governs your use of
              www.MechMiles.com, including any other website operated by
              MechMiles ('Website') and the other associated, including, mobile
              applications (‘Application’), products, websites and services
              managed by the Company. Please read this Policy carefully before
              using the Application/Website (‘Platform’), our services and
              products, along with the Terms of Use provided on the Application
              and the Website.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              YOUR USE OF THE WEBSITE, APPLICATION, OR SERVICES IN CONNECTION
              WITH THE PLATFORM OR PRODUCTS ('SERVICES'), OR REGISTRATIONS WITH
              US THROUGH ANY MODES OR USAGE OF ANY PRODUCTS INCLUDING THROUGH SD
              CARDS, TABLETS OR OTHER STORAGE/TRANSMITTING DEVICE SHALL SIGNIFY
              YOUR ACCEPTANCE OF THIS POLICY AND YOUR AGREEMENT TO BE LEGALLY
              BOUND BY THE SAME. IF YOU DO NOT AGREE WITH THE TERMS OF THIS
              POLICY, DO NOT USE THE PLATFORM, OUR PRODUCTS OR AVAIL ANY OF OUR
              SERVICES.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              THIS PRIVACY POLICY HAS BEEN DRAFTED AND PUBLISHED IN ACCORDANCE
              WITH THE INFORMATION TECHNOLOGY ACT 2000 AND THE APPLICABLE RULES.
              THIS PRIVACY POLICY CONSTITUTES A LEGAL AGREEMENT BETWEEN YOU, AS
              A USER OF THE PLATFORM AND US, AS THE OWNER OF THE PLATFORM. YOU
              MUST BE A NATURAL PERSON WHO IS AT LEAST 18 YEARS OF AGE.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"0in"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: "20px",
                  fontFamily: "Poppins",
                  color: "#6C17A4",
                }}
              >
                PRIVACY POLICY:
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              User Information
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              The Platform obtains the information you provide when you
              register/subscribe for the Services or products. When you register
              with us, you provide us the following information your name, age,
              email address, location, phone number; transaction-related
              information, such as when you make purchases, respond to any
              offers, or download or use Applications from us; information you
              provide us when you contact us for help; information you enter
              into our system when using the Application/Services/products; the
              information obtained through GPS or other means, such as the
              geographical location collectively, ‘Information’.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              MechMiles collects information about you and your use of the
              Platform, and the devices used by you to access the Platform
              including; your activity on the platform, which would include your
              search history, your search inputs, pages viewed on the Platform,
              statistics on page views and the URLs with time stamps; device
              information including your IP address, and identifiers such as
              browser setting type, operating system, network information and
              device type settings including but not limited to crash reports,
              system activity, the date, time and referral URL of your request.
              In case any individual under the age of 18 years of age is
              utilizing the Platform, such usage will be undertaken only with
              the strict supervision and approval of a parent or a legal
              guardian or any other person having parental responsibility for
              the child, and MechMiles will not be responsible for the same at
              any time and in any manner whatsoever.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Use of your Personal Information
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: "#ffffff",
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              The Information collected by us shall be used for availing our
              Services and utilised for other functions including but not
              limited to render Services (as defined in Terms of Service);
              maintaining the Platform; to evaluate the quality and competence
              of our personnel;
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              to resolve any complaints, you may have and ensure that you
              receive the highest quality of Services; to coordinate for order
              delivery; notify you about changes to our Platform; send out
              system updates on the booked services, detecting, preventing and
              addressing technical issues;
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              improve user experience; analyse trends, conduct research,
              administer the Platform/Services and products, learn about each
              user's learning patterns and movements around the
              Platform/Services and products and gather demographic information
              and usage behaviour about our user base as a whole. Aggregated and
              individual, anonymised, and non-anonymized data may periodically
              be transmitted to external service providers to help us improve
              the Platform, products and our Services.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              We will share your information with third parties only in the ways
              that are described below in this Policy and use the individual
              data and behaviour patterns combined with personal information to
              provide you with personalised content.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Third parties provide certain services which we may use to analyse
              Information to personalise, drive insights and help us better your
              experience or reach out to you with more value-added applications,
              products, information and Services. MechMiles will not be liable
              for the privacy practices of such third-party entities and
              encourages you to carefully read the privacy policies and
              procedures of such third parties.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              We do not sell, trade or rent your Information to any third party
              unless save and except in order to provide you with the Services
              or we have been expressly authorized by you either in writing or
              electronically to do so. We may at times provide aggregate
              statistics about our customers, traffic patterns, and related site
              information to reputable third parties. However, this information,
              when disclosed, will be in an aggregate form and does not contain
              any of your personally identifiable information. MechMiles will
              occasionally send email notices or contact you to communicate
              about our Services, products and benefits, as they are considered
              an essential part of the Services/products you have chosen. We may
              disclose Information: as required by law, such as to comply with a
              subpoena or similar legal process; to enforce applicable terms,
              including investigation of potential violations
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              thereof; when we believe in good faith that disclosure is
              necessary to protect our rights, protect your safety or the safety
              of others, investigate fraud, address security or technical issues
              or respond to a government request; with our trusted services
              providers who work on our behalf, do not have an independent use
              of the Information we disclose to them and have agreed to adhere
              to the terms set forth in this Policy; to protect against imminent
              harm to the rights, property or safety of the Platform/ MechMiles
              or its users or the public as required or permitted by law; with
              third-party service providers in order to personalise the
              Platform/Services/products for a better user experience and to
              perform behavioural analysis. We may share Information upon the
              merger or acquisition of MechMiles with another company. We shall
              transmit and transfer the Information upon acquisition or merger
              of MechMiles with another company.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              We follow a strict confidentiality policy with regard to the
              disclosure of confidential information to our employees or other
              personnel. There may be situations where we may disclose the
              confidential information only to those of our employees and other
              personnel on a need-to-know basis. Any breach of confidential
              information by the employees, personnel within the Company is
              dealt with stringently by us.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Transfer of Information
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Your information may be transferred to, and maintained on,
              computers located outside of your state, province, country or
              other governmental jurisdiction where the data protection laws may
              differ from those from your jurisdiction. Your consent to this
              Policy followed by your submission of such Information represents
              your agreement to that transfer. We will take all steps reasonably
              necessary to ensure that your Information is treated securely and
              in accordance with this Privacy Policy.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Your Rights
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              You can always choose not to provide the requested information to
              us; it will however result in you not availing certain features
              if, or the entire, of our Services. With respect to Information
              you will have the following rights/preferences: access, confirm,
              and review Information you may have provided; correct Information
              that may be inaccurate or irrelevant; deletion and erasure of your
              Information from the publicly available pages of the Platform;
              receive Information we hold about you in a portable format; object
              to or restrict any form of processing you may not be comfortable
              with; however, this will result in discontinuation of your use of
              the Services. In order to exercise these rights, please contact us
              on the email address info@MechMiles.com
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              If you want to withdraw your consent or raise any objection to the
              use of your Information for receiving any direct marketing
              information to which you previously opted-in, you can do so by
              contacting our customer support at the above-mentioned addresses.
              If you withdraw/object your consent, our use of your Information
              before you withdrew/objected shall still be lawful
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Third-Party Services
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              We may use your Information to send you promotional Information
              about third parties which, we think you may find interesting if
              you tell us that you wish this to happen. We shall not be
              responsible for any disclosure of Information due to unauthorised
              third-party access or other acts of third parties or acts or
              omissions beyond our reasonable control and you agree that you
              will not hold us responsible for any breach of security unless
              such breach has been caused as a direct result of our negligence
              or wilful default.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              We use support services of third-party platforms and/or companies
              to direct you to payment gateways when you opt to pay for our
              Services. Your financial information is collected, stored and
              retained by such third-party platforms. We and such third-party
              platforms undertake measures designed to provide a security level
              that is appropriate to the risks of processing your personal
              information and shall comply with applicable laws. However, you
              are requested to check and consent to the “Privacy Policy” of such
              third-party platforms in order to accept how such third-party
              platforms handle your Information.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Once you leave the Platform, we are not liable for any use/
              storage/ processing/ collection of your Information obtained by
              any third-party websites or payment facilitators or provided by
              you to these third parties or payment facilitators. Such entities
              and their respective websites/platforms may be governed by their
              own “Privacy Policy” and “Terms of Service”, which are beyond our
              control.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Cookies
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              We send cookies (small files containing a string of characters) to
              your computer, thereby uniquely identifying your browser. Cookies
              are used to track your preferences, help you log in faster, and
              aggregate to determine user trends. This data is used to improve
              our offerings, such as providing more content in areas of greater
              interest to a majority of users. Most browsers are initially set
              up to accept cookies, but you can reset your browser to refuse all
              cookies or to indicate when a cookie is being sent. Some of our
              features and services may not function properly if your cookies
              are disabled.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Alerts
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              We may alert you by email or phone (through RCS/ SMS/ Phone Call)
              to inform you about new service offerings or other information
              which we feel might be useful for you.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Public Forums
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              When you use certain features on our Platform like the discussion
              forums, and you post or share your personal information such as
              comments, messages, files, photos, will be available to all users,
              and will be in the public domain. All such sharing of information
              is done at your own risk. Please keep in mind that if you disclose
              Personal Information in your profile or when posting on our
              forums, this information may become publicly available.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Security
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              We are concerned about safeguarding the confidentiality of your
              Information. We provide physical, electronic, and procedural
              safeguards to protect the Information we process and maintain.
              Access to this Information shall be limited to authorised
              employees only who need to know that information in order to
              operate, develop or improve our Platform/Services/products.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              User Data Retention
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Currently, we plan to retain user Information while an account is
              active and for at least three years afterwards. We may alter this
              practice according to legal and business requirements. For
              example, we may lengthen the retention period for some data if
              needed to comply with law or voluntary codes of conduct. Unless
              otherwise prohibited, we may shorten the retention period for some
              types of data if needed to free up storage space. We will delete
              Information based on a request received from you within a
              reasonable period and the latest within ninety days of receiving a
              deletion request. However, we may retain such portions of
              Information and for such periods as may be required under
              applicable law. Notwithstanding anything contained herein,
              MechMiles may retain data after account deletion for reasons
              including but limited to the following purposes: if there is an
              unresolved issue relating to your account, or an unresolved claim
              or dispute; if we are required to by applicable law; and/or in
              aggregated and/or anonymised form; or MechMiles may also retain
              certain information if necessary for its legitimate business
              interests, such as fraud prevention and enhancing the user’s
              safety and security.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Log information
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              When you access our Platform, our servers automatically record
              information that your browser sends whenever you visit a website.
              These server logs may include information such as your web
              request, internet protocol address, browser type, browser
              language, the date and time of your request and one or more
              cookies that may uniquely identify your browser.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              User communications
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              When you send an email or other communication to us, we may retain
              those communications in order to process your inquiries, respond
              to your requests and improve our Services/products.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Changes to this Statement
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              We may modify or revise the Policy from time to time and shall
              accordingly notify you of any changes to the Privacy Policy by
              posting the revised Privacy Policy on the Platform with an updated
              date of revision. We shall endeavour to review, revise, update,
              modify, amend or correct the Policy on a regular and routine
              basis, especially whenever a significant update is made to the
              technology employed by us. You must periodically review the Policy
              for the latest information on our privacy practices. In the event
              you continue to use the Platform and our Services after any update
              in the Policy, your use of the Platform shall be subject to such
              updated policy. Your continued usage of Services posts any
              amendment would deem to mean that you accept and understand the
              revised Policy. Further, we retain the right at any time to deny
              or suspend access to all, or any part of, the service to anyone
              who we reasonably believe has violated any provision of this
              Policy.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Your Consent
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              We believe that every user of our Services/products/Platform must
              be in a position to provide informed consent prior to providing
              any Information required for the use of the
              Services/products/Platform. By registering with the Platform, you
              are expressly consenting to our collection, processing, storing,
              disclosing and handling of your Information as set forth in this
              Policy now and as amended by us. Processing your Information in
              any way, including, but not limited to, collecting, storing,
              deleting, using, combining, sharing, transferring and disclosing
              information, all of which activities will take place in India.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              This Policy shall be enforceable against you in the same manner as
              any other written agreement. By visiting or accessing the Platform
              and voluntarily providing us with Information, you are consenting
              to our use of the Information in accordance with this Privacy
              Policy. If you do not agree with this Policy, you may refuse or
              withdraw your consent any time or alternatively choose to not
              provide us with any Information. Under such circumstances, we may
              be unable to render Services.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Such an intimation to withdraw your consent can be sent to
              info@MechMiles.com.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Discrepancies and Grievances with Company
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              In case of any discrepancy or grievance with respect to all or any
              Information shared with the Company, please feel free to contact
              our Grievance Officer:
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: "15px",
                  fontFamily: "Poppins",
                  color: "#6C17A4",
                }}
              >
                Attention:
              </span>
            </strong>
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              &nbsp;Mr Rahul Mishra
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Email ID: rahul.mishra@MechMiles.com
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Please feel free to reach out to us by e-mail at
              info@MechMiles.com in case of any concerns, grievances, or
              questions relating to our privacy or data-related practices.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              INTELLECTUAL PROPERTY RIGHTS
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Except as mentioned below, all information, content, material,
              trademarks, services marks, trade names, and trade secrets,
              including but not limited to the software, text, images, graphics,
              video, script and audio, contained on the Platform, Services and
              products are the proprietary property of MechMiles (“Proprietary
              Information”). The products of the MechMiles shall include but
              shall not be limited to the curated courses material designed and
              published by the MechMiles and provided to you for delivering the
              Services to you.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              No Proprietary Information may be copied, downloaded, reproduced,
              modified, republished, uploaded, posted, transmitted or
              distributed in any way without obtaining prior written permission
              from the Company, and nothing on the Platform or Services shall be
              or products deemed to confer a license of or any other right,
              interest or title to or in any of the intellectual property rights
              belonging to the Company, to you.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              MechMiles shall at all times retain full and complete title to the
              information, content or materials and all Proprietary Information
              provided by the Company on any medium.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Certain contents on the Platform may belong to third parties. Such
              contents have been reproduced after taking prior consent from said
              party and all rights relating to such content will remain with
              such third party. Further, you recognise and acknowledge that the
              ownership of all trademarks, copyright, logos, service marks and
              other intellectual property owned by any third party shall
              continue to vest with such party and you are not permitted to use
              the same without the consent of the respective third party.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Your use of our products, Platform and Services is solely for your
              personal use. Any use of the Platform, Services or products or
              their contents other than for personal purposes is prohibited.
              Your use of our Platform or Services does not constitute any right
              or license for you to use our service marks or trademarks without
              the prior written permission of MechMiles. Your personal and
              non-commercial use of this Platform, products and/or our Services
              shall be subjected to the following restrictions.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              You may not decompile, reverse engineer, or disassemble the
              contents of the Products/ Platform / Services or modify, copy,
              distribute, transmit, display, perform, reproduce, publish,
              license, create derivative works from, transfer, or sell any
              information or software obtained from the Website, or remove any
              copyright, trademark registration, or other proprietary notices
              from the contents of the Website /Services/Products.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              You will not (a) use our Platform and/or any of our product/s or
              Service/s for any unauthorised commercial purposes of any kind, or
              (b) advertise or sell any products, Services or domain names or
              otherwise (whether or not for profit), or solicit others
              (including, without limitation, solicitations for contributions or
              donations) or use any public forum for commercial purposes of any
              kind, or (c) use the Platform/our products and Services in any way
              that is unlawful, or harms MechMiles or any other person or entity
              as determined by MechMiles.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              No User shall be permitted to perform any of the following
              prohibited activities while availing our Services: making
              available any content that is misleading, unlawful, harmful,
              threatening, abusive, tortious, defamatory, libellous, vulgar,
              obscene, child-pornographic, lewd, lascivious, profane, invasive
              of another's privacy, hateful, or racially, ethnically or
              otherwise objectionable; stalking, intimidating and/or harassing
              another and/or inciting other to commit violence; transmitting
              material that encourages anyone to commit a criminal offence, that
              results in civil liability or otherwise breaches any relevant
              laws, regulations or code of practice; interfering with any other
              person's use or enjoyment of the Platform/Services; making,
              transmitting or storing electronic copies of materials protected
              by copyright without the permission of the owner, committing any
              act that amounts to the infringement of intellectual property or
              making available any material that infringes any intellectual
              property rights or other proprietary rights of anyone else; make
              available any content or material that you do not have a right to
              make available under any law or contractual or fiduciary
              relationship, unless you own or control the rights thereto or have
              received all necessary consents for such use of the content;
              impersonate any person or entity, or falsely state or otherwise
              misrepresent your affiliation with a person or entity; post,
              transmit or make available any material that contains viruses,
              trojan horses, worms, spyware, time bombs, cancelbots, or other
              computer programming routines, code, files or such other programs
              that may harm the services, interests or rights of other users or
              limit the functionality of any computer software, hardware or
              telecommunications, or that may harvest or collect any data or
              personal information about other Users without their consent;
              access or use the Platform/Services/Products in any manner that
              could damage, disable, overburden or impair any of the Platform’s
              servers or the networks connected to any of the servers on which
              the Website is hosted; intentionally or unintentionally interfere
              with or disrupt the Services or violate any applicable laws
              related to the access to or use of the Platform/Services/products,
              violate any requirements, procedures, policies or regulations of
              networks connected to the Platform/Services/products, or engage in
              any activity prohibited by Policy and the terms contained herein;
              disrupt or interfere with the security of, or otherwise cause harm
              to, the Website/Services/Products, materials, systems resources,
              or gain unauthorized access to user accounts, passwords, servers
              or networks connected to or accessible through the
              Platform/Services/Products or any affiliated or linked sites;
              interfere with, or inhibit any user from using and enjoying access
              to the Platform/ Services/products, or other affiliated sites, or
              engage in disruptive attacks such as denial of service attack on
              the Platform/Services/products; use deep-links, page-scrape,
              robot, spider or other automatic device, program, algorithm or
              methodology, or any similar or equivalent manual process, to
              increase traffic to the Platform/Services/products, to access,
              acquire, copy or monitor any portion of the
              Platform/Services/products, or in any way reproduce or circumvent
              the navigational structure or presentation of the or any content,
              to obtain or attempt to obtain any content, documents or
              information through any means not specifically made available
              through the Platform/Services/products; alter or modify any part
              of the Services; use the Services for purposes that are not
              permitted by: (i) the Policy; and (ii) any applicable law,
              regulation or generally accepted practices or guidelines in the
              relevant jurisdiction; or violate any of the terms specified under
              the Terms for the use of the Platform/Services/products.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              By submitting content on or through the Services (your
              “Material”), you grant us a worldwide, non-exclusive, royalty-free
              license (with the right to sublicense) to use, copy, reproduce,
              process, adapt, modify, publish, transmit, display and distribute
              such Material in any and all media or distribution methods (now
              known or later developed) and to associate your Material with you,
              except as described below. You agree that others may use your
              Material in the same way as any other content available through
              the Services. Other users of the Services may fork, tweak and
              repurpose your Material in accordance with this Policy and the
              terms contained herein. If you delete your user account, your
              Material and name may remain available through the Services.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              In the preparation of the Platform/Services/products and contents
              therein, every effort has been made to offer the most current,
              correct, and clearly expressed information possible. Nevertheless,
              inadvertent errors may occur. In particular, but without limiting
              anything here, MechMiles disclaims any responsibility for any
              errors and accuracy of the information that may be contained on
              the Platform. Any feedback from you is most welcome to make the
              contents thereof error-free and user friendly. MechMiles also
              reserves the right and discretion to make any changes/corrections
              or withdraw/add contents at any time without notice. Neither
              MechMiles nor any third parties provide any warranty or guarantee
              as to the accuracy, timeliness, performance, completeness or
              suitability of the information and materials found or offered on
              Platform/Services/products for any particular purpose. You
              acknowledge that such information and materials may contain
              inaccuracies or errors, and we expressly exclude liability for any
              such inaccuracies or errors to the fullest extent permitted by
              applicable law.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              CONSEQUENCES OF BREACH &amp; INDEMNIFICATION:
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              In the event of a breach of the Policy, you shall be liable to pay
              liquidated damages along with costs of recovery of the damages to
              the Company. You shall be further liable to compensate MechMiles
              for the losses incurred by MechMiles due to the breach of Policy
              by you.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              MechMiles shall be entitled to immediately suspend/terminate the
              Services being provided to you if any of the terms of the Policy
              is breached by you.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              You agree to defend, indemnify and hold harmless MechMiles, its
              officers, directors, employees and agents, from and against any
              and all claims, damages, obligations, losses, liabilities, costs
              or debt, and expenses (including but not limited to attorney’s
              fees) arising from breach of any of the terms of the Policy.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              In no event shall MechMiles, its officers, directors, employees,
              partners or agents be liable to you or any third party for any
              special, incidental, indirect, consequential or punitive damages
              whatsoever, including those resulting from loss of use, data or
              profits or any other claim arising out, of or in connection with,
              your use of, or access to the Platform.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              General Provisions:
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Notice: All notices served by the Company shall be provided via
              email to Your account or as a general notification on the
              Application. Any notice to be provided to the Company should be
              sent to legal@MechMiles.com.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Entire Agreement: This Policy and any other guidelines made
              applicable to the Platform/Services from time to time constitute
              the entire agreement between MechMiles and you with respect to
              your access to or use of the Platform and the Services thereof.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Assignment: You cannot assign or otherwise transfer your
              obligations under the Policy or any right granted hereunder to any
              third party. MechMiles’s rights under the Policy are freely
              transferable by MechMiles to any third parties without the
              requirement of seeking your consent.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Severability: If for any reason, a court of competent jurisdiction
              finds any provision of the Terms, or portion thereof, to be
              unenforceable, that provision shall be enforced to the maximum
              extent permissible so as to give effect to the intent of the
              parties as reflected by that provision, and the remainder of the
              Policy shall continue in full force and effect.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Waiver: Any failure by MechMiles to enforce or exercise any
              provision of the Policy, or any related right, shall not
              constitute a waiver by MechMiles of that provision or right.
              MechMiles provides the Policy so that you are aware of the terms
              that apply to your use of the Platform and Services. You
              acknowledge that, MechMiles has given you a reasonable opportunity
              to review this Policy and that you have agreed to them.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Dispute Resolution: This Policy and any obligation arising from
              and in connection with this Policy shall be governed by the laws
              of the Republic of India. Any dispute or difference relating to
              any of the matters set out in this Policy, and the courts of New
              Delhi shall have exclusive jurisdiction over any and all disputes
              relating to this Policy.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"15.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Shipping Policy
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
              lineHeight: '"normal"',
              background: '"white"',
            }}
          >
            <span
              style={{
                fontSize: "15px",
                fontFamily: "Poppins",
                color: "#6C17A4",
              }}
            >
              Thank you for choosing MechMiles for your purchases. We appreciate
              your support of our products/services. Please be aware that
              MechMiles does not offer shipping or delivery services.
            </span>
          </p>
          <p
            style={{
              marginTop: '"0in"',
              marginRight: '"0in"',
              marginBottom: '"8.0pt"',
              marginLeft: '"0in"',
              fontSize: '"F1.0pt"',
              fontFamily: '"Calibri","sans-serif"',
            }}
          >
            &nbsp;
          </p>
        </div>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
