import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import useHeroAPI from "../../api/useHeroAPI";
import useVehicleAPI from "../../api/useVehicleAPI";
import { RootState } from "../../app/store";
import bannerImage from "../../assets/images/banner-image.jpg";
import bikeImg from "../../assets/images/bike_scooter.png";
import carStand from "../../assets/images/car-stand.png";
import carSuvImg from "../../assets/images/car_suv.png";
import checkIcon from "../../assets/images/check.png";
import commercialImg from "../../assets/images/commercial.png";
import threeWheelerImg from "../../assets/images/three_wheeler.png";
import vehicleText from "../../assets/images/vehicleText.png";
import Counter from "../../components/Counter/Counter";
import DownloadApp from "../../components/DownloadApp/DownloadApp";
import FAQ from "../../components/FAQ/FAQ";
import Franchise from "../../components/Franchise/Franchise";
import QuickSteps from "../../components/QuickSteps/QuickSteps";
import Services from "../../components/Services/Services";
import SimpleSlider from "../../components/SimpleSlider/SimpleSlider";
import { setLoading } from "../../redux/loadingSlice";
import { setTabIndex } from "../../redux/tabIndexSlice";
import "./ServicesAndRepairs.css";

interface Option {
  name: string;
  code: string;
}

interface DropdownOptionsProps {
  brand: Option | null;
  setBrand: React.Dispatch<React.SetStateAction<Option | null>>;
  model: Option | null;
  setModel: React.Dispatch<React.SetStateAction<Option | null>>;
  variant: Option | null;
  setVariant: React.Dispatch<React.SetStateAction<Option | null>>;
  searchData: () => void;
  brands: Option[];
  models: Option[];
  variants: Option[];
}

const DropdownOptions: React.FC<DropdownOptionsProps> = ({
  brand,
  setBrand,
  model,
  setModel,
  variant,
  setVariant,
  searchData,
  brands,
  models,
  variants,
}) => (
  <div className="pb-4 pl-4 pr-4 dropdown-options">
    <Dropdown
      filter
      value={brand}
      onChange={(e: { value: Option }) => setBrand(e.value)}
      options={brands}
      optionLabel="name"
      placeholder="Select a Make"
      className="w-3 banner-dropdown"
    />
    <Dropdown
      filter
      value={model}
      onChange={(e: { value: Option }) => setModel(e.value)}
      options={models}
      optionLabel="name"
      placeholder="Select a Model"
      className="w-3 banner-dropdown"
    />
    <Dropdown
      filter
      value={variant}
      onChange={(e: { value: Option }) => setVariant(e.value)}
      options={variants}
      optionLabel="name"
      placeholder="Select a Variant"
      className="w-3 banner-dropdown"
    />
    <Button
      icon="pi pi-search"
      label="Search"
      onClick={searchData}
      className="w-2 search-button"
    ></Button>
  </div>
);

const ServicesAndRepairs = () => {
  const basePath = process.env.REACT_APP_BASE_PATH;

  const loading = useSelector((state: any) => state.loading);

  const tabIndex = useSelector((state: RootState) => state.tabIndex);
  const dispatch = useDispatch();

  const { predefinedFunc } = useHeroAPI();
  const { getVehicleList, saveVehicle } = useVehicleAPI();

  const navigate = useNavigate();
  const toast = useRef<Toast>(null);

  const [userId, setUserId] = useState(null) as any;
  const [tyreType, setTyreType] = useState<Option | null | string>(null) as any;
  const [productByState, setProductByState] = useState<Option | null | string>(
    null
  );
  const [typesPreDefinedState, setTypesPreDefinedState] = useState([]);
  const [selectedTyreType, setSelectedTyreType] = useState("");
  const [tyreBrandImgs, setTyreBrandImgs] = useState([]) as any;

  const [brandsList, setBrandsList] = useState([]) as any;
  const [brands, setBrands] = useState<Array<{ name: string; code: string }>>(
    []
  ) as any;
  const [brand, setBrand] = useState<Option | null>(null) as any;
  const [models, setModels] = useState<Array<{ name: string; code: string }>>(
    []
  ) as any;
  const [model, setModel] = useState<Option | null>(null) as any;
  const [variant, setVariant] = useState<Option | null>(null) as any;
  const [variants, setVariants] = useState<
    Array<{ name: string; code: string }>
  >([]) as any;

  const [cars, setCars] = useState([]) as any;
  const [selectedCar, setSelectedCar] = useState(null) as any;
  const [visible, setVisible] = useState(false);
  const [vehicleNumber, setVehicleNumber] = useState(null) as any;
  const [category, setCategory] = useState(null) as any;
  const [categories, setCategories] = useState(null) as any;
  const [makes, setMakes] = useState(null) as any;
  const [make, setMake] = useState(null) as any;
  const [makesTwo, setMakesTwo] = useState(null) as any;
  const [makeTwo, setMakeTwo] = useState(null) as any;
  const [modelsTwo, setModelsTwo] = useState<
    Array<{ name: string; code: string }>
  >([]);
  const [modelTwo, setModelTwo] = useState<Option | null>(null);
  const [variantsTwo, setVariantsTwo] = useState<
    Array<{ name: string; code: string }>
  >([]);
  const [variantTwo, setVariantTwo] = useState<Option | null>(null);
  const [submitted, setSubmitted] = useState(false) as any;

  const [serviceAndRepairsData, setServiceAndRepairsData] = useState([]) as any;

  const fetchVehicleList = async () => {
    try {
      const payload = {
        user_id: userId,
      };

      const response: any = await getVehicleList(payload);

      const vehicleData = response?.data?.map((vehicle: any) => ({
        id: vehicle.id,
        color: vehicle.vehicle_color || "N/A",
        name:
          `${vehicle?.make?.name} ${vehicle?.model?.name} ${vehicle?.variant?.name}` ||
          "Name Not Available",
        licensePlate: vehicle.vehicle_number,
        imageUrl: vehicleText,
        make: vehicle?.make?.name,
        model: vehicle?.model?.name,
        variant: vehicle?.variant?.name,
      }));
      setCars(vehicleData || null);
    } catch (error) {
      console.error("Error fetching vehicles:", error);
    }
  };

  const fetchChildPredefined = async () => {
    const foundTyreType: any = typesPreDefinedState.find(
      (type: any) => type?.name === tyreType?.name
    );

    setSelectedTyreType(foundTyreType?.predefined_id);

    if (
      foundTyreType &&
      foundTyreType?.name !== undefined &&
      foundTyreType?.name !== ""
    ) {
      // let parent_id = null;
      // if (location.pathname === "/") {
      //   parent_id = 8151;
      // } else if (location.pathname === "/batteries") {
      //   parent_id = 8152;
      // }

      // const brandPredefinedPayload = {
      //   entity_type: "BRAND",
      // };
      const makePredefinedPayload = {
        entity_type: "MAKE",
        parent_id: foundTyreType?.predefined_id ?? null,
      };
      const modelPredefinedPayload = {
        entity_type: "MODEL",
        parent_id: brand?.code ?? "",
      };
      const variantPredefinedPayload = {
        entity_type: "VARIANT",
        parent_id: model?.code ?? "",
      };

      // const brandPredefinedResponse = await predefinedFunc(
      //   brandPredefinedPayload
      // );
      const makePredefinedResponse = await predefinedFunc(
        makePredefinedPayload
      );
      const modelPredefinedResponse = await predefinedFunc(
        modelPredefinedPayload
      );
      const variantPredefinedResponse = await predefinedFunc(
        variantPredefinedPayload
      );

      const makeData = makePredefinedResponse?.data?.data.map((child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      });
      const modelData = modelPredefinedResponse?.data?.data.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      const variantData = variantPredefinedResponse?.data?.data.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );

      // if (brandPredefinedResponse.status === 200) {
      //   dispatch(setLoading(false));
      // }

      if (makeData) {
        setBrands(makeData);
        dispatch(setLoading(false));
      }
      if (brand !== null) {
        setModels(modelData);
      }

      if (model !== null) {
        setVariants(variantData);
      }
      // setTyreBrandImgs(brandPredefinedResponse?.data?.data);
    }
  };

  const fetchDependentData = async () => {
    dispatch(setLoading(true));
    const modelPredefinedPayload = {
      entity_type: "MODEL",
      parent_id: brand?.code ?? null,
    };
    const variantPredefinedPayload = {
      entity_type: "VARIANT",
      parent_id: model?.code ?? null,
    };
    const modelPredefinedResponse = await predefinedFunc(
      modelPredefinedPayload
    );
    const variantPredefinedResponse = await predefinedFunc(
      variantPredefinedPayload
    );

    if (
      modelPredefinedResponse.status === 200 &&
      variantPredefinedResponse.status === 200
    ) {
      dispatch(setLoading(false));
    }

    if (brand !== null) {
      const modelData = modelPredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      setModels(modelData);
    }

    if (model !== null) {
      const variantData = variantPredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      setVariants(variantData);
    }
  };

  const fetchDependentDataForAdd = async () => {
    if (category !== null) {
      const makePredefinedPayload = {
        entity_type: "MAKE",
        parent_id: category ?? null,
      };
      const makePredefinedResponse = await predefinedFunc(
        makePredefinedPayload
      );
      const makeData = makePredefinedResponse?.data?.data?.map((child: any) => {
        return {
          name: child.name,
          code: child.predefined_id,
        };
      });
      setMakesTwo(makeData);
    }

    if (makeTwo !== null) {
      const modelPredefinedPayload = {
        entity_type: "MODEL",
        parent_id: makeTwo ?? null,
      };
      const modelPredefinedResponse = await predefinedFunc(
        modelPredefinedPayload
      );
      const modelData = modelPredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      setModelsTwo(modelData);
    }
    if (modelTwo !== null) {
      const variantPredefinedPayload = {
        entity_type: "VARIANT",
        parent_id: modelTwo ?? null,
      };
      const variantPredefinedResponse = await predefinedFunc(
        variantPredefinedPayload
      );
      const variantData = variantPredefinedResponse?.data?.data?.map(
        (child: any) => {
          return {
            name: child.name,
            code: child.predefined_id,
          };
        }
      );
      setVariantsTwo(variantData);
    }
  };

  useEffect(() => {
    if (category !== null || makeTwo !== null || modelTwo !== null) {
      fetchDependentDataForAdd();
    }
  }, [category, makeTwo, modelTwo]);

  useEffect(() => {
    if (brand !== null || model !== null) {
      fetchDependentData();
    }
  }, [brand, model]);

  const fetchPredefined = async () => {
    const predefinedParentPayload = {
      entity_type: "CATEGORY",
    };
    const predefinedResponse = await predefinedFunc(predefinedParentPayload);
    if (predefinedResponse.status === 200) {
      setCategories(predefinedResponse?.data?.data);
      setTypesPreDefinedState(predefinedResponse?.data?.data);
      const defaultTyreType = predefinedResponse?.data?.data?.find(
        (type: any) => type?.code === "TWO-WHEELER"
      );
      setTyreType(defaultTyreType);

      const twoWheelerIndex = predefinedResponse?.data?.data.findIndex(
        (type: any) => type.code === "TWO-WHEELER"
      );
      if (twoWheelerIndex !== -1) {
        dispatch(setTabIndex(twoWheelerIndex));
      }
    }
    fetchChildPredefined();

    const predefinedServices = {
      entity_type: "SERVICE-TYPE",
    };
    const predefinedServiceResponse = await predefinedFunc(predefinedServices);
    if (predefinedServiceResponse.status === 200) {
      setServiceAndRepairsData(predefinedServiceResponse?.data?.data);
    }
  };

  useEffect(() => {
    fetchPredefined();
  }, []);

  useEffect(() => {
    if (tyreType !== null && tyreType?.name !== "") {
      fetchChildPredefined();
    }
  }, [tyreType]);

  useEffect(() => {
    const authTokens = JSON.parse(localStorage.getItem("authTokens") ?? "{}");
    if (authTokens?.user?.id) {
      setUserId(authTokens?.user?.id);
    }

    if (userId) {
      fetchVehicleList();
    }
  }, [userId]);

  const handleTabSelect = (index: number, lastIndex: number, event: Event) => {
    event.preventDefault();
    // event.stopPropagation();
    const newVehicleType: any = typesPreDefinedState[index];
    // setTyreType((event.target as Element).textContent);
    setTyreType(newVehicleType);
    dispatch(setTabIndex(index));
    // setBrands(null);
    // setModels(null);
    // setVariants(null);
    setBrand(null);
    setModel(null);
    setVariant(null);
    dispatch(setLoading(true));
    fetchChildPredefined();
  };

  const handleByTabSelect = (index: any, lastIndex: number, event: Event) => {
    setProductByState((event.target as Element).textContent);
  };

  const searchData = () => {
    // navigate("/mechmiles-customer/products", {
    //   state: { brand, model, variant, tyreType, productByState },
    // });

    navigate(`${basePath}servicepackages`, {
      state: { brand, model, variant, tyreType, productByState, selectedCar },
    });
  };

  const navigateToProductList = (predefined_id: number) => {
    navigate(`${basePath}products`, {
      state: { predefined_id },
    });
    // navigate("/mechmiles-customer/products", {
    //   state: { predefined_id },
    // });
  };

  const predefinedImgArr = [carSuvImg, bikeImg, threeWheelerImg, commercialImg];

  const handleSelectCar = (carId: number) => {
    setSelectedCar(carId);
  };

  const handleCancel = () => {
    setVisible(false);
    setCategory(null);
    setCategories(null);
    setMakeTwo(null);
    setModelTwo(null);
    setVariantTwo(null);
  };
  const handleAddCar = async () => {
    if (!category || !modelTwo || !makeTwo || !variantTwo) {
      setSubmitted(true);
      return;
    }

    const payload = {
      // vehicle_name: vehicleName,
      vehicle_number: vehicleNumber,
      category_id: category,
      model_id: modelTwo,
      make_id: makeTwo,
      variant_id: variantTwo,
    };

    const formData = new FormData();

    formData.append("data", JSON.stringify(payload));

    const response = await saveVehicle(formData);

    if (response.status === 200 || response.status === 201) {
      fetchVehicleList();
      setCategory(null);
      setMakeTwo(null);
      setModelTwo(null);
      setVariantTwo(null);
      setVehicleNumber(null);
      setVisible(false);
      return toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `Vehicle Added Successfully`,
      });
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={handleCancel}
        className="p-button-text"
      />
      <Button
        label="Submit"
        icon="pi pi-check"
        onClick={handleAddCar}
        autoFocus
      />
    </div>
  );

  const navigateToServicesPackages = (service: any) => {
    navigate(`/servicepackages`, {
      state: {
        service,
        variant: variantTwo,
        selectedCar: selectedCar,
      },
    });
  };

  return (
    <div className="servicerepair-wrapper">
      <Toast ref={toast} />
      {loading && <div className="loading-overlay">Loading...</div>}
      <div className="servicerepair-image-wrapper">
        <Image src={bannerImage} />
      </div>
      <Container className="home-tab">
        {!userId && (
          <Tabs
            forceRenderTabPanel
            defaultIndex={0}
            onSelect={(index: any, lastIndex: number, event: Event) =>
              handleByTabSelect(index, lastIndex, event)
            }
          >
            <TabList>
              <Tab>By Vehicle</Tab>
              {/* <Tab>By Size</Tab> */}
              {/* <Tab>By Vehicle Number</Tab> */}
            </TabList>
            <TabPanel>
              <Tabs
                forceRenderTabPanel
                onSelect={(index: any, lastIndex: number, event: Event) =>
                  handleTabSelect(index, lastIndex, event)
                }
                selectedIndex={tabIndex}
              >
                <TabList>
                  <div className="internal-home-tab flex w-full justify-content-between">
                    <div>
                      <div className="tab-internal-title">
                        Experience the best vehicle service
                      </div>
                      <div className="tab-internal-sub-title">
                        Get instant quotes for your service
                      </div>
                    </div>
                    <div className="tab-internal-options">
                      {typesPreDefinedState?.map(
                        (types: any, index: number) => {
                          return (
                            <Tab key={index}>
                              <Image
                                src={
                                  tabIndex === index
                                    ? types?.active_thumbnail
                                    : types?.thumbnail
                                }
                                className="tyre-type-images"
                              />
                              {types.name}
                            </Tab>
                          );
                        }
                      )}
                    </div>
                  </div>
                </TabList>
                <TabPanel>
                  <DropdownOptions
                    brand={brand}
                    setBrand={setBrand}
                    model={model}
                    setModel={setModel}
                    variant={variant}
                    setVariant={setVariant}
                    searchData={searchData}
                    brands={brands}
                    models={models}
                    variants={variants}
                  />
                </TabPanel>
                <TabPanel>
                  <DropdownOptions
                    brand={brand}
                    setBrand={setBrand}
                    model={model}
                    setModel={setModel}
                    variant={variant}
                    setVariant={setVariant}
                    searchData={searchData}
                    brands={brands}
                    models={models}
                    variants={variants}
                  />
                </TabPanel>
                <TabPanel>
                  <DropdownOptions
                    brand={brand}
                    setBrand={setBrand}
                    model={model}
                    setModel={setModel}
                    variant={variant}
                    setVariant={setVariant}
                    searchData={searchData}
                    brands={brands}
                    models={models}
                    variants={variants}
                  />
                </TabPanel>
                <TabPanel>
                  <DropdownOptions
                    brand={brand}
                    setBrand={setBrand}
                    model={model}
                    setModel={setModel}
                    variant={variant}
                    setVariant={setVariant}
                    searchData={searchData}
                    brands={brands}
                    models={models}
                    variants={variants}
                  />
                </TabPanel>
              </Tabs>
            </TabPanel>
          </Tabs>
        )}
        {userId && (
          <div className="services-vehicle-list-wrapper">
            <div className="services-vehicle-list-title-wrapper">
              <div className="services-vehicle-list-title">
                Experience the best car service
              </div>
              <Button
                className="services-vehicle-list-add-new"
                onClick={() => setVisible(true)}
              >
                New Vehicle ?
              </Button>
            </div>
            <div className="select-your-car-wrapper">
              <div className="select-your-car-title">Select your car </div>
              <div className="select-your-car-container">
                <div className="select-your-car-wrapper">
                  <div className="select-your-car-header">
                    <h2>
                      Select Vehicle <span className="error">*</span>
                    </h2>
                    <p>Please select a Vehicle</p>
                  </div>
                  <div className="car-list">
                    {cars?.length === 0 && (
                      <div className="no-car-found-wrapper">
                        <div className="no-car-found-title">No Car Found</div>
                        <div className="no-car-found-sub-title">
                          Please add a new vehicle
                        </div>
                      </div>
                    )}
                    {cars?.map((car: any) => (
                      <div
                        key={car.id}
                        className={`car-item-wrapper ${
                          selectedCar?.id === car.id ? "selected-car" : ""
                        }`}
                        onClick={() => handleSelectCar(car)}
                      >
                        <div className="car-item">
                          <Image
                            src={car?.imageUrl}
                            alt={`${car?.name}`}
                            className="car-image"
                            height="108px"
                            width="103px"
                          />
                          <div className="car-data">
                            {/* <h3> */}
                            {/* {car.name} */}
                            <div className="vehicle-make">{car?.make}</div>
                            <div className="vehicle-model">{car?.model}</div>
                            <div className="vehicle-variant">
                              {car?.variant}
                            </div>
                            {/* </h3> */}
                            <p>{car.licensePlate}</p>
                          </div>
                        </div>
                        {selectedCar?.id === car?.id && (
                          <div className="check-icon-wrapper">
                            <Image
                              src={checkIcon}
                              alt="Select Icon"
                              height="26px"
                              width="26px"
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                {/* <Button className="proceed-button">Proceed</Button> */}
              </div>
            </div>
          </div>
        )}

        <div className="servicerepair-section-two">
          <div className="servicerepair-container">
            <div className="servicerepair-title-wrapper single-servicerepair-wrapper">
              <div className="servicerepair-title">
                Vehicle service available for you
              </div>
              <div className="servicerepair-sub-title">
                MechMiles offers customized vehicle services designed to meet
                your unique needs.
              </div>
            </div>
            {serviceAndRepairsData?.map((service: any) => (
              <div
                className="single-servicerepair-wrapper"
                onClick={() => navigateToServicesPackages(service)}
              >
                <div className="single-servicerepair-container">
                  <Image
                    // src={service?.serviceImg}
                    src={service?.url ? service?.url : carStand}
                    className="single-servicerepair-image"
                  />
                  <div className="single-servicerepair-content">
                    {/* {service?.serviceName} */}
                    {service?.name}
                  </div>
                </div>
              </div>
            ))}
            {/* <a href="#" className="single-servicerepair-wrapper">
              <div className="single-servicerepair-container">
                <Image src={carStand} className="single-servicerepair-image" />
                <div className="single-servicerepair-content">
                  Periodic Services
                </div>
              </div>
            </a> */}
          </div>
        </div>
      </Container>
      <section className="services-section">
        <Services />
      </section>
      <section className="counter-section">
        <Counter />
      </section>
      <section className="quick-steps-section">
        <QuickSteps />
      </section>
      <section className="testimonials-section">
        <SimpleSlider />
      </section>
      <section className="faq-section">
        <FAQ />
      </section>
      <section className="franchise-section">
        <Franchise />
      </section>
      <section className="download-app-section">
        <DownloadApp />
      </section>
      <Dialog
        header="Add a New Car"
        footer={footerContent}
        visible={visible}
        onHide={() => setVisible(false)}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "90%" }}
      >
        <div className="add-new-car-wrapper grid">
          {/* <div className="field col-6 vehicle-name">
            <label htmlFor="vehicle-name">
              Vehicle Name <span className="error">*</span>
            </label>
            <InputText
              className="w-full"
              value={vehicleName}
              onChange={(e) => setVehicleName(e.target.value)}
              placeholder="Vehicle Name"
              id="vehicle-name"
            />
            {submitted && !vehicleName && (
              <small className="error">Vehicle Name is Required</small>
            )}
          </div> */}
          <div className="field col-6 vehicle-number">
            <label htmlFor="vehicle-number">Vehicle Number</label>
            <InputText
              className="w-full"
              value={vehicleNumber}
              onChange={(e) => setVehicleNumber(e.target.value)}
              placeholder="Vehicle Number"
              id="vehicle-number"
            />
            {/* {submitted && !vehicleNumber && (
              <small className="error">Vehicle Number is Required</small>
            )} */}
          </div>
          <div className="field col-6 vehicle-category">
            <label>
              Category <span className="error">*</span>
            </label>
            <Dropdown
              className="w-full"
              value={category}
              options={categories}
              onChange={(e) => setCategory(e.value)}
              placeholder="Select a Category"
              optionValue="predefined_id"
              optionLabel="name"
              showClear
            />
            {submitted && !category && (
              <small className="error">Category is Required</small>
            )}
          </div>
          <div className="field col-6 vehicle-make">
            <label>
              Make <span className="error">*</span>
            </label>
            <Dropdown
              className="w-full"
              value={makeTwo}
              options={makesTwo}
              onChange={(e) => setMakeTwo(e.value)}
              placeholder="Select a Make"
              optionValue="code"
              optionLabel="name"
              filter
            />
            {submitted && !makeTwo && (
              <small className="error">Make is Required</small>
            )}
          </div>
          <div className="field col-6 vehicle-model">
            <label>
              Model <span className="error">*</span>
            </label>
            <Dropdown
              className="w-full"
              filter
              value={modelTwo}
              options={modelsTwo}
              onChange={(e) => setModelTwo(e.value)}
              placeholder="Select a Model"
              optionValue="code"
              optionLabel="name"
              showClear
            />
            {submitted && !modelTwo && (
              <small className="error">Model is Required</small>
            )}
          </div>
          <div className="field col-6 vehicle-variant">
            <label>
              Variant <span className="error">*</span>
            </label>
            <Dropdown
              className="w-full"
              value={variantTwo}
              options={variantsTwo}
              onChange={(e) => setVariantTwo(e.value)}
              placeholder="Select a Variant"
              optionValue="code"
              optionLabel="name"
            />
            {submitted && !variantTwo && (
              <small className="error">Variant is Required</small>
            )}
          </div>
          {/* <div className="field col-6 vehicle-type">
            <label>
              Type <span className="error">*</span>
            </label>
            <Dropdown
              className="w-full"
              value={typeId}
              options={typeIds}
              onChange={(e) => setTypeId(e.value)}
              placeholder="Select a Type"
              optionValue="code"
              optionLabel="name"
            />
            {submitted && !typeId && (
              <small className="error">Vehicle Type is Required</small>
            )}
          </div> */}
        </div>
      </Dialog>
    </div>
  );
};

export default ServicesAndRepairs;
