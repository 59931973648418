import React, { useEffect, useRef, useState } from "react";
import "./StaffManagement.css";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { FaPlus } from "react-icons/fa";
import { Dialog } from "primereact/dialog";
import useLoginAPI from "../../api/useLogin";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import debounce from "../../utils/debounce";
import useHeroAPI from "../../api/useHeroAPI";
import { Toast } from "primereact/toast";
import useDriverAPI from "../../api/useDriverAPI";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/loadingSlice";

const StaffManagement = () => {

  const corporateAdminRole = "ROLE_CORPORATE_ADMIN";
  const fleetAdminRole = "ROLE_FLEET_OWNER";

  const {
    saveUserAPI,
    getStaffList,
    getDepartmentNames,
    updateStaffById,
    importStaffUpload,
    getStaffStats,
    getStaffCount
  } = useLoginAPI();
  const { getDriverList, assignDriver } = useDriverAPI();

  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const toast = useRef<Toast>(null);
  const loading = useSelector((state: any) => state.loading);
  const dispatch = useDispatch();

  const handleImportStaffClick = () => {
    fileInputRef.current?.click();
  };

  // Updated renderColumnData function to handle 'STATUS' column
  const renderColumnData = (rowData: any, field: string) => {
    if (field === "status") {
      const status = rowData.is_deactivate === "N" ? "Active" : "In-active";
      const color = rowData.is_deactivate === "N" ? "green" : "red";
      return <span style={{ color }}>{status}</span>;
    }

    if (field === "driver.full_name") {
      // Check if the driver object exists and has a 'full_name' property
      const driverFullName = rowData.driver.full_name ? rowData.driver.full_name : "Not Available";
      return <span>{driverFullName}</span>;
    }

    return rowData[field] ? rowData[field] : "Not Available";
  };

  // New function to handle driver assignment status
  const renderDriverStatus = (rowData: any) => {
    const status = rowData.driver_id ? "Assigned" : "Un-Assigned";
    const color = rowData.driver_id ? "blue" : "grey";
    return <span style={{ color: "#642088" }}>{status}</span>;
  };

  const [columns, setColumns] = useState([
    {
      field: "user.full_name",
      header: "EMPLOYEE",
      body: (rowData: any) => renderColumnData(rowData.user, "full_name"),
      style: { minWidth: "2rem" },
    },
    {
      field: "user.username",
      header: "EMAIL",
      body: (rowData: any) => renderColumnData(rowData.user, "email"),
      style: { minWidth: "2rem" },
    },
    {
      field: "department_name",
      header: "DEPARTMENT",
      body: (rowData: any) => renderColumnData(rowData, "department_name"),
      style: { minWidth: "2rem" },
    },
    {
      field: "user.phone",
      header: "MOBILE",
      body: (rowData: any) => renderColumnData(rowData.user, "phone"),
      style: { minWidth: "2rem" },
    },
    // {
    //   field: "user.status",
    //   header: "STATUS",
    //   body: (rowData: any) => renderColumnData(rowData.user, "status"),
    //   style: { minWidth: "2rem" },
    // },
    // {
    //   field: "driver_id",
    //   header: "DRIVER",
    //   body: renderDriverStatus,
    //   style: { minWidth: "2rem" },
    // },
  ]);
  const [columnsTwo, setColumnsTwo] = useState([
    {
      field: "user.username",
      header: "Vehicle Number",
      body: (rowData: any) => renderColumnData(rowData.user, "username"),
      style: { minWidth: "2rem" },
    },
    {
      field: "driver.full_name",
      header: "Driver Name",
      body: (rowData: any) => renderColumnData(rowData, "driver.full_name"),
      style: { minWidth: "2rem" },
    },
    // {
    //   field: "user.status",
    //   header: "STATUS",
    //   body: (rowData: any) => renderColumnData(rowData.user, "status"),
    //   style: { minWidth: "2rem" },
    // },
    {
      field: "driver_id",
      header: "DRIVER",
      body: renderDriverStatus,
      style: { minWidth: "2rem" },
    },
  ]);

  const [selectedDepartment, setSelectedDepartment] = useState(null) as any;
  const [selectedDepartmentForSave, setSelectedDepartmentForSave] = useState(null) as any;
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null) as any;
  // const [statusOptions, setStatusOptions] = useState([]);
  const [visible, setVisible] = useState(false);
  const [staffListState, setStaffListState] = useState([]) as any;
  const [driverListState, setDriverListState] = useState([]) as any;
  const [staff, setStaff] = useState([]) as any;
  const [userId, setUserId] = useState(null) as any;
  const [companyId, setCompanyId] = useState(null) as any;
  const [departmentId, setDepartmentId] = useState(null) as any;
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPageOption, setRowsPerPageOption] = useState(10);
  const [records, setRecords] = useState(20);
  const [selectedDriver, setSelectedDriver] = useState(null) as any;
  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
    phone: "",
    username: "",
  });
  const [formErrors, setFormErrors] = useState({
    fullName: "",
    email: "",
    phone: "",
    username: "",
    department: "",
  });
  const [isAddingNewStaff, setIsAddingNewStaff] = useState(false) as any;
  const [isViewMode, setIsViewMode] = useState(false);
  const [isAssigningDialog, setIsAssigningDialog] = useState(false) as any;
  const [isUnAssigningDialog, setIsUnAssigningDialog] = useState(false) as any;
  const [userRole, setUserRole] = useState("") as any;
  const [staffStatsData, setStaffStatsData] = useState(null) as any;


  const statusOptions = [
    { name: "Active", value: "N" },
    { name: "In-active", value: "Y" },
  ];

  const fetchPredefined = async () => {
    let response: any;
    if (companyId) {
      response = await getDepartmentNames(companyId);
    }

    if (response?.status === 200) {
      setDepartmentOptions(response?.data);
    }
  };

  const fetchStaffList = async (searchValue?: string) => {
    const payload = {
      company_id: companyId ?? null,
      page_number: pageNumber,
      page_size: rowsPerPageOption,
      search_by: searchValue !== "" ? searchValue : null,
      dept_id: selectedDepartment !== undefined ? selectedDepartment : null,
      is_deactivate: selectedStatus !== undefined ? selectedStatus : null,
    };

    const response = await getStaffList(payload);
    const countResponse = await getStaffCount(payload);

    if (response.status === 200) {
      dispatch(setLoading(false));
      setStaffListState(response?.data);
      setRecords(countResponse?.data);
    }else{
      dispatch(setLoading(false));
    }
  };

  const fetchDriverList = async (searchValue?: string) => {
    const payload = {
      company_id: companyId ?? null,
      page_number: pageNumber,
      page_size: rowsPerPageOption,
      search_by: searchValue !== "" ? searchValue : null,
      dept_id: selectedDepartment !== undefined ? selectedDepartment : null,
      assigned_status_code: "UNASSIGNED"
    };

    const response = await getDriverList(payload);

    if (response.status === 200) {
      const mappedDriver = response?.data?.map((driver: any) => {
        return {
          label: driver?.full_name,
          value: driver?.driver_id,
          email: driver?.email,
        };
      });
      setDriverListState(mappedDriver);
    }
  };
  const fetStaffStats = async () => {
    const response = await getStaffStats(companyId);

    if (response.status === 200) {
      setStaffStatsData(response?.data);
    }
  };

  useEffect(() => {
    dispatch(setLoading(true));
    const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    if (authTokens?.user?.id) {
      setUserId(authTokens?.user?.id);
      setCompanyId(authTokens?.user?.company_id);
      setDepartmentId(authTokens?.user?.department_id);
      const userRole = authTokens?.user?.roles[0]
      if (userRole) setUserRole(userRole);
    }

    if (userId) {
      fetchStaffList();
      fetchPredefined();
      fetchDriverList();
      fetStaffStats();
    }
  }, [userId, pageNumber, selectedDepartment, selectedStatus, rowsPerPageOption]);

  // Generic input change handler for the form
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: e.target.value,
    }));
    // Clear errors when the user starts typing
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };

  // Validation logic
  const validateForm = () => {
    const errors = {
      fullName: "",
      email: "",
      phone: "",
      username: "",
      department: ""
    };
    let isValid = true;
    if (userRole.code !== fleetAdminRole) {
      if (!formValues.fullName.trim()) {
        errors.fullName = "Full name is required";
        isValid = false;
      }
      if (!selectedDepartmentForSave) {
        errors.department = "Department is required";
        isValid = false;
      }
      if (!formValues.email.trim()) {
        errors.email = "Email is required";
        isValid = false;
      } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
        errors.email = "Email is invalid";
        isValid = false;
      }
      if (!formValues.phone.trim()) {
        errors.phone = "Phone number is required";
        isValid = false;
      } else if (!/^\d{10}$/.test(formValues.phone)) {
        // Example pattern: adjust as needed
        errors.phone = "Invalid phone number";
        isValid = false;
      }
    }

    if (userRole.code === fleetAdminRole) {
      if (!formValues.username.trim()) {
        errors.username = "Vehicle Number is required";
        isValid = false;
      }
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (validateForm()) {

      const payload = {
        email: formValues?.email,
        roles: userRole.code === corporateAdminRole ? ["ROLE_CORPORATE_USER"] : ["ROLE_FLEET_USER"],
        full_name: formValues?.fullName,
        company_id: companyId ?? null,
        department_id: selectedDepartmentForSave ?? null,
        phone: formValues?.phone ?? null,
        username: formValues?.username ?? null,
      };

      if (staff.user_id) {
        const response = await updateStaffById(staff.user_id, payload);
        if (response.status === 200) {
          console.log(response);
          setVisible(false);
          setFormValues({
            fullName: "",
            email: "",
            phone: "",
            username: ""
          });
          setSelectedDepartmentForSave(null);
          fetchStaffList();
        } else {
          console.log(response);
        }
      } else {
        const response: any = await saveUserAPI(payload);
        if (response.status === 200 || response.status === 201) {
          console.log(response);
          setVisible(false);
          setFormValues({
            fullName: "",
            email: "",
            phone: "",
            username: "",
          });
          setSelectedDepartmentForSave(null);
          fetchStaffList();
          return toast?.current?.show({
            severity: "success",
            summary: "Success",
            detail: `Staff Added Successfully`,
          });
        } else if (response?.response?.status === 400) {
          return toast?.current?.show({
            severity: "error",
            summary: "Failed",
            detail: `${response?.response?.data?.error}`,
          });
        } else {
          console.log(response);
        }
      }
    }
  };

  const viewStaff = (staff: any) => {
    setFormValues({
      fullName: staff.user.full_name || "",
      email: staff.user.email || "",
      phone: staff.user.phone || "",
      username: staff.user.username || ""
    });
    setIsViewMode(true); // Set to view mode
    setVisible(true);
  };

  const editProduct = async (staff: any) => {
    setFormValues({
      fullName: staff.user.full_name || "",
      email: staff.user.email || "",
      phone: staff.user.phone || "",
      username: staff.user.username || ""
    });
    setFormErrors({
      fullName: "",
      email: "",
      phone: "",
      username: "",
      department: ""
    });
    setStaff(staff);
    setVisible(true);
    setIsAddingNewStaff(false);
    setIsViewMode(false);
  };

  const handleAssignDriverDialog = (rowData: any) => {
    setIsAssigningDialog(true);
    setStaff(rowData);
  };

  const handleAssignDriverFunc = async () => {
    const response = await assignDriver(selectedDriver, staff?.user_id)
    if (response.status === 200) {
      setSelectedDriver(null);
      setIsAssigningDialog(false)
      fetchStaffList();
      fetchDriverList()
      return toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `${response?.data?.message}`,
      });
    } else {
      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Failed To Assign Driver`,
      });
    }
  }
  const handleUnAssignDriverFunc = async () => {
    const response = await assignDriver(selectedDriver, staff?.user_id)
    if (response.status === 200 || response.status === 201) {
      setSelectedDriver(null);
      setIsUnAssigningDialog(false)
      fetchStaffList();
      fetchDriverList()
      return toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `${response?.data?.message}`,
      });
    } else {
      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Failed To Assign Driver`,
      });
    }
  }

  const handleUnAssignDriver = (rowData: any) => {
    setIsUnAssigningDialog(true);
    setStaff(rowData);
    setSelectedDriver(rowData?.driver_id);
  }

  const actionBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          className="rounded-button mr-2 view-btn"
          onClick={() => viewStaff(rowData)}
          tooltip="View" tooltipOptions={{ position: 'top' }}
        />
        <Button
          icon="pi pi-pencil"
          className="rounded-button mr-2 edit-btn"
          onClick={() => editProduct(rowData)}
          tooltip="Edit" tooltipOptions={{ position: 'top' }}
        />
        {/* <Button icon="pi pi-trash" className="rounded-button mr-2 delete-btn" /> */}
        {(userRole.code !== corporateAdminRole) && (!rowData?.driver_id) &&
          (<Button
            icon="pi pi-user-edit"
            className="rounded-button mr-2 edit-btn"
            onClick={() => handleAssignDriverDialog(rowData)}
            tooltip="Assign Driver" tooltipOptions={{ position: 'top' }}
          />)
        }
        {rowData?.driver_id &&
          <Button
            icon="pi pi-minus-circle"
            className="rounded-button mr-2 edit-btn"
            onClick={() => handleUnAssignDriver(rowData)}
            tooltip="Unassign Driver" tooltipOptions={{ position: 'top' }}
          />
        }
      </React.Fragment>
    );
  };

  const onPageChange = (e: any) => {
    const newPageNumber = e.first / e.rows + 1;
    setPageNumber(newPageNumber);
    // setCurrentPage(newPageNumber);
    setRowsPerPageOption(e.rows);
  };

  const paginatorTemplate = {
    layout:
      "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",

    CurrentPageReport: (options: any) => {
      return (
        <span
          style={{
            color: "#6c757d",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
            display: "block",
          }}
        >
          {options.first} to {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  const debouncedFetchStaffList = debounce((searchValue: string) => {
    fetchStaffList(searchValue);
  }, 500);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedFetchStaffList(e.target.value);
  };

  const handleAddStaff = () => {
    setFormValues({
      fullName: "",
      email: "",
      phone: "",
      username: ""
    });
    setStaff([]);
    setVisible(true);
    setIsViewMode(false);
    setIsAddingNewStaff(true);
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files[0]) {
      const formData = new FormData();
      formData.append("file", files[0]);

      const response = await importStaffUpload(formData);
      if (response.status === 200 || response.status === 201) {
        event.target.value = "";

        return toast?.current?.show({
          severity: "success",
          summary: "Success",
          detail: `${response?.data?.message}`,
        });
      }
    }
  };

  return (
    <div className="staff-mgmt-wrapper">
      {loading && <div className="loading-overlay">Loading...</div>}
      <Toast ref={toast} />
      <div className="staff-mgmt-header">
        <h1 className="staff-mgmt-title">Staff Management</h1>
        <div className="staff-mgmt-stats-wrapper">
          <div className="staff-mgmt-status-wrapper">
            <div className="total-staff-wrapper">
              <div className="total-staff-title">Total No. Staff:</div>
              <div className="total-staff-content">{staffStatsData?.total_staff}</div>
            </div>
          </div>
          <div className="staff-mgmt-status-wrapper">
            <div className="total-staff-wrapper">
              <div className="total-staff-title">Total No of Orders:</div>
              <div className="total-staff-content">{staffStatsData?.total_order}</div>
            </div>
          </div>
          <div className="staff-mgmt-status-wrapper">
            <div className="total-staff-wrapper">
              <div className="total-staff-title">
                Total Number of appointments:
              </div>
              <div className="total-staff-content">{staffStatsData?.total_appointment}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="staff-mgmt-section-two">
        <div className="staff-mgmt-filters-wrapper">
          <div className="staff-mgmt-container">
            <div className="staff-search-dropdowns-wrapper">
              <div className="staff-search-wrapper">
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    placeholder="Search"
                    className="staff-search-input"
                    onChange={handleSearchInputChange}
                  />
                </span>
              </div>
              <div className="staff-dropdowns-wrapper">
                <div className="all-staff-filter-right">
                  <div className="all-staff-department">
                    <Dropdown
                      value={selectedDepartment}
                      onChange={(e) => setSelectedDepartment(e.value)}
                      options={departmentOptions}
                      optionLabel="department_name"
                      optionValue="company_department_id"
                      placeholder="Department"
                      className="w-full staff-mgmt-filters"
                      showClear
                      filter
                    />
                  </div>
                  <div className="all-staff-status">
                    <Dropdown
                      value={selectedStatus}
                      onChange={(e) => setSelectedStatus(e.value)}
                      options={statusOptions}
                      optionLabel="name"
                      optionValue="value"
                      placeholder="Order Status"
                      className="w-full staff-mgmt-filters"
                      showClear
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="staff-mgmt-import-btn-container">
              <div className="staff-mgmt-import-btn-wrapper">
                <input
                  type="file"
                  ref={fileInputRef}
                  id="fileUpload"
                  style={{ display: "none" }}
                  accept=".xlsx, .xls"
                  onChange={handleFileUpload}
                />
                <Button
                  label="Import Staff"
                  className="staff-mgmt-import-btn"
                  onClick={handleImportStaffClick}
                />
              </div>
              <div className="staff-mgmt-add-btn-wrapper">
                <Button className="staff-mgmt-add-btn" onClick={handleAddStaff}>
                  <FaPlus /> Add Staff
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="staff-mgmg-list-wrapper">
        <div className="card">
          <DataTable value={staffListState || []} dataKey="id">
            {userRole.code === corporateAdminRole && columns.map((col, index) => (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                body={col.body}
                style={col.style}
                className="staff-mgmt-table-data"
              />
            ))}
            {userRole.code === fleetAdminRole && columnsTwo.map((col, index) => (
              <Column
                key={index}
                field={col.field}
                header={col.header}
                body={col.body}
                style={col.style}
                className="staff-mgmt-table-data"
              />
            ))}
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ minWidth: "12rem" }}
            ></Column>
          </DataTable>
          <Paginator
            first={(pageNumber - 1) * rowsPerPageOption}
            rows={rowsPerPageOption}
            totalRecords={records}
            rowsPerPageOptions={[5, 10, 25, records]}
            onPageChange={onPageChange}
            template={paginatorTemplate}
          />
        </div>
      </div>
      <Dialog
        visible={visible}
        modal
        style={{ width: "40%" }}
        onHide={() => setVisible(false)}
        header={
          isViewMode
            ? "View Staff Details"
            : isAddingNewStaff
              ? "Add Staff"
              : "Edit Staff"
        }
        className="add-staff-dialog"
      >
        <div className="staff-add-form-wrapper">
          {userRole.code !== fleetAdminRole &&
            <>
              <div className="staff-last-name-wrapper">
                <InputText
                  disabled={isViewMode}
                  value={formValues?.fullName}
                  onChange={(e) => handleInputChange(e, "fullName")}
                  placeholder="Full Name"
                  className="staff-add-form-input staff-add-form-last-name"
                />
                {formErrors?.fullName && (
                  <small className="p-error">{formErrors?.fullName}</small>
                )}
              </div>
              <div className="staff-phone-wrapper">
                <InputText
                  disabled={isViewMode}
                  value={formValues.phone}
                  onChange={(e) => handleInputChange(e, "phone")}
                  placeholder="Phone Number"
                  className="staff-add-form-input staff-add-form-phone"
                  maxLength={10}
                />
                {formErrors.phone && (
                  <small className="p-error">{formErrors.phone}</small>
                )}
              </div>
              <div className="staff-email-wrapper">
                <InputText
                  disabled={isViewMode}
                  value={formValues?.email}
                  onChange={(e) => handleInputChange(e, "email")}
                  placeholder="Email"
                  className="staff-add-form-input staff-add-form"
                />
                {formErrors?.email && (
                  <small className="p-error">{formErrors?.email}</small>
                )}
              </div>
              <div className="staff-email-wrapper">
                <Dropdown
                  value={selectedDepartmentForSave}
                  onChange={(e) => setSelectedDepartmentForSave(e.value)}
                  options={departmentOptions}
                  optionLabel="department_name"
                  optionValue="company_department_id"
                  placeholder="Department"
                  className="staff-add-form-input staff-add-form p-component staff-add-form-input staff-add-form"
                  showClear
                  filter
                />
                {formErrors?.department && (
                  <small className="p-error">{formErrors?.department}</small>
                )}
              </div>
            </>
          }
          {userRole.code === fleetAdminRole && <div className="staff-username-wrapper">
            <InputText
              disabled={isViewMode}
              value={formValues?.username}
              onChange={(e) => handleInputChange(e, "username")}
              placeholder="Vehicle Number"
              className="staff-add-form-input staff-add-form username"
            />
            {formErrors?.username && (
              <small className="p-error">{formErrors?.username}</small>
            )}
          </div>}
          {!isViewMode && (
            <Button
              className="staff-add-form-btn"
              type="submit"
              label="Submit"
              onClick={handleSubmit}
            />
          )}
        </div>
      </Dialog>
      <Dialog
        visible={isAssigningDialog}
        modal
        style={{ width: "40%" }}
        onHide={() => setIsAssigningDialog(false)}
        header={`Assign Driver to ${staff?.user?.full_name ?? 'Staff'}`}
        className="add-staff-dialog"
      >
        <div className="staff-add-form-wrapper">
          <Dropdown
            value={selectedDriver}
            onChange={(e) => setSelectedDriver(e.value)}
            options={driverListState}
            optionLabel="label"
            optionValue="value"
            placeholder="Select a Driver"
            className="w-full"
            filter
            showClear
          />
          <Button label="Assign" className="driver-assign-btn" onClick={handleAssignDriverFunc} />
        </div>
      </Dialog>
      <Dialog
        visible={isUnAssigningDialog}
        modal
        style={{ width: "40%" }}
        onHide={() => setIsUnAssigningDialog(false)}
        header={`Unassign Driver of ${staff?.full_name ?? 'Staff'}`}
        className="add-staff-dialog"
      >
        <div className="staff-add-form-wrapper">
          Are you sure you want to unassign this driver?
          <div className="flex ">
            <Button label="No" className="driver-assign-btn col-6" onClick={() => setIsUnAssigningDialog(false)} />
            <Button label="Yes" className="driver-assign-btn col-6" onClick={handleUnAssignDriverFunc} />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default StaffManagement;
