import React, { useState, useEffect, FC } from "react";
import { format, isWithinInterval, add, isSameDay } from "date-fns"; // Import isSameDay function

import "./HorizontalDatePicker.css";

interface HorizontalDatePickerProps {
  startDate: Date;
  endDate: Date;
  onDateSelect: (date: Date) => void;
  externalSelectedDate?: Date | null;
}

const HorizontalDatePicker: FC<HorizontalDatePickerProps> = ({
  startDate,
  endDate,
  onDateSelect,
  externalSelectedDate,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date>(startDate) as any;

  useEffect(() => {
    // Ensure that the dates are compared without their time components
    const startOfStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const startOfEndDate = new Date(endDate.setHours(0, 0, 0, 0));
    const startOfExternalDate = externalSelectedDate
      ? new Date(externalSelectedDate.setHours(0, 0, 0, 0))
      : null;

    if (
      startOfExternalDate &&
      isWithinInterval(startOfExternalDate, {
        start: startOfStartDate,
        end: startOfEndDate,
      })
    ) {
      setSelectedDate(startOfExternalDate);
    } else {
      setSelectedDate(null);
    }
  }, [externalSelectedDate, startDate, endDate]);

  const dates = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = add(currentDate, { days: 1 });
  }

  const handleDateClick = (date: Date) => {
    setSelectedDate(date);
    onDateSelect(date);
  };

  return (
    <div className="date-picker-container">
      {dates.map((date, index) => {
        // Ensure that the date being compared is set to the start of the day
        const startOfDate = new Date(date.setHours(0, 0, 0, 0));
        return (
          <div
            key={index}
            className={`date-item ${
              selectedDate && isSameDay(selectedDate, startOfDate)
                ? "selected"
                : ""
            }`}
            onClick={() => handleDateClick(startOfDate)}
          >
            <div className="date-number">{format(startOfDate, "d")}</div>
            <div className="date-day">{format(startOfDate, "EEE")}</div>
          </div>
        );
      })}
    </div>
  );
};

export default HorizontalDatePicker;
