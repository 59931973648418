import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { useEffect, useRef, useState } from "react";
import { BiShoppingBag } from "react-icons/bi";
import { FaBars, FaTimes } from "react-icons/fa";
import {
  MdGpsFixed,
  MdOutlineKeyboardArrowDown,
  MdSearch,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import useCartAPI from "../../api/useCartAPI";
import useHeroAPI from "../../api/useHeroAPI";
import { RootState } from "../../app/store";
import BuildingImg from '../../assets/images/building.png';
import locationPin from "../../assets/images/location_pin.png";
import loginBannerImg from "../../assets/images/login-banner-img-5.png";
import logo from "../../assets/images/logo.png";
import UserImg from '../../assets/images/user_2.png';
import { selectItems, updateCartQuantity } from "../../features/cart/cartSlice";
import Login from "../../pages/Login/Login";
import Register from "../../pages/Register/Register";
import UserLogin from "../../pages/UserLogin/UserLogin";
import UserRegister from "../../pages/UserRegister/UserRegister";
import { selectCity, setSelectedCity } from "../../redux/citySlice";
import {
  alreadyRegistered,
  hideDialog,
  hideUserDialog,
  selectDialog,
  selectUserDialog,
  setLoginType,
  setShowLoginDialog,
  setShowRegister,
  setShowUserRegister,
  userAlreadyRegistered
} from "../../redux/dialogSlice";
import { selectCartItems } from "../../redux/productSlice";
import {
  isLoggedInState,
  isLoggedOutState,
  logOutUserFunc,
} from "../../redux/userState";
import "./Header.css";

function Navbar() {
  const { cartCount } = useCartAPI();
  const { predefinedFunc } = useHeroAPI();

  const basePath = process.env.REACT_APP_BASE_PATH;

  const selectedCityState = useSelector(selectCity);

  // const [cartQuantity, setCartQuantity] = useState(0);
  const cartQuantity = useSelector(
    (state: RootState) => state.cart.cartQuantity
  );
  const [userIsLoggin, setUserIsLoggin] = useState(false);
  const [userLoginVisible, setUserLoginVisible] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [cityOptions, setCityOptions] = useState(null) as any;
  const [stateOptions, setStateOptions] = useState(null) as any;
  const [selectedState, setSelectedState] = useState(null) as any;
  // const [selectedCity, setSelectedCity] = useState(null) as any;
  const [selectingCityDialog, setSelectingCityDialog] = useState(false) as any;
  const [selectedServicePoint, setSelectedServicePoint] = useState(null) as any;
  const [filteredServicePoint, setFilteredServicePoint] = useState(null) as any;
  const [userName, setUserName] = useState(null) as any
  // const [showLoginDialog, setShowLoginDialog] = useState(false);
  // const [loginType, setLoginType] = useState('');

  const showLoginDialog = useSelector((state: RootState) => state.dialog.showLoginDialog);
  const loginType = useSelector((state: RootState) => state.dialog.loginType);
  const showUserRegister = useSelector((state: RootState) => state.dialog.showUserRegister);
  const showRegister = useSelector((state: RootState) => state.dialog.showRegister);


  const handleLoginOptionClick = (type: string) => {
    dispatch(setLoginType(type));
  
    // if (type === 'customer') {
    //   dispatch(setShowUserRegister(true)); 
    // } else {
    //   dispatch(setShowRegister(true)); 
    // }
  };


  const headerElement = (
    <div>
      <Image
        src={loginBannerImg}
        alt="Mechmiles Banner"
        className="login-banner-img"
      />
    </div>
  );

  const visible = useSelector(selectDialog);
  const userVisible = useSelector(selectUserDialog);
  const alreadyRegState = useSelector(alreadyRegistered);
  const alreadyUserRegState = useSelector(userAlreadyRegistered);

  const navRef = useRef() as any;
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const isUserLoggedin = useSelector(isLoggedInState);
  const isUserLoggedOut = useSelector(isLoggedOutState);
  const items = useSelector(selectItems);

  const openLoginDialog = () => {
    dispatch(setShowLoginDialog(true));
  };

  const showNavbar = () => {
    navRef?.current?.classList.toggle("responsive_nav");
  };
  const fetchCartProducts = async () => {
    const payload = {};
    const response: any = await cartCount(payload);
    if (response?.status === 200) {
      const newCartQuantity = response.data.count;
      dispatch(updateCartQuantity(newCartQuantity));
    }
    // setCartQuantity(response?.data?.data?.length || 0);
  };

  const fetchPredefined = async () => {
    const payload = {
      entity_type: "STATE",
    };
    const predefinedResponse = await predefinedFunc(payload);
    if (predefinedResponse.status === 200) {
      setStateOptions(predefinedResponse.data.data);
    }
  };

  const cartItems = useSelector(selectCartItems);

  useEffect(() => {
    // if(isUserLoggedin){
    fetchCartProducts();
    // fetchPredefined();
    // }
  }, [isUserLoggedin, cartItems]);

  useEffect(() => {
    const user: any = localStorage?.getItem("authTokens");
    const authTokens = JSON.parse(localStorage.getItem("authTokens") ?? "{}")

    if (authTokens?.user?.full_name) {
      const firstName = authTokens?.user?.full_name.split(' ')[0];
      setUserName(firstName);
    }
    // Check if the user token exists and is not "undefined"
    if (user && user !== "undefined") {
      const parsedUser = JSON.parse(user);
      // Additional checks can be added here to validate the token
      setUserIsLoggin(!!parsedUser);
    } else {
      setUserIsLoggin(false);
    }
  }, [isUserLoggedin, isUserLoggedOut]);

  useEffect(() => {
    const fetchData = async () => {
      // if (selectedState) {
      const payload = {
        entity_type: "CITY",
        // parent_id: selectedState,
      };
      const predefinedResponse = await predefinedFunc(payload);
      if (predefinedResponse.status === 200) {
        setCityOptions(predefinedResponse.data.data);
      }
      // }
    };

    fetchData();
  }, []);

  const logOutUserFuncCall = () => {
    localStorage.clear();
    setUserIsLoggin(false);
    dispatch(logOutUserFunc());
    navigate(`${basePath}`);
  };

  const redirectToProfile = () => {
    navigate(`${basePath}profile`);
  };

  const redirectToCart = (e: any) => {
    e.preventDefault();
    navigate(`${basePath}cart`);
  };

  const handleStateSelection = async (e: any) => {
    setSelectedState(e.target.value);
    setSelectedCity(null);
  };
  const handleCitySelection = async (e: any) => {
    setSelectedCity(e.target.value);
    localStorage.setItem("selectedCity", e.target.value);
    setSelectingCityDialog(false);
  };

  const itemTemplate = (item: any) => {
    return (
      <div className="auto-complete-text-wrapper">
        <div className="auto-complete-container">
          <Image
            alt={item.name}
            src={locationPin}
            className={`auto-complete-pin-img`}
          // style={{ width: "26px" }}
          />
          <div>{item.name}</div>
        </div>
        <hr />
      </div>
    );
  };

  const handleLocationSelect = (e: any) => {
    setSelectedServicePoint(e.value);
    // if (typeof e.value === "object") {
    //   setIsLocationSelected(true);
    // }
  };
  const handleLocationSelection = async (e: any) => {
    // setSelectedCity(e.value.predefined_id);
    setSelectingCityDialog(false);
    localStorage.setItem("selectedCity", e.value.predefined_id);
    dispatch(setSelectedCity(e.value.predefined_id));
  };
  const search = (event: any) => {
    setTimeout(() => {
      let _filteredServicePoint: any;

      if (!event.query.trim().length) {
        _filteredServicePoint = [...cityOptions];
      } else {
        _filteredServicePoint = cityOptions?.filter((country: any) => {
          return country.name
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      setFilteredServicePoint(_filteredServicePoint);
    }, 250);
  };

  const handleLoginType = () => {
    dispatch(setLoginType(''));
    dispatch(setShowLoginDialog(false));
    // Reset the registration dialogs to be hidden when closing the login dialog
    dispatch(setShowUserRegister(false));
    dispatch(setShowRegister(false));
  
  }

  return (
    <>
      <header className="sticky-header">
        <div className="card flex justify-content-center nav-image">
          <Link to={`${basePath}`}>
            <Image src={logo} alt="Image" width="132" height="32px" />
          </Link>
        </div>
        <div
          className="login-dialog"
          style={{ display: `${visible ? "block" : "none"}` }}
        >
          <Dialog
            visible={visible}
            modal
            style={{ width: "30%" }}
            onHide={() => dispatch(hideDialog())}
            header={headerElement}
            className="login-dialog-box"
          >
            {alreadyRegState ? <Login /> : <Register />}
            {/* <Login/> */}
          </Dialog>
        </div>
        <div
          className="login-dialog"
          style={{ display: `${visible ? "block" : "none"}` }}
        >
          <Dialog
            visible={userVisible}
            modal
            style={{ width: "30%" }}
            onHide={() => dispatch(hideUserDialog())}
            header={headerElement}
            className="login-dialog-box"
          >
            {alreadyUserRegState ? <UserLogin /> : <UserRegister />}
            {/* {<UserLogin/>} */}
            {/* <Login/> */}
          </Dialog>
        </div>
        <nav ref={navRef}>
          <NavLink
            to={`${basePath}`}
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Tyres
          </NavLink>
          <NavLink
            // to={`${basePath}batteries`}
            onClick={() => navigate(`${basePath}batteries`, {
              state: {
                code: 'BATTERY'
              }
            })}
            to={{
              pathname: `${basePath}batteries`
              // state: {code:'BATTERY'}  
            }}
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Batteries
          </NavLink>
          <NavLink
            to={`${basePath}servicesandrepair`}
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Services and Repair
          </NavLink>
          {/* <NavLink
            to={`${basePath}offers`}
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Offers
          </NavLink> */}
          {/* <NavLink
            to={`${basePath}findservicepoints`}
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Find service points
          </NavLink> */}
          {/* {!isUserLoggedin && !userIsLoggin && (
            <Button
              icon="pi pi-user"
              label="Login"
              className="nav-custom-btn"
              onClick={() => dispatch(showUserDialog())}
            />
          )}
          {!isUserLoggedin && !userIsLoggin && (
            <Button
              icon="pi pi-building"
              label="Corporate Login"
              className="ml-2 nav-custom-btn"
              onClick={() => dispatch(showDialog())}
            />
          )} */}
          {!isUserLoggedin && !userIsLoggin && (
            <Button
              icon="pi pi-user"
              label="Login"
              className="nav-custom-btn"
              onClick={openLoginDialog}
            />
          )}
          {(isUserLoggedin || userIsLoggin) && (
            <div>
              {(isUserLoggedin || userIsLoggin) && (
                <div
                  onMouseEnter={() => setShowLogout(true)}
                  onMouseLeave={() => setShowLogout(false)}
                  className="flex nav-custom-btn-profile ml-2"
                >
                  <Button
                    label={userName ? userName : "User Name"}
                    className="ml-2 nav-custom-btn"
                    onClick={redirectToProfile}
                  />
                  {/* <div className="logout-div" onClick={logOutUserFuncCall}>Logout</div> */}
                </div>
              )}
            </div>
          )}
          <div
            className="flex nav-custom-btn-location ml-2"
            onClick={() => setSelectingCityDialog(true)}
          >
            <Button
              icon="pi pi-map-marker"
              label={
                selectedCityState && cityOptions?.find(
                  (city: any) => city.predefined_id === selectedCityState
                )?.name ? cityOptions.find(
                  (city: any) => city.predefined_id === selectedCityState
                ).name : "Location"
              }
              className="p-0 clear location-btn"
              onClick={() => setSelectingCityDialog(true)}
            />
            <MdOutlineKeyboardArrowDown className="ml-2" />
          </div>
          {(isUserLoggedin || userIsLoggin) && (
            <div className="cart-icon-wrapper">
              <Button
                icon={<BiShoppingBag />}
                onClick={(e) => redirectToCart(e)}
                className="ml-2 cart-icon"
              />
              <div className="cart-quantity">{`${cartQuantity}`}</div>
            </div>
          )}
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
        {/* {showCart && (
          <div>
            {items.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </div>
        )} */}
      </header>
      <Dialog
        visible={selectingCityDialog}
        modal
        style={{ width: "30%" }}
        onHide={() => setSelectingCityDialog(false)}
        header={"Please Select a City"}
        className=""
      >
        <span className="w-full">
          <AutoComplete
            field="name"
            value={selectedServicePoint}
            suggestions={filteredServicePoint}
            completeMethod={search}
            onChange={(e) => handleLocationSelect(e)}
            itemTemplate={itemTemplate}
            // panelFooterTemplate={panelFooterTemplate}
            className="service-point-auto-complete"
            placeholder="Enter a location"
            onSelect={(e) => handleLocationSelection(e)}
          />
          <div className="auto-complete-icons-wrapper p-input-icon-right">
            <MdSearch className="auto-complete-search-location" />
            <MdGpsFixed className="auto-complete-choose-location" />
          </div>
        </span>
      </Dialog>
       <Dialog
        visible={showLoginDialog}
        onHide={handleLoginType}
        modal
        className="login-dialog-box login-dialog-box-2"
        header={headerElement}
        style={{ width: "30%" }}
        position={'right'}
      >
        {!loginType && (
          <div className="login-buttons-wrapper">
            <div className="login-buttons-title">
            Choose the type of <br/>login
            </div>
            <Button onClick={() => handleLoginOptionClick('customer')}><Image src={BuildingImg} className="login-buttons-icons"/> Customer</Button>
            <Button onClick={() => handleLoginOptionClick('corporate')}><Image src={UserImg} className="login-buttons-icons"/> Corporate</Button>
          </div>
        )}
        {loginType === 'customer' && !showUserRegister && <UserLogin />}
        {loginType === 'customer' && showUserRegister && <UserRegister />}
        {loginType === 'corporate' && !showRegister && <Login />}
        {loginType === 'corporate' && showRegister && <Register />}
      </Dialog>
    </>
  );
}

export default Navbar;
