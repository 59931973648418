import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const baseURL = process.env.REACT_APP_BASE_URL;

const useAxios = () => {
    const navigate = useNavigate();

    const tokenData = localStorage?.getItem('authTokens');

    if (tokenData === undefined) {
        navigate('/');
    }

    const { access_token, refresh_token } = tokenData && tokenData !== '' ? JSON.parse(tokenData) : { access_token: '', refresh_token: '' };
    const headers = {
        Authorization: `Bearer ${access_token}`,
        "Access-Control-Allow-Origin": '*',
        withCredentials: false,
    };

    const axiosInstance = axios.create({
        baseURL,
        headers,
    });

    axiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const originalRequest = error.config;
            if (error.response && error.response.status === 401 && error.response.data.error === 'TOKEN_EXPIRED') {
                const token = await getNewAccessToken(refresh_token);
                originalRequest.headers['Access-Control-Allow-Origin'] = `*`;
                
                if (token) {
                    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    originalRequest.headers['Authorization'] = `Bearer ${token}`;
                    return axiosInstance(originalRequest);
                }
            } else if (error.response && error.response.status === 401 && error.response.data.error === 'SESSION_EXPIRED') {
                localStorage.clear();
                navigate('/');
            }else{
                return error;
            }
            // return Promise.reject(error);
        }
    );

    return {axiosInstance};
};

const getNewAccessToken = async (refreshToken: string) => {
    let result = await fetch(baseURL + '/auth/refresh-token', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            refresh_token: refreshToken
        })
    });
    let json = await result.json();
    localStorage.setItem('authTokens', JSON.stringify(json));
    return json.access_token;
};

export default useAxios;

