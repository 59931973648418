import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  cartItems: [] as any,
  refreshTrigger: false,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    addToProduct: (state, action) => {
      state.cartItems.push(action.payload);
    },
    refreshProductList: (state) => {
      state.refreshTrigger = !state.refreshTrigger;
    },
  },
});

export const { addToProduct, refreshProductList } = productSlice.actions;
export default productSlice.reducer;

export const selectCartItems = createSelector(
  (state: any) => state.product.cartItems,
  (cartItems: any) => cartItems
);

export const selectRefreshTrigger = createSelector(
  (state: any) => state.product.refreshTrigger,
  (refreshTrigger: boolean) => refreshTrigger
);