import { Image } from "primereact/image";
import ceatMini from "../../assets/images/TyreBrandLogos/ceat_mini.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StarPicker } from "react-star-picker";
import { Carousel } from "../Carousel/Carousel";
import { useDispatch, useSelector } from "react-redux";
import { isLoggedInState, isLoggedOutState } from "../../redux/userState";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import useCartAPI from "../../api/useCartAPI";
import { addToCart } from "../../features/cart/cartSlice";
import { addToProduct, refreshProductList } from "../../redux/productSlice";
import {
  CarouselProvider,
  ButtonNext,
  ButtonBack,
  Slide,
  Slider,
} from "pure-react-carousel";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { Product } from "../Product/Product";
import useProductAPI from "../../api/useProductsAPI";
import userDummyImg from "../../assets/images/user.png";
import { setLoading } from "../../redux/loadingSlice";
import { setShowLoginDialog } from "../../redux/dialogSlice";
import { Button } from "primereact/button";
import { FaPlus } from "react-icons/fa";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "./productDetails.css";

export function removeTags(str: string) {
  if (str === null || str === "") return false;
  else str = str.toString();
  return str.replace(/(<([^>]+)>)/gi, "");
}

// type Reviews = {
//   name: string;
//   reviewPara: string;
//   star_count: number;
//   imageUrl?: string;
// };

// // Example dynamic data array
// const reviews: Reviews[] = [
//   {
//     reviewPara:
//       "I recently used Mechmiles for my tire replacement, and I am beyond impressed! The convenience of booking through their website and the expert recommendations for my vehicle made the entire process seamless. The service was quick, and I feel much safer on the road with my new, top-quality tires. Mechmiles is now my go-to for all things tire-related!",
//     name: "Nikhil Mistry",
//     star_count: 4,
//     imageUrl: "https://www.faceplusplus.com/demo/images/demo-pic34.jpg",
//   },
//   {
//     reviewPara:
//       "Kudos to the Mechmiles team for their exceptional tire service! I appreciated the detailed information on tire options available for my car. The professionalism and efficiency displayed during the tire replacement were remarkable. My driving experience has been significantly improved, and I highly recommend Mechmiles for their reliable and customer-centric approach.",
//     name: "Siddharth Sharma",
//     star_count: 3,
//     imageUrl: "https://www.faceplusplus.com/demo/images/demo-pic34.jpg",
//   },
//   {
//     reviewPara:
//       "I cannot thank Mechmiles enough for their outstanding tire-related services. From tire selection to the installation process, everything was smooth and hassle-free. The team's knowledge and attention to detail instilled confidence in their recommendations. I am extremely satisfied with the quality of the tires and the overall service. Mechmiles has earned a loyal customer in me!",
//     name: "Raju Rathod",
//     star_count: 3.5,
//     imageUrl: "https://www.faceplusplus.com/demo/images/demo-pic34.jpg",
//   },
//   {
//     reviewPara:
//       "Choosing the right tires for my vehicle used to be a daunting task until I discovered Mechmiles. Their user-friendly website simplified the entire process. The tire options were well-explained, and the recommended tires have made a noticeable difference in my car's performance. Mechmiles delivers not just tires but a comprehensive and reliable service.",
//     name: "Fairoz Logde",
//     star_count: 4.5,
//     imageUrl: "https://www.faceplusplus.com/demo/images/demo-pic34.jpg",
//   },
// ];

interface Review {
  review_id: number;
  rating: number;
  comments: string;
  product: {
    product_id: number;
    product_name: string;
    reviews_rating: number;
    // Add other product properties as needed
  };
  user: {
    id: number;
    full_name: string;
    // Add other user properties as needed
  };
  // Add other properties as needed
}

type ReviewResponse = Review[];

export default function ProductDetails() {
  const { slug } = useParams();

  const basePath = process.env.REACT_APP_BASE_PATH;

  const loading = useSelector((state: any) => state.loading);

  const {
    getProductList,
    getProductReview,
    getProductBySlug,
    saveProductReviewAPi,
  } = useProductAPI();
  const { cartSave } = useCartAPI();

  const location = useLocation();
  // const productData = location.state?.product;

  const isLoggedIn = useSelector(isLoggedInState);
  const isLoggedOut = useSelector(isLoggedOutState);
  const [userId, setUserId] = useState(null) as any;
  const [isShowPrice, setIsShowPrice] = useState(false) as any;
  const [productData, setProductData] = useState(null) as any;
  const [productImages, setProductImages] = useState(null) as any;

  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [slideCount, setSlideCount] = useState(3);

  const [userReviews, setUserReviews] = useState<ReviewResponse | []>([]);
  const [popularList, setPopularList] = useState(null) as any;
  const [isWritingReview, setIsWritingReview] = useState(false) as any;

  const [formValues, setFormValues] = useState({
    rating: 0,
    comments: "",
    email: "",
    phone: "",
    dl: "",
  });
  const [formErrors, setFormErrors] = useState({
    rating: "",
    comments: "",
    email: "",
    phone: "",
    dl: "",
  });

  const productFeatures = productData?.product_feature?.map((features: any) => {
    return (
      <div style={{ display: `${features?.feature?.url ? `block` : `none`}` }}>
        <div className="prod-bottom-section-text">
          <Image src={features?.feature?.url} />
          <div className="features-text">
            {features?.feature?.name ?? "Not Available"}
          </div>
        </div>
      </div>
    );
  });

  const handleAddToCart = async () => {
    if (!userId) {
      setTimeout(() => {
        dispatch(setShowLoginDialog(true));
      }, 1000);

      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Please Login`,
      });
    }

    // navigate("/mechmiles-customer/cart", { state: { product } });

    const payload = {
      product_id: productData?.product_id,
      item_quantity: 1,
      is_wishList: false,
    };

    const response: any = await cartSave(payload);

    if (response.status === 201) {
      dispatch(addToCart(productData));
      dispatch(addToProduct(productData));
      dispatch(refreshProductList());
      setProductData((prevData: any) => ({
        ...prevData,
        is_goto: true,
      }));
      return toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `${productData?.product_name} Added to cart`,
      });
    }
  };

  const handleGoToCart = () => {
    // Add forward slash for for production
    navigate(`${basePath}cart`);
  };

  const fetchPopularList = async () => {
    const payload = {
      entity_type: "POPULAR",
      page_size: 6,
      page_number: 1,
      product_type_id: productData?.product_type_id,
    };

    const response: any = await getProductList(payload);
    if (response?.status === 200 || response?.status === 201) {
      setPopularList(response?.data?.slice(0, 6));
    }
  };

  const fetchReviews = async () => {
    const payload = {
      // "product_id": productData?.product_id || 508,
      product_id: 508,
      page_number: 1,
      page_size: 5,
    };

    const response: any = await getProductReview(payload);
    setUserReviews(response?.data);
  };

  useEffect(() => {
    const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    setUserId(authTokens?.user?.id);
    if (authTokens?.user?.is_show_price === true) {
      setIsShowPrice(true);
    }
  }, [isLoggedIn, isLoggedOut, userId]);

  useEffect(() => {
    function updateSlideCount() {
      const width = window.innerWidth;
      if (width < 768) {
        setSlideCount(1);
      } else if (width < 1200) {
        setSlideCount(2);
      } else {
        setSlideCount(3);
      }
    }

    updateSlideCount();
    window.addEventListener("resize", updateSlideCount);
    return () => window.removeEventListener("resize", updateSlideCount);
  }, []);

  const fetchProductBySlugFunc = async (slug?: any) => {
    const response: any = await getProductBySlug(slug);
    if (response?.status === 200 || response?.status === 201) {
      setProductData(response?.data?.data);

      const productImages: any = response?.data?.data?.document_as_product?.map(
        (image: any) => {
          return image?.document?.file_path;
        }
      );

      setProductImages(productImages);
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(false));
      navigate(`${basePath}`);
    }
  };

  useEffect(() => {
    if (slug) {
      fetchProductBySlugFunc(slug);
      dispatch(setLoading(true));
      // if (productData) {
      fetchPopularList();
      fetchReviews();
      // }
      fetchProductBySlugFunc(slug);
    }
  }, [slug]);

  const isWritingReviewFunc = () => {
    setIsWritingReview(true);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: e.target.value,
    }));
    // Clear errors when the user starts typing
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };

  const validateForm = () => {
    const errors = {
      rating: "",
      comments: "",
      email: "",
      phone: "",
      dl: "",
    };

    let isValid = true;

    if (!formValues.rating) {
      errors.rating = "Rating is required";
      isValid = false;
    }

    // if (!formValues.comments.trim()) {
    //   errors.comments = "Comment is required";
    //   isValid = false;
    // }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (!userId) {
      setTimeout(() => {
        dispatch(setShowLoginDialog(true));
      }, 1000);

      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Please Login`,
      });
    }
    if (validateForm()) {
      const payload = {
        rating: formValues?.rating,
        comments: formValues?.comments,
        product_id: parseInt(productData?.product_id),
        user_id: parseInt(userId),
      };

      const response: any = await saveProductReviewAPi(payload);
      if (response.status === 201) {
        console.log(response);
        setIsWritingReview(false);
        setFormValues({
          rating: 0,
          comments: "",
          email: "",
          phone: "",
          dl: "",
        });
        fetchReviews();
        return toast?.current?.show({
          severity: "success",
          summary: "Success",
          detail: `Review Saved Successfully`,
        });
      } else {
        return toast?.current?.show({
          severity: "error",
          summary: "Failed",
          detail: `Failed To Save Review`,
        });
      }
    }
  };

  const onChange = (value: any) => {
    setFormValues((prevData) => ({ ...prevData, rating: value }));
  };

  return (
    <>
      {loading && <div className="loading-overlay">Loading...</div>}
      <Toast ref={toast} />
      <section className="detail-hero product-detail-page">
        <div className="container">
          <div className="product-overview">
            <div className="product-image-carousel">
              <Carousel productImages={productImages} />
            </div>
            <div className="overview-content">
              <div className="product-manufacturer-logo">
                <Image src={productData?.brand?.thumbnail || ceatMini} />
              </div>
              <div className="product-name" title={productData?.product_name}>
                {productData?.product_name}
              </div>
              {productData?.rim?.name ? (
                <div className="prod-model">{productData?.rim?.name}</div>
              ) : (
                <p className="prod-model"></p>
              )}
              {/* {productData?.reviews_count > 0 ? (
                <div className="flex">
                  <div className="prod-ratings">
                    <StarPicker
                      value={4.5}
                      halfStars
                      disabled={true}
                      size={20}
                    />
                  </div>

                  <div className="prod-review">
                    {productData?.reviews_count} Reviews
                  </div>
                </div>
              ) : (
                <div className="flex">
                  <div className="prod-ratings">
                    <StarPicker
                      value={4.5}
                      halfStars
                      disabled={true}
                      size={20}
                    />
                  </div>

                  <div className="prod-review">
                    10 Reviews
                  </div>
                </div>
              )} */}

              <div className="popup-mid">
                {productData?.compatible_vehicles && (
                  <p className="product-description-compatible-vehicle">
                    <strong className="popup-title">
                      Compatible Vehicles :
                    </strong>
                    {productData?.compatible_vehicles !== null ? (
                      <div
                        className="section-para"
                        dangerouslySetInnerHTML={{
                          __html: productData?.compatible_vehicles || "",
                        }}
                      ></div>
                    ) : (
                      "Not Available"
                    )}
                  </p>
                )}

                <p className="product-details-warranty">
                  Warranty:{" "}
                  {productData?.warranty !== null
                    ? productData?.warranty
                    : null}
                </p>
              </div>

              <div className="prod-bottom-section-container">
                {productFeatures}
              </div>

              <div className="prod-price-inner-wrapper flex">
                {/* <div className="prod-price"> */}
                {isLoggedOut ? (
                  <>
                    <div className="prod-price">
                      &#8377;{productData?.product_offer_price}
                    </div>
                    <div className="prod-offer-price">
                      &#8377;{productData?.product_price}
                    </div>
                  </>
                ) : (
                  <>
                    {!isShowPrice && (
                      <>
                        <div className="prod-price">&#8377;***</div>
                        <div className="prod-offer-price">&#8377;***</div>
                      </>
                    )}
                    {isShowPrice && (
                      <div className="prod-price">
                        &#8377;{productData?.product_offer_price}
                      </div>
                    )}
                    {isShowPrice && (
                      <div className="prod-offer-price">
                        &#8377;{productData?.product_price}
                      </div>
                    )}
                  </>
                )}
                {/* </div> */}

                {/* <div className="prod-price">
                  &#8377;{productData?.product_price}
                </div>
                <div className="prod-offer-price">
                  &#8377;{productData?.product_offer_price}
                </div> */}
                <div className="prod-inclusive-tax">Inclusive of all Taxes</div>
              </div>

              {productData?.is_goto && (
                <div className="product-go-to-cart" onClick={handleGoToCart}>
                  Go to cart
                </div>
              )}
              {!productData?.is_goto && (
                <div className="product-add-to-cart" onClick={handleAddToCart}>
                  Add to cart
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {productData?.product_type?.code !== "BATTERY" && (
        <section className="specs">
          <div className="container">
            <h3 className="section-title">Specifications</h3>

            <div className="specs-table">
              <p>
                <span className="spec-label">Tyre Type : </span>
                <span className="spec-property">
                  {productData?.tyer_type?.name
                    ? productData?.tyer_type?.name
                    : "Not Available"}
                </span>
              </p>
              <p>
                <span className="spec-label">Tyre Position : </span>
                <span className="spec-property">
                  {productData?.tyer_ps?.name
                    ? productData?.tyer_ps?.name
                    : "Not Available"}
                </span>
              </p>
              <p>
                <span className="spec-label">Width : </span>
                <span className="spec-property">
                  {productData?.width?.name
                    ? productData?.width?.name
                    : "Not Available"}
                </span>
              </p>
              <p>
                <span className="spec-label">Aspect Ratio : </span>
                <span className="spec-property">
                  {productData?.aspect_ratio?.name
                    ? productData?.aspect_ratio?.name
                    : "Not Available"}
                </span>
              </p>
              <p>
                <span className="spec-label">RIM Description : </span>
                <span className="spec-property">
                  {productData?.rim?.name
                    ? productData?.rim?.name
                    : "Not Available"}
                </span>
              </p>
            </div>
          </div>
        </section>
      )}

      {productData?.key_feature && (
        <section className="key-feature">
          <div className="container">
            <span className="section-title">Key Features:</span>
            <div
              className="section-para"
              dangerouslySetInnerHTML={{
                __html: productData?.key_feature || "",
              }}
            ></div>
            {/* {productData?.description !== "NULL" ? (
            <ul className="key-points">

              {productData?.description
                ?.split(", ")
                ?.map((item: any, i: number) => (
                  <li key={i}>{removeTags(item)}</li>
                ))}
            </ul>
          ) : null} */}
          </div>
        </section>
      )}

      {productData?.description && (
        <section className="detail-hero">
          <div className="container">
            <h3 className="section-title">Description</h3>
            <div
              className="section-para"
              dangerouslySetInnerHTML={{
                __html: productData?.description || "Not Available",
              }}
            ></div>
          </div>
        </section>
      )}

      {popularList !== null && popularList.length > 0 && (
        <section className="related-prod-section">
          <div className="container">
            <div className="testimonials-slider">
              <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={100}
                totalSlides={popularList?.length}
                visibleSlides={slideCount}
                step={1}
                currentSlide={0}
                isIntrinsicHeight={true}
              >
                <div className="slider-controls similar-products-carousel">
                  <div className="similar-products-carousel-text">
                    Similar Products
                  </div>

                  <div className="similar-products-carousel-buttons">
                    <ButtonBack className="slider-button back">
                      <MdArrowBackIosNew />
                    </ButtonBack>
                    <ButtonNext className="slider-button next">
                      <MdArrowForwardIos />
                    </ButtonNext>
                  </div>
                </div>
                <Slider>
                  {popularList?.length > 0 &&
                    popularList?.map((product: any, index: any) => (
                      <Slide
                        key={index}
                        index={index}
                        className="testimonial-slide"
                      >
                        <div
                          className={`single-testimonial testimonial-${index}`}
                        >
                          <div className="testimonial-content">
                            <Product product={product} />
                          </div>
                        </div>
                      </Slide>
                    ))}
                </Slider>
              </CarouselProvider>
            </div>
          </div>
        </section>
      )}

      {/* <section className="popular-products-section">
        <PopularProducts code={productData?.product_type?.code} />
      </section> */}

      <section className="product-reviews detail-hero">
        <div className="container">
          <div className="review-wrapper">
            {/* <div className="scores">
              <div className="prod-ratings">
                <StarPicker
                  value={productData?.reviews_rating || 4.5}
                  halfStars
                  disabled={true}
                  size={20}
                />
              </div>
              <p>
                {productData?.reviews_rating || 4.5} average based on
                {productData?.reviews_count || 1000} reviews
              </p>
              <Button className="add-review-btn" label={`+  Write a review`} onClick={isWritingReviewFunc} />
              
            </div> */}
            <div className="feedbacks">
              <div className="flex flex-sb">
                <h3 className="section-title">Customer Reviews </h3>
                <button className="directReviewsPage">View All</button>
              </div>

              {/* {userReviews?.length > 0 &&
                userReviews.slice(0, 2).map((r, i) => (
                <>
                  <div key={r?.review_id} className="customer-review flex">
                    <Image src={userDummyImg} />
                    <div>
                      <h6 className="mb-0">{r?.user?.full_name ?? 'Name Not Available'}</h6>
                      <StarPicker
                        value={r?.product?.reviews_rating}
                        halfStars
                        disabled={true}
                        size={20}
                      />
                    </div>
                  </div>
                  <p>{r?.comments}</p>
                </>
                ))
              } */}

              {userReviews?.length > 0 &&
                userReviews.map((review) => (
                  <>
                    <div
                      key={review.review_id}
                      className="customer-review flex"
                    >
                      <Image src={userDummyImg} />
                      <div>
                        <h6 className="mb-0">
                          {review.user?.full_name ?? "Name Not Available"}
                        </h6>
                        <StarPicker
                          value={review.product?.reviews_rating}
                          halfStars
                          disabled={true}
                          size={20}
                        />
                      </div>
                    </div>
                    <p>{review.comments}</p>
                  </>
                ))}
            </div>
          </div>
        </div>
      </section>
      <Dialog
        visible={isWritingReview}
        modal
        style={{ width: "40%" }}
        onHide={() => setIsWritingReview(false)}
        header={"Add Review"}
        className="add-driver-dialog"
      >
        <div className="driver-add-form-wrapper">
          <div className="driver-last-name-wrapper">
            <StarPicker
              value={formValues?.rating}
              halfStars
              size={40}
              onChange={onChange}
            />
            {formErrors?.rating && (
              <small className="p-error">{formErrors?.rating}</small>
            )}
          </div>

          <div className="driver-comments-wrapper">
            <InputText
              value={formValues.comments}
              onChange={(e) => handleInputChange(e, "comments")}
              placeholder="Comments Number"
              className="driver-add-form-input driver-add-form-comments"
            />
            {/* {formErrors.comments && (
              <small className="p-error">{formErrors.comments}</small>
            )} */}
          </div>

          <Button
            className="driver-add-form-btn"
            type="submit"
            label="Submit"
            onClick={handleSubmit}
          />
        </div>
      </Dialog>
    </>
  );
}
