import { useEffect, useState } from "react";
import { CarouselItem } from "./CarouselItem";
import "./carousel.css";
import { Image } from "primereact/image";

interface CarouselProps {
  productImages: string[];
}

export const Carousel: React.FC<CarouselProps> = ({ productImages }: any) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [propImgs, setPropImgs] = useState(null) as any;

  const items = [
    {
      title: "Image One",
      description:
        "Baseball is a bat-and-ball sport played between two teams of nine players each",
      url: "http://13.126.50.235/final_image/ea90b8de-196c-4c4a-9a59-a0c89ff81584.jpeg"
    },
    {
      title: "Image Two",
      description:
        "Walking (also known as ambulation) is one of the main gaits of terrestrial locomotion among legged animals.",
      url: "http://13.126.50.235/final_image/ea90b8de-196c-4c4a-9a59-a0c89ff81584.jpeg"
    },
    {
      title: "Image Three",
      description:
        "Weightlifting generally refers to activities in which people lift weights, often in the form of dumbbells or barbells.",
      url: "http://13.126.50.235/final_image/ea90b8de-196c-4c4a-9a59-a0c89ff81584.jpeg"
    },
  ];

  // const [carouselData, setCarouselData] = useState(items);



  const updateIndex = (newIndex: any) => {
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= items.length) {
      newIndex = items.length - 1;
    }

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    if (productImages) {
      setPropImgs(productImages)
    }
    const carouselItems = productImages?.length ? productImages : items;
    setPropImgs(carouselItems)
    // console.log("carouselItems", carouselItems)
  }, [productImages])

  return (
    <>
      <div className="img-carousel">
        <div
          className="img-carousel-inner"
          style={{ transform: `translate(-${activeIndex * 100}%)` }}
        >
          {/* {productImages &&
            productImages.map(
              (item: any, i: any) => (
                <div className="img-carousel-item" style={{ width: "100%" }} key={i}>
                  <Image src={item} />
                </div>
              )
            )} */}

          {propImgs?.map((item: any, i: any) => (
            <div className="img-carousel-item" style={{ width: "100%" }} key={i}>
              <Image src={item.url || item} className="carousel-images" />
            </div>
          ))}

        </div>
      </div>
      <div className="carousel-buttons">
        <button
          className="button-arrow prev"
          onClick={() => {
            updateIndex(activeIndex - 1);
          }}
        >
          <span className="material-symbols-outlined">arrow_back_ios</span>
        </button>
        <button
          className="button-arrow next"
          onClick={() => {
            updateIndex(activeIndex + 1);
          }}
        >
          <span className="material-symbols-outlined">arrow_forward_ios</span>
        </button>
      </div>
    </>
  );
};
