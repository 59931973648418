import React, { useEffect, useRef, useState } from "react";
import "./MyOrders.css";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import useOrderAPI from "../../../api/useOrderAPI";
import yokohamaMini from "../../assets/images/TyreBrandLogos/yokohama_mini.png";
import miniDot from "../../../assets/images/mini_dot.png";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/loadingSlice";

interface Item {
  id: number;
  name: string;
  rim: string;
  price: string;
  quantity: number;
  total: string;
}

const MyOrders = () => {
  const { allOrders, allOrdersCount, changeOrderStatus } = useOrderAPI();
  const loading = useSelector((state: any) => state.loading);
  const dispatch = useDispatch();

  const toast = useRef<Toast>(null);

  const [items, setItems] = useState<Item[]>([]);
  const [userId, setUserId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [isShowPrice, setIsShowPrice] = useState(false) as any;
  const [searchTerm, setSearchTerm] = useState("");
  const [debounceTimeoutId, setDebounceTimeoutId] =
    useState<NodeJS.Timeout | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPageOption, setRowsPerPageOption] = useState(10);
  const [records, setRecords] = useState(20);

  const fetchMyOrders = async (searchTerm?: string) => {
    const payload = {
      company_id: companyId,
      search_by: searchTerm,
      user_id: userId,
      page_number: pageNumber,
      page_size: rowsPerPageOption,
    };

    const response: any = await allOrders(payload);
    const responseCount: any = await allOrdersCount(payload);
    if (response.status === 200 || response.status === 201) {
      dispatch(setLoading(false));
      setItems(response?.data);
      setRecords(responseCount?.data?.data);
    }else{
      dispatch(setLoading(false));
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    if (debounceTimeoutId) clearTimeout(debounceTimeoutId);
    setDebounceTimeoutId(setTimeout(() => fetchMyOrders(newSearchTerm), 300));
  };

  // const fetchMyOrders = async () => {
  //   const payload = {
  //     user_id: userId,
  //   };

  //   const response: any = await allOrders(payload);
  //   if (response.status === 200 || response.status === 201) {
  //     setItems(response?.data);
  //   }
  // };

  useEffect(() => {
    dispatch(setLoading(true));
    const authTokens: any = JSON.parse(
      localStorage.getItem("authTokens") || "{}"
    );

    setUserId(authTokens?.user?.id);
    setCompanyId(authTokens?.user?.company_id);
    if (userId !== "") {
      fetchMyOrders();
    }

    if (authTokens?.user?.is_show_price === true) {
      setIsShowPrice(true);
    }

  }, [userId, pageNumber, rowsPerPageOption]);

  const handleAcceptReject = async (order_id: number, type: string) => {
    const payload = {
      order_id: order_id,
      status_code: type,
    };

    const response: any = await changeOrderStatus(payload);
    if (response.status === 200 || response.status === 201) {
      fetchMyOrders();
      return toast?.current?.show({
        severity: "success",
        summary: "Success",
        detail: `${response?.data?.message}`,
      });
    } else {
      return toast?.current?.show({
        severity: "error",
        summary: "Failed",
        detail: `Failed to change status`,
      });
    }
  };

  const downloadFile = (url: string, fileName: string) => {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = url;
    a.download = fileName || 'download';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

  const onPageChange = (e: any) => {
    const newPageNumber = e.first / e.rows + 1;
    setPageNumber(newPageNumber);
    // setCurrentPage(newPageNumber);
    setRowsPerPageOption(e.rows);
  };

  const paginatorTemplate = {
    layout:
      "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",

    CurrentPageReport: (options: any) => {
      return (
        <span
          style={{
            color: "#6c757d",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
            display: "block",
          }}
        >
          {options.first} to {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <>
    {loading && <div className="loading-overlay">Loading...</div>}
      <Toast ref={toast} />
      <div className="my-order-wrapper">
        <div className="my-order-header-wrapper">
          <div className="my-order-title">My Orders</div>
          <div className="my-order-search-input">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </span>
          </div>
        </div>
        {items.length === 0 && (
          <div className="no-order">No Orders Are Available</div>
        )}
        {items.map((item: any) => (
          <div
            key={`order_${item.order_id}`}
            className="my-order-single-order-container"
          >
            <div className="my-order-single-order">
              <div className="items-container">
                <div className="item-header">
                  <span>Items</span>

                  <div className="item-header-buttons-wrapper">
                    <div
                      className={`item-header-status-indicator ${item?.status?.name
                        .toLowerCase()
                        .replace(/\s/g, "-")}`}
                    >
                      {item?.status?.name}
                    </div>
                    <Button
                      label="Download Invoice"
                      className="p-button-text p-button-plain item-header-download-invoice"
                      onClick={()=>downloadFile(item?.invoice_url, `order-id-${item.order_id}`)}

                    />
                  </div>
                </div>
                <ul className="item-list">
                  {item?.order_items?.map((itm: any) => (
                    <li key={`order_${itm?.order_item_id}`} className="item">
                      <div className="item-title-wrapper">
                        <Image
                          src={itm?.product?.brand?.thumbnail}
                          alt="Yokohama Brand Logo"
                          className="item-title-image"
                        />
                        <div className="item-title-container">
                          <div className="item-name">
                            {(itm?.product?.product_name ?? itm?.service?.service_name) ?? "Not Available"}
                          </div>
                        </div>
                      </div>
                      {/* <Image
                        src={miniDot}
                        alt="Mini Dot"
                        className="item-dot"
                      />
                      <div className="item-details">
                        {itm?.product?.product_rim || "Not Available"}
                      </div> */}
                      <Image
                        src={miniDot}
                        alt="Mini Dot"
                        className="item-dot"
                      />
                      {isShowPrice && (
                        <>
                          <div className="item-price">
                            Price: &#8377;
                            {itm?.product?.product_price || "N/A"}
                          </div>
                          <Image
                            src={miniDot}
                            alt="Mini Dot"
                            className="item-dot"
                          />
                        </>
                      )}
                      <div className="item-quantity">
                        Quantity: {itm?.item_quantity || 0}
                      </div>
                      {isShowPrice && (
                        <Image
                          src={miniDot}
                          alt="Mini Dot"
                          className="item-dot"
                        />
                      )}
                      {isShowPrice && (
                        <div className="item-total">
                          &#8377;{itm?.total_amount || 0}
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* <div className="all-order-single-order">
                      <div className="all-orders-service-point-data">
                        <div className="all-orders-service-point-name">
                          {item?.service_point?.service_point_name}
                        </div>
                      </div>
                    </div> */}
            <div className="all-order-single-order my-order-single-details">
              <div className="single-orders-data">
                <div className="single-orders-data-left">
                  <div className="order-id-container">
                    <div className="order-id-title">Order ID</div>
                    <div className="order-id-content">
                      Order: {item?.order_id || "Not Available"}
                    </div>
                  </div>
                </div>
                <div className="single-orders-data-right">
                  <div className="order-id-container">
                    <div className="order-id-title">Order Date</div>
                    <div className="order-id-content">
                      {new Date(item?.created_at).toLocaleDateString("en-IN", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }) || "Not Available"}
                    </div>
                  </div>
                  <div className="order-id-container">
                    {isShowPrice && (
                      <>
                        <div className="order-id-title">Total</div>
                        <div className="order-id-content">
                          {item?.payable_amount || "Not Available"}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="my-order-action-buttons">
              <Button
                className="cancel-item-button"
                onClick={() => {
                  handleAcceptReject(item?.order_id, "REJECTED");
                }}
              >
                Cancel Item
              </Button>
              <Button className="view-appointment-button">
                View Appointment
              </Button>
            </div> */}
          </div>
        ))}
        <Paginator
          first={(pageNumber - 1) * rowsPerPageOption}
          rows={rowsPerPageOption}
          totalRecords={records}
          rowsPerPageOptions={[5, 10, 25, records]}
          onPageChange={onPageChange}
          template={paginatorTemplate}
        />
      </div>
    </>
  );
};

export default MyOrders;
