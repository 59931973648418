import React, { useEffect, useRef, useState } from "react";
import "./MyWallet.css";
import { Button } from "primereact/button";
import cashBackImg from "../../../assets/images/cashback.png";
import rewardsImg from "../../../assets/images/reward.png";
import { Image } from "primereact/image";
import useWalletAPI from "../../../api/useWalletAPI";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import useCartAPI from "../../../api/useCartAPI";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/loadingSlice";

const MyWallet = () => {
  const basePath = process.env.REACT_APP_BASE_PATH;

  const {
    getWalletHistory,
    getWalletBalance,
    rechargeWalletAPI,
    rechargeCustomerWalletAPI,
  } = useWalletAPI();
  const { getPaymentLink, getPaymentStatus } = useCartAPI();

  const [userId, setUserId] = useState(null) as any;
  const [companyId, setCompanyId] = useState(null) as any;
  const [walletBalance, setWalletBalance] = useState(null) as any;
  const [walletHistoryState, setWalletHistoryState] = useState([]) as any;
  const [addWalletAmount, setAddWalletAmount] = useState(null) as any;
  const [newWindow, setNewWindow] = useState(null);
  const [paymentId, setPaymentId] = useState(null);

  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const loading = useSelector((state: any) => state.loading);
  const dispatch = useDispatch();

  const fetchWalletHistoryFunc = async () => {
    let payload = {} as any;

    let user = JSON.parse(localStorage.getItem("authTokens") as any);

    const userRoles: string[] = user.user.roles.map((role: any) => role.code);
    if (
      userRoles.some((role) =>
        [
          "ROLE_CORPORATE_ADMIN",
          "ROLE_CORPORATE_USER",
          "ROLE_FLEET_OWNER",
          "ROLE_FLEET_USER",
        ].includes(role)
      )
    ) {
      payload = { company_id: user.user.company_id };
    } else {
      payload = { user_id: userId };
    }

    const response: any = await getWalletHistory(payload);
    const walletBalanceResponse: any = await getWalletBalance(
      companyId,
      userId
    );

    if (response.status === 200 || response.status === 201) {
      dispatch(setLoading(false));
      setWalletHistoryState(response.data);
    } else {
      dispatch(setLoading(false));
    }
    if (
      walletBalanceResponse?.status === 200 ||
      walletBalanceResponse?.status === 201
    ) {
      if (walletBalanceResponse?.data?.balance >= 0) {
        setWalletBalance(walletBalanceResponse?.data);
      } else {
        setWalletBalance(0);
      }
    } else {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    dispatch(setLoading(true));
    const authTokens: any = JSON.parse(
      localStorage.getItem("authTokens") ?? "{}"
    );
    if (authTokens?.user?.id) {
      setUserId(authTokens?.user?.id);
      setCompanyId(authTokens?.user?.company_id);
      // setWalletBalance(authTokens?.user?.wallet_balance ?? 0);
    }
    if (userId) {
      fetchWalletHistoryFunc();
    }
  }, [userId]);

  const handleAmountInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAddWalletAmount(value);
  };

  const paymentStatus = async (paymentId: any) => {
    const response: any = await getPaymentStatus(paymentId);
    if (response?.status === 200 || response?.status === 201) {
      if (response?.data?.data?.order_status === "FAILED") {
        return toast?.current?.show({
          severity: "error",
          summary: "Failed",
          detail: `Payment Failed`,
        });
      }
      if (response?.data?.data?.order_status === "SUCCESS") {
        // toast?.current?.show({
        //   severity: "success",
        //   summary: "Successful",
        //   detail: `Payment Successful`,
        // });

        const payload = {
          user_id: userId,
          amount: parseInt(addWalletAmount),
        };

        let addWalletBalanceFunc: any;

        if (companyId) {
          addWalletBalanceFunc = await rechargeWalletAPI(companyId, payload);
        } else {
          addWalletBalanceFunc = await rechargeCustomerWalletAPI(payload);
        }
        if (
          addWalletBalanceFunc.status === 200 ||
          addWalletBalanceFunc.status === 201
        ) {
          // setTimeout(() => {
          //   navigate(`${basePath}profile`);
          // }, 500);

          fetchWalletHistoryFunc();
          setAddWalletAmount("");

          return toast?.current?.show({
            severity: "success",
            summary: "Successful",
            detail: `Payment Successful`,
          });
        }
      }
    }
  };

  const handlePaymentFunc = async () => {
    try {
      if (!addWalletAmount || addWalletAmount === 0) {
        return toast?.current?.show({
          severity: "error",
          summary: "Failed",
          detail: `Please Add Amount`,
        });
      }

      const payload = {
        user_id: userId,
        amount: parseInt(addWalletAmount),
      };
      const response: any = await getPaymentLink(payload);
      // setMakePaymentObject(response?.data);
      const paymentWindow: any = window?.open(
        response?.data?.short_url,
        "",
        "toolbar=0,status=0"
      );
      setNewWindow(paymentWindow);
      setPaymentId(response?.data?.payment_id);
      const timer = setInterval(() => {
        if (paymentWindow?.closed) {
          clearInterval(timer);
          paymentStatus(response?.data?.payment_id);
        }
      }, 1000);
    } catch (err: any) {
      if (err.status === 200) {
        let jsonString = err.error.text;
        jsonString = jsonString.substr(0, jsonString.indexOf('{"timestamp"'));
        // Handle the error case
      }
    }
  };

  return (
    <div className="my-wallet-wrapper">
      {loading && <div className="loading-overlay">Loading...</div>}
      <Toast ref={toast} />
      <div className="my-wallet-title-wrapper">
        <div className="my-wallet-title-text">My Wallet</div>
      </div>
      <div className="my-wallet-amount-add-wrapper">
        <div className="my-wallet-amount-wrapper">
          <div className="my-wallet-amount-text">Your wallet balance</div>
          <div className="my-wallet-amount-value">
            &#8377;{" "}
            {new Intl.NumberFormat("en-IN", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(walletBalance?.balance.toFixed(2) ?? 0)}
          </div>
        </div>
        <div className="my-wallet-add-wrapper">
          <InputText
            value={addWalletAmount}
            placeholder="Add Wallet Amount"
            onChange={handleAmountInputChange}
          />
          <Button className="my-wallet-add-button" onClick={handlePaymentFunc}>
            Add Money
          </Button>
        </div>
      </div>
      <div className="my-wallet-cashback-reward-wrapper">
        <div className="cashback-wrapper">
          <Image src={cashBackImg} className="cashback-image" />
          <div className="cashback-text-wrapper">
            <div className="cashback-title">Reward Point</div>
            <div className="cashback-amount">
              &#8377;
              {new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(walletBalance?.rewards?.rewards.toFixed(2) ?? 0)}
            </div>
          </div>
        </div>
        <div className="cashback-wrapper">
          <Image src={rewardsImg} className="cashback-image" />
          <div className="cashback-text-wrapper">
            <div className="cashback-title">Redeemed</div>
            <div className="cashback-amount">
              &#8377;
              {new Intl.NumberFormat("en-IN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(walletBalance?.rewards?.redeem.toFixed(2) ?? 0)}
            </div>
          </div>
        </div>
      </div>
      <div className="my-wallet-transaction-history-wrapper">
        <div className="my-wallet-transaction-history-wrapper">
          <div className="transaction-history-title">Wallet transactions</div>
          {walletHistoryState.map((walletHistory: any) => {
            return (
              <div className="transaction-item">
                <div className="transaction-description">
                  {walletHistory?.title
                    ? walletHistory?.title
                    : "Not Available"}
                  {/* Assuming you have a way to format the date */}
                  <div className="transaction-time">
                    {walletHistory?.created_at
                      ? new Date(walletHistory.created_at).toLocaleString(
                          "en-IN",
                          { hour: "numeric", minute: "numeric", hour12: true }
                        ) +
                        ", " +
                        new Date(walletHistory.created_at).toLocaleDateString(
                          "en-IN",
                          { day: "2-digit", month: "2-digit", year: "2-digit" }
                        )
                      : "Not Available"}
                  </div>
                </div>
                <div
                  className={`transaction-amount ${
                    walletHistory?.transaction_type === "DEBIT"
                      ? "transaction-amount-negative"
                      : "transaction-amount-positive"
                  }`}
                >
                  {walletHistory?.transaction_type === "DEBIT"
                    ? `- ₹${Math.abs(walletHistory?.amount)}`
                    : `+ ₹${new Intl.NumberFormat("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(walletHistory?.amount ?? 0)}`}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MyWallet;
