import React, { useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";

import "./FAQ.css";
import { Button } from "primereact/button";
import { Container } from "react-bootstrap";

const FAQ = () => {
  const [faqsData, setFaqsData] = useState([
    {
      title: "Where can I avail tyre services with Mechmiles?",
      description:
        "Ans: You can avail services PAN India, across our centres situated in 15 states and 36 cities. These outlets are present at the leading Oil Marketing companies - IOCL, HPCL, BPCL and Nayara Fuel stations. We are working towards scaling our outlets and tying up with other players in India.",
    },
    {
      title:
        "Why should I choose Mechmiles?",
      description:
        "Ans: Mechmiles offers the best Tyres and services with use of latest tools and equipment too at a fair and flexible price. You end up saving up to 10-15% compared to what is charged at a roadside Puncture person.",
    },
    {
      title: "How can you offer upto 10-15% savings on services?",
      description:
        "Ans: We have fixed rate cards and the same is monitored on a regular basis. Over the last 9-10 years we have built an ecosystem conducive to our partners, customers and ourselves.",
    },
    {
      title: "Can I avail tyre services in Pune, although I am based out of Mumbai.",
      description:
        "Ans: Yes, you can avail Tyre related services anywhere in India; the choice is yours. We are a location independent network of Tyre services company. You have to login into our app and opt for a service station which suits your needs",
    },
    {
      title:
        "How is Mechmiles different from other Tyre service outlets out there?",
      description:
        "Ans: Built on a fulfillment model, our tire service outlets have flourished over the past 9-10 years. We've nurtured relationships with partners across India, engaging in successful business dealings for many years. This extensive experience allows us to confidently position ourselves as industry leaders, outshining other players in the market today.",
    },
    {
      title:
        "How can I schedule a tire-related service with Mechmiles?",
      description:
        "Ans: Booking tire-related services is convenient through either our website or our user-friendly Android mobile application.",
    },
    {
      title:
        "Does your application showcase tyres that are best suited for my vehicle?",
      description:
        "Ans: Yes, we have a comprehensive list of Vehicles in our database and we keep updating it on a regular basis. We understand that tyres form the most important part of the vehicle. Hence, they have to be monitored and a best tyre will keep away from accidents. On our application and website, the vehicles are mapped with the tyres with recommendation for your vehicle.",
    },
    {
      title:
        "Would I be charged in case I cancel the services?",
      description:
        "Ans: We would like to provide our services to you at all times. In case you wish to cancel we will not charge you for the same, however we would like to know the reason for the cancellation and understand your requirement.",
    },
    {
      title:
        "What if your product or services are not up to the mark?",
      description:
        "Ans: We always try to provide the best of the services and products. In business we cannot have 100% appreciation from our customers, but we strive to improve the quality by taking timely feedback from our esteemed customers and rectifying the same on a real time basis. We will do our best to keep you happy and handle your query as it comes.",
    },
  ]);

  return (
    <div>
      <h3 className="faq-section-title">Frequently asked questions</h3>
      <Container>
        <div className="faq-wrapper">
          <Accordion activeIndex={0}>
            {faqsData.map((faq: any, index: number) => (
              <AccordionTab header={`${index + 1}. ${faq.title}`} key={index}>
                <p className="m-0">{faq.description}</p>
              </AccordionTab>
            ))}
          </Accordion>
          {/* <div className="read-more-faq">
            <Button label={"Read More FAQs"} className="read-more-faq-btn" />
          </div> */}
        </div>
      </Container>
    </div>
  );
};

export default FAQ;
