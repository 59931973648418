import axios from "axios";
import useAxios from "../hooks/useAxios";

const baseURL = process.env.REACT_APP_BASE_URL;

let url = "/driver";
let staffListUrl = "/driver-list";
let staffCountUrl = "/driver-count";
let saveDriverURL = "/save";
let updateDriverURL = "/update";
let assignDriverUrl = "/assigned-driver";
let uploadFileUrl = "/driver-import";

const useLoginAPI = () => {
  const { axiosInstance } = useAxios();

  const getDriverList = async (data: any) => {
    let response = await axiosInstance.post(
      baseURL + `${url}${staffListUrl}`,
      data
    );
    return response;
  };
  const getDriverCount = async (data: any) => {
    let response = await axiosInstance.post(
      baseURL + `${url}${staffCountUrl}`,
      data
    );
    return response;
  };
  const assignDriver = async (driverId: any, staffId: any) => {
    let response = await axiosInstance.get(
      baseURL + `${url}${assignDriverUrl}/${driverId}/staff/${staffId}`
    );
    return response;
  };
  const saveDriver = async (data: any) => {
    let response = await axiosInstance.post(
      baseURL + `${url}${saveDriverURL}`,
      data
    );
    return response;
  };
  const updateDriver = async (driverId: any, data: any) => {
    let response = await axiosInstance.put(
      baseURL + `${url}${updateDriverURL}/${driverId}`,
      data
    );
    return response;
  };
  const importDriverUpload = async (data: any) => {
    let response = await axiosInstance.post(
      baseURL + `${url}${uploadFileUrl}`,
      data
    );
    return response;
  };

  return {
    getDriverList,
    getDriverCount,
    assignDriver,
    saveDriver,
    importDriverUpload,
    updateDriver,
  };
};

export default useLoginAPI;
