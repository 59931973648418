import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AllOrders from "../../components/AllOrders/AllOrders";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import DriverManagement from "../../components/DriverManagement/DriverManagement";
import MyAddress from "../../components/MyProfileComponents/MyAddress/MyAddress";
import MyAppointment from "../../components/MyProfileComponents/MyAppointment/MyAppointment";
import MyFavorite from "../../components/MyProfileComponents/MyFavorite/MyFavorite";
import MyOrders from "../../components/MyProfileComponents/MyOrders/MyOrders";
import MyProfileDetails from "../../components/MyProfileComponents/MyProfileDetails/MyProfileDetails";
import MyVehicles from "../../components/MyProfileComponents/MyVehicles/MyVehicles";
import MyWallet from "../../components/MyProfileComponents/MyWallet/MyWallet";
import RateCard from "../../components/RateCard/RateCard";
import StaffManagement from "../../components/StaffManagement/StaffManagement";
import { setLoginType, setShowLoginDialog } from "../../redux/dialogSlice";
import { logOutUserFunc } from "../../redux/userState";
import "./MyProfile.css";

const MyProfile = () => {
  const basePath = process.env.REACT_APP_BASE_PATH;
  const corporateAdminRole = "ROLE_CORPORATE_ADMIN";
  const corporateUserRole = "ROLE_CORPORATE_USER";
  const customerRole = "ROLE_CUSTOMER";
  const fleetAdminRole = "ROLE_FLEET_OWNER";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userRole, setUserRole] = useState("") as any;
  const [visible, setVisible] = useState(false);
  const [activeView, setActiveView] = useState("myProfileDetails");

  const viewComponentMap: any = {
    myProfileDetails: MyProfileDetails,
    myAddress: MyAddress,
    myVehicles: MyVehicles,
    myWallet: MyWallet,
    myFavorite: MyFavorite,
    myAppointment: MyAppointment,
    myOrders: MyOrders,
    allOrders: AllOrders,
    staffManagement: StaffManagement,
    driverManagement: DriverManagement,
    rateCard: RateCard,
  };

  // const userSidebarOptions = [
  //   { view: "myProfileDetails", label: "My Profile" },
  //   { view: "myAddress", label: "My Address" },
  //   { view: "myVehicles", label: "My Vehicles" },
  //   { view: "myWallet", label: "My Wallet" },
  //   { view: "myFavorite", label: "My Favorite" },
  //   { view: "myOrders", label: "My Orders" },
  //   { view: "myAppointment", label: "My Appointment" },
  // ];

  const userSidebarOptions = [
    { view: "myProfileDetails", label: "My Profile" },
    ...(userRole &&
    userRole.code !== corporateAdminRole &&
    userRole.code !== corporateUserRole
      ? [{ view: "myAddress", label: "My Address" }]
      : []),
    { view: "myVehicles", label: "My Vehicles" },
    ...((userRole && userRole.code === corporateAdminRole) ||
    userRole.code === customerRole ||
    userRole.code === fleetAdminRole
      ? [{ view: "myWallet", label: "My Wallet" }]
      : []),
    { view: "myFavorite", label: "My Favorite" },
    { view: "myOrders", label: "My Orders" },
    { view: "myAppointment", label: "My Appointment" },
  ];

  const adminSidebarOptions = [
    { view: "allOrders", label: "All Orders" },
    { view: "staffManagement", label: "Staff Management" },
    ...(userRole && userRole.code !== corporateAdminRole
      ? [{ view: "driverManagement", label: "Driver Management" }]
      : []),
    // { view: "driverManagement", label: "Driver Management" },
    { view: "rateCard", label: "Rate Card" },
  ];

  const handleViewChange = useCallback((view: string) => {
    setActiveView(view);
  }, []);

  useEffect(() => {
    const authToken: any = JSON.parse(
      localStorage.getItem("authTokens") || "{}"
    );
    // const userRole = authToken?.user?.roles.find(
    //   (role: any) => role.code === corporateAdminRole
    // );
    const userRole = authToken?.user?.roles[0];
    if (userRole) setUserRole(userRole);
  }, []);

  const logOutFunc = () => {
    localStorage.clear();
    dispatch(logOutUserFunc());
    dispatch(setLoginType(""));
    dispatch(setShowLoginDialog(false));
    setTimeout(() => {
      navigate(`${basePath}`);
    }, 300);
  };

  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      <Button label="Yes" icon="pi pi-check" onClick={logOutFunc} autoFocus />
    </div>
  );

  const renderActiveView = () => {
    const ActiveComponent = viewComponentMap[activeView];
    return <ActiveComponent />;
  };

  return (
    <div>
      <div className="my-profile-head-wrapper">
        <Container>
          <BreadCrumbs />
          <div className="my-profile-title">My Profile</div>
        </Container>
        <div>
          <Container className="my-profile-container">
            <Row>
              <Col md={3} className="my-profile-sidebar">
                {userSidebarOptions.map((option) => (
                  <div
                    key={option.view}
                    className={`my-profile-sidebar-option cursor-pointer ${
                      activeView === option.view ? "active-text" : ""
                    }`}
                    onClick={() => handleViewChange(option.view)}
                  >
                    {option.label}
                  </div>
                ))}
                <div className="logout-wrapper my-profile-sidebar-option">
                  <div
                    className="logout-button"
                    onClick={() => setVisible(true)}
                  >
                    {/* <FaPowerOff /> Logout */}
                    Logout
                  </div>
                </div>
                {(userRole.code === corporateAdminRole ||
                  userRole.code === fleetAdminRole) && (
                  <div className="my-profile-sidebar-wrapper">
                    <div className="my-profile-sidebar-title">For Admin</div>
                    {adminSidebarOptions.map((option) => (
                      <div
                        key={option.view}
                        className={`my-profile-sidebar-option cursor-pointer ${
                          activeView === option.view ? "active-text" : ""
                        }`}
                        onClick={() => handleViewChange(option.view)}
                      >
                        {option.label}
                      </div>
                    ))}
                  </div>
                )}
                {/* <div className="logout-wrapper my-profile-sidebar-option">
                  <div
                    className="logout-button"
                    onClick={() => setVisible(true)}
                  >
                    Logout
                  </div>
                </div> */}
              </Col>
              <Col md={9} className="my-profile-content">
                {renderActiveView()}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Dialog
        header={""}
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
        className="logout-dialog"
      >
        Are you sure you want to logout?
      </Dialog>
    </div>
  );
};

export default MyProfile;
